import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { validateUpdateTenantSource } from '@/features/domain/gps'
import { type AppDispatch } from '@/store'

import { useTexts } from '../useTexts'

export function createLinxupFormValidation(
  tenantSourceId: string,
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'credentialsValidator',
      fields: 'credentials',
      validator: async formApi => {
        const { value: wrongCredentials } = formApi.getField('wrongCredentials')
        const { value: credentials } = formApi.getField('credentials')

        const credentialsRequiredError: FormErrors = {
          id: 'credentialsRequired',
          field: 'credentials',
          message: texts.linxup.errors.apiKeyRequired,
        }

        const empty = credentials.some(v => v.value.trim() === '')

        const wrongCredentialsError: FormErrors = {
          id: 'wrongCredentialsError',
          field: 'credentials',
          message: texts.linxup.errors.apiKeyIncorrect,
        }

        const wrongApiKey = wrongCredentials === true

        if (empty || wrongApiKey) {
          return {
            [credentialsRequiredError.id]: empty ? credentialsRequiredError : null,
            [wrongCredentialsError.id]: wrongApiKey ? wrongCredentialsError : null,
            credentialsAlreadyInUse: null,
          }
        }

        const response = await dispatch(
          validateUpdateTenantSource({
            tenantSourceId,
            credentials,
          }),
        )

        if (validateUpdateTenantSource.rejected.match(response)) {
          const errorCode =
            response.payload?.type === 'rpcFailure' ? response.payload.error.errorCode : undefined

          if (errorCode === 101) {
            // An integration to this account already exist
            return {
              [credentialsRequiredError.id]: null,
              [wrongCredentialsError.id]: null,
              credentialsAlreadyInUse: {
                id: 'credentialsAlreadyInUse',
                field: 'credentials',
                message: texts.linxup.errors.apiKeyAlreadyUsed,
              },
            }
          }

          throw new Error(response.payload?.message ?? 'Internal error')
        }

        return {
          [credentialsRequiredError.id]: null,
          [wrongCredentialsError.id]: null,
          credentialsAlreadyInUse: null,
        }
      },
    },
  ]
}
