import type { PreferencesViewProps as Props } from '../types'

import { Box } from '@mui/material'
import { FormHeader } from '@workwave-tidal/form-ui/layout'
import { ViewContainer } from '@/formUi'

import { useTexts } from '../hooks/useTexts'
import { useCreateFormatLanguage } from '../hooks/useCreateFormatLanguage'

import { Actions } from './components/Actions'
import { Field } from './components/Field'

export function PreferencesView(props: Props) {
  const { onEdit, formValues, supportedLanguages } = props

  const texts = useTexts()
  const formatLanguage = useCreateFormatLanguage(supportedLanguages)

  return (
    <ViewContainer testid="preferences-view">
      <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />
      <Box paddingLeft={4} paddingRight={4}>
        <Actions onEdit={onEdit} />

        <Field label={texts.notificationLanguage}>
          {formatLanguage(formValues.notificationLanguage)}
        </Field>

        <Field label={texts.companyFromName}>{formValues.companyFromName}</Field>

        <Field label={texts.timeFormat}>{texts.timeFormatExample(formValues.timeFormat)}</Field>

        <Field label={texts.dateFormat}>{texts.dateFormatExample(formValues.dateFormat)}</Field>
      </Box>
    </ViewContainer>
  )
}
