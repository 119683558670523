import { getCalendarizedSettings } from '@/server-data'
import { ModalData, MovePairedOrdersPayload } from '../typings'

export const computeModalDataValues = (
  payload: MovePairedOrdersPayload,
  date: string,
  isExecuted: boolean,
  gotExecutedPaired: boolean,
  order?: uui.domain.client.rm.Order,
  orderStep?: uui.domain.client.rm.OrderStep,
  pairedOrderStep?: uui.domain.client.rm.OrderStep,
  vehicle?: uui.domain.client.rm.Vehicle,
  pairedRoute?: uui.domain.client.rm.Route,
  pairedVehicle?: uui.domain.client.rm.Vehicle,
): ModalData => {
  if (!orderStep || !order || !pairedOrderStep) {
    return {
      issue: 'orderStepMissing',
    }
  }
  if (!vehicle) {
    return {
      issue: 'routeMissing',
    }
  }

  const settings = getCalendarizedSettings(vehicle, date)
  if (!settings.available) {
    return {
      issue: 'routeUnavailable',
    }
  }

  const pairedOrderStepLocked =
    pairedRoute && pairedVehicle ? pairedVehicle.lockedDates.includes(pairedRoute.date) : false

  const pairedOrderStepUnassigned = !pairedRoute

  const orderStepStatus = isExecuted
    ? ('executed' as const)
    : orderStep.trackingData.status === 'reschedule'
      ? ('not-completed' as const)
      : ('not-executed' as const)

  const pairedOrderStepStatus = pairedOrderStepLocked
    ? ('in-a-locked-route' as const)
    : pairedOrderStepUnassigned
      ? ('not-assigned' as const)
      : pairedOrderStep.trackingData.status === 'done'
        ? ('executed' as const)
        : pairedOrderStep.trackingData.status === 'reschedule'
          ? ('not-completed' as const)
          : ('not-executed' as const)

  return {
    issue: 'none',
    pairedOrderStepUnassigned,
    pairedOrderStepLocked,
    orderStep,
    pairedOrderStep,
    order,
    payload,
    isExecuted,
    gotExecutedPaired,
    orderStepStatus,
    pairedOrderStepStatus,
  }
}
