import { useMemo, useRef } from 'react'

import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { Text } from '@/local/components'
import { useComponentSize } from '@/local/hooks'

import { Stack } from '@mui/material'

import { RenderFrom } from '../../../../components/RenderFrom'
import { StatsContainer } from './StatsContainer'
import { useTexts } from './useTexts'

interface Props {
  extOrders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function Bulk(props: Props) {
  const { extOrders } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const texts = useTexts()
  const ordersData = useMemo(
    () =>
      extOrders.reduce(
        (acc, o) => {
          // Accumulate service time
          acc.totalServiceTimeInSeconds += o.orderStep.serviceTimeSec

          // Accumulate completed orders
          if (!o.isUnassigned && o.hasBeenExecuted) acc.completedOrders++

          return acc
        },
        { completedOrders: 0, totalServiceTimeInSeconds: 0 },
      ),
    [extOrders],
  )

  return (
    <StatsContainer ref={ref}>
      <RenderFrom width={width} renderFrom={150}>
        <Stack
          direction="column"
          height="100%"
          justifyContent="space-between"
          width="100%"
          flexShrink={0}
        >
          <Stack direction="row" height="auto">
            <Text size="$p3">
              {texts.totalServiceTime}:{' '}
              <FormatSeconds seconds={ordersData.totalServiceTimeInSeconds} />
            </Text>
          </Stack>

          <Stack direction="row" height="auto">
            <Text size="$p3">{`${texts.status}:`}&nbsp;</Text>
            <Text size="$p3" weight="$semiBold">
              {`${ordersData.completedOrders} / ${extOrders.length} ${texts.done}`}
            </Text>
          </Stack>
        </Stack>
      </RenderFrom>
    </StatsContainer>
  )
}
