import type { FormErrors, FormFields } from '../../formFields'
import { useMemo } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'
import { RadioGroup } from '@workwave-tidal/form-ui'
import { useTexts } from './hooks/useTexts'

type Option = {
  label: string
  value: 'ON' | 'OFF'
}

export function AutomatedMessageEnabledField() {
  const texts = useTexts()
  const options: Option[] = useMemo(
    () => [
      { label: texts.automatedMessageEnabled.values.on, value: 'ON' },
      { label: texts.automatedMessageEnabled.values.off, value: 'OFF' },
    ],
    [texts],
  )
  const formApi = useFormApi<FormFields, FormErrors>()
  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  return (
    <RadioGroup<'automatedMessageEnabled', 'ON' | 'OFF', FormFields, FormErrors>
      options={options}
      name="automatedMessageEnabled"
      validateOn="blur change"
      label={texts.automatedMessageEnabled.label}
      disabled={formDisabled}
    />
  )
}
