import type { MouseEvent } from 'react'
import type { RenderItemProps } from '@workwave-tidal/core/components/VirtualList'
import type { DriverWithVehicleAndDistance } from '../../../../../typings'

import { useCallback } from 'react'

import { Box } from '@mui/material'
import { useGetDistanceUnitOfMeasure, useFormatNumber } from '@/hooks'
import { Radio } from '@/components/primitives/Radio'
import { FlexBox, InvisibleButton, Text } from '@/local/components'

type Props = RenderItemProps<DriverWithVehicleAndDistance>

const radioStyle = { marginTop: 4 }

export function DriverListItem(props: Props) {
  const { item, onClick, selected } = props

  const vehicleId = item.vehicle.id
  const activeVehicle = vehicleId === item.id
  const unitOfMeasure = useGetDistanceUnitOfMeasure()
  const formattedDistance = useFormatNumber(typeof item.distance === 'number' ? item.distance : 0)

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick({ item, event })
    },
    [onClick, item],
  )

  return (
    <InvisibleButton
      data-testid="send-message-to-driver-list-item"
      disabled={activeVehicle}
      onClick={handleOnClick}
      style={{ width: 'calc(100% - 12px)' }}
      key={item.id}
    >
      <FlexBox w="100%" paddingBottom={8} vAlignContent="top">
        <Radio checked={selected} disabled={activeVehicle} style={radioStyle} />

        <FlexBox column grow>
          <Text size="$h3" weight="$semiBold" color="$nightRider" marginLeft={5} ellipsis>
            {item.driver.name}
          </Text>

          <Text size="$p3" weight="$regular" color="$silver" marginLeft={5} ellipsis>
            {item.vehicle.vehicle.externalId}
          </Text>
        </FlexBox>

        <Box display="flex" flexShrink={0}>
          <Text size="$p3" color="$silver" marginTop={4}>
            {typeof item.distance === 'number' ? `${formattedDistance} ${unitOfMeasure}` : '-'}
          </Text>
        </Box>
      </FlexBox>
    </InvisibleButton>
  )
}
