import { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { formState } from '../../formState'
import { DriverSelection } from './DriverSelection'
import { useDropdownOptionsByVehicleId } from './hooks/useDropdownOptionsByVehicleId'

interface Props {
  submitting: boolean
  initialDriverAssignments: Record<string, string | null>
}

export function AssignmentsField(props: Props) {
  const [assignments, setAssignments] = useState<Record<string, string | null>>(
    props.initialDriverAssignments,
  )

  const optionsByVehicleId = useDropdownOptionsByVehicleId(assignments)

  const formApi = formState.useFormApi()

  useEffect(() => {
    formApi.change('assignments', assignments)
  }, [assignments, formApi])

  const onChange = useCallback((selectedDriverId: string | null, vehicleId: string) => {
    setAssignments(prevAssignments => {
      const updatedValue = { ...prevAssignments }

      // look for occurrences of the driverId before updating the value
      for (const [vId, dId] of Object.entries(updatedValue)) {
        if (dId === selectedDriverId) {
          updatedValue[vId] = null
          break
        }
      }

      // set the updated driverId to the vehicleId
      updatedValue[vehicleId] = selectedDriverId

      return updatedValue
    })
  }, [])

  return (
    <Box px={4}>
      {optionsByVehicleId &&
        Object.values(optionsByVehicleId).map(data => (
          <Box key={data.vehicleId} width="100%" mb={3}>
            <DriverSelection
              id={data.vehicleId}
              value={data.value}
              label={data.label}
              options={data.options}
              submitting={props.submitting}
              color={data.color}
              hasDevice={data.hasDevice}
              onChange={onChange}
            />
          </Box>
        ))}
    </Box>
  )
}
