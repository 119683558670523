import { useCallback } from 'react'

export function useContextualMenuActions(
  setGroupBy: (by: 'dateAsString' | 'vehicleId') => void,
  setUnitMode: (unit: 'percentage' | 'absolute') => void,
  setApprovedRoutesVisible: (visible: boolean) => void,
  setShowUnavailableRoutes: (show: boolean) => void,
  toggleSelectAll: () => void,
  toggleExpandCollapseAll: () => void,
  setOpen: (value: boolean) => void,
  removeUnavailableRoutesFromSelection: () => void,
  editColumns: () => void,
) {
  const onGroupByChange = useCallback(
    (_, value: 'dateAsString' | 'vehicleId') => {
      setGroupBy(value)
    },
    [setGroupBy],
  )

  const onUnitModeChange = useCallback(
    (_event: any, value: 'percentage' | 'absolute') => {
      setUnitMode(value)
    },
    [setUnitMode],
  )

  const onShowApprovedRoutesChange = useCallback(
    (_event: any, value: boolean) => {
      setApprovedRoutesVisible(value)
    },
    [setApprovedRoutesVisible],
  )

  const onShowUnavailableRoutesChange = useCallback(
    (_event: any, value: boolean) => {
      // If we're hiding unavailable routes we should remove them from the selection
      if (value === false) {
        removeUnavailableRoutesFromSelection()
      }

      setShowUnavailableRoutes(value)
    },
    [setShowUnavailableRoutes, removeUnavailableRoutesFromSelection],
  )

  const onSelectAll = useCallback(() => {
    toggleSelectAll()
    setOpen(false)
  }, [toggleSelectAll, setOpen])

  const onExpandCollapseAll = useCallback(() => {
    toggleExpandCollapseAll()
    setOpen(false)
  }, [toggleExpandCollapseAll, setOpen])

  const onEditColumns = useCallback(() => {
    editColumns()
    setOpen(false)
  }, [editColumns, setOpen])

  return {
    onSelectAll,
    onExpandCollapseAll,
    onEditColumns,
    onGroupByChange,
    onUnitModeChange,
    onShowApprovedRoutesChange,
    onShowUnavailableRoutesChange,
  }
}
