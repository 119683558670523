import type {
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/form-fairy'
import type { BulkFieldValue } from '../../../types'
import type { BulkTimeWindowsError } from './types'

import { Stack } from '@mui/material'
import { useFormField } from '@workwave-tidal/form-fairy'

import { FieldHeader } from '../../FieldHeader'

import { AddTimeWindowAction } from './components/AddTimeWindowAction'
import { Menu } from './components/Menu'
import { Mixed } from './components/Mixed'
import { EditTimeWindows } from './components/EditTimeWindows'
import { useActions } from './hooks/useActions'

type RequiredFormField = BulkFieldValue<[uui.domain.rm.TimeWindow?, uui.domain.rm.TimeWindow?]>

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends BulkTimeWindowsError = BulkTimeWindowsError,
> = {
  label: string
  name: FIELD_NAME
  testId?: string
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkTimeWindows<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends BulkTimeWindowsError = BulkTimeWindowsError,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const { label, name, testId = 'bulk-timeWindows-root', ...options } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field,
    errors,
    fieldApi: { validate },
    formApi,
  } = useFormField<FIELD_NAME, PartialForm, BulkTimeWindowsError>(
    name,
    options as UseFormFieldOptions<PartialForm, BulkTimeWindowsError>,
  )

  const { disabled, visible, value: fieldValue } = field

  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled
  const actions = useActions<FIELD_NAME, FIELDS, ERROR>(name)

  const addTimeWindowDisabled =
    fieldValue.status === 'mixed' ||
    (fieldValue.status === 'exact' && fieldValue.value.length === 2)

  const deleteAllTimeWindowsDisabled =
    fieldValue.status === 'exact' && fieldValue.value.length === 0

  if (!visible) return null

  return (
    <Stack data-testid={testId ?? ''}>
      <FieldHeader
        label={label}
        action1={
          <AddTimeWindowAction
            onClick={actions.onAddTimeWindow}
            disabled={addTimeWindowDisabled}
            testId={`${testId}_add-time-window`}
          />
        }
        action2={
          <Menu
            onDeleteAllTimeWindows={actions.onDeleteAll}
            deleteAllTimeWindowsDisabled={deleteAllTimeWindowsDisabled}
          />
        }
      />
      {fieldValue.status === 'mixed' && (
        <Mixed
          onDeleteAllTimeWindows={actions.onDeleteAll}
          deleteAllTimeWindowsDisabled={deleteAllTimeWindowsDisabled}
        />
      )}
      {fieldValue.status === 'exact' && (
        <EditTimeWindows
          onUpdateTimeWindow={actions.onUpdateTimeWindow}
          onRemoveTimeWindow={actions.onRemoveTimeWindow}
          validate={validate}
          value={fieldValue.value}
          disabled={fieldDisabled}
          errors={errors}
        />
      )}
    </Stack>
  )
}
