import { styled, Button } from '@mui/material'
import { theme } from '@/styles'

interface ButtonContainerProps {
  active?: boolean
  disabled?: boolean
}

export const ButtonContainer = styled(Button, { name: 'ButtonContainer' })<ButtonContainerProps>(
  ({ active }) => ({
    display: 'block',
    borderWidth: 0,
    borderRadius: 0,
    background: 'transparent',
    padding: 0,
    position: 'relative',
    outline: 0,
    height: '42px',
    '&:disabled': {
      cursor: 'default',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-4px',
      bottom: '-2px',
      left: 0,
      width: '100%',
      opacity: active ? 1 : 0,
      background: theme.colors.$whiteSmoke,
      borderRadius: '7px',
      transition: 'transform 0.3s ease 0s, opacity',
      transform: `scale(${active ? 1 : 0})`,
    },
  }),
)
