import OlMap from 'ol/Map'
import FullScreen from 'ol/control/FullScreen'

export const registerFullScreenControl = (map: OlMap) => {
  const uniqueId = 'rm-fullscreen'
  const controls = map.getControls()

  if (controls.get(uniqueId)) return

  const fullscreenControl = new FullScreen({ source: 'map-viewport' })
  fullscreenControl.set('uid', uniqueId)
  map.addControl(fullscreenControl)

  setTimeout(() => {
    const fullscreenElement = fullscreenControl
      .getMap()
      ?.getOverlayContainerStopEvent()
      ?.querySelector('.ol-full-screen')?.firstChild as HTMLElement

    if (fullscreenElement) {
      fullscreenElement.setAttribute('data-testid', 'main-map-control-full-screen-button')
      fullscreenElement.setAttribute('data-trackid', 'main-map-control-full-screen-button')
    }
  }, 300)

  controls.set(uniqueId, fullscreenControl)
}
