import { Entry } from '../Entry'

export function Entry20250123() {
  return (
    <Entry title="2025 Jan 23 - v3.10.1">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/17338/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
