import type {
  FormFieldDescriptor,
  MappedFormState,
  NarrowFieldsByValueType,
  TidalFormState,
} from '@workwave-tidal/core/form'
import {
  Divider as BaseDivider,
  type DividerProps as BaseDividerProps,
} from '../components/Divider'
import {
  Fieldset as BaseFieldset,
  type FieldsetProps as BaseFieldsetProps,
} from '../components/Fieldset'
import { Form as BaseForm, type FormProps as BaseFormProps } from '../components/Form'
import {
  SectionTitle as BaseSectionTitle,
  type SectionTitleProps as BaseSectionTitleProps,
} from '../components/SectionTitle'
import {
  ColorPicker as BaseColorPicker,
  type ColorPickerProps as BaseColorPickerProps,
  type ColorPickerValue,
} from '../fields/ColorPicker'
import {
  CurrencyInput as BaseCurrencyInput,
  type CurrencyInputProps as BaseCurrencyInputProps,
  type CurrencyInputValue,
} from '../fields/CurrencyInput'
import {
  DrawAreaField as BaseDrawAreaField,
  type DrawAreaFieldProps as BaseDrawAreaFieldProps,
  type DrawAreaFieldValue,
} from '../fields/DrawAreaField'
import {
  DrawRoadSegmentField as BaseDrawRoadSegmentField,
  type DrawRoadSegmentFieldProps as BaseDrawRoadSegmentFieldProps,
  type DrawRoadSegmentFieldValue,
} from '../fields/DrawRoadSegmentField'
import {
  DurationInput as BaseDurationInput,
  type DurationInputProps as BaseDurationInputProps,
  type DurationInputValue,
} from '../fields/DurationInput'

interface FormStateRmAdapter<State extends TidalFormState> {
  ColorPicker: <
    TargetField extends NarrowFieldsByValueType<
      AcceptedFormValue,
      State,
      FormFieldDescriptor<State>
    >,
    AcceptedFormValue = ColorPickerValue,
  >(
    props: BaseColorPickerProps<TargetField, State, AcceptedFormValue>,
  ) => JSX.Element
  CurrencyInput: <
    TargetField extends NarrowFieldsByValueType<
      AcceptedFormValue,
      State,
      FormFieldDescriptor<State>
    >,
    AcceptedFormValue = CurrencyInputValue,
  >(
    props: BaseCurrencyInputProps<TargetField, State, AcceptedFormValue>,
  ) => JSX.Element
  DurationInput: <
    TargetField extends NarrowFieldsByValueType<
      AcceptedFormValue,
      State,
      FormFieldDescriptor<State>
    >,
    AcceptedFormValue = DurationInputValue,
  >(
    props: BaseDurationInputProps<TargetField, State, AcceptedFormValue>,
  ) => JSX.Element
  DrawAreaField: <
    TargetField extends NarrowFieldsByValueType<
      AcceptedFormValue,
      State,
      FormFieldDescriptor<State>
    >,
    AcceptedFormValue = DrawAreaFieldValue,
  >(
    props: BaseDrawAreaFieldProps<TargetField, State, AcceptedFormValue>,
  ) => JSX.Element
  DrawRoadSegmentField: <
    TargetField extends NarrowFieldsByValueType<
      AcceptedFormValue,
      State,
      FormFieldDescriptor<State>
    >,
    AcceptedFormValue = DrawRoadSegmentFieldValue,
  >(
    props: BaseDrawRoadSegmentFieldProps<TargetField, State, AcceptedFormValue>,
  ) => JSX.Element
  Form: <State extends TidalFormState, ReturnedData = unknown>(
    props: BaseFormProps<State, ReturnedData>,
  ) => JSX.Element
  Fieldset: (props: BaseFieldsetProps) => JSX.Element
  Divider: (props: BaseDividerProps) => JSX.Element
  SectionTitle: (props: BaseSectionTitleProps) => JSX.Element
}

export type RmAdapterMappedState<NS extends string, State extends TidalFormState> = MappedFormState<
  NS,
  FormStateRmAdapter<State>
>

export function setupFormStateRmAdapter<State extends TidalFormState>(): RmAdapterMappedState<
  '',
  State
> {
  // ---------------------------------------------------
  // ATTENTION: the type assertion won't enforce that the mapped keys are correct. Any key will be accepted.
  // The typed object will be correct, but the concrete implementation will not be enforced.
  // ---------------------------------------------------
  return {
    ColorPicker: function ColorPicker<
      TargetField extends NarrowFieldsByValueType<
        AcceptedFormValue,
        State,
        FormFieldDescriptor<State>
      >,
      AcceptedFormValue = ColorPickerValue,
    >(props: BaseColorPickerProps<TargetField, State, AcceptedFormValue>) {
      return <BaseColorPicker<TargetField, State, AcceptedFormValue> {...props} />
    },
    CurrencyInput: function CurrencyInput<
      TargetField extends NarrowFieldsByValueType<
        AcceptedFormValue,
        State,
        FormFieldDescriptor<State>
      >,
      AcceptedFormValue = CurrencyInputValue,
    >(props: BaseCurrencyInputProps<TargetField, State, AcceptedFormValue>) {
      return <BaseCurrencyInput<TargetField, State, AcceptedFormValue> {...props} />
    },
    DurationInput: function DurationInput<
      TargetField extends NarrowFieldsByValueType<
        AcceptedFormValue,
        State,
        FormFieldDescriptor<State>
      >,
      AcceptedFormValue = DurationInputValue,
    >(props: BaseDurationInputProps<TargetField, State, AcceptedFormValue>) {
      return <BaseDurationInput<TargetField, State, AcceptedFormValue> {...props} />
    },
    DrawAreaField: function DrawAreaField<
      TargetField extends NarrowFieldsByValueType<
        AcceptedFormValue,
        State,
        FormFieldDescriptor<State>
      >,
      AcceptedFormValue = DrawAreaFieldValue,
    >(props: BaseDrawAreaFieldProps<TargetField, State, AcceptedFormValue>) {
      return <BaseDrawAreaField<TargetField, State, AcceptedFormValue> {...props} />
    },
    DrawRoadSegmentField: function DrawRoadSegmentField<
      TargetField extends NarrowFieldsByValueType<
        AcceptedFormValue,
        State,
        FormFieldDescriptor<State>
      >,
      AcceptedFormValue = DrawRoadSegmentFieldValue,
    >(props: BaseDrawRoadSegmentFieldProps<TargetField, State, AcceptedFormValue>) {
      return <BaseDrawRoadSegmentField<TargetField, State, AcceptedFormValue> {...props} />
    },
    Form: function Form<State extends TidalFormState, ReturnedData = unknown>(
      props: BaseFormProps<State, ReturnedData>,
    ) {
      return <BaseForm<State, ReturnedData> {...props} />
    },
    Fieldset: function Fieldset(props: BaseFieldsetProps) {
      return <BaseFieldset {...props} />
    },
    Divider: function Divider(props: BaseDividerProps) {
      return <BaseDivider {...props} />
    },
    SectionTitle: function SectionTitle(props: BaseSectionTitleProps) {
      return <BaseSectionTitle {...props} />
    },
  } as RmAdapterMappedState<'', State>
}
