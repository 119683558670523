import type { FormError, FormField } from '@workwave-tidal/form-fairy'
export type TimeRangeType = 'live' | 'custom'

const typeField: FormField<TimeRangeType> = {
  required: false,
  value: 'live',
}

const timeRangeStart: FormField<number> = {
  required: false,
  value: -1,
}

const timeRangeEnd: FormField<number> = {
  required: false,
  value: -1,
}

export const formFields = {
  type: typeField,
  timeRangeEnd: timeRangeEnd,
  timeRangeStart: timeRangeStart,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
