import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { validateAddTenantSource } from '@/features/domain/gps'
import { type AppDispatch, store } from '@/store'

import { useTexts } from '../useTexts'

export function createAzugaFormValidation(
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'labelValidator',
      fields: 'label',
      validator: formApi => {
        const state = store.getState()

        const { tenantSources } = state.domain.publicData.domain.gps.telematics
        const { value } = formApi.getField('label')

        const labelRequiredError: FormErrors = {
          id: 'labelRequiredError',
          field: 'label',
          message: texts.azuga.errors.labelRequired,
        }

        const empty = value.trim() === ''

        const labelUniqueError: FormErrors = {
          id: 'uniqueLabelError',
          field: 'label',
          message: texts.azuga.errors.labelUnique,
        }

        const unique = Object.values(tenantSources).every(source => source.label !== value)

        return {
          [labelRequiredError.id]: empty ? labelRequiredError : null,
          [labelUniqueError.id]: !unique ? labelUniqueError : null,
        }
      },
    },
    {
      id: 'credentialsValidator',
      fields: 'credentials',
      validator: async formApi => {
        const { value: wrongCredentials } = formApi.getField('wrongCredentials')
        const { value: credentials } = formApi.getField('credentials')
        const { value: sourceId } = formApi.getField('sourceId')
        const { value: label } = formApi.getField('label')

        const credentialsRequiredError: FormErrors = {
          id: 'credentialsRequired',
          field: 'credentials',
          message: texts.azuga.errors.apiKeyRequired,
        }

        const empty = credentials.some(v => v.value.trim() === '')

        const wrongCredentialsError: FormErrors = {
          id: 'wrongCredentialsError',
          field: 'credentials',
          message: texts.azuga.errors.apiKeyIncorrect,
        }

        const wrongApiKey = wrongCredentials === true

        if (empty || wrongApiKey) {
          return {
            [credentialsRequiredError.id]: empty ? credentialsRequiredError : null,
            [wrongCredentialsError.id]: wrongApiKey ? wrongCredentialsError : null,
            credentialsAlreadyInUse: null,
          }
        }

        const response = await dispatch(
          validateAddTenantSource({
            credentials,
            sourceId,
            label,
          }),
        )

        if (validateAddTenantSource.rejected.match(response)) {
          const errorCode =
            response.payload?.type === 'rpcFailure' ? response.payload.error.errorCode : undefined

          if (errorCode === 101) {
            // An integration to this account already exist
            return {
              [credentialsRequiredError.id]: null,
              [wrongCredentialsError.id]: null,
              credentialsAlreadyInUse: {
                id: 'credentialsAlreadyInUse',
                field: 'credentials',
                message: texts.azuga.errors.apiKeyAlreadyUsed,
              },
            }
          }

          throw new Error(response.payload?.message ?? 'Internal error')
        }

        return {
          [credentialsRequiredError.id]: null,
          [wrongCredentialsError.id]: null,
          credentialsAlreadyInUse: null,
        }
      },
    },
  ]
}
