import type { FormFields, FormErrors } from '../../formFields'

import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from './useTexts'

export function Email() {
  const texts = useTexts()

  return (
    <TextField<'email', FormFields, FormErrors>
      name="email"
      testId="email"
      label={texts.email}
      validateOn="blur change"
    />
  )
}
