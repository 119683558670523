import { type Dispatch, useCallback } from 'react'
import type { DurationInputAction } from '../../types'

export function useClickAwayProps(dispatch: Dispatch<DurationInputAction>) {
  const onClickAway = useCallback(() => {
    dispatch({ type: 'clickAway:click' })
  }, [dispatch])

  return {
    onClickAway,
  }
}
