import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

import { useAppDispatch } from '@/store'
import { useValidateEmail } from '@/hooks'

import { createValidateLoads } from './createValidateLoads'
import { createValidateTimeWindows } from './createValidateTimeWindows'
import { createValidateServiceTimeSec } from './createValidateServiceTimeSec'
import { createValidateEligibility } from './createValidateEligibility'
import { createValidatePhone } from './createValidatePhone'
import { createValidateEmail } from './createValidateEmail'

type Dispatch = ReturnType<typeof useAppDispatch>
type ValidateEmail = ReturnType<typeof useValidateEmail>

export function createValidations(
  texts: ValidationTexts,
  workingDayStartSec: number,
  dispatch: Dispatch,
  validateEmail: ValidateEmail,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'loads',
      fields: 'loads',
      validator: createValidateLoads(texts),
    },
    {
      id: 'serviceTimeSec',
      fields: 'serviceTimeSec',
      validator: createValidateServiceTimeSec(texts),
    },
    {
      id: 'timeWindows',
      fields: 'timeWindows',
      validator: createValidateTimeWindows(texts, workingDayStartSec),
    },
    {
      id: 'eligibility',
      fields: 'eligibility',
      validator: createValidateEligibility(texts),
    },
    {
      id: 'phone',
      fields: 'phone',
      validator: createValidatePhone(dispatch, texts),
    },
    {
      id: 'email',
      fields: 'email',
      validator: createValidateEmail(texts, validateEmail),
    },
  ]
}
