import { ViewContainer, ViewContent, ViewField, ViewFieldsLayout, ViewHeader } from '@/formUi'

import { Box, Typography } from '@mui/material'

import { useBorderRestriction } from './hooks/useBorderRestriction'
import { useTexts } from './hooks/useTexts'
import { useTimezone } from './hooks/useTimezone'

export function Territory() {
  const texts = useTexts()
  const timezone = useTimezone()
  const borderRestriction = useBorderRestriction()
  const hasBorderRestriction = borderRestriction.code !== ''

  return (
    <ViewContainer testid="driver-activity-view">
      <ViewContent>
        <ViewHeader title={texts.title} subtitle={texts.description} />

        <ViewFieldsLayout>
          <ViewField label={texts.timezone}>{timezone}</ViewField>
        </ViewFieldsLayout>

        <ViewFieldsLayout>
          <Box pb={4} data-testid="border-restriction" data-trackid="border-restriction">
            <Typography variant="caption" color="textSecondary">
              {texts.borderRestrictionLabel}
            </Typography>

            <Typography variant="body1" color="textPrimary">
              {hasBorderRestriction ? borderRestriction.name : texts.noBorderRestriction}
            </Typography>

            {hasBorderRestriction && (
              <Typography variant="body2" color="textSecondary" fontSize={12}>
                {texts.borderRestrictionNote}
              </Typography>
            )}
          </Box>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
