import { Alert, Stack, Typography, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useTexts } from '../../../hooks/useTexts'

interface ListPointProps {
  id: string
}

function ListPoint(props: ListPointProps) {
  return (
    <li>
      <FormattedMessage
        id={props.id}
        values={{
          b: chunks => (
            <Typography variant="subtitle2" display="inline">
              {chunks}
            </Typography>
          ),
        }}
      />
    </li>
  )
}

export function InfoBanner() {
  const texts = useTexts()
  const theme = useTheme()

  return (
    <Alert severity="info" component="div">
      <Stack>
        <Typography variant="body2" fontWeight="bold" color="inherit">
          {texts.info.title}
        </Typography>
        <ul style={{ paddingLeft: theme.spacing(2), lineHeight: '143%' }}>
          <ListPoint id={texts.info.point1} />
          <ListPoint id={texts.info.point2} />
          <ListPoint id={texts.info.point3} />
          <ListPoint id={texts.info.point4} />
        </ul>
      </Stack>
    </Alert>
  )
}
