import { useMemo } from 'react'
import { SimpleSelect } from '@workwave-tidal/form-ui/fields'

import { type FormErrors, type FormFields } from '../formFields'
import { useTexts } from '../hooks/useTexts'

export function Type() {
  const texts = useTexts()
  const options = useMemo(
    () => [
      { value: 'planner', label: texts.labels.roles.planner, type: 'option' as const },
      { value: 'viewer', label: texts.labels.roles.viewer, type: 'option' as const },
      { value: 'guest', label: texts.labels.roles.guest, type: 'option' as const },
      { value: 'courier', label: texts.labels.roles.courier, type: 'option' as const },
    ],
    [texts],
  )

  return (
    <SimpleSelect<'type', string, FormFields, FormErrors>
      name="type"
      label={texts.labels.role}
      testId="type"
      options={options}
      validateOn="blur change"
    />
  )
}
