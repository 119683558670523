import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'

import { useTexts } from '../useTexts'

const companyFromNameMaxLength = 30

export const createValidateCompanyFromName =
  (texts: ReturnType<typeof useTexts>): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const field = formApi.getField('companyFromName')

    const errorMaxLength: FormError<keyof FormFields> = {
      id: 'companyFromName-max-length',
      field: 'companyFromName',
      message: texts.companyFromNameMaxLength(companyFromNameMaxLength),
      priority: 5,
    }

    const errorRequired: FormError<keyof FormFields> = {
      id: 'companyFromName-required',
      field: 'companyFromName',
      message: texts.companyFromNameRequired,
      priority: 8,
    }

    const invalidMaxLength = field.value.trim().length > companyFromNameMaxLength
    const empty = field.value.trim().length === 0

    return {
      'companyFromName-required': empty ? errorRequired : null,
      'companyFromName-max-length': invalidMaxLength ? errorMaxLength : null,
    }
  }
