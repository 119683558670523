import type { FormErrors, FormFields } from '../hooks/formFields'

import { Box } from '@mui/material'

import { FormRoot, useCreateForm } from '@workwave-tidal/form-fairy'
import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { formFields } from '../hooks/formFields'

import { Footer } from './Footer'
import { Header } from './Header'
import { Body } from './Body'

interface Props {
  placeIds: string[]
}

export function Content(props: Props) {
  const { placeIds } = props
  const { ready } = useConfigureController(placeIds)
  const formState = useCreateForm<FormFields, FormErrors>(`export-places-form`, formFields)

  if (!ready) return null

  return (
    <FormRoot formState={formState}>
      <ModalContent header={<Header />} footer={<Footer />}>
        <Box paddingTop={2}>
          <Body />
        </Box>
      </ModalContent>
    </FormRoot>
  )
}
