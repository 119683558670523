import { Separator } from '@/components/Separator'

import { NavigoContainer } from '../../elements/NavigoContainer'
import { useResetOnUnmount } from '../../hooks/useResetOnUnmount'
import { ExecutionDetails } from './views/ExecutionDetails'
import { MapStyle } from './views/MapStyle'
import { Overview } from './views/Overview'

import { Details } from './components/Details'
import { OrderStepsNavigator } from './components/OrderStepsNavigator'

import { useNavigoView } from './hooks/useNavigoView'

const testid = 'orderSteps-navigo'

export function OrderSteps() {
  const { view } = useNavigoView()
  useResetOnUnmount()

  return (
    <NavigoContainer data-testid={testid} data-trackid={testid}>
      <OrderStepsNavigator />
      <Details />
      <Separator />
      {view === 'overview' && <Overview />}
      {view === 'mapStyle' && <MapStyle />}
      {view === 'executionDetails' && <ExecutionDetails />}
    </NavigoContainer>
  )
}
