import type { DurationDisplayValue, DurationFieldValue, DurationFormat } from '../types'

export function formatValue(value: DurationFieldValue, format: DurationFormat): DurationDisplayValue {
  switch (format) {
    case 'hh':
      return formatHH(value)

    case 'mm':
      return formatMM(value)

    case 'ss':
      return formatSS(value)

    case 'hh:mm':
      return formatHHMM(value)

    case 'hh:mm:ss':
      return formatHHMMSS(value)
  }
}

function formatHH(value: DurationFieldValue): DurationDisplayValue {
  if (value === null) return ''
  const hours = Math.floor(value / 3600)
  return padTo2Digits(hours)
}

function formatSS(value: DurationFieldValue): DurationDisplayValue {
  if (value === null) return ''
  return padTo2Digits(value)
}

function formatMM(value: DurationFieldValue): DurationDisplayValue {
  if (value === null) return ''
  const minutes = Math.floor(value / 60)
  return padTo2Digits(minutes)
}

function formatHHMM(value: DurationFieldValue): DurationDisplayValue {
  if (value === null) return ''

  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value % 3600) / 60)

  const hh = padTo2Digits(hours)
  const mm = padTo2Digits(minutes)

  return `${hh}:${mm}`
}

function formatHHMMSS(value: DurationFieldValue): DurationDisplayValue {
  if (value === null) return ''

  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value % 3600) / 60)
  const secs = value % 60

  const hh = padTo2Digits(hours)
  const mm = padTo2Digits(minutes)
  const ss = padTo2Digits(secs)

  return `${hh}:${mm}:${ss}`
}

function padTo2Digits(value: number): string {
  return value.toString().padStart(2, '0')
}
