import type { IconContainerProps } from './typings'

import { IconContainer } from './elements/IconContainer'

export function DownloadKML(props: IconContainerProps) {
  return (
    <IconContainer {...props}>
      <svg viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2H15L20 7V20C20 21.1 19.1 22 18 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2ZM6 4V20H18V8H14V4H6ZM12 17L8 13H11V9.02L13 9V13H16L12 17Z"
          fill="#091B2A"
          fillOpacity="0.56"
        />
      </svg>
    </IconContainer>
  )
}
