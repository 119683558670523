import { ModalContent } from '@/components/Modal'
import { Body } from './Body'
import { Footer } from './Footer'
import { Header } from './Header'
import { useConfigureController } from '../hooks/useController'

interface Props {
  regions: string[]
}

export function Content(props: Props) {
  const { ready } = useConfigureController(props)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />}>
      <Body />
    </ModalContent>
  )
}
