import type { FormFields, FormErrors } from './formFields'
import type { TestValues } from '../types'
import type { NotificationFormType } from '@/notificationForms'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { testNotificationMessage } from '@/features/domain/territory'
import { useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useController } from './useController'
import { generateTestNotificationPayload } from './generateTestNotificationPayload'

export function useOnSubmit(notification: NotificationFormType, values: TestValues) {
  const { update } = useController()
  const formApi = useFormApi<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    // Set FormState into submitting mode
    formApi.setSubmitting(true)
    update({ status: 'submitting' })

    await formApi.waitForValidation()

    const valid =
      formApi.getMeta().status === 'indeterminate'
        ? await formApi.validate()
        : formApi.getMeta().status === 'valid'

    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    if (valid) {
      try {
        const payload = generateTestNotificationPayload(
          notification,
          formApi.getField('phoneNumber').value,
          values,
        )

        const thunkResult = await dispatch(testNotificationMessage(payload))

        if (!testNotificationMessage.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        // Clear FormState submitting mode
        formApi.setSubmitting(false)

        update({ status: 'success' })
      } catch (error) {
        formApi.setSubmitting(false)
        update({ status: 'error' })
      }
    } else {
      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'ready' })
    }
  }, [formApi, dispatch, update, notification, values, isUnmounted])
}
