import type { FormError, FormField } from '@workwave-tidal/form-fairy'

const emailField: FormField<string> = {
  required: true,
  value: '',
}

export const formFields = {
  email: emailField,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
