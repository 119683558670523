import type { MouseEvent } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import { FormFooter as FooterLayout } from '@workwave-tidal/form-ui/layout'

interface Props {
  onDiscard: () => void
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void
  disableSubmit?: boolean
  submitting?: boolean

  texts: {
    save: string
    cancel: string
  }
}

export function FormFooter(props: Props) {
  const { onDiscard, disableSubmit, submitting, texts, onSubmit } = props

  return (
    <FooterLayout align="right" gap={2} padding={4}>
      <LoadingButton
        type="button"
        size="medium"
        color="primary"
        variant="contained"
        onClick={onSubmit}
        loading={submitting}
        disabled={disableSubmit}
        data-testid={'button-save'}
      >
        {texts.save}
      </LoadingButton>
      <Button
        type="button"
        size="medium"
        color="primary"
        onClick={onDiscard}
        variant="text"
        data-testid="button-cancel"
      >
        {texts.cancel}
      </Button>
    </FooterLayout>
  )
}
