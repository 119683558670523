import type { FormField } from '@workwave-tidal/form-fairy'
import type { CourierFilterError } from '../types'

const id: FormField<string> = {
  value: '',
}

const enabled: FormField<boolean> = {
  value: true,
}

const name: FormField<string> = {
  value: '',
}

const surname: FormField<string> = {
  value: '',
}

const password: FormField<string> = {
  value: '',
}

const email: FormField<string> = {
  value: '',
}

const username: FormField<string> = {
  value: '',
}

const territoryFilter: FormField<uui.domain.client.rm.Territory[]> = {
  value: [],
}

const type: FormField<uui.domain.rm.RouteManagerUserType> = {
  value: 'planner',
}

const limitedAccess: FormField<boolean> = {
  value: false,
}

const courierFilter: FormField<uui.domain.client.rm.CourierFilter> = {
  value: {},
}

const isCompanyFiltered: FormField<boolean> = {
  value: false,
  disabled: true,
}

const companies: FormField<uui.domain.client.rm.Company[]> = {
  value: [],
}

export const formFields = {
  id,
  name,
  type,
  email,
  'new-password': password,
  limitedAccess,
  enabled,
  surname,
  username,
  courierFilter,
  territoryFilter,
  isCompanyFiltered,
  companies,
} as const

export type FormFields = typeof formFields
export type FormErrors = CourierFilterError<keyof FormFields>
