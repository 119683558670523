import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { TrafficRoot } from './components/form/TrafficRoot'

import { Box, Stack } from '@mui/material'

export function Traffic() {
  return (
    <Stack direction="row" data-testid="setup-traffic-area-root" height="100%" width="100%">
      <TrafficRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%">
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
