import { styled } from '@mui/material/styles'

import { RouteViolationsTooltipContent } from '@/components/RouteViolationsTooltipContent'
import { Tooltip } from '@/components/primitives/Tooltip'
import { getColorValue } from '@/styles'

import { useViolationsCountFromRoutes } from '../hooks/useViolationsCountFromRoutes'

import { Stack } from '@mui/material'
import { DriverTag } from './components/DriverTag'
import { NameAndDate } from './components/NameAndDate'
import { VehicleTag } from './components/VehicleTag'
import { VehicleViolationsTag } from './components/VehicleViolationsTag'
import { useData } from './hooks/useData'
import { useSingleActions } from './hooks/useSingleActions'

interface Props {
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
}

const StyledStack = styled(Stack)({
  '& > *': {
    marginLeft: 5,
  },
  '& > :first-child': {
    marginLeft: 0,
  },
})

export function Single(props: Props) {
  const { routeInfo } = props

  const { constraintsCount: vehicleViolations } = useViolationsCountFromRoutes([routeInfo])
  const { idx, vehicleName, extDriver } = useData(routeInfo)

  const actions = useSingleActions(routeInfo.vehicleId, extDriver?.id)

  return (
    <Stack
      direction="column"
      data-trackid="navigo-routes-overview-details-single"
      data-testid="navigo-routes-overview-details-single"
      width="100%"
      justifyContent="space-between"
    >
      <NameAndDate idx={idx} date={routeInfo.date} />

      <StyledStack direction="row" sx={{ marginTop: '3px' }}>
        {vehicleViolations > 0 && (
          <Tooltip
            placement="top"
            title={<RouteViolationsTooltipContent violations={routeInfo.violations} />}
          >
            <VehicleViolationsTag onClick={actions.selectVehicle}>
              {vehicleViolations}
            </VehicleViolationsTag>
          </Tooltip>
        )}

        {extDriver && <DriverTag onClick={actions.selectDriver}>{extDriver.driver.name}</DriverTag>}

        <VehicleTag
          vehicleName={vehicleName}
          onClick={actions.selectVehicle}
          backgroundColor={getColorValue(routeInfo.avatar.color)}
        >
          {`${idx}`}
        </VehicleTag>
      </StyledStack>
    </Stack>
  )
}
