import type { Option } from '@/components/formUi/fields/Select'
import type { FormFields, FormErrors } from '../../formFields'

import { useState } from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'

import { useFormMeta } from '@workwave-tidal/form-fairy'
import { FormHeader, Form } from '@workwave-tidal/form-ui/layout'

import { Name } from './fields/Name'
import { Email } from './fields/Email'
import { useTexts } from './hooks/useTexts'
import { Password } from './fields/Password'
import { TrackDriver } from './fields/TrackDriver'
import { MobileFutureDaysVisibility } from './fields/MobileFutureDaysVisibility'
import { SummarizeFutureMobileRoute } from './fields/SummarizeFutureMobileRoute'
import { FormFooter } from './components/FormFooter'
import { useFormActions } from './hooks/useFormActions'
import { useCoordinateFields } from './hooks/useCoordinateFields'

interface Props {
  driver?: uui.domain.client.rm.Driver
  gpsDeviceOptions: Option<string>[]
}

export function EditDriver(props: Props) {
  const { driver, gpsDeviceOptions } = props
  const creating = !driver

  const texts = useTexts()
  const theme = useTheme()

  const [firstSubmitDone, setFirstSubmitDone] = useState(false)
  const { onSubmit, onDiscard, onEnter } = useFormActions(setFirstSubmitDone, driver?.id)

  const {
    meta: { submitting, validating },
  } = useFormMeta<FormFields, FormErrors>()

  useCoordinateFields(creating)

  return (
    <Box width={360} height="100%" display="flex" paddingY={2}>
      <Form
        fieldsSpacing={4}
        fieldsPadding={`0 ${theme.spacing(4)}`}
        onEnter={onEnter}
        header={<FormHeader title={driver?.name ?? texts.newDriver} subtitle="" />}
        footer={
          <FormFooter
            texts={texts}
            creating={creating}
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            submitting={submitting}
            disableSubmit={submitting || validating}
          />
        }
      >
        <Name />
        <Email />
        <Password firstSubmitDone={firstSubmitDone} />

        <Divider />
        <Typography variant="subtitle3">{texts.driverAppTitle}</Typography>

        <TrackDriver gpsDeviceOptions={gpsDeviceOptions} />
        <MobileFutureDaysVisibility />
        <SummarizeFutureMobileRoute />
      </Form>
    </Box>
  )
}
