import { Stack, Typography } from '@mui/material'
import { NoResult2 } from '@workwave-tidal/core/icons/illustrations'

import { type LocaleText } from '../../../types'

interface Props {
  localeText: LocaleText
}

export function NoResults(props: Props) {
  const { localeText } = props

  return (
    <Stack gap={2} justifyContent="center" alignItems="center">
      <NoResult2 size={40} />
      <Stack gap={0.5} justifyContent="center" alignItems="center">
        <Typography variant="subtitle2">{localeText.noResults}</Typography>
        <Typography variant="caption">{localeText.noResultsDescription}</Typography>
      </Stack>
    </Stack>
  )
}
