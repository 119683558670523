import type { FormField, FormError, FormValues as FormValuesType } from '@workwave-tidal/form-fairy'
import type { GpsTimezone } from './types'

const timezoneField: FormField<GpsTimezone> = {
  value: 'UTC',
}

export const formFields = {
  timezone: timezoneField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
