import { useEffect, useLayoutEffect, useRef } from 'react'

import { useRoutingLayout } from '@/hooks'
import { useMouseDrag } from '@/local/hooks'

const reservedArea = 160

export const useSchedulerHeight = (containerHeight: number) => {
  const [{ offsetY }, dragging, onMouseDown] = useMouseDrag(false)

  const [{ schedulerHeight }, setLayout] = useRoutingLayout()

  const initialHeight = useRef<number>(schedulerHeight)
  const maxHeight = containerHeight - reservedArea

  useLayoutEffect(() => {
    if (dragging) {
      const newHeight = initialHeight.current - offsetY

      if (newHeight > maxHeight) {
        setLayout({ schedulerHeight: maxHeight })
        return
      }

      if (newHeight < reservedArea) {
        setLayout({ schedulerHeight: reservedArea })
        return
      }

      setLayout({ schedulerHeight: newHeight })
    }
  }, [maxHeight, setLayout, offsetY, dragging])

  useEffect(() => {
    if (!dragging) {
      initialHeight.current = schedulerHeight
    }
  }, [dragging, schedulerHeight])

  return {
    onMouseDown,
    height: schedulerHeight,
    dragging,
  }
}
