import { type SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Editor } from '@monaco-editor/react'
import { Clear } from '@mui/icons-material'
import { parse } from 'date-fns/esm'

import { LookupTable } from '@workwave-tidal/core/icons'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectSchedulerRoutes } from '@/features/domain/scheduler'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { useMouseDrag } from '@/local/hooks'
import { timeUtils } from '@/server-data'
import { theme } from '@/styles'

interface Props {
  currentRouteIds: string[]
}

const modalWidth = 500
const modalHeight = 400

export function VehicleJsonButton(props: Props) {
  const { currentRouteIds } = props
  const [open, setOpen] = useState(false)
  const routes = useSelector(selectSchedulerRoutes)
  const vehicles = useSelector(selectVehicles)
  const userConfig = useSelector(selectUserConfiguration)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const [{ offsetX, offsetY }, dragging, onMouseDown] = useMouseDrag(true)

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  const preventPropagation = useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const routeData = useMemo(() => {
    const extRoute = routes[currentRouteIds[0]]
    if (!extRoute) return

    const uv = vehicles[vehiclesByVehicleId[extRoute.vehicleId]]
    if (!uv.hasRoutingLicense) return

    const vehicleJson = JSON.stringify(uv.vehicle, null, 2)
    const vehicleName = uv.vehicle.externalId
    const routeDate = timeUtils.formatDate(userConfig)(parse(extRoute.date, 'yyyyMMdd', new Date()))

    return { vehicleJson, vehicleName, routeDate }
  }, [routes, vehicles, userConfig, vehiclesByVehicleId, currentRouteIds])

  // Prevent the modal from being dragged outside the viewport
  const transform = useMemo(() => {
    // Retrieve viewport size
    const { innerWidth, innerHeight } = window

    // Calculate the maximum and minimum offset
    const maxX = innerWidth / 2 - modalWidth / 2
    const maxY = innerHeight / 2 - modalHeight / 2

    const minX = -maxX
    const minY = -maxY

    // Calculate the final position
    const x = Math.min(Math.max(offsetX, minX), maxX)
    const y = Math.min(Math.max(offsetY, minY), maxY)

    return `translate(${x}px, ${y}px)`
  }, [offsetX, offsetY])

  if (!routeData) return null

  const { vehicleJson, vehicleName, routeDate } = routeData
  const canShow = currentRouteIds.length === 1

  return (
    <>
      <NavigoIconButton
        active={open}
        onClick={() => setOpen(!open)}
        testid="navigo-routes-overview-actions-vehicle-json"
        Icon={
          <div
            style={{ fontSize: 16, transform: 'translateY(3px)', color: theme.colors.$nightRider }}
          >
            <LookupTable fontSize="inherit" color="inherit" />
          </div>
        }
        text="JSON"
      />
      {open && canShow && routeData && (
        <Stack
          position="fixed"
          bgcolor="white"
          display="block"
          bottom={400}
          zIndex={9999}
          height={modalHeight}
          width={modalWidth}
          left={`calc(50% - ${modalWidth}px / 2)`}
          top={`calc(50% - ${modalHeight}px / 2)`}
          id="vehicle-json-modal"
          sx={{ transform, willChange: 'transform' }}
        >
          <Paper
            elevation={6}
            onMouseDown={onMouseDown}
            style={{ cursor: dragging ? 'grabbing' : 'grab' }}
          >
            <Stack padding={1} spacing={1}>
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  variant="h6"
                  flexGrow={1}
                  textAlign="center"
                >{`${vehicleName} (${routeDate})`}</Typography>
                <IconButton onClick={close}>
                  <Clear fontSize="small" />
                </IconButton>
              </Stack>
              <Stack onMouseDown={preventPropagation}>
                <Editor
                  value={vehicleJson}
                  theme="vs-dark"
                  height={400}
                  options={{ readOnly: true }}
                  language="json"
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      )}
    </>
  )
}
