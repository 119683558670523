import { Tick } from '@/icons'

import { StyledTick } from './hooks/useStyles'

export function ActiveIcon() {
  return (
    <StyledTick>
      <Tick size={12} color="$scienceBlue" marginTop={2} marginRight={10} />
    </StyledTick>
  )
}
