import type { DayToDispatch } from '../../../typings'

import { format } from 'date-fns/esm'

import { PaperPlaneWithStrikeThrough } from '@/icons'
import { Button } from '@/local/components'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useActions } from '../useActions'
import { useTexts } from '../useTexts/useTexts'
interface Props {
  day: DayToDispatch
}

export function RevokeButton(props: Props) {
  const { date, isToday, isTomorrow, routesCount, dispatchedRoutesCount, canBeRevoked } = props.day

  const texts = useTexts()
  const { revokeDate } = useActions()

  const title = texts.revokeButtonTitle(routesCount, dispatchedRoutesCount, canBeRevoked)
  const className = isToday
    ? 'pendo-dispatch-routes_revoke_today-button'
    : 'pendo-dispatch-routes_revoke_not-today-button'

  const testid = isToday
    ? 'dispatch-routes__revoke_today-button'
    : 'dispatch-routes__revoke_not-today-button'

  const testLabel = isToday ? 'today' : isTomorrow ? 'tomorrow' : undefined

  return (
    <ReadOnlyTooltip
      sx={{ display: 'flex', flex: '1 1 100%' }}
      canEdit={canBeRevoked}
      title={title}
      render={preventEditing => (
        <Button
          disabled={preventEditing}
          backgroundTint="$whiteSmoke"
          onClick={revokeDate}
          canFocus={false}
          data-date={format(date, 'yyyyMMdd')}
          className={className}
          data-testlabel={testLabel}
          data-testid={testid}
          data-trackid={testid}
        >
          <PaperPlaneWithStrikeThrough size={12} marginRight={6} />
          {texts.revoke}
        </Button>
      )}
    />
  )
}
