import { WarningTriangle } from '@/icons'
import { WarningCard } from '@local/components'

import { useController } from '../hooks/useController'

import { Stack } from '@mui/material'

export function Body() {
  const {
    data: { title, subtitle },
  } = useController()

  return (
    <Stack direction="column" width="100%" height="100%" flexShrink={0} marginTop={4}>
      <WarningCard
        Icon={<WarningTriangle size={16} color="$pureWhite" background="$darkOrange" />}
        description={subtitle}
        preset="warning"
        title={title}
      />
    </Stack>
  )
}
