import type { DriverWithVehicleAndDistance, ModalDevice } from '../typings'
import type { OnSelectCallbackParams as VirtualListOnSelectCallbackParams } from '@workwave-tidal/core/components/VirtualList'

import { useCallback } from 'react'

import { sendMessage } from '@/features/domain/driver'
import { useNotification, useIsUnmounted } from '@/hooks'
import { useAppDispatch } from '@/store'
import { geo } from '@/server-data'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export const useControllerActions = () => {
  const texts = useTexts()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const { data, update, updateData } = useController()

  const onPhoneChange = useCallback((phone: string) => updateData({ phone }), [updateData])
  const onEmailChange = useCallback((email: string) => updateData({ email }), [updateData])
  const onMessageChange = useCallback((message: string) => updateData({ message }), [updateData])

  const setMessageTypeToSms = useCallback(() => updateData({ messageType: 'sms' }), [updateData])
  const setMessageTypeToEmail = useCallback(
    () => updateData({ messageType: 'email' }),
    [updateData],
  )

  const goToChooseRecipient = useCallback(() => {
    update({ status: 'chooseRecipient' })
  }, [update])

  const goToComposeMessage = useCallback(() => {
    update({ status: 'composeMessage' })
  }, [update])

  const confirmRecipientChoice = useCallback(() => {
    update({
      data: { ...data, message: texts.prefilled(geo.urlFromLatLng(data.locationToShare)) },
      status: 'composeMessage',
    })
  }, [update, texts, data])

  const updateSelectedDriver = useCallback(
    ({ item }: VirtualListOnSelectCallbackParams<DriverWithVehicleAndDistance>) => {
      updateData({ selectedId: item.driver.id })
    },
    [updateData],
  )

  const updateSelectedDevice = useCallback(
    ({ item }: VirtualListOnSelectCallbackParams<ModalDevice>) => {
      updateData({ selectedId: item.id })
    },
    [updateData],
  )

  const dispatchSendMessage = useCallback(async () => {
    try {
      const { message, messageType: type } = data
      const address = data.messageType === 'email' ? data.email : data.phone

      if (!message || !address) return

      const response = await dispatch(
        sendMessage({
          type,
          address,
          message,
        }),
      )

      if (!sendMessage.fulfilled.match(response)) {
        if (response.payload) {
          throw new Error(response.payload.message)
        }
      }

      if (isUnmounted()) return

      //Update the modal state based on the result
      update({ status: response.payload ? 'success' : 'failure' })
    } catch (e) {
      toast.error(e.message)

      if (isUnmounted()) return

      update({ status: 'failure' })
    }
  }, [dispatch, toast, update, data, isUnmounted])

  return {
    onPhoneChange,
    onEmailChange,
    onMessageChange,
    goToComposeMessage,
    dispatchSendMessage,
    goToChooseRecipient,
    setMessageTypeToSms,
    updateSelectedDriver,
    updateSelectedDevice,
    setMessageTypeToEmail,
    confirmRecipientChoice,
  }
}
