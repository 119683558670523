import type { FormErrors, FormFields } from '../formFields'
import { useFormField } from '@workwave-tidal/form-fairy'
import { Box } from '@mui/material'
import { SimpleSelect as Select } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

export function TypeField() {
  const texts = useTexts()
  const options: {
    type: 'option'
    value: uui.domain.server.rm.MobileAppInteraction['type']
    label: string
  }[] = [
    { type: 'option', value: 'NONE', label: texts.off },
    { type: 'option', value: 'ASSISTED_TIME_OUT', label: texts.suggest },
    { type: 'option', value: 'DRIVER_REQUIRED_FLOW', label: texts.require },
  ]
  const { formApi } = useFormField<'type', FormFields, FormErrors>('type')
  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  return (
    <Box paddingTop={1}>
      {/* Please remove the above <Box /> when https://workwave.atlassian.net/browse/CUI-419 will be fixed */}
      <Select<'type', uui.domain.server.rm.MobileAppInteraction['type'], FormFields, FormErrors>
        name="type"
        label={texts.mode}
        options={options}
        disabled={formDisabled}
        validateOn="blur change"
        helperText={texts.description}
        testId="driver-assistance-form-type-field"
      />
    </Box>
  )
}
