import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { selectVoiceLanguageOptions } from '@/features/domain/territory'

import { useTexts } from './hooks/useTexts'

type Option = {
  type: 'option'
  label: string
  value: 'FEMALE' | 'MALE'
}

export function AutomatedMessageVoiceField() {
  const { maleAvailable, femaleAvailable } = useSelector(selectVoiceLanguageOptions)
  const texts = useTexts()

  const options: Option[] = useMemo(
    () => [
      {
        type: 'option',
        label: `${texts.automatedMessageVoice.values.female} ${
          !femaleAvailable ? `(${texts.automatedMessageVoice.notAvailable})` : ``
        }`,
        value: 'FEMALE',
        disabled: !femaleAvailable,
      },
      {
        type: 'option',
        label: `${texts.automatedMessageVoice.values.male} ${
          !maleAvailable ? `(${texts.automatedMessageVoice.notAvailable})` : ``
        }`,
        value: 'MALE',
        disabled: !maleAvailable,
      },
    ],
    [texts, maleAvailable, femaleAvailable],
  )

  return (
    <RadioGroup<'automatedMessageVoice', 'FEMALE' | 'MALE', FormFields, FormErrors>
      label={texts.automatedMessageVoice.label}
      name="automatedMessageVoice"
      options={options}
      validateOn="blur change"
    />
  )
}
