import type { ModalState } from '@/atoms'
import { ReactElement, ReactNode, useCallback, useState, useMemo, useRef } from 'react'
import { Dialog, Backdrop, Stack, Box } from '@mui/material'
import { DraggableLayer } from '@/components/DraggableLayer'
import { useCurrentModal, useCloseModal } from '@/atoms'
import { ModalBody } from './elements/ModalBody'
import { StyledModalContainer, DragHandle } from './hooks/useStyles'

interface Props {
  scrollbars?: boolean
  header?: ReactElement
  footer?: ReactElement
  config?: Partial<ModalState['config']>
  children?: ReactNode
  adornment?: ReactNode
  minWidth?: number
  maxWidth?: number
  testid?: string
}

export function ModalContent(props: Props) {
  const {
    children,
    header,
    footer,
    config: customConfig,
    maxWidth,
    minWidth,
    testid = 'modal',
    adornment,
  } = props

  const ref = useRef<null | HTMLDivElement>(null)
  const closeModal = useCloseModal()
  const { config } = useCurrentModal()

  const { maxHeight, disableEscapeKeyDown, disableBackdropClick } = useMemo(
    () => ({
      ...config,
      ...(customConfig ?? {}),
    }),
    [config, customConfig],
  )

  const [innerHeight, setInnerHeight] = useState<number>(Number.MAX_SAFE_INTEGER)

  const scrollbarsHeight = useMemo(
    () => Math.ceil(Math.min(Math.abs(maxHeight), innerHeight)),
    [maxHeight, innerHeight],
  )

  const onClose = useCallback(
    (_event, reason) => {
      if (reason === 'backdropClick' && disableBackdropClick) return
      closeModal()
    },
    [closeModal, disableBackdropClick],
  )

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth={false}
      BackdropComponent={Backdrop}
      disableEscapeKeyDown={disableEscapeKeyDown}
      transitionDuration={600}
      PaperProps={{
        ref,
      }}
    >
      <StyledModalContainer data-testid={testid} minWidth={minWidth} maxWidth={maxWidth}>
        <DraggableLayer
          draggable
          container={ref.current}
          render={(onMouseDown, dragging) => (
            <Stack direction="row" width="100%" height="100%">
              <Stack direction="column" height="100%" width="100%" flexShrink={1}>
                {!!header && (
                  <DragHandle dragging={dragging} onMouseDown={onMouseDown}>
                    {header}
                  </DragHandle>
                )}

                <Box width="100%" height="100%" flex="0 1 auto">
                  <ModalBody
                    height={scrollbarsHeight}
                    onUpdateHeight={setInnerHeight}
                    scrollBars={props.scrollbars}
                  >
                    {children}
                  </ModalBody>
                </Box>

                {!!footer && footer}
              </Stack>
              {adornment}
            </Stack>
          )}
        />
      </StyledModalContainer>
    </Dialog>
  )
}
