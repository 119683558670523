import { CategoryButton } from '../../components/CategoryButton'
import { CategoryButtonContainer } from '../../components/CategoryButtonContainer'
import { CategoryButtonWithBadge } from '../../components/CategoryButtonWithBadge'
import { CategoryIcon } from '../../components/CategoryIcon'
import { CloseButton } from '../../elements/CloseButton'
import { NavigoContainer } from '../../elements/NavigoContainer'

import { useMultiCategory } from './useMultiCategory'

export function MultiCategory() {
  const {
    selectedCategories,
    selection,
    violationsCount,
    getCategoryLabel,
    handleOnNarrowSelection,
  } = useMultiCategory()

  return (
    <NavigoContainer data-testid="navigo-multicategory" data-trackid="navigo-multicategory">
      <CategoryButtonContainer>
        {selectedCategories.map(category => {
          const count = selection[category]?.length ?? 0
          const buttonText = `${count} ${getCategoryLabel(category, count)}`
          const buttonProps = {
            key: category,
            autoWidth: true,
            'data-category': category,
            onClick: handleOnNarrowSelection,
            Icon: <CategoryIcon category={category} />,
          }

          return category === 'orderSteps' ? (
            <CategoryButtonWithBadge {...buttonProps} badgeText={violationsCount}>
              {buttonText}
            </CategoryButtonWithBadge>
          ) : (
            <CategoryButton {...buttonProps}>{buttonText}</CategoryButton>
          )
        })}
      </CategoryButtonContainer>
      <CloseButton />
    </NavigoContainer>
  )
}
