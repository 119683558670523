import type { FormFields, FormErrors, FormValues } from '../formFields'
import type { FormConfig } from '@workwave-tidal/form-fairy'

import { useState } from 'react'

export function useFormConfig(initialValues: FormValues): FormConfig<FormFields, FormErrors> {
  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    overrideValues: initialValues,
  }))

  return formConfig
}
