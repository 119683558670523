import { useEffect } from 'react'

import { H4 } from '@/local/components'
import { OrderWarningCard } from './OrderWarningCard'
import { Dropdown } from '@/components/Dropdown'

import { useSelector } from 'react-redux'
import { useTexts } from '../useTexts'
import { useControllerActions } from '../hooks/useControllerActions'
import { useController } from '../hooks/useController'
import { useStepOptions } from '../hooks/useStepOptions'
import { useDateOptions } from '../hooks/useDateOptions'
import { useVehicleOptions } from '../hooks/useVehicleOptions'

import { selectTerritory } from '@/features/domain/territory'
import { Stack } from '@mui/material'

const labelSpace = 8
const dropdownMaxHeight = 120
const dropdownBlockSpace = 22

const warningCardStyle = { marginTop: dropdownBlockSpace }

export function Body() {
  const texts = useTexts()
  const dateOptions = useDateOptions()
  const stepOptions = useStepOptions()
  const vehicleOptions = useVehicleOptions()
  const actions = useControllerActions()
  const { borderRestrictionCode, borderRestrictionFullName } = useSelector(selectTerritory)
  const {
    updateData,
    status,
    data: { isForceFitting, areDateAndVehicleSet, orderSteps, outOfBorder },
  } = useController()

  // If there is no selected step, select the first one not disabled
  useEffect(() => {
    const selectedStep = stepOptions.find(o => o.selected)

    if (selectedStep) return

    const option = stepOptions.find(s => !s.disabled)
    updateData({ activeStep: option?.routeStep })
  }, [updateData, stepOptions])

  useEffect(() => {
    if (borderRestrictionCode) {
      actions.onValidateSteps()
    }
  }, [borderRestrictionCode])

  return (
    <Stack direction="column" height="100%" width="100%" flexShrink={0}>
      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.date}
        </H4>
        <Dropdown
          disabled={status === 'submitting'}
          onChange={actions.onChangeDateOption}
          maxListHeight={dropdownMaxHeight}
          options={dateOptions}
          testId="date-dropdown"
        />
      </Stack>

      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.vehicle}
        </H4>
        <Dropdown
          onChange={actions.onChangeVehicleOption}
          disabled={status === 'submitting'}
          maxListHeight={dropdownMaxHeight}
          options={vehicleOptions}
          testId="vehicle-dropdown"
        />
      </Stack>

      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.insertAfter}
        </H4>
        <Dropdown
          options={stepOptions}
          disabled={status === 'submitting' || !areDateAndVehicleSet}
          maxListHeight={dropdownMaxHeight}
          onChange={actions.onChangeStepOption}
          testId="step-dropdown"
        />
      </Stack>

      <OrderWarningCard
        isForceFitting={isForceFitting}
        outOfBorder={outOfBorder}
        borderRestrictionFullName={borderRestrictionFullName}
        orderSteps={orderSteps}
        style={warningCardStyle}
      />
    </Stack>
  )
}
