import { Stack } from '@mui/material'

import { FormContainer } from '@workwave-tidal/form-ui/core'
import { TextField } from '@workwave-tidal/form-ui'

import { ModalContent } from '@/components/Modal'

import { FormErrors, type FormFields } from '../formFields'
import { useController } from '../hooks/useController'
import { useOnSubmit } from '../hooks/useOnSubmit'
import { useTexts } from '../useTexts'

import { Footer } from './Footer'
import { Header } from './Header'

export function Form() {
  const {
    data: { tenantSource },
  } = useController()

  // Callback for handling submit
  const onSubmit = useOnSubmit()
  const texts = useTexts()

  return (
    <ModalContent
      header={<Header tenantSource={tenantSource} />}
      footer={<Footer onSubmit={onSubmit} />}
      maxWidth={600}
    >
      <Stack paddingTop={2} spacing={2} maxWidth="100%">
        <FormContainer testId="edti-telematics-tenant-source-label-form" width={444}>
          <Stack spacing={2} width="100%">
            <TextField<'label', FormFields, FormErrors>
              name="label"
              label={texts.connectionNameLabel}
              required={false}
              validateOn="blur focus change"
              textfieldProps={{ size: 'medium' }}
            />
          </Stack>
        </FormContainer>
      </Stack>
    </ModalContent>
  )
}
