import { useCallback } from 'react'
import { Stack, Typography } from '@mui/material'

import { TruncateList } from '@workwave-tidal/core/components'

import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from '../hooks/useTexts'

type Props = { tags: uui.domain.client.gps.wwgps.Tag[] }
type ShowMoreProps = { values: uui.domain.client.gps.wwgps.Tag[]; count: number }

function CustomShowMore(props: ShowMoreProps) {
  const { count, values } = props
  const texts = useTexts()

  const title = values
    .slice(-count)
    .map(b => b.value)
    .join(', ')

  return (
    <Tooltip title={title} placement="top">
      <Stack data-testid="show-more">
        <Typography variant="caption" noWrap color="text.secondary">
          + {count} {texts.more}
        </Typography>
      </Stack>
    </Tooltip>
  )
}

export function Tags(props: Props) {
  const { tags } = props

  const ShowMore = useCallback(
    ({ count }) => <CustomShowMore values={tags} count={count} />,
    [tags],
  )

  return (
    <TruncateList density="compact" ShowMore={ShowMore} showMoreColor="transparent" threshold={1}>
      {tags.map((tag, index) => (
        <Typography key={tag.id} variant="caption" noWrap color="text.secondary">
          {tag.value}
          {index < tags.length - 1 ? ',' : ''}
        </Typography>
      ))}
    </TruncateList>
  )
}
