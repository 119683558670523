import { Link, useGpsReportsLink } from '@/routing'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'

import { useTexts } from '../../../useTexts'
import { getSelectedDeviceIds } from '../../../utils/getSelectedDeviceIds'
import { HeaderLink } from './HeaderLink'

const FullHeightLink = styled(Link)({
  height: '100%',
})

const StyledButton = styled(Button)<{
  href?: string
  target?: string
}>(({ theme }) => ({
  height: '100%',
  fontWeight: theme.typography.fontWeightRegular,
}))

export function GpsOnlyLinks() {
  const texts = useTexts()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <FullHeightLink
        to="tracking"
        data-testid="header__nav-tracking-button-link"
        className={'pendo-bluebar__tracking-list-menu'}
      >
        <HeaderLink
          data-testid="header__nav-tracking-button-content"
          data-trackid="gpsonly-header__nav-tracking-button-content"
        >
          {texts.tracking}
        </HeaderLink>
      </FullHeightLink>

      <StyledButton
        href={gpsReportsLink}
        target="_blank"
        onClick={onGpsReportClick}
        data-testid="header__nav-reports-button-link"
        className={`pendo-bluebar__GPS-reports-menu`}
      >
        <HeaderLink
          data-testid="header__nav-reports-button-content"
          data-trackid="gpsonly-header__nav-reports-button-content"
        >
          {texts.reports}
        </HeaderLink>
      </StyledButton>
    </>
  )
}
