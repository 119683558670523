import type { FormFields, FormErrors } from '../../formFields'

import { useSelector } from 'react-redux'

import { TextField } from '@workwave-tidal/form-ui'

import { ViewField } from '@/formUi'

import { selectUserType } from '@/features/domain/user'
import { useRmAccountData } from '../../../hooks/useRmAccountData'
import { useTexts } from './useTexts'

export function Username() {
  const userType = useSelector(selectUserType)
  const [account] = useRmAccountData()
  const texts = useTexts()

  const canEditUsername = ['admin', 'planner', 'gpsonly'].includes(userType)

  return canEditUsername ? (
    <TextField<'username', FormFields, FormErrors>
      name="username"
      label={texts.username}
      testId="username"
      disabled={!canEditUsername}
      validateOn="blur"
    />
  ) : (
    <ViewField label={texts.username}>{account.username}</ViewField>
  )
}
