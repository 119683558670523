import { isDark, isValidColor } from '@/styles'
import { Pentagon } from '@mui/icons-material'
import { Avatar, useTheme } from '@mui/material'
import { StyledContainer } from './hooks/useStyles'

interface Props {
  color: string
}

export function RegionAvatar(props: Props) {
  const { color } = props

  const theme = useTheme()
  let bgcolor = color

  if (!isValidColor(color)) {
    console.warn(`Invalid color: ${color}`)
    bgcolor = '#fff'
  }
  const textColor = isDark(bgcolor)
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary

  return (
    <StyledContainer>
      <Avatar variant="circular" sx={{ bgcolor, color: textColor }}>
        <Pentagon />
      </Avatar>
    </StyledContainer>
  )
}
