import { MenuList as MuiMenuList, type MenuListProps as MuiMenuListProps, styled } from '@mui/material'
import type { ComponentType } from 'react'

export type MenuListProps = MuiMenuListProps

export const MenuList = styled(MuiMenuList, {
  slot: 'MenuList',
})(() => ({
  maxHeight: 300,
  overflowX: 'hidden',
  overflowY: 'auto',
  pointerEvents: 'all',
})) as ComponentType<MenuListProps>
