import { styled } from '@mui/material/styles'
import type { CSSProperties } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { IconButton } from '@/components/primitives/buttons/IconButton'
import { Dashboard, Info, Wrench } from '@/icons'

import { HeaderSlice } from '../../elements/HeaderSlice'

import { CalendarDropdownMenu } from './components/CalendarDropdownMenu'
import { GpsUserConsole } from './components/GpsUserConsole'
import { HelpDropdownMenu } from './components/HelpDropdownMenu'
import { LinkIcon } from './components/LinkIcon'
import { RoutingLayoutDropdownMenu } from './components/RoutingLayoutDropdownMenu'
import { ExtractedMapWarning } from './components/RoutingLayoutDropdownMenu/components/ExtractedMapWarning'
import { SettingsDropdownMenu } from './components/SettingsDropdownMenu'
import { TerritoryDropdownMenu } from './components/TerritoryDropdownMenu'

import { Box, Divider } from '@mui/material'

import { usePermissions } from './hooks/usePermissions'
import { useTexts } from './useTexts'

const VerticalDivider = styled(Divider)(() => ({
  height: '32px',
  alignSelf: 'center',
  opacity: 0.2,
}))

const dashboardUrl = 'https://wwrm.workwave.com/analytics'

const iconButtonStyle: CSSProperties = { height: 36, padding: 8, position: 'relative' }

export function MenuArea() {
  const { showDashboard, showUserConsole, showLayoutMenu, showSetup, showHelp } = usePermissions()
  const texts = useTexts()

  return (
    <Box width="auto" height="100%" flex="0 1 auto">
      <HeaderSlice gutter={20} paddingX={34}>
        <CalendarDropdownMenu />
        <VerticalDivider orientation="vertical" />

        <TerritoryDropdownMenu />
        <VerticalDivider orientation="vertical" />

        {showSetup && (
          <LinkIcon
            to="setup"
            testid="header__setup-button"
            tooltipTitle={texts.setupArea}
            className="pendo-bluebar__setup-area-button"
            Icon={<Wrench size={14} color="$pureWhite" />}
          />
        )}

        {showLayoutMenu && (
          <>
            <RoutingLayoutDropdownMenu />
            <ExtractedMapWarning />
          </>
        )}

        {showUserConsole && <GpsUserConsole />}

        {showDashboard && (
          <Tooltip placement="bottom" title={texts.dashboard}>
            <IconButton
              style={iconButtonStyle}
              data-testid="header__dashboard-button"
              data-trackid="header__dashboard-button"
            >
              <a
                target="_blank"
                href={dashboardUrl}
                rel="noopener noreferrer"
                style={{ display: 'flex' }}
                className="pendo-bluebar__dashboard-button"
              >
                <Dashboard size={16} color="$pureWhite" />
              </a>
            </IconButton>
          </Tooltip>
        )}

        <Tooltip placement="bottom" title={texts.pendoResourceCenter}>
          <IconButton style={iconButtonStyle} data-trackid="Pendo-resourcecenter">
            <Info size={16} color="$pureWhite" />
          </IconButton>
        </Tooltip>

        {showHelp && <HelpDropdownMenu />}

        <SettingsDropdownMenu />
      </HeaderSlice>
    </Box>
  )
}
