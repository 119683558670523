import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from './formFields'

import { useMemo } from 'react'

import { FormProvider } from '@workwave-tidal/form-ui/layout'

import { useConfigureController } from '../../hooks/useController'

import { useValidations } from './hooks/useValidations'
import { formFields } from './formFields'
import { Form } from './components/Form'

type Props = {
  label: string
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
  pinType: uui.domain.ui.map.LocationPinType
  onChange: (payload: uui.domain.client.Location) => void
  location: uui.domain.client.Location
}

export function Content(props: Props) {
  const { label, location, pinType, pinId, onChange } = props

  const validations = useValidations<FormFields, FormErrors>()
  const { ready } = useConfigureController(location.latLng, pinId)
  const config = useMemo<FormConfig<FormFields, FormErrors>>(
    () => ({
      validations,
      overrideValues: {
        newLocation: { ...location },
        label,
      },
    }),
    [label, location, validations],
  )

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors>
      name="adjust-location-position-label-modal"
      fields={formFields}
      config={config}
      verbose={false}
    >
      <Form onChange={onChange} center={location.latLng} pinId={pinId} pinType={pinType} />
    </FormProvider>
  )
}
