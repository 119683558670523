import type { ReactElement } from 'react'

import { Stack as CustomStack } from '@/local/components'

import { Stack } from '@mui/material'

interface Props {
  indentation?: 0 | 1
  children: ReactElement | ReactElement[]
}

export function Section(props: Props) {
  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      flexShrink={0}
      sx={{ paddingLeft: `${props.indentation === 1 ? 22 : 0}px` }}
      alignItems="stretch"
    >
      <CustomStack space={props.indentation === 1 ? 's' : 'm'}>{props.children}</CustomStack>
    </Stack>
  )
}
