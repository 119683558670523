import { setMainSelection } from '@/atoms'
import { intl } from '@/intl'
import { type Model, type SchedulerProConfig } from '@bryntum/schedulerpro'

import { getSchedulerPreferences } from '../../getSchedulerPreferences'

function createUnavailableRoutesButton(record: Model) {
  const button = document.querySelector(
    `.b-grid-row[data-id="${record.id}"] .scheduler-group-button.unavailable-routes-chip`,
  )
  if (!button) return

  if (getSchedulerPreferences().showUnavailableRoutes === false) {
    button.remove()
    return
  }

  const counter = button?.querySelector('.counter')
  if (!counter) return

  const unavailableRouteIds = record.groupChildren
    ?.filter(r => !r.getData('available'))
    .map(r => r.id) as string[]

  const groupRecords = record.groupChildren
  const counterTexts =
    unavailableRouteIds.length === 0
      ? '-'
      : unavailableRouteIds.length === (groupRecords?.length ?? 0)
        ? intl.translate({
            id: 'scheduler.group.header.all',
            values: { count: unavailableRouteIds.length },
          })
        : unavailableRouteIds.length.toString()

  counter.textContent = counterTexts

  button.addEventListener('click', clickEvent => {
    clickEvent.stopImmediatePropagation()
    setMainSelection('routes', unavailableRouteIds)
  })
}

function createUsedRoutesButton(record: Model) {
  const button = document.querySelector(
    `.b-grid-row[data-id="${record.id}"] .scheduler-group-button.used-routes-chip`,
  )
  if (!button) return
  const counter = button?.querySelector('.counter')
  if (!counter) return

  const groupRecords = record.groupChildren
  const usedRouteIds = record.groupChildren
    ?.filter(r => !r.getData('empty'))
    .map(r => r.id) as string[]

  const counterTexts =
    usedRouteIds.length === 0
      ? '-'
      : usedRouteIds.length === (groupRecords?.length ?? 0)
        ? intl.translate({
            id: 'scheduler.group.header.all',
            values: { count: usedRouteIds.length },
          })
        : usedRouteIds.length.toString()

  counter.textContent = counterTexts

  button.addEventListener('click', clickEvent => {
    clickEvent.stopImmediatePropagation()
    setMainSelection('routes', usedRouteIds)
  })
}

function createEmptyRoutesButton(record: Model) {
  const button = document.querySelector(
    `.b-grid-row[data-id="${record.id}"] .scheduler-group-button.empty-routes-chip`,
  )
  if (!button) return
  const counter = button?.querySelector('.counter')
  if (!counter) return

  const groupRecords = record.groupChildren
  const emptyRouteIds = groupRecords
    ?.filter(r => r.getData('empty') && r.getData('available'))
    .map(r => r.id) as string[]

  const counterTexts =
    emptyRouteIds.length === 0
      ? '-'
      : emptyRouteIds.length === (groupRecords?.length ?? 0)
        ? intl.translate({
            id: 'scheduler.group.header.all',
            values: { count: emptyRouteIds.length },
          })
        : emptyRouteIds.length.toString()

  counter.textContent = counterTexts

  button.addEventListener('click', clickEvent => {
    clickEvent.stopImmediatePropagation()
    setMainSelection('routes', emptyRouteIds)
  })
}

export const onRenderRow: SchedulerProConfig['onRenderRow'] = e => {
  // If the row is not a group header, we don't need to do anything
  if (!e.record.isGroupHeader) return

  createUnavailableRoutesButton(e.record)
  createUsedRoutesButton(e.record)
  createEmptyRoutesButton(e.record)
}
