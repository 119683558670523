import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

export const createValidateEligibility =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField('eligibility')

    const invalidEligibilityNoDatesError: FormErrors = {
      id: 'invalidEligibilityNoDatesError',
      field: 'eligibility',
      message: texts.invalidEligibilityNoDates,
      priority: 3,
    }

    const valid = value.status === 'exact' ? isValidEligibility(value.value) : true

    return {
      [invalidEligibilityNoDatesError.id]: !valid ? invalidEligibilityNoDatesError : null,
    }
  }

function isValidEligibility(eligibility: uui.domain.client.rm.Eligibility) {
  if (eligibility.type === 'on') {
    return eligibility.dates.length > 0
  }

  return true
}
