import type { FormFields, FormErrors } from '../formFields'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'
import { useTimeFormatOptions } from '../hooks/useTimeFormatOptions'

interface Props {
  submitting: boolean
}

export function TimeFormat(props: Props) {
  const texts = useTexts()
  const options = useTimeFormatOptions()

  return (
    <RadioGroup<
      'timeFormat',
      uui.domain.client.rm.NotificationConfig['timeFormat'],
      FormFields,
      FormErrors
    >
      disabled={props.submitting}
      validateOn="blur focus"
      options={options}
      testId="timeFormat"
      label={texts.timeFormat}
      name="timeFormat"
    />
  )
}
