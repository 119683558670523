import type { FormErrors, FormFields } from '../formFields'

import { useMemo } from 'react'

import { useFormField } from '@workwave-tidal/form-fairy'
import { RadioGroup } from '@workwave-tidal/form-ui'

import { useTexts } from '../hooks/useTexts'

type Options = { label: string; value: uui.domain.server.rm.FlowRequirement }[]

type Props = {
  name: 'includePicture' | 'includeSignature' | 'includeAudio' | 'includeNote' // Fields of type uui.domain.server.rm.FlowRequirement
  disabled: boolean
}

export function OrdersType(props: Props) {
  const { name, disabled: fieldDisabled } = props

  const texts = useTexts()

  const options: Options = useMemo(
    () => [
      { label: texts.notCompleted, value: 'REQUIRED_TO_NOT_COMPLETE' },
      { label: texts.completed, value: 'REQUIRED_TO_COMPLETE' },
      { label: texts.all, value: 'ALWAYS_REQUIRED' },
    ],
    [texts],
  )

  const { formApi } = useFormField<'includeAudio', FormFields, FormErrors>('includeAudio')

  const { submitting, disabled } = formApi.getMeta()

  const disableField = submitting || disabled || fieldDisabled

  return (
    <RadioGroup<typeof name, uui.domain.server.rm.FlowRequirement, FormFields, FormErrors>
      label=""
      name={name}
      options={options}
      validateOn="blur change"
      disabled={disableField}
      testId={`driver-assistance-form-${name}-orders-type-field`}
    />
  )
}
