import { Gps } from '@/icons'
import { Text } from '@/local/components'
import { useInspectPin } from '@/map'

import { Box, Stack } from '@mui/material'

import { useTexts } from '../useTexts'

export function Position() {
  const [inspectPin] = useInspectPin()
  const texts = useTexts()

  if (!inspectPin) return null

  return (
    <Box height="auto" width="100%" flex="0 1 auto">
      <Stack
        direction="column"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="start"
        sx={{ marginLeft: '25px' }}
        flexShrink={0}
      >
        <Stack direction="row" height="100%" width="100%" alignItems="center">
          <Text size="$h4" color="$silver">
            {texts.lat}
          </Text>
          <Text size="$h4" color="$nightRider" marginLeft={5}>
            {inspectPin.latLng.lat.toFixed(4)}
          </Text>
          <Text size="$h4" color="$silver" marginLeft={10}>
            {texts.lng}
          </Text>
          <Text size="$h4" color="$nightRider" marginLeft={5}>
            {inspectPin.latLng.lng.toFixed(4)}
          </Text>
        </Stack>

        <Stack direction="row" height="100%" width="100%" alignItems="center">
          <Gps size={10} color="$sailBlue" marginLeft={-15} marginRight={5} />

          <Text size="$h4" color="$silver" italic>
            {inspectPin.status === 'ready' ? inspectPin.location.geoAddress : texts.geocoding}
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}
