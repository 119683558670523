import type {
  FormError,
  FormField,
  FormSingleField,
  NarrowFieldsByValueType,
} from '@workwave-tidal/form-fairy'
import type { ValidateOn } from '@/formUi'
import type { BulkFieldValue } from '../../../../types'

import { useCallback } from 'react'
import { format } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { useFormField } from '@workwave-tidal/form-fairy'
import { useValidateOn } from '@/formUi'
import { selectUserConfiguration } from '@/features/domain/user'

type RequiredFormField = BulkFieldValue<uui.domain.client.rm.Eligibility>

export function useActions<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
>(name: FIELD_NAME, validateOn?: ValidateOn) {
  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    fieldApi: { change: apiChange, validate },
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(name)

  const { validateOnBlur, validateOnChange, validateOnFocus } = useValidateOn(validate, validateOn)

  const { today } = useSelector(selectUserConfiguration)

  const onChangeEligibilityType = useCallback(
    (type: uui.domain.client.rm.Eligibility['type']) => {
      switch (type) {
        case 'any':
          apiChange({ status: 'exact', value: { type } })
          break

        case 'on':
          apiChange({ status: 'exact', value: { type, dates: [today] } })
          break

        case 'by':
          apiChange({ status: 'exact', value: { type, date: today } })
          break
      }
      validateOnChange()
    },
    [apiChange, today, validateOnChange],
  )

  const onChangeDate = useCallback(
    (type: uui.domain.client.rm.Eligibility['type'], dates: Date[]) => {
      switch (type) {
        case 'any':
          apiChange({ status: 'exact', value: { type } })
          break

        case 'on': {
          const datesAsString = dates.map(d => format(d, 'yyyyMMdd'))
          apiChange({
            status: 'exact',
            value: {
              type,
              dates: datesAsString,
            },
          })
          break
        }

        case 'by': {
          const date = format(dates[0], 'yyyyMMdd')
          apiChange({
            status: 'exact',
            value: {
              type,
              date,
            },
          })
          break
        }
      }
      validateOnChange()
    },
    [apiChange, validateOnChange],
  )

  return {
    onChangeEligibilityType,
    onChangeDate,
    onBlur: validateOnBlur,
    onFocus: validateOnFocus,
  }
}
