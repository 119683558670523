import type { FormFields, FormErrors } from '../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'
import type { ValidationTexts } from '../../useTexts'

import { getDateInTimezone } from '@/server-data'

export const createValidateForm =
  (
    isToday: boolean,
    transitions: uui.domain.TimezoneTransition[],
    texts: ValidationTexts,
  ): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { type, timeRangeStart, timeRangeEnd } = formApi.getValues()

    const midnightDate: Date = new Date()
    midnightDate.setHours(0, 0, 0, 0)

    const todayAtMidnight = getDateInTimezone(midnightDate, transitions).getTime()
    const now = new Date().getTime()

    const timeRangeEndBeforeStartError: FormError<keyof FormFields> = {
      priority: 3,
      field: 'timeRangeStart',
      id: 'time-range-end-before-end-error',
      message: texts.timeRangeStartAfterEndError,
    }

    const timeRangeStartInFutureError: FormError<keyof FormFields> = {
      priority: 4,
      field: 'timeRangeStart',
      id: 'time-range-start-in-future-error',
      message: texts.timeRangeStartInFutureError,
    }

    const timeRangeEndInFutureError: FormError<keyof FormFields> = {
      priority: 5,
      field: 'timeRangeEnd',
      id: 'time-range-end-in-future-error',
      message: texts.timeRangeEndInFutureError,
    }

    const timeRangeEndBeforeStart = type === 'custom' && timeRangeStart >= timeRangeEnd

    const timeRangeStartInFuture =
      type === 'custom' && isToday && todayAtMidnight + timeRangeStart * 1000 > now

    const timeRangeEndInFuture =
      type === 'custom' && isToday && todayAtMidnight + timeRangeEnd * 1000 > now

    return {
      [timeRangeEndBeforeStartError.id]: timeRangeEndBeforeStart
        ? timeRangeEndBeforeStartError
        : null,
      [timeRangeStartInFutureError.id]: timeRangeStartInFuture ? timeRangeStartInFutureError : null,
      [timeRangeEndInFutureError.id]: timeRangeEndInFuture ? timeRangeEndInFutureError : null,
    }
  }
