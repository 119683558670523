import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'

import { useFormatSeconds } from '@/hooks'
import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    data: { orderSteps, totalServiceTime },
    close,
  } = useController()

  const formattedServiceTime = useFormatSeconds(totalServiceTime)

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      subtitle={formattedServiceTime}
      title={texts.headerTitle(orderSteps.length)}
    />
  )
}
