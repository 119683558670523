import { useCallback, useMemo, useEffect } from 'react'
import { useFormApi, useForm } from '@workwave-tidal/form-fairy'
import { MultiSelect } from '@workwave-tidal/form-ui'
import { useSelector } from 'react-redux'

import { selectTerritories } from '@/features/domain/territory'

import { type FormErrors, type FormFields } from '../formFields'
import { useTexts } from '../hooks/useTexts'

export function TerritoryFilter() {
  const texts = useTexts()
  const { options, getOptionLabel } = useTerritoriesFilterData()
  const formApi = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    const unsubscribe = formApi.subscribeToField('limitedAccess', field => {
      // toggle the required state of the territoryFilter field
      // changing the required state will invalidate the field
      formApi.setFieldRequiredState('territoryFilter', field.value)

      if (!field.value) {
        formApi.clearError('territoryFilter')
        formApi.change('territoryFilter', [])
      }
    })

    return () => {
      unsubscribe()
    }
  }, [formApi])

  return (
    <MultiSelect<'territoryFilter', FormFields, FormErrors, uui.domain.client.rm.Territory>
      name="territoryFilter"
      testId="territoryFilter"
      validateOn="blur change"
      multiSelectProps={{
        label: texts.labels.territoriesFilter,
        items: options,
        getLabel: getOptionLabel,
        inputMode: 'normal',
        optionMode: 'normal',
        texts: texts.multiSelect.territories,
      }}
    />
  )
}

export function useTerritoriesFilterData() {
  const territories = useSelector(selectTerritories)
  const { api, values } = useForm<FormFields, FormErrors>()

  const options = useMemo(() => Object.values(territories), [territories])

  const getOptionLabel = useCallback(option => option.name, [])

  const value = useMemo(() => values.territoryFilter, [values])

  const onChange = useCallback(
    (_, territories: uui.domain.client.rm.Territory[] | null) => {
      if (!territories) return

      const value = [...territories]
      api.change('territoryFilter', value)
    },
    [api],
  )

  const territoriesFilterVisible = api.getField('territoryFilter').visible
  const hasError = api.getField('territoryFilter').status === 'invalid'

  return {
    options,
    onChange,
    getOptionLabel,
    value,
    territoriesFilterVisible,
    hasError,
  }
}
