import { isDark, getColorValue, theme } from '@/styles'
import { AvatarContainer } from './hooks/useStyles'
import { DeviceIcon } from './DeviceIcon'
import { useShowText } from './hooks/useShowText'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
  size: number
  marginRight?: number
  icon?: string
}

export function DeviceAvatar(props: Props) {
  const { device, size, marginRight = 0 } = props

  const showText = useShowText(device)

  const backgroundColor = getColorValue(device.iconColor1) ?? theme.colors.$pureWhite
  const color = isDark(backgroundColor) ? theme.colors.$pureWhite : theme.colors.$nightRider
  const label = device.label
  const iconId = device.iconId

  return (
    <AvatarContainer
      size={size}
      backgroundColor={backgroundColor}
      color={color}
      marginRight={marginRight}
    >
      {showText ? label : <DeviceIcon iconId={iconId} />}
    </AvatarContainer>
  )
}
