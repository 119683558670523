import { CircularProgress } from '@mui/material'

import { LightBulb } from '@/icons'

import { BackButton } from '../../../../elements/BackButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'
import { Styler } from './components/Styler'

import { useActions } from './hooks/useActions'
import { useMapStyle } from './hooks/useMapStyle'
import { useTexts } from './useTexts'

export function MapStyle() {
  const texts = useTexts()
  const { goToOverview, currentDepots } = useMapStyle()
  const {
    methodInExecution,
    actions: { popoutDepots },
  } = useActions()

  return (
    <>
      <Styler />
      <NavigoIconButton
        onClick={popoutDepots}
        text={texts.popoutDepots(currentDepots.length)}
        Icon={
          methodInExecution === 'popoutDepots' ? (
            <CircularProgress size={16} color="primary" />
          ) : (
            <LightBulb size={12} color="$nightRider" />
          )
        }
      />
      <BackButton onClick={goToOverview} />
    </>
  )
}
