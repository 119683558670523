import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate, texts } = useIntl()
  const [api] = useState({
    getDayLabelFromDayIndex: texts.getDayLabelFromDayIndex,
    headerTitle: (count: number) =>
      translate({ id: 'modals.fitin.header.title', values: { count } }),
    date: translate({ id: 'modals.fitin.body.date' }),
    vehicle: translate({ id: 'modals.fitin.body.vehicle' }),
    insertAfter: translate({ id: 'modals.fitin.body.insertAfter' }),
    fittingOptionsTitle: translate({ id: 'modals.fitin.fittingOptions.title' }),
    fittingOptionsText: translate({ id: 'modals.fitin.fittingOptions.text' }),
    forceAssignmentTitle: translate({ id: 'modals.fitin.forcedAssignment.title' }),
    forceAssignmentText: translate({ id: 'modals.fitin.forcedAssignment.text' }),
    outOfBorderText: (
      outOfBorderCount: number,
      totalOrders: number,
      borderRestrictionFullName: string,
    ): string =>
      translate({
        id: 'modals.fitin.forcedAssignment.outOfBorderText',
        values: { outOfBorderCount, totalOrders, borderRestrictionFullName },
      }),
    multipleFittingOptionsText: translate({ id: 'modals.fitin.fittingOptions.multiple.text' }),
    multipleForceAssignmentText: translate({ id: 'modals.fitin.forcedAssignment.multiple.text' }),
    btnCancel: translate({ id: 'modals.fitin.footer.cancel' }),
    btnClose: translate({ id: 'modals.fitin.footer.close' }),
    btnBackToOrder: translate({ id: 'modals.fitin.footer.backToOrder' }),
    btnTryFitIn: translate({ id: 'modals.fitin.footer.tryFitin' }),
    btnForceFitIn: translate({ id: 'modals.fitin.footer.forceFitin' }),
    btnPrimaryTooltip: translate({ id: 'modals.fitin.footer.primaryTooltip' }),
    bestDate: translate({ id: 'modals.fitin.options.bestDate' }),
    bestVehicle: translate({ id: 'modals.fitin.options.bestVehicle' }),
    bestStep: translate({ id: 'modals.fitin.options.bestStep' }),
    tomorrow: translate({ id: 'modals.fitin.options.tomorrow' }),
    today: translate({ id: 'modals.fitin.options.today' }),
    noAvailableVehicles: translate({ id: 'modals.fitin.options.noAvailableVehicles' }),
    start: translate({ id: 'modals.fitin.options.start' }),
    end: translate({ id: 'modals.fitin.options.end' }),
    break: translate({ id: 'modals.fitin.options.break' }),
    vehicleUnavailable: translate({ id: 'modals.fitin.options.vehicleUnavailable' }),
    routeLocked: translate({ id: 'modals.fitin.options.routeLocked' }),
    routeClosed: translate({ id: 'modals.fitin.options.routeClosed' }),
    eta: translate({ id: 'modals.fitin.options.eta' }),
    yesterday: translate({ id: 'modals.fitin.options.yesterday' }),
    inProgress: translate({ id: 'modals.fitin.options.inProgress' }),
    setDateToProceed: translate({ id: 'modals.fitin.options.setDateToProceed' }),
    setVehicleToProceed: translate({ id: 'modals.fitin.options.setVehicleToProceed' }),
    setDateAndVehicleToProceed: translate({
      id: 'modals.fitin.options.setDateAndVehicleToProceed',
    }),
    disabledForReadonlyReason: translate({ id: 'modals.fitin.options.disabledForReadonlyReason' }),
    outDatedInexistentTitle: translate({ id: 'modals.fitin.outdated.inexistent.title' }),
    outDatedInexistentDescription: translate({
      id: 'modals.fitin.outdated.inexistent.description',
    }),
    outDatedAlreadyAssignedTitle: translate({
      id: 'modals.fitin.outdated.alreadyAssigned.title',
    }),
    outDatedAlreadyAssignedDescription: translate({
      id: 'modals.fitin.outdated.alreadyAssigned.description',
    }),
    dataHasBeenUpdated: translate({
      id: 'modals.fitin.dataHasBeenUpdated',
    }),

    transactionWarningCardTitle: translate({
      id: 'modals.fitin.transaction.warningCard.title',
    }),
    transactionWarningCardDescription: translate({
      id: 'modals.fitin.options.disabledForReadonlyReason',
    }),
  })

  return api
}
