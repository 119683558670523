import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../useTexts'
import { useController } from '../hooks/useController'

export function InvalidHeader() {
  const texts = useTexts()
  const {
    close,
    data: { initialSelection },
  } = useController()

  return (
    <ModalHeader
      title={texts.invalidDataEmptyTitle(initialSelection.length)}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      subtitle=""
    />
  )
}
