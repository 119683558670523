import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { useMemo } from 'react'

import { intl } from '@/intl'
import { getRecurrenceLabel } from '@/server-data'
import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: {
      trafficProfile: {
        recurrence: { type, value },
      },
    },
  } = useController()

  const { translate } = intl

  const recurrence = useMemo(
    () => getRecurrenceLabel(type, value, translate),
    [type, value, translate],
  )

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={texts.header.title(recurrence)}
    />
  )
}
