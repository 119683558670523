import type { GpsTimezone } from './types'
import type { FormFields, FormErrors } from './formFields'

import { Box } from '@mui/material'

import { useFormMeta } from '@workwave-tidal/form-fairy'
import { Form, FormHeader } from '@workwave-tidal/form-ui/layout'
import { ViewField } from '@/formUi'

import { useTexts } from './hooks/useTexts'
import { useOnSubmit } from './hooks/useOnSubmit'

import { Timezone } from './components/fields/Timezone'
import { FormFooter } from './components/FormFooter'

type Props = {
  onDiscard: () => void
  updateTimezone: (timezone: GpsTimezone) => Promise<boolean>
  account: {
    username: string
    email: string
  }
}

export function GpsAccountForm(props: Props) {
  const { onDiscard, updateTimezone, account } = props

  const texts = useTexts()

  const {
    meta: { submitting },
  } = useFormMeta<FormFields, FormErrors>()

  // create a submit function linked to
  // - the domain update function
  // - the form API
  // - the application editing status
  const onSubmit = useOnSubmit(updateTimezone, onDiscard)

  return (
    // ATTENTION: The custom style attached to the `form` is required until we fix the dynamic scrollable content.
    // see: https://workwave.atlassian.net/browse/RM-11796
    <Box width={360} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={<FormFooter onDiscard={onDiscard} onSubmit={onSubmit} submitting={submitting} />}
        testId="gps-account-form"
        onEnter={onSubmit}
        disableScroll
        fieldsSpacing={1}
        fieldsPadding="0 32px"
      >
        <ViewField label={texts.email}>{account.email}</ViewField>
        <ViewField label={texts.username}>{account.username}</ViewField>
        <Timezone />
      </Form>
    </Box>
  )
}
