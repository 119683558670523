import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { getDateFormats } from '../../../../formats'
import { useTexts } from './useTexts'

export function DateFormat() {
  const texts = useTexts()

  const dateFormats = getDateFormats()
  const options = useMemo(
    () =>
      Object.entries(dateFormats).map(([value, label]) => ({
        value,
        label,
      })),
    [dateFormats],
  )

  return (
    <RadioGroup<'dateFormat', string, FormFields, FormErrors>
      validateOn="blur focus"
      options={options}
      testId="dateFormat"
      label={texts.dateFormat}
      name="dateFormat"
    />
  )
}
