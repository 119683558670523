import { styled, keyframes } from '@mui/material/styles'
import { theme } from '@/styles'

interface Props {
  size: number
  duration?: number
}

const loadAnimation = (size: number) => keyframes`
  from {
    left: -${size}px;
  }
  65% {
    left: 100%;
  }
  100% {
    left: -${size}px;
  }
`

const StyledIndicator = styled('div')<Props>(({ size, duration = 3 }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 10,
  width: `${size}px`,
  height: '4px',
  background: theme.colors.$dodgerBlue,
  animation: `${loadAnimation(size)} ${duration}s infinite`,
}))

export function SuperCarIndicator(props: Props) {
  return <StyledIndicator {...props} />
}
