import type { FormFields, FormErrors } from '../formFields'
import type { FormConfig } from '@workwave-tidal/form-fairy'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'
import { useAppDispatch } from '@/store'

import { createFormValidation } from '../validations/createFormValidation'
import { useTexts } from '../useTexts'

function composeUniqueLabel(
  label: string,
  tenantSources: uui.domain.server.gps.telematics.TenantSource[],
) {
  const existingLabels = tenantSources.map(tenantSource => tenantSource.label)
  let uniqueLabel = label
  let i = 1
  while (existingLabels.includes(uniqueLabel)) {
    uniqueLabel = `${label} (${i})`
    i++
  }
  return uniqueLabel
}

function createInitialValues(
  source: uui.domain.server.gps.telematics.Source,
  tenantSources: uui.domain.server.gps.telematics.TenantSource[],
) {
  const label = composeUniqueLabel(source.label, tenantSources)
  switch (source.label) {
    case 'Azuga':
      return {
        label,
        sourceId: source.id,
        credentials: [{ fieldName: 'apiKey', value: '' }],
      }
    case 'Linxup':
      return {
        label,
        sourceId: source.id,
        credentials: [{ fieldName: 'apiKey', value: '' }],
      }
    case 'Verizon Connect':
    case 'Verizon Connect (US)':
    case 'Verizon Connect (EU)':
      return {
        label,
        sourceId: source.id,
        credentials: [
          { fieldName: 'username', value: '' },
          { fieldName: 'password', value: '' },
        ],
      }
    case 'Geotab':
      return {
        label,
        sourceId: source.id,
        credentials: [
          { fieldName: 'username', value: '' },
          { fieldName: 'password', value: '' },
          { fieldName: 'database', value: '' },
        ],
      }
  }
}

export function useFormConfig(
  source: uui.domain.server.gps.telematics.Source,
): FormConfig<FormFields, FormErrors> {
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)
  const dispatch = useAppDispatch()
  const texts = useTexts()

  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    overrideValues: createInitialValues(source, tenantSources),
    validations: createFormValidation(source, dispatch, texts),
  }))

  return formConfig
}
