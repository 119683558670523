import { useMemo } from 'react'

import { Stack } from '@mui/material'

import { Block, InputFieldText } from '@/forms-legacy'
import { H4 } from '@/local/components'

import { useController } from '../../../../../../hooks/useController'
import { useControllerActions } from '../../../../../../hooks/useControllerActions'
import { useValidations } from '../../../../../../hooks/useValidations'
import { ErrorMessage } from '../ErrorMessage'

import { useTexts } from './useTexts'

export function Sms() {
  const texts = useTexts()
  const { data } = useController()
  const { onPhoneChange } = useControllerActions()
  const { phone: validation } = useValidations()

  const errorMessage = useMemo(() => {
    switch (validation) {
      case 'empty':
        return texts.phoneErrorEmpty

      case 'invalidFormat':
        return texts.phoneErrorFormat

      case 'valid':
      case 'pristine':
      default:
        return ''
    }
  }, [texts, validation])

  if (data.messageType !== 'sms') return null
  const phone = data?.phone ?? ''

  return (
    <Stack
      direction="column"
      sx={{ marginBottom: '5px' }}
      width="100%"
      data-testid="send-message-to-driver-sms-type-panel"
    >
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.phoneTitle}
        </H4>
      </Block>

      <Block>
        <InputFieldText tabIndex={1} name="phone" onChange={onPhoneChange} value={phone} />
      </Block>

      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Stack>
  )
}
