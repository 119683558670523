import type { FormFields } from '../../../formFields'

import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from '../hooks/useTexts'

export function Email() {
  const texts = useTexts()

  return (
    <TextField<'email', FormFields>
      name="email"
      testId="email"
      label={texts.labels.email}
      placeholder={texts.labels.email}
      validateOn="change blur"
      debounce={200}
    />
  )
}
