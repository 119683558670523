import { Box } from '@mui/material'
import { useDepotsNavigator } from '../../hooks/useDepotsNavigator'
import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Details() {
  const { currentDepots } = useDepotsNavigator()

  if (currentDepots.length === 0) return null

  return (
    <Box width="100%" flex="0 1 auto" sx={growStyle}>
      {currentDepots.length > 1 ? (
        <Bulk depots={currentDepots} />
      ) : (
        <Single depot={currentDepots[0]} />
      )}
    </Box>
  )
}
