import { type SchedulerProFeaturesConfigType } from '@bryntum/schedulerpro'
import { isValid, parse } from 'date-fns/esm'
import { renderToStaticMarkup } from 'react-dom/server'

import { selectUserConfiguration } from '@/features/domain/user'
import { getDayLabelFromIndex, intl } from '@/intl'
import { timeUtils } from '@/server-data'
import { store } from '@/store'

import { getSchedulerPreferences } from '../../getSchedulerPreferences'

export const createGroupFeature = (): SchedulerProFeaturesConfigType['group'] => {
  return {
    field: getSchedulerPreferences().groupBy === 'vehicleId' ? 'vehicleName' : 'dateAsString',

    headerHeight: 32,

    renderer: params => {
      // if we are grouping by date the groupRowFor will be a date formatted as yyyyMMdd
      // otherwise it will be a vehicle name

      const {
        //@ts-expect-error
        cellElement,
        groupRowFor,
        column,
        record,
        grid,
      } = params

      if (column.getData('field') !== 'vehicleAvatar') return ''

      cellElement.classList.add('b-group-title')

      const parsedDate = parse(groupRowFor, 'yyyyMMdd', new Date())

      //@ts-expect-error
      const collapsed = record.isCollapsed

      const title =
        getSchedulerPreferences().groupBy === 'vehicleId'
          ? intl.translate({ id: 'scheduler.group.header.days' })
          : intl.translate({ id: 'scheduler.group.header.vehicles' })

      return renderToStaticMarkup(
        <div className="o-scheduler-group-header" style={{ minWidth: grid.width }}>
          <div className="o-scheduler-group-title-container">
            <div className="o-scheduler-group-title-container-caret">
              {collapsed ? (
                <svg viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
                  <path d="m7 10 5 5 5-5z"></path>
                </svg>
              ) : (
                <svg viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
                  <path d="m7 14 5-5 5 5z"></path>
                </svg>
              )}
            </div>

            {isValid(parsedDate)
              ? `${getDayLabelFromIndex(parsedDate.getDay(), 'short')} • ${timeUtils.formatDate(
                  selectUserConfiguration(store.getState()),
                )(parsedDate)}`
              : groupRowFor}
          </div>
          <div className="o-scheduler-group-header-counters">
            <div className="group-type">{`${title}:`}</div>

            <button className="scheduler-group-button used-routes-chip">
              <div className="text">
                {intl.translate({ id: 'scheduler.group.header.usedRoutes' })}
              </div>
              <div className="counter"></div>
            </button>

            <button className="scheduler-group-button empty-routes-chip">
              <div className="text">
                {intl.translate({ id: 'scheduler.group.header.emptyRoutes' })}
              </div>
              <div className="counter"></div>
            </button>

            <button className="scheduler-group-button unavailable-routes-chip">
              <div className="text">
                {intl.translate({ id: 'scheduler.group.header.unavailableRoutes' })}
              </div>
              <div className="counter"></div>
            </button>
          </div>
        </div>,
      )
    },
  }
}
