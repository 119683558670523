import type {
  CalendarClickType,
  MonthlyValidSelection,
  MonthCalendarProps as Props,
  YYYYMMDD,
} from '../../typings'

import { getDate } from 'date-fns/esm'
import { useCallback } from 'react'

import { Month } from '../../components/Month'
import { useMonths } from '../../hooks/useMonths'
import { parseDate } from '../../utils/parseDate'

import { Stack } from '@mui/material'
import { useMonthDays } from './useMonthDays'
import { useSelectionData } from './useSelectionData'

const monthWith31Days = new Date('2020-10-01')
const minDateAsString = '20201001'
const maxDateAsString = '20201031'

export function Monthly(props: Props) {
  const { highlightedDays, onChange, selection, allowAutoMonthHeight, dotColor } = props

  const months = useMonths(monthWith31Days, false, 1, minDateAsString, maxDateAsString, 'd')

  const selectionData = useSelectionData(selection)

  const daysByMonth = useMonthDays({
    months,
    selection: selectionData,
    highlightedDays,
  })

  const handleOnClick = useCallback(
    (day?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (day) {
        const dayDate = getDate(parseDate(day)) as MonthlyValidSelection
        onChange(dayDate, selection, type)
      }
    },
    [onChange, selection],
  )

  return (
    <Stack direction="column" height="auto" alignItems="center" flexShrink={0}>
      <Stack direction="row" width="auto">
        {months.map(
          (month, index) =>
            daysByMonth[month.id] && (
              <Month
                index={index}
                key={month.id}
                dotColor={dotColor}
                onClick={handleOnClick}
                days={daysByMonth[month.id]}
                allowAutoMonthHeight={allowAutoMonthHeight}
              />
            ),
        )}
      </Stack>
    </Stack>
  )
}
