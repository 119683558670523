import { Tooltip } from '@/components/primitives/Tooltip'
import { NoTraffic, Traffic } from '@/icons'
import { useShowMapTrafficTiles } from '@/map'

import { StyledButton } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

export function LiveTrafficToggle() {
  const [active, toggleLiveTraffic] = useShowMapTrafficTiles()
  const texts = useTexts()

  const testid = 'map-controls-live-traffic'

  return (
    <Tooltip placement="bottom" title={active ? texts.liveTrafficOff : texts.liveTrafficOn}>
      <StyledButton onClick={toggleLiveTraffic} data-testid={testid} data-trackid={testid}>
        {active ? (
          <Traffic size={18} color="$nightRider" />
        ) : (
          <NoTraffic size={18} color="$nightRider" />
        )}
      </StyledButton>
    </Tooltip>
  )
}
