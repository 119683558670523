import type { MouseEvent } from 'react'

import { useEffect, useMemo, useState } from 'react'
import {
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
  Switch,
  Radio,
  Box,
} from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'

import { useContextualMenuActions } from './hooks/useContextualMenuActions'
import { SwitchSection } from './components/SwitchSection'
import { RadioSection } from './components/RadioSection'
import { useTexts } from './useTexts'
import { checkAllExpanded } from '../../../../utils/checkAllExpanded'
import { getOperatingSystem } from '@/utils'
import { ExpandCollapseContainer } from './components/ExpandCollapseContainer'

interface Props {
  removeUnavailableRoutesFromSelection: () => void
  areUnavailableRoutesVisible: boolean
  setShowUnavailableRoutes: (show: boolean) => void
  approvedRoutesVisible: boolean
  setShowApprovedRoutes: (value: boolean) => void
  setUnitMode: (value: 'percentage' | 'absolute') => void
  setGroupBy: (value: 'dateAsString' | 'vehicleId') => void
  setAllExpanded: (value: boolean) => void
  unavailableRoutes: number
  toggleSelectAll: () => void
  toggleExpandCollapseAll: () => void
  selectedRoutes: workwave.DeepReadonly<string[]>
  numberOfRoutes: number
  routesCounter: number
  allSelected: boolean
  allExpanded: boolean
  editColumns: () => void
  unitMode: 'percentage' | 'absolute'
  groupBy: 'dateAsString' | 'vehicleId'
}

const firstRadioBoxStyle = { marginTop: 1 }

function noop(e: MouseEvent) {
  e.preventDefault()
}

const radioComponentProps = { typography: { fontSize: 15 } }

export function ContextualMenu(props: Props) {
  const {
    removeUnavailableRoutesFromSelection,
    areUnavailableRoutesVisible,
    setShowUnavailableRoutes,
    setShowApprovedRoutes,
    approvedRoutesVisible,
    unavailableRoutes,
    toggleSelectAll,
    toggleExpandCollapseAll,
    routesCounter,
    allSelected,
    allExpanded,
    editColumns,
    setUnitMode,
    setGroupBy,
    setAllExpanded,
    unitMode,
    groupBy,
  } = props

  const [open, setOpen] = useState(false)
  const [shortcutText, setShortcutText] = useState('')

  const actions = useContextualMenuActions(
    setGroupBy,
    setUnitMode,
    setShowApprovedRoutes,
    setShowUnavailableRoutes,
    toggleSelectAll,
    toggleExpandCollapseAll,
    setOpen,
    removeUnavailableRoutesFromSelection,
    editColumns,
  )

  const texts = useTexts()

  const currentOs = useMemo(() => getOperatingSystem(), [])

  useEffect(() => {
    setAllExpanded(checkAllExpanded())
  }, [open])

  useEffect(() => {
    setShortcutText(getShortcutText())
  }, [allExpanded, currentOs])

  const getShortcutText = () => {
    const isMac = currentOs === 'MacOS'

    if (allExpanded) {
      return isMac ? '⌘⌃C' : 'Alt+CTRL+C'
    } else {
      return isMac ? '⌘⌃E' : 'Alt+CTRL+E'
    }
  }

  return (
    <DropdownMenu
      setOpen={setOpen}
      open={open}
      autoWidth
      trigger={
        // href="#" onClick={noop} ==> Hack to have an <a> component and not a button
        <IconButton
          href="#"
          onClick={noop}
          data-testid="scheduler-more-menu"
          data-trackid="scheduler-more-menu"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <div
        data-testid="scheduler-contextual-menu-container"
        data-trackid="scheduler-contextual-menu-container"
      >
        <DropdownMenuItem
          size="$l"
          weight="$regular"
          onClick={actions.onSelectAll}
          data-testid="scheduler-contextual-menu-select-all"
          data-trackid="scheduler-contextual-menu-select-all"
        >
          <Box paddingY={1} component="span">
            {allSelected ? texts.deselectAll(routesCounter) : texts.selectAll(routesCounter)}
          </Box>
        </DropdownMenuItem>

        <DropdownMenuItem
          size="$l"
          weight="$regular"
          onClick={actions.onExpandCollapseAll}
          data-testid="scheduler-contextual-menu-expand-collapse-all"
          data-trackid="scheduler-contextual-menu-expand-collapse-all"
        >
          <ExpandCollapseContainer>
            <Box paddingY={1} component="span">
              {allExpanded ? texts.collapseAll(routesCounter) : texts.expandAll(routesCounter)}
            </Box>
            <Typography color="text.secondary" sx={{ fontSize: 12 }}>
              {shortcutText}
            </Typography>
          </ExpandCollapseContainer>
        </DropdownMenuItem>

        <DropdownMenuItem
          size="$l"
          weight="$regular"
          onClick={actions.onEditColumns}
          data-testid="scheduler-contextual-menu-edit-columns"
          data-trackid="scheduler-contextual-menu-edit-columns"
        >
          <Box paddingY={1} component="span">
            {texts.editColumns}
          </Box>
        </DropdownMenuItem>

        <SwitchSection>
          {texts.unavailableVehicles(unavailableRoutes)}
          <Switch
            checked={areUnavailableRoutesVisible}
            onChange={actions.onShowUnavailableRoutesChange}
            data-testid="scheduler-contextual-menu-show-unavailable-routes"
            data-trackid="scheduler-contextual-menu-show-unavailable-routes"
          />
        </SwitchSection>

        <SwitchSection>
          {texts.dispatchedRoutes}
          <Switch
            checked={approvedRoutesVisible}
            onChange={actions.onShowApprovedRoutesChange}
            data-testid="scheduler-contextual-menu-show-approved-routes"
            data-trackid="scheduler-contextual-menu-show-approved-routes"
          />
        </SwitchSection>

        <RadioSection sx={firstRadioBoxStyle}>
          <Typography color="grey.700" fontSize={15}>
            {texts.values}
          </Typography>

          <RadioGroup onChange={actions.onUnitModeChange}>
            <FormControlLabel
              value="percentage"
              control={<Radio />}
              label={texts.percentage}
              checked={unitMode === 'percentage'}
              componentsProps={radioComponentProps}
            />
            <FormControlLabel
              value="absolute"
              control={<Radio />}
              label={texts.absolute}
              checked={unitMode === 'absolute'}
              componentsProps={radioComponentProps}
            />
          </RadioGroup>
        </RadioSection>

        <RadioSection>
          <Typography color="grey.700" fontSize={15}>
            {texts.group}
          </Typography>

          <RadioGroup onChange={actions.onGroupByChange}>
            <FormControlLabel
              value="vehicleId"
              control={<Radio />}
              label={texts.byVehicle}
              checked={groupBy === 'vehicleId'}
              componentsProps={radioComponentProps}
            />
            <FormControlLabel
              value="dateAsString"
              control={<Radio />}
              label={texts.byDate}
              checked={groupBy === 'dateAsString'}
              componentsProps={radioComponentProps}
            />
          </RadioGroup>
        </RadioSection>
      </div>
    </DropdownMenu>
  )
}
