import { Modal } from '@/components/Modal'
import { Content } from './Content'
import { modalId } from '../hooks/useController'

interface Props {
  regions: string[]
}

export function Root(props: Props) {
  return (
    <Modal modalId={modalId}>
      <Content regions={props.regions} />
    </Modal>
  )
}
