import { CategoryButton } from '@/components/Navigo/components/CategoryButton'
import { CategoryIcon } from '@/components/Navigo/components/CategoryIcon'
import { FormatDate } from '@/components/smartUtils/time/FormatDate'
import { Text } from '@/local/components'

import { useOrdersData } from './hooks/useOrdersData'
import { useSelectOrderSteps } from './hooks/useSelectOrderSteps'
import { useTexts } from './useTexts'

import { Stack } from '@mui/material'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

export function OrdersButton(props: Props) {
  const { routeInfos } = props

  const { totalViolations, orderStepIds, activeRange } = useOrdersData(routeInfos)
  const selectOrderSteps = useSelectOrderSteps(orderStepIds)
  const texts = useTexts()

  const title = `${orderStepIds.length} ${texts.orders(orderStepIds.length)}`

  return (
    <CategoryButton
      badge={totalViolations > 0 ? totalViolations : undefined}
      data-trackid="navigo-routes-go-to-orders-button"
      data-testid="navigo-routes-go-to-orders-button"
      Icon={<CategoryIcon category="orderSteps" />}
      disabled={orderStepIds.length === 0}
      onClick={selectOrderSteps}
      autoWidth
    >
      <Stack
        direction="column"
        height="100%"
        flexShrink={0}
        width="auto"
        justifyContent="center"
        alignItems="start"
      >
        {title}
        {activeRange && (
          <Text size="$p3" weight="$regular">
            {texts.on} <FormatDate source={activeRange.startDate} shortFormat />
            {activeRange.endDate && ' - '}
            {activeRange.endDate && <FormatDate source={activeRange.endDate} shortFormat />}
          </Text>
        )}
      </Stack>
    </CategoryButton>
  )
}
