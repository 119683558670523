import { mapAtom } from '../map'

import { createLiveTrafficLayer } from './createLiveTrafficLayer'
import { getLiveTrafficLayer } from './getLiveTrafficLayer'
import { getLiveTrafficTileUrl } from './tiles'

export function createOrUpdateLiveTrafficLayer(showTraffic: boolean) {
  let liveTrafficLayer = getLiveTrafficLayer()

  const { authenticationToken } = mapAtom

  if (!authenticationToken) {
    throw new Error(`The authentication token is not valid.`)
  }

  if (liveTrafficLayer) {
    const source = liveTrafficLayer.getSource()

    if (!source) {
      throw new Error(`The Map Tile Source is not valid.`)
    }

    if (!authenticationToken) {
      throw new Error(`The authentication token is not valid.`)
    }

    source.setUrl(getLiveTrafficTileUrl())
  } else {
    // initialize Map layer only once
    liveTrafficLayer = createLiveTrafficLayer(getLiveTrafficTileUrl(), showTraffic)

    liveTrafficLayer.set('uid', 'tilesLayer')

    // add it to the map below all the other layers
    mapAtom.map.getLayers().insertAt(1, liveTrafficLayer)
  }
}
