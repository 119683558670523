import { useCallback, type MouseEvent } from 'react'
import { useForm, type FormValues } from '@workwave-tidal/form-fairy'
import { useAppDispatch } from '@/store'
import { updateDrivers } from '@/features/domain/driver'
import { isDeepEqual } from '@/server-data'
import { useResetEditingState } from '@/atoms'
import { useNotification } from '@/hooks'

import { type FormErrors, type FormFields } from '../../../formFields'

export function useFormActions(drivers: uui.domain.client.rm.ExtendedDriver[]) {
  const stopEditing = useResetEditingState()
  const { api } = useForm<FormFields, FormErrors>()

  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onEnter = useCallback(async () => {
    try {
      const formValues = api.getValues()

      const formValueUnchanged = isDeepEqual(formValues, api.getInitialValues())

      if (api.getMeta().status === 'pristine' || formValueUnchanged) {
        stopEditing()
        return
      }

      api.setSubmitting(true)

      await api.waitForValidation()

      const valid =
        api.getMeta().status === 'indeterminate'
          ? await api.validate()
          : api.getMeta().status === 'valid'

      if (!valid) {
        api.setSubmitting(false)
        return
      }

      const thunkResult = await dispatch(updateDrivers(computeDriversValues(formValues, drivers)))

      if (!updateDrivers.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      stopEditing()
    } catch (error) {
      toast.error(error)
    } finally {
      api.setSubmitting(false)
    }
  }, [api, stopEditing, dispatch, toast, drivers])

  const onSubmit = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      onEnter()
    },
    [onEnter],
  )

  return {
    onDiscard: stopEditing,
    onSubmit,
    onEnter,
  }
}

/**
 * Takes the formValues, filter only the `exact` values
 *
 * @param formValues form values
 * @returns uui.domain.ui.forms.BulkDriverUpdate
 */
function computeDriversValues(
  formValues: FormValues<FormFields>,
  drivers: uui.domain.client.rm.ExtendedDriver[],
): uui.domain.ui.forms.BulkDriverUpdate {
  const bulkDriverUpdate: uui.domain.ui.forms.BulkDriverUpdate = {
    driverIds: drivers.map(d => d.id),
  }

  for (const [key, value] of Object.entries(formValues)) {
    if (value.status === 'exact') {
      const val =
        key === 'mobileFutureDaysVisibility' ? parseInt(value.value as string) : value.value
      bulkDriverUpdate[key] = val
    }
  }

  return bulkDriverUpdate
}
