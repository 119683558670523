import { form } from '../formState'
import { useTexts } from '../hooks/useTexts'

export function Name() {
  const texts = useTexts()

  form.useAddFieldValidation<'name'>('name', (field, _formApi) => {
    if (!field.value) {
      return texts.errorName
    }
  })

  return (
    <form.TextField label={texts.labelName} validateOn="change" name="name" testId="region-name" />
  )
}
