import { readFromPersistentStorage, removeFromPersistentStorage } from '@/local/server-data'

export async function collectListOptions() {
  const listOptions =
    await readFromPersistentStorage<uui.domain.StoredListOptions>('storedListOptions')

  const clearListOptions = () => {
    try {
      removeFromPersistentStorage('storedListOptions')
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error)
      }
    }
  }

  return { listOptions, clearListOptions }
}
