import { NumericStepperField } from '@/forms-legacy'
import { useMemo } from 'react'
import { StyledRoot } from './useStyles'

interface Props {
  disabled: boolean
}

export function AdditionalDrivingTimeSec(props: Props) {
  const { disabled } = props

  const wrappedInputProps = useMemo(
    () => ({
      step: 1,
      canBeNaN: false,
      showResetIcon: true,
      defaultValue: 1,
    }),
    [],
  )

  return (
    <StyledRoot disabled={disabled}>
      <NumericStepperField
        name="additionalDrivingTimeSec"
        label=""
        parse={minToSec}
        format={secToMin}
        wrappedInputProps={wrappedInputProps}
        half
      />
    </StyledRoot>
  )
}

function secToMin(seconds: number): number {
  return Math.round(seconds / 60)
}

function minToSec(minutes: number): number {
  return minutes * 60
}
