import type { ReactNode } from 'react'

import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

interface Props {
  children?: ReactNode
}
export const StyledStack = styled(Stack)({
  '& > * + *': {
    marginLeft: 8,
  },
})

export function ButtonsContainer(props: Props) {
  return (
    <StyledStack direction="row" height="100%" width="auto">
      {props.children}
    </StyledStack>
  )
}
