import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { UsernameAvailable } from '../useIsUsernameAvailable'
import type { Texts } from '../useTexts'

import { useValidateEmail } from '@/hooks'

import { createValidateEmail } from './createValidateEmail'
import { createValidateName } from './createValidateName'
import { createValidateUsername } from './createValidateUsername'
import { createValidateSurname } from './createValidateSurname'
import { createValidateCourierFilter } from './createValidateCourierFilter'
import { createValidateCompanies } from './createValidateCompanies'
import { createValidateTerritoryFilter } from './createValidateTerritoryFilter'

type ValidateEmail = ReturnType<typeof useValidateEmail>

export function createValidations(
  texts: Texts,
  validateEmail: ValidateEmail,
  isAvailableUsername: UsernameAvailable,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'email',
      fields: 'email',
      validator: createValidateEmail(texts, validateEmail),
    },
    {
      id: 'name',
      fields: 'name',
      validator: createValidateName(texts),
    },
    {
      id: 'username',
      fields: 'username',
      validator: createValidateUsername(texts, isAvailableUsername),
    },
    {
      id: 'surname',
      fields: 'surname',
      validator: createValidateSurname(texts),
    },
    {
      id: 'courierFilter',
      fields: 'courierFilter',
      validator: createValidateCourierFilter(texts),
    },
    {
      id: 'territoryFilter',
      fields: 'territoryFilter',
      validator: createValidateTerritoryFilter(texts),
    },
    {
      id: 'companies',
      fields: 'companies',
      validator: createValidateCompanies(texts),
    },
  ]
}
