import { useReadOnlyTooltipData } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { Stack, Typography } from '@mui/material'
import {
  type ActionBarItemProps,
  ActionBarItem as TidalActionBarItem,
} from '@workwave-tidal/core/components/ActionBar'

interface Props extends ActionBarItemProps {
  disableWhenReadOnly: boolean
}

/**
 * Renders a wrapped ActionBarItem that respects read-only mode.
 */
export function ActionBarItem(props: Props) {
  const {
    tooltip: providedTooltip,
    disabled: providedDisabled,
    disableWhenReadOnly,
    ...rest
  } = props
  const { preventEditing, title, subtitle } = useReadOnlyTooltipData()

  const tooltip =
    preventEditing && disableWhenReadOnly ? (
      <Stack>
        <Typography fontSize="inherit" fontWeight="inherit" lineHeight="inherit" textAlign="center">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            color="divider"
            fontSize="inherit"
            fontWeight="inherit"
            lineHeight="inherit"
            textAlign="center"
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
    ) : (
      providedTooltip
    )
  const disabled = (preventEditing && disableWhenReadOnly) || providedDisabled

  return <TidalActionBarItem tooltip={tooltip} disabled={disabled} {...rest} />
}
