import { components } from './components'

export const MuiTheme = {
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1630,
      xl: 1920,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        action: {
          selectedOpacity: 0.16,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none' as const,
    },
  },
}
