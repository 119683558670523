import { Box, Stack, styled } from '@mui/material'

import { theme } from '@/styles'

export function TabsContainer(props) {
  return (
    <Container>
      <Stack direction="row" height="100%" alignItems="center">
        {props.children}
      </Stack>
    </Container>
  )
}

const Container = styled(Box, { name: 'Container' })({
  borderRadius: '5px',
  width: '100%',
  height: '24px',
  backgroundColor: theme.colors.$silver,
  padding: '2px',
})
