import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { updateUserUiDataSettings } from '@/features/domain/ui'
import { selectMapConfig } from '@/features/domain/user'
import { useAppDispatch } from '@/store'

export const useShowMapTrafficTiles = () => {
  const dispatch = useAppDispatch()
  const mapConfig = useSelector(selectMapConfig)

  const toggleShowTraffic = useCallback(async () => {
    dispatch(
      updateUserUiDataSettings({
        mapConfig: {
          tile: mapConfig.tile,
          showTraffic: !mapConfig.showTraffic,
        },
      }),
    )
  }, [mapConfig])

  return [mapConfig.showTraffic, toggleShowTraffic] as const
}
