import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'

import { ModalHeader } from '@/components/Modal'

import { useTexts } from '../hooks/useTexts'
import { useController } from '../hooks/useController'

export function Header() {
  const texts = useTexts()
  const {
    close,
    data: { selectedRoutes },
  } = useController()

  return (
    <ModalHeader
      title={texts.title(selectedRoutes.length)}
      actions={[
        <ActionBarItem
          icon={<Close />}
          key="close"
          onClick={close}
          label=""
          testId="export-route-close"
          data-trackid="export-route-close"
        />,
      ]}
    />
  )
}
