import { Box } from '@mui/material'
import { styled } from '@mui/system'

interface Props {
  size: number
  color: string
}

const VehicleColor = styled(Box)<Props>(({ size, color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  width: `${size}px`,
  height: `${size}px`,
  marginRight: '4px',
  flexShrink: 0,
}))

export default VehicleColor
