import type { FormEvent } from 'react'
import type { FormFields, FormErrors } from '../../formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'

import { computeBulkDataFromFormValues } from './computeBulkDataFromFormValues'

export function useOnSubmit(
  updateOrders: (data: uui.domain.ui.forms.BulkOrderUpdate) => Promise<boolean>,
  clearEditingState: () => void,
  orders: uui.domain.client.rm.ExtendedOrderStep[],
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  return useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault()
        formApi.setSubmitting(true)

        const valid = await formApi.validate()

        if (!valid) {
          formApi.setSubmitting(false)
          return
        }

        const formValues = formApi.getValues()

        const bulkData = computeBulkDataFromFormValues(formValues, orders)

        const result = await updateOrders(bulkData)

        // Clear FormState submitting mode
        formApi.setSubmitting(false)

        // if the update has been completed successfully let's close the form
        if (result) clearEditingState()
      } catch (e) {
        formApi.setSubmitting(false)
      }
    },
    [formApi, updateOrders, clearEditingState, orders],
  )
}
