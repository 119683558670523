import { styled } from '@mui/material/styles'
import { Box, Paper } from '@mui/material'
import { theme } from '@local/components'

export const StyledPaper = styled(Paper)(() => ({
  backgroundColor: theme.colors.$pureWhite,
  height: '100%',
  width: 320,
}))

export const StyledBox = styled(Box)(({ theme: muiTheme }) => ({
  marginRight: muiTheme.spacing(2),
  maxHeight: '100%',
  padding: muiTheme.spacing(2),
  paddingRight: 0,
}))
