import type { FormValues } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

export function useOnSubmit(
  updateLanguageAndFormats: (data: FormValues<FormFields>) => Promise<boolean>,
  clearEditingState: () => void,
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)

      await formApi.waitForValidation()

      const valid =
        formApi.getMeta().status === 'indeterminate'
          ? await formApi.validate()
          : formApi.getMeta().status === 'valid'

      if (!valid) {
        formApi.setSubmitting(false)
        return
      }

      const formValues = formApi.getValues()
      const result = await updateLanguageAndFormats(formValues)

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      // if the update has been completed successfully let's close the form
      if (result) clearEditingState()
    } catch (e) {
      formApi.setSubmitting(false)
    }
  }, [formApi, updateLanguageAndFormats, isUnmounted, clearEditingState])
}
