import type { TestValues } from '../types'
import type { FormErrors, FormFields } from '../hooks/formFields'
import type { NotificationFormType } from '@/notificationForms'

import { Box } from '@mui/material'
import { FormProvider } from '@workwave-tidal/form-ui/layout'

import { FormValidationIndicator } from '@/formUi'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { formFields } from '../hooks/formFields'

import { Footer } from './Footer'
import { Header } from './Header'
import { Body } from './Body'

interface Props {
  notification: NotificationFormType
  values: TestValues
}

export function Content(props: Props) {
  const { notification, values } = props
  const { ready } = useConfigureController(notification, values)

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors>
      name="test-notification-phone-form"
      fields={formFields}
      verbose={false}
    >
      <ModalContent header={<Header />} footer={<Footer />}>
        <Box paddingTop={2}>
          <FormValidationIndicator<FormFields, FormErrors> size={100} />
          <Body />
        </Box>
      </ModalContent>
    </FormProvider>
  )
}
