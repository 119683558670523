import type { ReactNode } from 'react'

import { Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { usePlacement } from './hooks/usePlacement'

interface Props {
  open: boolean
  onClose: () => void
  placement: 'left' | 'right'
  anchorEl: HTMLLIElement | null
  children?: ReactNode
}

const StyledMenu = styled(Menu)({
  pointerEvents: 'none',
})

const StyledChildrenContainer = styled('div')({
  pointerEvents: 'auto',
  width: 214,
})

export function SubMenu(props: Props) {
  const { open, anchorEl, onClose } = props
  const { anchorOrigin, transformOrigin } = usePlacement(props.placement)

  return (
    <StyledMenu
      disableAutoFocus
      open={open}
      autoFocus={false}
      onClose={onClose}
      anchorEl={anchorEl}
      disableEnforceFocus
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <StyledChildrenContainer>{props.children}</StyledChildrenContainer>
    </StyledMenu>
  )
}
