import type { ReactNode } from 'react'

import { useEffect, useState } from 'react'

import { useToggleSidebar } from '@/hooks'

import { Box, Stack } from '@mui/material'

import { theme } from '@/styles'

import { HoverSensor, ToggleSidebarButton } from './components/ToggleSidebarButton'

const sidebarWidth = 320
const collapsedSidebarWidth = 18

interface Props {
  children?: ReactNode
}

export function Sidebar(props: Props) {
  const { isSidebarOpen, toggleSidebar } = useToggleSidebar()

  const [width, setWidth] = useState(sidebarWidth)

  useEffect(() => {
    setWidth(isSidebarOpen ? sidebarWidth : collapsedSidebarWidth)
  }, [isSidebarOpen])

  return (
    <Box width="auto" height="100%" flex="0 1 auto">
      <Stack
        direction="column"
        height="100%"
        position="relative"
        width={width}
        sx={{ background: theme.colors.$alabaster }}
      >
        <HoverSensor collapsed={!isSidebarOpen}>
          {isSidebarOpen && props.children}
          <ToggleSidebarButton toggle={toggleSidebar} collapsed={!isSidebarOpen} />
        </HoverSensor>
      </Stack>
    </Box>
  )
}
