import { DropdownMenu, DropdownMenuItem } from '@/components/DropdownMenu'
import LaunchIcon from '@mui/icons-material/Launch'
import { Divider } from '@mui/material'
import { useCallback, useState } from 'react'

import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Export } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  disabled: boolean
  multiple: boolean
  isSimulation: boolean
  onExportManifest: () => void
  canExportInSimulation: boolean
  onShowExportRoutesModal: () => void
  canExportDispatchedManifest: boolean
  onExportDispatchedManifest: () => void
}

export function ExportRoutesButton(props: Props) {
  const {
    disabled,
    multiple,
    isSimulation,
    onExportManifest,
    canExportInSimulation,
    onShowExportRoutesModal,
    onExportDispatchedManifest,
    canExportDispatchedManifest,
  } = props
  const [open, setOpen] = useState(false)
  const texts = useTexts()

  const handleOnShowExportRoutesModal = useCallback(() => {
    setOpen(false)
    onShowExportRoutesModal()
  }, [onShowExportRoutesModal])

  const handleOnExportManifest = useCallback(() => {
    setOpen(false)
    onExportManifest()
  }, [onExportManifest])

  const handleOnExportDispatchedManifest = useCallback(() => {
    setOpen(false)
    onExportDispatchedManifest()
  }, [onExportDispatchedManifest])

  const testid = isSimulation
    ? 'navigo-routes-overview-actions-export-routes-simulation'
    : 'navigo-routes-overview-actions-export-routes'

  if (disabled) {
    const title =
      isSimulation && !canExportInSimulation
        ? texts.cannotExportInSimulation
        : texts.routesEmpty(multiple)

    return (
      <Tooltip title={title} disabled={false} placement="top">
        <NavigoIconButton
          onClick={() => setOpen(true)}
          disabled
          text={texts.export}
          className="pendo__navigo-export-routes"
          Icon={<Export size={16} color="$silver" />}
          testid={testid}
        />
      </Tooltip>
    )
  }

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      anchorOrigin={{ vertical: -90, horizontal: 0 }}
      trigger={
        <Tooltip title={texts.routesEmpty(multiple)} disabled placement="top">
          <NavigoIconButton
            as="span"
            onClick={() => setOpen(true)}
            text={texts.export}
            className="pendo__navigo-export-routes"
            Icon={<Export size={16} color={'$nightRider'} />}
            testid={testid}
          />
        </Tooltip>
      }
    >
      <DropdownMenuItem
        onClick={handleOnShowExportRoutesModal}
        testid="navigo-routes-overview-actions-export-routes-submenu-export-route"
      >
        {texts.exportRoute(multiple)}
      </DropdownMenuItem>
      <Divider />
      <DropdownMenuItem
        onClick={handleOnExportManifest}
        Icon={<LaunchIcon fontSize="small" />}
        testid="navigo-routes-overview-actions-export-routes-submenu-export-current-manifest"
      >
        {texts.exportManifest(multiple)}
      </DropdownMenuItem>
      <>
        {canExportDispatchedManifest && !isSimulation && (
          <DropdownMenuItem
            onClick={handleOnExportDispatchedManifest}
            Icon={<LaunchIcon fontSize="small" />}
            testid="navigo-routes-overview-actions-export-routes-submenu-export-dispatched-manifest"
          >
            {texts.exportDispatchedManifest(multiple)}
          </DropdownMenuItem>
        )}
      </>
    </DropdownMenu>
  )
}
