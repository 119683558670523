import { getDataFromLocalStorage } from '../utils'

export function collectSchedulerPreferences() {
  const schedulerPreferences = getDataFromLocalStorage(schedulerPreferencesLocalStorageId)

  return [
    schedulerPreferences,
    () => {
      localStorage.removeItem(schedulerPreferencesLocalStorageId)
    },
  ]
}

const schedulerPreferencesLocalStorageId =
  'routemanager/v3/atoms/local/scheduler/schedulerPreferences'
