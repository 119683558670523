import { CircularProgress } from '@mui/material'

import { OrdersVisible, OrdersVisibleMinimized } from '@/icons'

import { Stack } from '@mui/material'

import { Text } from '@/local/components'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { ButtonsContainer } from './components/ButtonsContainer'
import { StylerContainer } from './components/StylerContainer'
import { useTexts } from './useTexts'

interface Props {
  ids: string[]
  hideOrders: () => Promise<void>
  showOrders: () => Promise<void>
  maximizeOrders: () => Promise<void>
  methodInExecution: 'popoutOrders' | 'maximizeOrders' | 'showOrders' | 'hideOrders' | null
  activeMapStyle?: uui.domain.ui.map.markers.MapMode | 'maximized'
}

export function Styler(props: Props) {
  const { hideOrders, showOrders, maximizeOrders, activeMapStyle, methodInExecution } = props
  const texts = useTexts()

  return (
    <StylerContainer data-testid="navigo-order-map-styles">
      <Stack
        direction="column"
        justifyContent="space-between"
        width="auto"
        height="100%"
        flexShrink={0}
        marginRight={3}
      >
        <Text size="$p3" weight="$semiBold">
          {texts.title}
        </Text>

        <Text size="$p3">{texts.subtitle}</Text>
      </Stack>
      <ButtonsContainer>
        <NavigoIconButton
          text={texts.show}
          onClick={maximizeOrders}
          active={activeMapStyle === 'maximized'}
          Icon={
            methodInExecution === 'maximizeOrders' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisible size={16} color="$nightRider" />
            )
          }
        />

        <NavigoIconButton
          text={texts.reduce}
          onClick={showOrders}
          active={activeMapStyle === 'on'}
          Icon={
            methodInExecution === 'showOrders' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisibleMinimized size={16} color="$nightRider" />
            )
          }
        />

        <NavigoIconButton
          text={texts.hide}
          onClick={hideOrders}
          active={activeMapStyle === 'off'}
          Icon={
            methodInExecution === 'hideOrders' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <OrdersVisibleMinimized size={16} color="$silver" />
            )
          }
        />
      </ButtonsContainer>
    </StylerContainer>
  )
}
