import type { FormFields, FormErrors } from '../../../../../hooks/formFields'
import type { FormValidator } from '@workwave-tidal/form-fairy'

import { useCallback } from 'react'
import { useTexts } from '../useTexts'

export function useEmailRequired(): FormValidator<FormFields, FormErrors> {
  const texts = useTexts()

  return useCallback(
    formApi => {
      // access the current name form-field
      const field = formApi.getField('email')

      return {
        emailRequiredError:
          field.required && field.value.trim().length === 0
            ? {
                id: 'emailRequiredError',
                message: texts.emailRequired,
                field: 'email',
                priority: 10,
              }
            : null,
      }
    },
    [texts],
  )
}
