import { Stack, Typography } from '@mui/material'
import type { ReactNode } from 'react'

import { RouteViolationsTooltipContent } from '@/components/RouteViolationsTooltipContent'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Flag } from '@/icons'

interface Props {
  violations: uui.domain.rm.RouteViolations
  children: ReactNode
}

export function RouteTitle(props: Props) {
  const { children, violations } = props

  const violationsCount = !!violations.vehicle ? violations.vehicle.length : 0

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography variant="h6" data-testid="routeName">
        {children}
      </Typography>

      {violationsCount > 0 && (
        <Tooltip
          placement="bottom"
          title={<RouteViolationsTooltipContent violations={violations} />}
        >
          <Stack
            bgcolor="warning.main"
            spacing={0.5}
            paddingX={1}
            direction="row"
            alignItems="center"
            data-testid="routeViolations"
            borderRadius={1}
            justifyContent="center"
          >
            <Flag size={15} color="$nightRider" data-testid="violationFlag" />

            <Typography color="text" fontWeight={600} fontSize="small">
              {violationsCount}
            </Typography>
          </Stack>
        </Tooltip>
      )}
    </Stack>
  )
}
