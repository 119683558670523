import type { FormError, FormField, FormValidator } from '@workwave-tidal/form-fairy'

const preparationTimeStart: FormField<number> = {
  value: -1,
}

const preparationTimeEnd: FormField<number> = {
  value: -1,
}

const closeoutTimeStart: FormField<number> = {
  value: -1,
}

const closeoutTimeEnd: FormField<number> = {
  value: -1,
}

const drivers: FormField<Record<string, { start: number; end: number }>> = {
  value: {},
}

export const formFields = {
  preparationTimeStart,
  preparationTimeEnd,
  closeoutTimeStart,
  closeoutTimeEnd,
  drivers,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {}

export type Validation = {
  id: string
  validator: FormValidator<FormFields, FormErrors>
  fields?: keyof FormFields
}
