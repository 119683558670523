import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../../../hooks/useController'
import { useTexts } from '../../useTexts'

export function ResultHeader() {
  const texts = useTexts()
  const { close, data } = useController()

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={texts.shareLocation}
      subtitle={data.gpsOnly ? texts.gpsOnlySubtitle : data.driverName}
    />
  )
}
