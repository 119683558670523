import type { FormMutation, FormValues } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from '../../formFields'

type Mutable<T> = {
  -readonly [k in keyof T]: T[k]
}

export const territoryFilterMutation: FormMutation<FormFields, FormErrors> = (
  _api,
  changed,
  _initialChanged,
) => {
  const final: Mutable<Partial<FormValues<FormFields>>> = {}
  if (!changed.type || changed.type === 'viewer' || changed.type === 'planner') return

  final.territoryFilter = []

  return final
}
