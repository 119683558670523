import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'

import { isUserAuthenticated } from '@/features/domain/user'
import { useNavigate } from '@/routing'
import { WarningCircle } from '@/icons'
import { theme } from '@/styles'

import { useTexts } from './useTexts'

const StyledRoot = styled('div')({
  top: 0,
  left: 0,
  zIndex: 9999,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.colors.$pureWhite,
})

const StyledTitle = styled('div')({
  marginTop: 20,
  marginBottom: 10,
  textAlign: 'center',
  fontSize: theme.fonts.$h1,
  fontWeight: theme.weights.$semiBold,
})

const StyledSubtitle = styled('div')({
  marginBottom: 10,
  textAlign: 'center',
  fontSize: theme.fonts.$h2,
})

export function TooManySessions() {
  const authenticated = useSelector(isUserAuthenticated)
  const navigate = useNavigate(true)
  const texts = useTexts()

  // Redirects the user to the home if is authenticated
  useEffect(() => {
    if (authenticated) {
      navigate('/')
    }
  }, [navigate, authenticated])

  return (
    <StyledRoot>
      <WarningCircle size={40} color="$outrageousRed" />

      <StyledTitle>{texts.tooManyOpenTabsTitle}</StyledTitle>
      <StyledSubtitle>{texts.tooManyOpenTabsSubtitle}</StyledSubtitle>
    </StyledRoot>
  )
}
