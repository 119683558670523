import { useIsQaEnabled } from './hooks/useIsQaEnabled'
import { useExposeSelection } from './hooks/useExposeSelection'
import { useExposeImportIndexedDB } from './hooks/useExposeImportIndexedDB'
import { useExposeGetTrackingToken } from './hooks/useExposeGetTrackingToken'
import { useExposeTelematicsActions } from './hooks/useExposeTelematicsActions'
import { useExposeGetBreadcrumbMapMarkerByDeviceId } from './hooks/useExposeGetBreadcrumbMapMarkerByDeviceId'

/**
 * Expose all the app-wide test actions.
 */
export function TestActions() {
  const qaEnabled = useIsQaEnabled()

  useExposeSelection(qaEnabled)
  useExposeGetTrackingToken(qaEnabled)
  useExposeTelematicsActions(qaEnabled)
  useExposeGetBreadcrumbMapMarkerByDeviceId(qaEnabled)
  useExposeImportIndexedDB(qaEnabled)

  return null
}
