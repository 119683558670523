import type { ModalData } from '../../../../../../../hooks/useController'
import type { FormErrors, FormFields } from '../../../../../formFields'
import type { Geometry, Point } from 'ol/geom'
import type VectorSource from 'ol/source/Vector'
import type { FormApi } from '@workwave-tidal/form-fairy'
import type { Layer } from 'ol/layer'

import type { AppDispatch } from '@/store'

import { useEffect } from 'react'
import OlMap from 'ol/Map'

import { useFormApi } from '@workwave-tidal/form-fairy'

import { reverseGeocodeLocationEditorPin } from '@/features/domain/ui'

import { useAppDispatch } from '@/store'
import { gis } from '@/server-data'

import { useControllerById } from '../../../../../../../hooks/useController'

// updatePinPosition updates the position of a pin on the map based on its coordinates
async function updatePinPosition(
  map: OlMap,
  api: Readonly<FormApi<FormFields, FormErrors>>,
  latLng: uui.domain.LatLng,
  dispatch: AppDispatch,
  updateData: (nextData: Partial<ModalData>) => void,
) {
  // get the last layer on the map
  const layer = map.getLayers().item(map.getLayers().getLength() - 1) as Layer<
    VectorSource<Geometry>
  >

  // return if layer is not found
  if (!layer) return

  // get the first feature on the layer
  const feature = layer?.getSource()?.getFeatures()?.[0]

  // return if feature is not found
  if (!feature) return

  // set status of feature to loading
  feature.set('status', 'loading')

  // reverse geocode the location
  const result = await dispatch(reverseGeocodeLocationEditorPin(latLng))

  // throw error if reverse geocoding fails
  if (reverseGeocodeLocationEditorPin.rejected.match(result)) {
    throw new Error(result.payload?.message ?? 'Internal error')
  }

  // set the coordinates of the feature to the new coordinates
  const geometry = feature.getGeometry() as Point
  geometry.setCoordinates(gis.fromLatLngToCoordinate(result.payload.latLng))

  // update the form field with the new coordinates
  api.change('newLocation', result.payload)

  // update the modal data with the new address
  updateData({ proposedLabel: result.payload.geoAddress })

  // set the status of the feature to normal
  feature.set('status', 'normal')
}

// useGeocodePinPosition is a hook that updates the position of a pin on the map
export function useGeocodePinPosition(
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery',
  pinLatLng: uui.domain.LatLng,
  map?: OlMap,
) {
  const {
    updateData,
    data: { center },
  } = useControllerById(pinId)
  const dispatch = useAppDispatch()
  const api = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    // return if map is not found
    if (!map) return

    // return if the pin is not moved
    if (pinLatLng.lat === center.lat && pinLatLng.lng === center.lng) return

    updatePinPosition(map, api, pinLatLng, dispatch, updateData)
  }, [map, api, dispatch, center, updateData, pinLatLng])
}
