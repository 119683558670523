import type { ReactElement } from 'react'

import { Box, Typography } from '@mui/material'

import { styled } from '@mui/material/styles'

const StyledBody = styled(Typography)({
  whiteSpace: 'pre-line',
})

interface Props {
  children: ReactElement | string
  label: string
}

/**
 * Generic read-only field. Used read-only mode in Notification Forms
 */
export function Field(props: Props) {
  return (
    <Box paddingBottom={4} data-testid={props.label}>
      <Typography variant="caption" color="textSecondary">
        {props.label}
      </Typography>
      <StyledBody variant="body1" color="textPrimary">
        {props.children}
      </StyledBody>
    </Box>
  )
}
