import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'
import { useTexts } from '../../../useTexts'

interface Props {
  close?: () => void
  source: uui.domain.server.gps.telematics.Source
}
export function VerizonConnect(props: Props) {
  const { close } = props

  const texts = useTexts()

  return (
    <ModalHeader
      title={texts.verizonConnect.headerTitle}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
