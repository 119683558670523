import type { FormFields, FormErrors } from '../formFields'
import type { RouteDetailEntry } from '../../../../../types'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

import { updateRouteExecutionEvents } from '@/features/domain/route'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { computePayload } from './utils/computePayload'

export function useOnSubmit(
  vehicleId: string,
  date: string,
  routeDetailsData: RouteDetailEntry[],
  clearEditingState: () => void,
) {
  const dispatch = useAppDispatch()
  const formApi = useFormApi<FormFields, FormErrors>()
  const toast = useNotification()

  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        event.preventDefault()

        formApi.setSubmitting(true)

        await formApi.waitForValidation()
        const valid = await formApi.validate()

        if (!valid) {
          formApi.setSubmitting(false)
          return
        }

        const formValues = formApi.getValues()
        const payload = computePayload(formValues, routeDetailsData, vehicleId, date)
        const result = await dispatch(updateRouteExecutionEvents(payload))

        if (updateRouteExecutionEvents.rejected.match(result)) {
          throw new Error(result.payload?.message ?? 'Internal error')
        }

        // Clear FormState submitting mode
        formApi.setSubmitting(false)

        // if the component has been unmounted during the async operation stop here
        if (isUnmounted()) return

        // if the update has been completed successfully let's close the form
        if (result) clearEditingState()
      } catch (e) {
        toast.error(e.message)
        formApi.setSubmitting(false)
      }
    },
    [formApi, isUnmounted, toast, dispatch, clearEditingState, routeDetailsData, date, vehicleId],
  )
}
