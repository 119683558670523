import { Box, Stack, Typography } from '@mui/material'
import { useCallback } from 'react'
import type { View } from '../../types'

import { RouteManager } from '@workwave-tidal/core/icons/logos'
import { ForgotPasswordForm, LoginForm } from '@workwave-tidal/core/login-ui'
import { LoginCallToAction } from '@workwave-tidal/core/login-ui/LoginCallToAction'
import { LoginFormContainer } from '@workwave-tidal/core/login-ui/LoginFormContainer'

import { useQueryParameters } from '@/hooks'

import { useLoginActions } from '../hooks/useLoginActions'
import { useLoginInitialValues } from '../hooks/useLoginInitialValues'
import { useTexts } from '../hooks/useTexts'

interface Props {
  view: View
  setView: (view: View) => void
}

const boxStyle = { height: 'auto' }
const logoStyle = { marginBottom: 2 }

export function Main(props: Props) {
  const { view, setView } = props

  const texts = useTexts()
  const query = useQueryParameters()
  const initialValues = useLoginInitialValues()
  const { onLogin, forgotPassword } = useLoginActions(query)

  const goToForgotPassword = useCallback(() => {
    setView('forgot-password')
  }, [setView])

  const goToLogin = useCallback(() => {
    setView('login')
  }, [setView])

  switch (view) {
    case 'login':
      return (
        <Box height={580} maxWidth={340}>
          <LoginFormContainer
            logo={<RouteManager width={340} sx={logoStyle} />}
            title={texts.login.logo}
            form={
              <LoginForm
                texts={texts.login}
                sx={boxStyle}
                testId="login-form"
                onLogin={onLogin}
                initialValues={initialValues}
                hideRememberMe
              />
            }
            actions={
              <Stack width="100%" alignItems="center">
                <LoginCallToAction
                  label={texts.login.goToForgotPassword.label}
                  action={goToForgotPassword}
                />
              </Stack>
            }
          />
        </Box>
      )

    case 'forgot-password':
      return (
        <Box height={580} maxWidth={340}>
          <LoginFormContainer
            logo={<RouteManager width={340} sx={logoStyle} />}
            title={texts.forgotPassword.logo}
            form={
              <ForgotPasswordForm
                sx={boxStyle}
                texts={texts.forgotPassword}
                testId="login-form"
                onSubmit={forgotPassword}
              />
            }
            actions={
              <Stack width="100%" alignItems="center" paddingX={1} spacing={1}>
                <Typography textAlign="center">{texts.forgotPassword.forgotDescription}</Typography>
                <LoginCallToAction
                  description={texts.forgotPassword.goToLoginDescription}
                  label={texts.forgotPassword.goToLogin.label}
                  action={goToLogin}
                />
              </Stack>
            }
          />
        </Box>
      )
  }
}
