import { useRoutingSidebar } from '@/atoms'
import { PlanSummary } from '@/components/PlanSummary'

import { Stack } from '@mui/material'

import { DispatchPanel } from './components/DispatchPanel'
import { RouteDetails } from './components/RouteDetails'
import { RoutingNav } from './components/RoutingNav'

export function RoutingSidebar() {
  const [settings] = useRoutingSidebar()

  switch (settings.view) {
    case 'main':
      return (
        <Stack direction="column" height="100%" width="100%" justifyContent="space-between">
          <RoutingNav />
          <PlanSummary />
        </Stack>
      )

    case 'dispatchPanel':
      return <DispatchPanel />

    case 'routeDetails':
      return <RouteDetails routeId={settings.routeId} />

    default:
      return null
  }
}
