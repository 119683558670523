import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/form-fairy'

import { Stack, FormHelperText, Checkbox, FormControlLabel, Box } from '@mui/material'

import { useFormField } from '@workwave-tidal/form-fairy'
import { useCallback } from 'react'

import { useValidateOn, type ValidateOn } from '@/formUi'

type BulkFieldValue<T> =
  | {
      status: 'mixed'
    }
  | {
      status: 'exact'
      value: T
    }

type RequiredFormField = BulkFieldValue<boolean>

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  name: FIELD_NAME
  helperText?: string
  testId?: string
  validateOn?: ValidateOn
  disabled?: boolean
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkCheckbox<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const { label, name, helperText, disabled, validateOn, testId, ...options } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field,
    errors,
    formApi,
    fieldApi: { validate, change: apiChange },
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const { validateOnChange } = useValidateOn(validate, validateOn)

  const { visible, value: fieldValue, status } = field

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  const hasMessageToDisplay = !!errorText || helperText

  const checked = fieldValue.status === 'exact' ? fieldValue.value : false
  const indeterminate = fieldValue.status === 'mixed'

  const onChange = useCallback(
    (_, checked: boolean) => {
      apiChange({ status: 'exact', value: fieldValue.status === 'mixed' ? true : checked })
      validateOnChange()
    },
    [validateOnChange, apiChange, fieldValue],
  )

  if (!visible) return null

  return (
    <Stack data-testid={testId} data-trackid={testId}>
      <FormControlLabel
        control={<Checkbox onChange={onChange} checked={checked} indeterminate={indeterminate} />}
        label={label}
        disabled={fieldDisabled}
      />

      {hasMessageToDisplay && (
        <Box ml={4}>
          <FormHelperText error={fieldHasError}>
            {fieldHasError ? errorText : helperText}
          </FormHelperText>
        </Box>
      )}
    </Stack>
  )
}
