import { MapControls } from '@/components/MapControls'

import { PopupWindow, UnusableMapMask, usePopupApi } from '@/atoms'

import { Box, Stack } from '@mui/material'

import { Map } from './Map'

export function Popup() {
  const { status } = usePopupApi()

  if (status !== 'open') return null

  return (
    <PopupWindow>
      <Box width="100%" height="100%" flex="0 1 auto">
        <UnusableMapMask />
        <Stack direction="column" width="100%" height="100%" flexShrink={0}>
          <MapControls enableInspectPin canFilterOrders />

          <Map />
        </Stack>
      </Box>
    </PopupWindow>
  )
}
