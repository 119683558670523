import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'
import type { ValidationTexts } from '../useTexts'

import { useValidateEmail } from '@/hooks'

export const createValidateEmail =
  (
    validateEmail: ReturnType<typeof useValidateEmail>,
    texts: ValidationTexts,
  ): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value, required } = formApi.getField('email')

    const requiredError: FormError<keyof FormFields> = {
      id: 'email-required',
      field: 'email',
      message: texts.emailRequired,
      priority: 3,
    }

    const validError: FormError<keyof FormFields> = {
      id: 'email-valid',
      field: 'email',
      message: texts.emailValid,
      priority: 7,
    }

    const empty = value.trim().length === 0
    const valid = await validateEmail(value.trim())

    return {
      [requiredError.id]: required && empty ? requiredError : null,
      [validError.id]: valid ? null : validError,
    }
  }
