import type { RadioProps as Props } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Radio as MUIRadio } from '@mui/material'
import { RadioIcon } from './RadioIcon'

const StyledButton = styled(MUIRadio)({
  padding: '0',
})

export function Radio(props: Props) {
  const { disabled } = props

  return (
    <StyledButton
      {...props}
      disableRipple
      icon={<RadioIcon disabled={disabled} />}
      checkedIcon={<RadioIcon disabled={disabled} checked />}
    />
  )
}
