import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { updateUserUiDataSettings } from '@/features/domain/ui'
import { selectRoutingLayout } from '@/features/domain/user'
import { useAppDispatch } from '@/store'

export function useRoutingLayout() {
  const dispatch = useAppDispatch()
  const routingLayout = useSelector(selectRoutingLayout)

  const setRoutingLayout = useCallback(
    async (updatedValue: Partial<uui.domain.server.rm.RoutingLayout>) => {
      dispatch(updateUserUiDataSettings({ routingLayout: { ...routingLayout, ...updatedValue } }))
    },
    [routingLayout],
  )

  return [routingLayout, setRoutingLayout] as const
}
