import { getDefaultUiData } from '../../../store/sagas/rpc/territory/utils/generateEmptyPublicData'
import * as accountHelpers from '../../helpers/accountFormats'

/**
 * Converts a server user profile into a client user profile.
 *
 * ATTENTION: It will mutate original object
 *
 * @param {uui.domain.server.UserProfile} sourceUserProfile
 * @returns {uui.domain.client.UserProfile} clientUserProfile
 */
export function convertToClientUserProfile(
  sourceUserProfile: uui.domain.server.UserProfile,
): uui.domain.client.UserProfile {
  const fullName = `${sourceUserProfile.user.name} ${sourceUserProfile.user.surname}`

  const sourceUserSettings = sourceUserProfile.user.settings
  const dateFormatLong = accountHelpers.convertAccountDateFormatLong(sourceUserSettings.dateFormat)
  const dateFormatShort = accountHelpers.convertAccountDateFormatShort(
    sourceUserSettings.dateFormat,
  )
  const dateParsingFormats = accountHelpers.convertAccountDateParsingFormat(
    sourceUserSettings.dateFormat,
  )
  const dateFormatShortWithExtendedMonth =
    accountHelpers.convertAccountDateFormatShortWithExtendedMonth(sourceUserSettings.dateFormat)

  return Object.assign(sourceUserProfile, {
    user: convertToClientRouteManagerUser(sourceUserProfile.user),
    fullName,
    account: {
      ...sourceUserProfile.account,
      preferences: {
        ...sourceUserProfile.account.preferences,
        dateFormatLong,
        dateFormatShort,
        dateParsingFormats,
        dateFormatShortWithExtendedMonth,
        timeFormat: accountHelpers.convertServerTimeFormat(
          sourceUserProfile.account.preferences.timeFormat,
        ),
      },
    },
  })
}

/**
 * Converts a server RM user into a client user RM user.
 *
 * ATTENTION: It will mutate original object
 *
 * @param {uui.domain.server.rm.RouteManagerUser} sourceUser
 * @returns {uui.domain.client.rm.RouteManagerUser}
 */
export function convertToClientRouteManagerUser(
  sourceUser: uui.domain.server.rm.RouteManagerUser,
): uui.domain.client.rm.RouteManagerUser {
  const { uiData: sourceUiData, settings: sourceSettings } = sourceUser

  const settings: uui.domain.client.rm.RouteManagerUser['settings'] = {
    currency: sourceSettings.currency,
    dateFormat: sourceSettings.dateFormat,
    distanceFormat: sourceSettings.distanceFormat,
    language: sourceSettings.language,
    timeFormat: accountHelpers.convertServerTimeFormat(sourceSettings.timeFormat),
  }

  return Object.assign(sourceUser, {
    uiData: { ...getDefaultUiData(sourceUser.type === 'gpsonly'), ...sourceUiData },
    settings,
  })
}

export function createRouteManagerUsers(source: uui.domain.server.rm.RouteManagerUser[]) {
  return source.reduce<Record<string, uui.domain.client.rm.RouteManagerUser>>((acc, user) => {
    acc[user.id] = convertToClientRouteManagerUser(user)
    return acc
  }, {})
}
