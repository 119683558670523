import { useIntl } from '@/intl'
import { useState } from 'react'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    calendar: translate({ id: 'global.calendar.calendar' }),
    previous: translate({ id: 'global.calendar.previous' }),
    next: translate({ id: 'global.calendar.next' }),
  }))

  return api
}
