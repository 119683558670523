import { useEffect } from 'react'
import localforage from 'localforage'

function importIndexedDB() {
  const input = document.createElement('input')
  input.type = 'file'
  input.onchange = async () => {
    const file = input.files?.[0]
    if (file) {
      const text = await file.text()
      const jsonData = JSON.parse(text)
      await localforage.clear()
      localforage.setItem('mapStyles', jsonData.keyvaluepairs[0])
      localforage.setItem('storedListOptions', jsonData.keyvaluepairs[1])
    }
  }
  input.click()
}

export function useExposeImportIndexedDB(qaEnabled: boolean) {
  useEffect(() => {
    if (!qaEnabled) return

    window.testActions ??= {}
    window.testActions.importIndexedDB = importIndexedDB

    return () => void delete window.testActions?.importIndexedDB
  }, [qaEnabled])
}
