import { type ReactElement, useCallback, ReactNode } from 'react'
import {
  type NarrowFieldsByValueType,
  type UseFormFieldOptions,
  type FormField,
  type FormError,
  useFormField,
} from '@workwave-tidal/form-fairy'

import {
  type SelectProps as MuiSelectProps,
  type MenuProps as MuiMenuProps,
  type SelectChangeEvent,
  type InputLabelProps as MuiInputLabelProps,
  FormHelperText,
  FormControl,
  MenuItem,
  Divider,
  Typography,
} from '@mui/material'

import { type ValidateOn, useValidateOn } from '../hooks/useValidateOn'

import { FormUIInputLabel } from './FormUIInputLabel'
import { FormUISelect } from './FormUISelect'

type RenderOptions<T extends string> = (option: Option<T>, index: number) => ReactNode

export type Option<VALUE extends string> =
  | { preset: 'divider' }
  | ({
      label: string
      value: VALUE
      disabled?: boolean
    } & (
      | { preset?: never }
      | { preset: 'secondaryText'; secondaryText: string }
      | { preset: 'description'; description: string }
      | { preset: 'descriptionAndIcon'; description: string; Icon: ReactElement }
    ))

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<FIELD_VALUE, FIELDS>,
  FIELD_VALUE extends string,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
> = {
  name: FIELD_NAME
  testId: FIELD_NAME
  label: string
  options: Option<FIELD_VALUE>[]
  validateOn: ValidateOn
  helperText?: string
  placeholder?: string
  selectProps?: Partial<MuiSelectProps<FIELD_VALUE>>
  MenuProps?: Partial<MuiMenuProps>
  inputLabelProps?: Partial<MuiInputLabelProps>
  renderOptions?: RenderOptions<FIELD_VALUE>
} & UseFormFieldOptions<FIELDS, ERROR>

export function Select<
  FIELD_NAME extends NarrowFieldsByValueType<FIELD_VALUE, FIELDS>,
  FIELD_VALUE extends string,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELD_VALUE, FIELDS, ERROR>) {
  const {
    name,
    label,
    testId,
    options,
    helperText,
    validateOn,
    placeholder,
    selectProps = {},
    MenuProps = {},
    inputLabelProps = {},
    errorVisibility = 'all',
    renderOptions,
    ...formFieldOptions
  } = props

  // internal type used to resolve the connected Form Field to a `string` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormField<FIELD_VALUE>>

  const { field, errors, fieldApi, formApi } = useFormField<
    FIELD_NAME,
    PartialForm,
    FormError<FIELD_NAME>
  >(name, formFieldOptions as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>)

  const { value, required, status, disabled, visible } = field
  const { validateOnBlur, validateOnChange, validateOnFocus } = useValidateOn(
    fieldApi.validate,
    validateOn,
  )

  const onChange = useCallback(
    (event: SelectChangeEvent<FIELD_VALUE>) => {
      const value = event.target.value as FIELD_VALUE
      fieldApi.change(value)
      validateOnChange()
    },
    [fieldApi, validateOnChange],
  )

  const onBlur = useCallback(() => {
    validateOnBlur()
  }, [validateOnBlur])

  const onFocus = useCallback(() => {
    validateOnFocus()
  }, [validateOnFocus])

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'

  const showErrorText = errorVisibility === 'all' || errorVisibility === 'text-only'
  const showErrorAccent = errorVisibility === 'all' || errorVisibility === 'accent-only'

  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined
  const errorOrHelperText = showErrorText && fieldHasError ? errorText : helperText
  const showHelperText = !!errorOrHelperText

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  if (!visible) return null

  return (
    <FormControl error={showErrorAccent && fieldHasError} data-testid={testId}>
      <FormUIInputLabel {...inputLabelProps} id={name} required={required}>
        {label}
      </FormUIInputLabel>
      <FormUISelect
        label={label}
        {...selectProps}
        MenuProps={MenuProps}
        value={value}
        onBlur={onBlur}
        labelId={name}
        onFocus={onFocus}
        onChange={onChange}
        required={required}
        disabled={fieldDisabled}
        autoWidth={false}
      >
        {options.map(renderOptions ?? defaultRenderOptions)}
      </FormUISelect>

      {showHelperText && <FormHelperText error={fieldHasError}>{errorOrHelperText}</FormHelperText>}
    </FormControl>
  )
}

function defaultRenderOptions<VALUE extends string>(option: Option<VALUE>, index: number) {
  switch (option.preset) {
    case 'description':
    case 'descriptionAndIcon':
      return <div>Coming Soon</div>
    case 'divider':
      return <Divider key={index} />
    case 'secondaryText':
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          <Typography noWrap sx={{ marginRight: 2 }}>
            {option.label}
          </Typography>
          <Typography sx={{ textAlign: 'right', marginLeft: 'auto' }} noWrap>
            {option.secondaryText}
          </Typography>
        </MenuItem>
      )

    default:
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      )
  }
}
