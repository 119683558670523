import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/form-ui'
import { useTexts } from '../../useTexts'

export function Tolerance() {
  const texts = useTexts()

  const options = useMemo(
    () => [
      {
        value: '0',
        label: texts.toleranceMinsValue(),
      },
      {
        value: '10',
        label: texts.toleranceMinsValue(10),
      },
      {
        value: '20',
        label: texts.toleranceMinsValue(20),
      },
      {
        value: '30',
        label: texts.toleranceMinsValue(30),
      },
    ],
    [texts],
  )

  return (
    <RadioGroup<'toleranceMins', string, FormFields, FormErrors>
      label={texts.toleranceMinsLabel}
      validateOn="blur focus"
      testId="toleranceMins"
      name="toleranceMins"
      options={options}
    />
  )
}
