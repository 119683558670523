import type { SingleEditProps } from '../types'

import { useEffect, useRef, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { Form as TidalForm, FormHeader } from '@workwave-tidal/form-ui/layout'
import { useFormApi } from '@workwave-tidal/form-fairy'

import { CourierFilterField } from '../fields/CourierFilter'

import { Name } from '../fields/Name'
import { Surname } from '../fields/Surname'
import { Email } from '../fields/Email'
import { Username } from '../fields/Username'
import { Password } from '../fields/Password'
import { Type } from '../fields/Type'
import { LimitedAccess } from '../fields/Access'
import { Companies } from '../fields/Companies'
import { TerritoryFilter } from '../fields/TerritoryFilter'
import { useTexts } from '../hooks/useTexts'
import { useOnSubmit } from '../hooks/useOnSubmit/useOnSubmit'
import { useFieldsVisibility } from '../hooks/useFieldsVisibility'
import { type FormErrors, type FormFields } from '../formFields'

import { Footer } from './Footer'

type Props = SingleEditProps & {
  territoriesData: uui.domain.client.rm.TerritoriesDriversAndVehicles
  territoryNames: Record<string, string>
  forceValidateOnMount: boolean
}

export function Form(props: Props) {
  const { onDiscard, creating, territoriesData, territoryNames, forceValidateOnMount } = props

  const [firstSubmitDone, setFirstSubmitDone] = useState(false)
  const { onEnter, onSubmit } = useOnSubmit(creating, setFirstSubmitDone)
  const theme = useTheme()
  const { canShow } = useFieldsVisibility(creating)

  const texts = useTexts()
  const formApi = useFormApi<FormFields, FormErrors>()

  const oneTimeValidationRef = useRef(false)

  useEffect(() => {
    if (oneTimeValidationRef.current) {
      return
    }

    if (forceValidateOnMount) {
      formApi.validate()
      oneTimeValidationRef.current = true
    }
  }, [formApi, forceValidateOnMount])

  return (
    <Box width="100%" height="100%" display="flex" paddingY={2}>
      <TidalForm
        header={
          <FormHeader title={creating ? texts.titleCreating : texts.titleEditing} subtitle="" />
        }
        footer={<Footer creating={creating} onDiscard={onDiscard} onSubmit={onSubmit} />}
        onEnter={onEnter}
        fieldsSpacing={4}
        fieldsPadding={`0 ${theme.spacing(4)}`}
      >
        <Name />
        <Surname />
        <Email />
        <Username />
        {canShow.password && <Password firstSubmitDone={firstSubmitDone} />}
        <Type />
        {canShow.companies && <Companies />}
        {canShow.limitedAccess && <LimitedAccess />}
        {canShow.territoryFilter && <TerritoryFilter />}
        {canShow.courier && (
          <CourierFilterField territoriesData={territoriesData} territoryNames={territoryNames} />
        )}
      </TidalForm>
    </Box>
  )
}
