import type { FormFields, FormErrors } from '../../formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

import { useAppDispatch } from '@/store'
import { useValidateEmail } from '@/hooks'

import { createValidateCompanyEmail } from './validations/createValidateCompanyEmail'
import { createValidateCompanyPhone } from './validations/createValidateCompanyPhone'
import { createValidateCompanyName } from './validations/createValidateCompanyName'
import { createValidateCompanySlogan } from './validations/createValidateCompanySlogan'

import { useTexts } from './useTexts'

export function useFormValidation() {
  const formApi = useFormApi<FormFields, FormErrors>()
  const texts = useTexts()

  const dispatch = useAppDispatch()
  const validateEmail = useValidateEmail()

  useEffect(() => {
    formApi.addValidation(
      'validation-companyEmail',
      createValidateCompanyEmail(validateEmail, texts),
      'companyEmail',
    )
    formApi.addValidation(
      'validation-companyPhone',
      createValidateCompanyPhone(texts),
      'companyPhone',
    )
    formApi.addValidation('validation-companyName', createValidateCompanyName(texts), 'companyName')
    formApi.addValidation(
      'validation-companySlogan',
      createValidateCompanySlogan(texts),
      'companySlogan',
    )
    return () => {
      formApi.removeValidation('validation-companyEmail')
      formApi.removeValidation('validation-companyPhone')
      formApi.removeValidation('validation-companyName')
      formApi.removeValidation('validation-companySlogan')
    }
  }, [formApi, texts, dispatch, validateEmail])
}
