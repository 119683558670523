import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'
import type { ValidationTexts } from './useTexts'

export const createValidateTopic =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField('topic')

    const error: FormErrors = {
      id: 'invalidTopicError',
      field: 'topic',
      message: texts.topic,
      priority: 3,
    }

    const valid = value !== null && value.type === 'option' && !value.disabled

    return {
      [error.id]: !valid ? error : null,
    }
  }
