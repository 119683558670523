import type { FormErrors, FormFields } from './formFields'
import { Box } from '@mui/material'

import { FormHeader, Form } from '@workwave-tidal/form-ui/layout'
import { useFormField } from '@workwave-tidal/form-fairy'

import { useTexts } from './hooks/useTexts'

import { DriverAssistanceFormFooter } from './DriverAssistanceFormFooter'
import { DriverAssistanceFormAlert } from './DriverAssistanceFormAlert'
import { useOnSubmit } from './hooks/useOnSubmit'

import {
  SignatureField,
  CheckoutField,
  CheckinField,
  PictureField,
  StatusField,
  AudioField,
  NoteField,
  TypeField,
} from './fields'

type Props = {
  onDiscard: () => void
  updateDriverAssistance: (value: uui.domain.server.rm.MobileAppInteraction) => Promise<void>
}

export function DriverAssistanceForm(props: Props) {
  const { onDiscard, updateDriverAssistance } = props
  const texts = useTexts()
  const {
    field: { value: type },
  } = useFormField<'type', FormFields, FormErrors>('type')

  const isSuggestMode = type === 'ASSISTED_TIME_OUT' || type === 'ASSISTED_TIME_IN_OUT'
  const isRequireMode = type === 'DRIVER_REQUIRED_FLOW'

  const alertType = isRequireMode ? 'require' : isSuggestMode ? 'suggest' : 'off'
  const onSubmit = useOnSubmit(updateDriverAssistance, onDiscard)

  return (
    <Box width={442} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        header={<FormHeader title={texts.title} subtitle={texts.headerDescription}></FormHeader>}
        footer={<DriverAssistanceFormFooter onDiscard={onDiscard} onSubmit={onSubmit} />}
        testId="driver-assistance-form"
        onEnter={onSubmit}
        fieldsSpacing={4}
        fieldsPadding="0 32px"
      >
        <TypeField />

        {isSuggestMode && (
          <>
            <CheckinField />
            <CheckoutField disabled={true} />
          </>
        )}

        {isRequireMode && (
          <>
            <CheckinField disabled={true} helperText={texts.helperText} />
            <PictureField />
            <SignatureField />
            <AudioField />
            <NoteField />
            <StatusField />
            <CheckoutField helperText={texts.helperText} />
          </>
        )}

        <Box marginTop={2}>
          <DriverAssistanceFormAlert type={alertType} />
        </Box>
      </Form>
    </Box>
  )
}
