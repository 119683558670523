import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

export function Body() {
  const { status } = useController()
  const texts = useTexts()
  return (
    <Stack direction="column" width="100%" height="100%" marginTop={4}>
      {status !== 'pending' ? (
        <WarningCard
          title={texts.warningCardTitle}
          description={texts.warningCardDescription}
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      ) : (
        <WarningCard
          title={texts.transactionWarningCardTitle}
          description={texts.transactionWarningCardDescription}
          preset="warning"
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
        />
      )}
    </Stack>
  )
}
