import { useState } from 'react'
import { useSelector } from 'react-redux'
import { type LazyCreateFormConfig } from '@workwave-tidal/form-fairy'

import { selectGpsTrackingProvider } from '@/features/domain/user'
import { type Option } from '@/components/formUi/fields/Select'

import { type FormErrors, type FormFields } from '../formFields'

import { useValidations } from './validations/useValidations'

export function useFormConfig(
  extDriver: uui.domain.client.rm.ExtendedDriver | undefined,
  gpsDeviceOptions: Option<string>[] | undefined,
) {
  const validations = useValidations(!extDriver)
  const firstEligibleGpsDeviceId = findFirstEligibleGpsDeviceId(gpsDeviceOptions) ?? ''
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const overrideValues = computeOverrideValues(
    trackingProvider,
    extDriver,
    firstEligibleGpsDeviceId,
  )

  const [formConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      validations,
      overrideValues,
    })
  })

  return { formConfig }
}

function computeOverrideValues(
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  extDriver?: uui.domain.client.rm.ExtendedDriver,
  firstEligibleGpsDeviceId?: string,
) {
  if (!extDriver) {
    return {
      gpsDeviceId: trackingProvider === 'telematics' ? '' : (firstEligibleGpsDeviceId ?? ''),
    }
  }

  const {
    driver: {
      name,
      deployment: { email },
      mobileFutureDaysVisibility,
      summarizeFutureMobileRoute,
    },
  } = extDriver

  const trackDriver = trackingProvider === 'telematics' ? false : extDriver.type === 'linked'
  const gpsDeviceId =
    trackingProvider === 'telematics'
      ? ''
      : extDriver.type === 'linked'
        ? extDriver.device.deviceId
        : (firstEligibleGpsDeviceId ?? '')

  return {
    name,
    email,
    'new-password': '',
    gpsDeviceId,
    trackDriver,
    mobileFutureDaysVisibility: `${mobileFutureDaysVisibility}`,
    summarizeFutureMobileRoute,
  }
}

function findFirstEligibleGpsDeviceId(gpsDeviceIdOptions?: Option<string>[]) {
  if (!gpsDeviceIdOptions) return undefined

  const suggestedOption = gpsDeviceIdOptions.find(
    option => option.preset !== 'divider' && !option.disabled,
  )
  return suggestedOption?.preset !== 'divider' ? suggestedOption?.value : undefined
}
