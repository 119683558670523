import { useIntl } from '@/intl'
import { useState } from 'react'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    cancelButtonTitle: translate({ id: 'global.cancel' }),
    confirmButtonTitle: translate({ id: 'global.delete' }),
    title: (count: number) =>
      translate({
        id: 'setup.regions.delete.modalTitle',
        values: { count },
      }),
    body: (count: number) =>
      translate({
        id: 'setup.regions.delete.title',
        values: { count },
      }),
  }))

  return api
}
