import { Stack, StackProps, styled } from '@mui/material'
import type { ComponentType } from 'react'

export type FieldsetProps = StackProps

const DEFAULT_SPACING = 2

export const Fieldset = styled(props => (
  <Stack component="fieldset" spacing={DEFAULT_SPACING} {...props} />
))(() => ({
  padding: 0,
  border: 0,
})) as ComponentType<FieldsetProps>
