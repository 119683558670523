import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'
import { useTexts } from '../../../useTexts'

interface Props {
  close?: () => void

  source: uui.domain.server.gps.telematics.Source
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}
export function Geotab(props: Props) {
  const { close, tenantSource } = props

  const texts = useTexts()

  return (
    <ModalHeader
      title={texts.headerTitle(tenantSource.label)}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
