import type { ReactNode } from 'react'

import { styled } from '@mui/material/styles'
import { Text } from '@/local/components'

interface Props {
  title: string
  children?: ReactNode
}

const StyledContainer = styled('div')({
  marginBottom: 10,
})

export function Entry(props: Props) {
  const { title, children } = props

  return (
    <StyledContainer>
      <Text size="$xl" weight="$semiBold">
        {title}
      </Text>
      <hr />
      {children}
    </StyledContainer>
  )
}
