import type { DurationDisplayValue, DurationFormat, DurationParseResult } from '../../types'

export function createParseDurationCache() {
  const parseCache = new Map<string, DurationParseResult>()

  function createCacheKey(displayValue: DurationDisplayValue, format: DurationFormat) {
    return `${displayValue}:${format}`
  }

  function getValue(displayValue: DurationDisplayValue, format: DurationFormat) {
    const cacheKey = createCacheKey(displayValue, format)

    if (parseCache.has(cacheKey)) {
      const cachedResult = parseCache.get(cacheKey)
      if (cachedResult) return cachedResult
    }
  }

  function reset() {
    parseCache.clear()
  }

  function setValue(displayValue: DurationDisplayValue, format: DurationFormat, result: DurationParseResult) {
    const cacheKey = createCacheKey(displayValue, format)
    parseCache.set(cacheKey, result)
  }

  return {
    getValue,
    reset,
    setValue,
  }
}
