import { useSelector } from 'react-redux'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { Text } from '@/local/components'
import { timeUtils } from '@/server-data'

import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

interface Props {
  timeWindow: uui.domain.rm.TimeWindow
}

export function WorkingHoursBlock(props: Props) {
  const { timeWindow } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const startTime = timeUtils.formatSecondsFromMidnight(
    timeWindow.startSec,
    accountPreferences.timeFormat,
  )
  const endTime = timeUtils.formatSecondsFromMidnight(
    timeWindow.endSec,
    accountPreferences.timeFormat,
  )

  return (
    <Stack
      direction="row"
      data-trackid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock"
      data-testid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock"
      justifyContent="space-between"
      width="auto"
      height="100%"
    >
      <Text size="$p3">{`${texts.workingHours}:`}&nbsp;</Text>
      <Text
        testid="navigo-routes-overview-details-single-vehicleStats-workingHoursBlock-value"
        weight="$semiBold"
        size="$p3"
      >
        {startTime}
        {' - '}
        {endTime}
      </Text>
    </Stack>
  )
}
