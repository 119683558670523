import { styled } from '@mui/material/styles'
import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

const StyledDiv = styled('div')({
  minWidth: 260,
  height: '100%',
  padding: '2px 0',
})

export function Details(props: Props) {
  const { routeInfos } = props

  if (routeInfos.length === 0) return null

  return (
    <StyledDiv
      data-trackid="navigo-routes-overview-details"
      data-testid="navigo-routes-overview-details"
    >
      {routeInfos.length > 1 ? (
        <Bulk routeInfos={routeInfos} />
      ) : (
        <Single routeInfo={routeInfos[0]} />
      )}
    </StyledDiv>
  )
}
