import { Link } from '@/routing'
import { styled } from '@mui/material/styles'

import { useTexts } from '../../../useTexts'
import { HeaderLink } from './HeaderLink'

const FullHeightLink = styled(Link)({
  height: '100%',
})

export function RmGuestLinks() {
  const texts = useTexts()

  return (
    <>
      <FullHeightLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        data-trackid="rm-guest-header__nav-orders-button-link"
        className={'pendo-bluebar__orders-menu'}
      >
        <HeaderLink data-testid="header__nav-orders-button-content">{texts.orders}</HeaderLink>
      </FullHeightLink>
    </>
  )
}
