import type { FormFields, FormErrors } from '../../../../../hooks/formFields'
import type { FormValidator } from '@workwave-tidal/form-fairy'

import { useCallback } from 'react'

import { validatePhoneNumbers } from '@/features/domain/order/actions/validatePhoneNumbers'
import { useAppDispatch } from '@/store'
import { useTexts } from '../useTexts'

const phoneRegex: RegExp = /^\+\d{10,}$/

export function usePhoneNumberValid(): FormValidator<FormFields, FormErrors> {
  const texts = useTexts()
  const dispatch = useAppDispatch()

  return useCallback(
    async formApi => {
      // access the current name form-field
      const phoneNumber = formApi.getField('phoneNumber').value.trim()

      // Formal validation
      const formallyValid = phoneRegex.test(phoneNumber)
      if (!formallyValid) {
        return {
          phoneNumberValidError: {
            id: 'phoneNumberValidError',
            message: texts.phoneInvalid,
            field: 'phoneNumber',
            priority: 10,
          },
        }
      }

      // Server validation

      const thunkResult = await dispatch(validatePhoneNumbers([phoneNumber]))

      if (!validatePhoneNumbers.fulfilled.match(thunkResult)) {
        throw new Error(thunkResult.payload?.message ?? 'Internal error')
      }

      if (!thunkResult.payload[phoneNumber]) {
        return {
          phoneNumberValidError: {
            id: 'phoneNumberValidError',
            message: texts.phoneInvalid,
            field: 'phoneNumber',
            priority: 10,
          },
        }
      }

      return { phoneNumberValidError: null }
    },
    [dispatch, texts],
  )
}
