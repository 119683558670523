import type { FormErrors, FormFields } from './formFields'

import { Box } from '@mui/material'
import { FormProvider } from '@workwave-tidal/form-ui/layout/FormProvider'

import { FormLoading } from '@/components/FormLoading'

import { FormColumn } from '@/components/layout'
import { useFormTransaction } from '@/hooks'

import { formFields } from './formFields'
import { EditDriver } from './components/EditDriver'
import { useFormConfig } from './hooks/useFormConfig'
import { useGpsDeviceIdOptions } from './hooks/useGpsDeviceIdOptions'

type Props = {
  driver?: uui.domain.client.rm.ExtendedDriver
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>
}

export function SingleEdit(props: Props) {
  const { driver } = props
  const initialDeviceId = driver?.type === 'linked' ? driver.device.deviceId : undefined

  const gpsDeviceOptions = useGpsDeviceIdOptions(initialDeviceId)

  const { formConfig } = useFormConfig(driver, gpsDeviceOptions)

  const transactionOpen = useFormTransaction() === 'ready'

  switch (transactionOpen) {
    case false:
      return (
        <Box height="100%">
          <FormColumn width={360} testid="form__column-secondary">
            <FormLoading style={{ width: '100%' }} />
          </FormColumn>
        </Box>
      )
    case true:
      return (
        <Box height="100%">
          <FormColumn width={360} testid="form__column-secondary">
            <FormProvider<FormFields, FormErrors>
              name="driver-single-edit"
              fields={formFields}
              verbose={false}
              config={formConfig}
            >
              <EditDriver driver={driver?.driver} gpsDeviceOptions={gpsDeviceOptions} />
            </FormProvider>
          </FormColumn>
        </Box>
      )
  }
}
