import { Box } from '@mui/material'
import { RouteManager } from '@workwave-tidal/core/icons/logos'

export function Footer() {
  const sx = { transform: `translateX(-50%)` }
  const sxLogo = { float: `left` }

  return (
    <Box position="absolute" left="50vw" bottom={71} sx={sx}>
      <a href="/">
        <RouteManager width={334} sx={sxLogo} />
      </a>
    </Box>
  )
}
