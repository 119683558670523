import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useConvertDistance } from '@/hooks'

import { numberUtils } from '@/utils'
import { conversionUtils } from '@/server-data'
import { selectWorkingDayStartSec } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'

import { formatWorkingDayInfo } from './utils/formatWorkingDayInfo'
import { formatBreaks } from './utils/formatBreaks'
import { computeLoads } from './utils/computeLoads'

const disabledDepotValue = 'disabledDepotValue'

export function useFormInitialValue(
  exception: string,
  settings: uui.domain.client.rm.VehicleSettings,
): uui.domain.ui.forms.ExceptionFormValues {
  const maxDistanceMt = parseFloat(useConvertDistance(settings.maxDistanceMt, 2))
  const workingDayStartSec = useSelector(selectWorkingDayStartSec)
  const userConfig = useSelector(selectUserConfiguration)

  const metric = userConfig.distanceFormat === 'METRIC'

  const [initialValues] = useState<uui.domain.ui.forms.ExceptionFormValues>(() => {
    const kmCost = metric
      ? settings.kmCost / 100
      : numberUtils.roundNumberToHundredths(
          conversionUtils.convertCostPerKmToCostPerMi(settings.kmCost / 100),
        )

    return {
      available: settings.available ? 'available' : 'unavailable',

      arrivalDepotId: settings.arrivalDepotId || disabledDepotValue,
      departureDepotId: settings.departureDepotId || disabledDepotValue,

      workingDayInfo: formatWorkingDayInfo(
        settings.timeWindow,
        settings.earlyDeparture,
        workingDayStartSec,
      ),

      breaks: formatBreaks(settings.breaks, workingDayStartSec),

      regionIds: [...settings.regionIds].sort(),
      maxOrders: settings.maxOrders,
      maxStops: settings.maxStops,
      maxLocations: settings.maxLocations,
      maxDistanceMt,
      maxDrivingTimeSec: settings.maxDrivingTimeSec,
      maxWorkingTimeSec: settings.maxWorkingTimeSec,
      minWorkingTimeSec: settings.minWorkingTimeSec,

      loads: computeLoads(settings.loadCapacities, settings.minLoadCapacities),

      tags: settings.tags,

      speedFactor: Math.max(settings.speedFactor - 50, 0),

      activationCost: settings.activationCost / 100,
      serviceTimeCost: settings.serviceTimeCost / 100,
      drivingTimeCost: settings.drivingTimeCost / 100,
      idleTimeCost: settings.idleTimeCost / 100,
      breakTimeCost: settings.breakTimeCost / 100,
      kmCost,
      perStopCost: settings.perStopCost / 100,

      notes: settings.notes,

      id: exception,
      // FIXME: this field should be handled
      applyFromDate: undefined,
      perStopTimeSec: settings.perStopTimeSec,

      preparationTimeSec: settings.preparationTimeSec,
      closeOutTimeSec: settings.closeOutTimeSec,
    }
  })

  return initialValues
}
