import { Stack } from '@mui/material'
import { FormContainer } from '@workwave-tidal/form-ui/core'

import { ModalContent } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useOnSubmit } from '../../hooks/useOnSubmit'

import { Footer } from '../Footer'
import { Header } from '../Header'

import { VerizonConnect } from './components/VerizonConnect'
import { Linxup } from './components/Linxup'
import { Azuga } from './components/Azuga'
import { Geotab } from './components/Geotab'

function getForm(sourceLabel: string) {
  switch (sourceLabel) {
    case 'Azuga':
      return Azuga

    case 'Linxup':
      return Linxup

    case 'Verizon Connect':
    case 'Verizon Connect (EU)':
    case 'Verizon Connect (US)':
      return VerizonConnect

    case 'Geotab':
      return Geotab
  }
}

export function Form() {
  const {
    data: { source },
  } = useController()

  // Callback for handling submit
  const onSubmit = useOnSubmit()

  const FormComponent = getForm(source.label)

  if (!FormComponent) return null

  return (
    <ModalContent
      header={<Header source={source} />}
      footer={<Footer onSubmit={onSubmit} />}
      maxWidth={600}
    >
      <Stack paddingTop={2} spacing={2} maxWidth="100%">
        <FormContainer testId="create-telematics-tenant-source-form" width={444}>
          <Stack spacing={2} width="100%">
            <FormComponent />
          </Stack>
        </FormContainer>
      </Stack>
    </ModalContent>
  )
}
