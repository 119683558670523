import type { RouteDetailEntry } from '../../../../types'
import type { FormFields, FormErrors } from './formFields'

import { Box } from '@mui/material'
import { FormProvider } from '@workwave-tidal/form-ui/layout'

import { useFormConfig } from './hooks/useFormConfig'

import { RouteDetailsForm } from './RouteDetailsForm'
import { formFields } from './formFields'

interface Props {
  routeDetailsData: RouteDetailEntry[]
  clearEditingState: () => void
  routeColor: string
  vehicleId: string
  onDiscard: () => void
  date: string
}

export function RouteDetailsFormProvider(props: Props) {
  const { routeDetailsData, routeColor, vehicleId, date, onDiscard, clearEditingState } = props
  const lazyConfig = useFormConfig(routeDetailsData)

  return (
    <Box height="100%" display="flex" paddingBottom={1}>
      <FormProvider<FormFields, FormErrors>
        name="reset"
        config={lazyConfig}
        fields={formFields}
        verbose={false}
      >
        <RouteDetailsForm
          date={date}
          onDiscard={onDiscard}
          vehicleId={vehicleId}
          routeColor={routeColor}
          routeDetailsData={routeDetailsData}
          clearEditingState={clearEditingState}
        />
      </FormProvider>
    </Box>
  )
}
