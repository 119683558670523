import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'

import { useValidateEmail } from '@/hooks'

type ValidateEmail = ReturnType<typeof useValidateEmail>

export const createValidateEmail =
  (texts: Texts, validateEmail: ValidateEmail): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value } = formApi.getField('email')
    const email = value.trim()

    const invalidEmailError: FormErrors = {
      id: 'invalidEmailError',
      field: 'email',
      message: texts.errors.format,
      priority: 3,
    }

    const requiredEmailError: FormErrors = {
      id: 'emailRequiredError',
      message: texts.errors.required,
      field: 'email' as const,
      priority: 3,
    }

    const requiredError = email.length === 0

    // until email has value we don't want to validate it
    const valid = requiredError ? true : await validateEmail(email)

    return {
      [requiredEmailError.id]: requiredError ? requiredEmailError : null,
      [invalidEmailError.id]: !valid ? invalidEmailError : null,
    }
  }
