import { useSelector } from 'react-redux'
import { FormHeader, FormActionButton } from '@workwave-tidal/form-ui/layout'

import { Tooltip } from '@/components/primitives/Tooltip'
import { selectTerritories } from '@/features/domain/territory'
import { ViewFieldsLayout, ViewContainer, ViewActions, ViewContent } from '@/formUi'

import { useCopyDriverAssistanceModal } from '../../modals/CopyDriverAssistanceModal'
import { useDriverAssistanceData } from '../../hooks/useDriverAssistanceData'
import { DriverAssistanceType } from '../DriverAssistanceType'
import { useTexts } from './useTexts'

interface Props {
  onEdit: () => void
}

export function DriverAssistanceView(props: Props) {
  const { onEdit } = props
  const texts = useTexts()
  const territories = useSelector(selectTerritories)

  // Get driver assistance from the domain state
  const [driverAssistance] = useDriverAssistanceData()

  // Instance the modal to copy driver assistance
  const { show: showCopyDriverAssistanceModal, Modal: CopyDriverAssistanceModal } =
    useCopyDriverAssistanceModal()

  const singleTerritory = Object.keys(territories).length <= 1

  return (
    <ViewContainer>
      <ViewContent>
        <FormHeader title={texts.title} subtitle={texts.description} />

        <ViewFieldsLayout>
          <ViewActions>
            <FormActionButton
              label={texts.edit}
              onClick={onEdit}
              testId="driver-assistance-view-edit-button"
            />

            <Tooltip placement="top" title={texts.oneTerritory} disabled={!singleTerritory}>
              <FormActionButton
                disabled={singleTerritory}
                onClick={showCopyDriverAssistanceModal}
                label={texts.copy}
                testId="driver-assistance-view-copy-button"
              />
            </Tooltip>
          </ViewActions>

          <DriverAssistanceType driverAssistance={driverAssistance} />

          <CopyDriverAssistanceModal />
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
