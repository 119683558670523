import type { IntervalCalendarProps as Props } from '../../typings'

import { Month } from '../../components/Month'
import { MonthNavigator } from '../../components/MonthNavigator'
import { useMonthNavigatorProps } from '../../hooks/useMonthNavigatorProps'
import { useMonths } from '../../hooks/useMonths'

import { Stack } from '@mui/material'
import { useFocusOnSelection } from './useFocusOnSelection'
import { useIntervalDays } from './useIntervalDays'
import { useSelectionData } from './useSelectionData'
import { useUpdateSelection } from './useUpdateSelection'

export function Interval(props: Props) {
  const {
    today,
    minDate,
    maxDate,
    onChange,
    dotColor,
    withTestIds,
    disabledDays,
    selectionEnd,
    referenceDate,
    highlightToday,
    selectionStart,
    tooltipIntlText,
    highlightedDays,
    fixedRangeStart,
    focusOnSelection,
    setReferenceDate,
    lastSelectableDate,
    maxIntervalLength,
    firstNonArchivedDay,
    months: monthsCount,
    showNeighboringMonth,
    allowAutoMonthHeight,
  } = props

  // That hook that if the selection changes and is not already showed, the calendar scrolls to it
  useFocusOnSelection(referenceDate, setReferenceDate, selectionStart, focusOnSelection)

  const months = useMonths(referenceDate, showNeighboringMonth, monthsCount, minDate, maxDate)

  const selectionData = useSelectionData(
    selectionStart,
    selectionEnd,
    minDate,
    maxIntervalLength,
    lastSelectableDate,
  )

  const daysByMonth = useIntervalDays({
    ...selectionData,
    today,
    tooltipIntlText,
    firstNonArchivedDay,
    highlightedDays,
    disabledDays,
    months,
    fixedRangeStart,
    selectionStart,
    selectionEnd,
    highlightToday,
    lastSelectableDate,
    withTestIds,
  })

  const monthNavigatorProps = useMonthNavigatorProps({
    referenceDate,
    setReferenceDate,
    months: monthsCount,
    minDateAsString: minDate,
    maxDateAsString: maxDate,
    labelFormat: 'month-year',
  })

  const handleOnClick = useUpdateSelection(onChange, selectionStart, selectionEnd)

  return (
    <Stack direction="column" height="auto" alignItems="center" width="100%" flexShrink={0}>
      <MonthNavigator {...monthNavigatorProps} />
      <Stack direction="row" width="100%" justifyContent="space-between">
        {months.map(
          (month, index) =>
            daysByMonth[month.id] && (
              <Month
                index={index}
                key={month.id}
                showDaysOfWeek
                dotColor={dotColor}
                onClick={handleOnClick}
                days={daysByMonth[month.id]}
                allowAutoMonthHeight={allowAutoMonthHeight}
              />
            ),
        )}
      </Stack>
    </Stack>
  )
}
