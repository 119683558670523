import type { FormFields, FormErrors } from '../hooks/formFields'

import { FormProvider } from '@workwave-tidal/form-ui/layout'
import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../hooks/useController'
import { useFormConfig } from '../hooks/useFormConfig'
import { formFields } from '../hooks/formFields'

import { Header } from './Header'
import { Footer } from './Footer'
import { Body } from './Body'

export function Content() {
  const { ready } = useConfigureController()
  const config = useFormConfig()

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors>
      name="set-breadcrumb-time-range-form"
      config={config}
      fields={formFields}
    >
      <ModalContent header={<Header />} footer={<Footer />}>
        <Body />
      </ModalContent>
    </FormProvider>
  )
}
