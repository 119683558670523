import { useAppLanguage } from '@/atoms'
import { Paper, Stack } from '@mui/material'

import { LogoArea } from './components/LogoArea'
import { MainNav } from './components/MainNav'
import { MenuArea } from './components/MenuArea'

import { HeaderContainer } from './elements/HeaderContainer'

import { useHeader } from './hooks/useHeader'

export function Header() {
  // guarantees that the component is re-rendered when the language changes
  useAppLanguage()

  const { authenticated, planType } = useHeader()

  if (!authenticated) return null

  return (
    <Paper elevation={4}>
      <HeaderContainer planType={planType}>
        <Stack
          direction="row"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <LogoArea />

          <MainNav />

          <MenuArea />
        </Stack>
      </HeaderContainer>
    </Paper>
  )
}
