import { useSelector } from 'react-redux'

import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { selectUserAccountPreferences } from '@/features/domain/user'
import { Text } from '@/local/components'
import { timeUtils } from '@/server-data'

import { Stack } from '@mui/material'
import { useTexts } from '../useTexts'

interface Props {
  breaks: uui.domain.rm.Break[]
}

export function BreaksBlock(props: Props) {
  const { breaks } = props

  const texts = useTexts()

  const accountPreferences = useSelector(selectUserAccountPreferences)

  return (
    <Stack
      direction="row"
      height="100%"
      data-trackid="navigo-routes-overview-details-single-vehicleStats-breaksBlock"
      data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock"
      justifyContent="space-between"
      marginLeft={3}
      width="auto"
    >
      <Text size="$p3">{`${texts.breaks}:`}&nbsp;&nbsp;</Text>

      <Stack direction="column" height="100%" width="100%" flexShrink={0}>
        {breaks.map((breakItem, index) => (
          <Stack
            direction="row"
            height="100%"
            width="100%"
            data-trackid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break"
            data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break"
            key={index}
          >
            <Text
              data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break-duration"
              weight="$semiBold"
              size="$p3"
            >
              <FormatSeconds seconds={breakItem.durationSec} format="MINUTES_ONLY" />
              &nbsp;&nbsp;
            </Text>

            <Text size="$p3">{`${texts.starting}`}&nbsp;&nbsp;</Text>

            <Text
              data-testid="navigo-routes-overview-details-single-vehicleStats-breaksBlock-break-interval"
              size="$p3"
            >
              {timeUtils.formatSecondsFromMidnight(
                breakItem.startSec,
                accountPreferences.timeFormat,
              )}
              {' - '}
              {timeUtils.formatSecondsFromMidnight(breakItem.endSec, accountPreferences.timeFormat)}
            </Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
