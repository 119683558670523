import type { DurationInputAction, DurationInputState } from '../../types'
import { computeDropdownSelectedIndex } from '../../utils/computeDropdownSelectedIndex'
import { formatValue } from '../../utils/formatValue'
import { parseDuration } from '../../utils/parseDuration/parseDuration'
import { computeDropdownOptions } from './useDropdownOptions'

export function durationInputStateReducer(state: DurationInputState, action: DurationInputAction): DurationInputState {
  switch (action.type) {
    case 'textfield:change': {
      const {
        target: { value: displayValue },
      } = action.event

      const duration = parseDuration(displayValue, state.format)

      if (duration === 'invalidFormat') {
        return {
          ...state,
          dirty: true,
          error: 'invalidFormat',
          dropdownSelectedIndex: -1,
          displayValue,
        }
      }

      return {
        ...state,
        dirty: true,
        error: null,
        fieldValue: duration,
        dropdownSelectedIndex: computeDropdownSelectedIndex(duration, state.dropdownOptions),
        displayValue,
      }
    }

    case 'textfield:keydown': {
      switch (action.event.code) {
        case 'Tab': {
          return {
            ...state,
            open: false,
          }
        }

        case 'Enter': {
          action.event.preventDefault()
          if (state.open === false) return state

          const selectedOption = state.dropdownOptions[state.dropdownSelectedIndex]
          if (!selectedOption) return state

          return {
            ...state,
            dirty: true,
            fieldValue: selectedOption.value,
            displayValue: selectedOption.label,
            error: null,
            open: false,
          }
        }

        case 'Escape': {
          action.event.preventDefault()

          return { ...state, open: false }
        }

        case 'ArrowUp': {
          action.event.preventDefault()

          if (state.open === false) {
            return { ...state, open: true }
          }

          const dropdownSelectedIndex = Math.max(state.dropdownSelectedIndex - 1, 0)
          return { ...state, dropdownSelectedIndex }
        }

        case 'ArrowDown': {
          action.event.preventDefault()

          if (state.open === false) {
            return { ...state, open: true }
          }

          const dropdownSelectedIndex = Math.min(state.dropdownSelectedIndex + 1, state.dropdownOptions.length - 1)
          return { ...state, dropdownSelectedIndex }
        }

        default:
          return state
      }
    }

    case 'textfield:blur': {
      action.event?.stopPropagation()

      return {
        ...state,
        displayValue: formatValue(state.fieldValue, state.format),
        error: null,
      }
    }

    case 'textfield:click': {
      return {
        ...state,
        open: true,
      }
    }

    case 'dropdownItem:click': {
      const value = (action.event.target as HTMLLIElement).value

      return {
        ...state,
        dirty: true,
        fieldValue: value,
        displayValue: formatValue(value, state.format),
        open: false,
      }
    }

    case 'clickAway:click': {
      return {
        ...state,
        open: false,
      }
    }

    case 'reaction/change:value': {
      if (action.value === state.fieldValue) return state

      return {
        ...state,
        fieldValue: action.value,
        dirty: true,
        displayValue: formatValue(action.value, state.format),
        dropdownSelectedIndex: computeDropdownSelectedIndex(action.value, state.dropdownOptions),
      }
    }

    case 'reaction/change:format': {
      if (action.format === state.format) return state

      return {
        ...state,
        format: action.format,
        displayValue: formatValue(state.fieldValue, action.format),
      }
    }

    case 'reaction/change:dropdownOptions': {
      const dropdownOptions = computeDropdownOptions(action.format, action.optionValues)
      return {
        ...state,
        dropdownOptions,
        dropdownSelectedIndex: computeDropdownSelectedIndex(state.fieldValue, dropdownOptions),
      }
    }

    default:
      return state
  }
}
