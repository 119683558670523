import type { FormError, FormField, FormValues as FormValuesType } from '@workwave-tidal/form-fairy'

import type { Option } from '../../types'

const topicField: FormField<Option | null> = {
  required: true,
  value: null,
}

const messageField: FormField<string> = {
  required: true,
  value: '',
}

export const formFields = {
  topic: topicField,
  message: messageField,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
export type FormValues = FormValuesType<FormFields>
