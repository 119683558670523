import { selectMapConfig } from '@/features/domain/user'
import { store } from '@/store'

import { mapAtom } from '../map'

import { createOrUpdateLiveTrafficLayer } from './createOrUpdateLiveTrafficLayer'
import { createOrUpdateTilesLayer } from './createOrUpdateTilesLayer'

export const createMapTiles = () => {
  store.subscribe(() => {
    const state = store.getState()
    const { tile, showTraffic } = selectMapConfig(state)

    if (!state) return

    const { token } = state.domain.publicData.profile.hereAuthToken
    const { language } = state.domain.publicData.userConfiguration

    if (!token) return

    const authenticationToken = { type: 'oAuth', value: token } as const

    // store Here map config
    mapAtom.authenticationToken = authenticationToken

    // Create (or update) the base map tiles layer
    createOrUpdateTilesLayer(tile, language)

    // Create (or update) the live traffic layer
    createOrUpdateLiveTrafficLayer(showTraffic)
  })
}
