import { styled } from '@mui/material/styles'
import { Popover, MenuList } from '@mui/material'

export const StyledPopover = styled(Popover)({
  width: '100%',
  pointerEvents: 'none',
})

export const StyledMenuList = styled(MenuList)({
  maxHeight: 300,
  overflowX: 'hidden',
  overflowY: 'scroll',
  pointerEvents: 'all',

  '& .MuiMenu-paper': {
    marginTop: 25,
    width: '100%',
    transitionDuration: '0s !important',
  },
})
