import { styled } from '@mui/material/styles'
import { Text } from '@/local/components'

interface TitleProps {
  showTitle: boolean
  color: '$silver' | '$nightRider'
}

export const StyledTitle = styled(Text, {
  shouldForwardProp: prop => prop !== 'showTitle',
})<TitleProps>(({ showTitle }) => ({
  opacity: showTitle ? 1 : 0,
}))
