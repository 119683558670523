import type { MouseEvent } from 'react'
import type { RenderItemProps } from '@workwave-tidal/core/components/VirtualList'
import type { ModalDevice } from '../../../../../typings'

import { useCallback } from 'react'
import { Box } from '@mui/material'

import { Radio } from '@/components/primitives/Radio'
import { FlexBox, InvisibleButton, Text } from '@/local/components'
import { useGetDistanceUnitOfMeasure, useFormatNumber } from '@/hooks'

type Props = RenderItemProps<ModalDevice>

export function DeviceListItem(props: Props) {
  const { item, onClick, selected } = props

  const unitOfMeasure = useGetDistanceUnitOfMeasure()

  const formattedDistance = useFormatNumber(typeof item.distance === 'number' ? item.distance : 0)

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick({ item, event })
    },
    [onClick, item],
  )

  return (
    <InvisibleButton
      onClick={handleOnClick}
      key={item.id}
      style={{ width: 'calc(100% - 12px)' }}
      data-testid="send-message-to-driver-list-item"
    >
      <FlexBox w="100%" h={20} paddingBottom={8} vAlignContent="center">
        <Radio checked={selected} />

        <Text
          size="$h3"
          weight="$semiBold"
          color={selected ? '$silver' : '$nightRider'}
          marginLeft={5}
          ellipsis
        >
          {item.label}
        </Text>

        <Box display="flex" flexShrink={0}>
          <Text size="$p3" color="$silver" marginTop={4}>
            {typeof item.distance === 'number' ? `${formattedDistance} ${unitOfMeasure}` : '-'}
          </Text>
        </Box>
      </FlexBox>
    </InvisibleButton>
  )
}
