import { styled } from '@mui/material/styles'
import { theme } from '@/styles'
import { Paper } from '@mui/material'

export const StyledWrapper = styled('div')({
  bottom: 30,
  right: 30,
  zIndex: 9999,
  position: 'fixed',
})

export const StyledContainer = styled(Paper)({
  zIndex: 9999,
  width: '40vw',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: '#FFFFFFEB',
})

export const StyledContent = styled('div')({
  padding: 30,
  overflow: 'hidden',
})

export const StyledScrollbar = styled('div')({
  marginTop: 20,
})

export const StyledHeader = styled('div')({
  padding: '10px 30px',
  width: '100%',
  cursor: 'grab',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.$paleSky,
})
