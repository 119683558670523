import { FormError, FormField } from '@workwave-tidal/form-fairy'

const status: FormField<uui.domain.server.rm.VoiceConfig['status']> = {
  value: 'OFF',
}

const forbidDriverCallsToCompletedOrderSteps: FormField<boolean> = {
  value: false,
  visible: false, //TODO: Re-enable that field once it will be available
}

const forbidDriverCallsToIncompleteOrderSteps: FormField<boolean> = {
  value: false,
  visible: false, //TODO: Re-enable that field once it will be available
}

const callbackConfigStatus: FormField<'BACK_OFFICE' | 'OFF'> = {
  value: 'OFF',
}

const backOfficePhone: FormField<string> = {
  value: '',
}

const automatedMessageEnabled: FormField<'ON' | 'OFF'> = {
  value: 'OFF',
}

const automatedMessage: FormField<string> = {
  value: '',
}

const automatedMessageVoice: FormField<'MALE' | 'FEMALE'> = {
  value: 'FEMALE',
}

export const formFields = {
  status,
  forbidDriverCallsToCompletedOrderSteps,
  forbidDriverCallsToIncompleteOrderSteps,
  callbackConfigStatus,
  backOfficePhone,
  automatedMessageEnabled,
  automatedMessage,
  automatedMessageVoice,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {}
