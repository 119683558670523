import type { FormErrors, FormFields } from '../../../formFields'

import { useSelector } from 'react-redux'

import { TimePicker } from '@workwave-tidal/form-ui'

import { selectUserAccountPreferences } from '@/features/domain/user'

import { useTexts } from '../../../useTexts'
import { useNotificationTimeOptions } from './hooks/useNotificationTimeOptions'
import { useErrorLabels } from './useErrorLabels'

export function NotificationTime() {
  const { timeFormat } = useSelector(selectUserAccountPreferences)
  const errorLabels = useErrorLabels()
  const options = useNotificationTimeOptions()
  const texts = useTexts()

  const format = timeFormat === 'HH:mm' ? '24' : '12'
  return (
    <TimePicker<'notificationCutoffMins', FormFields, FormErrors>
      name="notificationCutoffMins"
      label={texts.notificationCutoffMinsLabel}
      format={format}
      testId="notificationCutoffMins"
      errorLabels={errorLabels}
      optionValues={options}
      // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
      componentsProps={{
        popper: {
          sx: {
            zIndex: 9999,
          },
        },
      }}
      allowUnlistedValues
    />
  )
}
