import type { FormFields, FormErrors } from '../components/TicketForm/formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { openSupportTicket as openSupportTicketAction } from '@/features/support/openSupportTicket'
import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'

import { useTexts } from './useTexts'
import { useController } from './useController'
import { useAttachAppInfoToMessage } from './useAttachAppInfoToMessage'

export const useControllerActions = () => {
  const dispatch = useAppDispatch()
  const { close, status, update } = useController()
  const toast = useNotification()
  const texts = useTexts()
  const attachAppInfo = useAttachAppInfoToMessage()
  const isUnmounted = useIsUnmounted()
  const formApi = useFormApi<FormFields, FormErrors>()

  const openSupportTicket = useCallback(async () => {
    try {
      if (status !== 'ready') return

      formApi.setSubmitting(true)
      update({ status: 'submitting' })

      // verify if the form is already valid
      const formIsValidBeforeSubmitting = formApi.getMeta().status === 'valid'

      // Validate the form if necessary, and wait for the validation result
      const formIsValid = formIsValidBeforeSubmitting || (await formApi.validate())

      if (isUnmounted()) return

      if (!formIsValid) {
        formApi.setSubmitting(false)
        update({ status: 'ready' })
        return
      }

      const formValues = formApi.getValues()

      const message = attachAppInfo(formValues.message)
      const topic = formValues.topic?.type === 'option' ? formValues.topic.value : ''

      const result = await dispatch(openSupportTicketAction({ topic, message }))

      if (openSupportTicketAction.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'ready' })
      toast.success(texts.success)
      close?.()
    } catch (e) {
      // Clear FormState submitting mode
      formApi.setSubmitting(false)
      update({ status: 'error' })

      toast.error(texts.error)
    }
  }, [dispatch, close, toast, texts, status, attachAppInfo, isUnmounted, formApi, update])

  return { openSupportTicket }
}
