import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritory } from '@/features/domain/territory'

export function useBorderRestriction() {
  const territory = useSelector(selectTerritory)

  return useMemo(
    () => ({
      code: territory.borderRestrictionCode,
      name: territory.borderRestrictionFullName,
    }),
    [territory],
  )
}
