import { useEffect } from 'react'

import { useInitializeMapOnTerritoryChange } from '@/hooks'
import { subscribeMap } from '@/map'
import { subscribeSchedulerProject } from '@/scheduler'
import { useDomainMessage } from '@/store'

import {
  subscribeAccountsData,
  subscribeAppLanguage,
  subscribeApplicationMode,
  subscribeChangeCalendarRange,
  subscribeLoadingState,
  subscribeReplaceDomainData,
  subscribeSchedulerTimeSpanChangeCalendarRange,
  subscribeSchedulerTimeSpanReplaceDomainData,
  subscribeTransactionState,
} from '@/atoms'
import { createMapTiles } from '../../map/atoms/map/utils/createMapTiles'

export function DomainSubscriptions() {
  // toast notifications
  useDomainMessage()

  // Fit map on all orders and depots once the territory changes
  useInitializeMapOnTerritoryChange()

  useEffect(() => {
    const unsubscribeSchedulerProject = subscribeSchedulerProject()
    const unsubscribeMap = subscribeMap()

    // Subscribe atoms to domain notifications
    subscribeSchedulerTimeSpanChangeCalendarRange()
    subscribeSchedulerTimeSpanReplaceDomainData()
    subscribeChangeCalendarRange()
    subscribeReplaceDomainData()
    subscribeTransactionState()
    subscribeApplicationMode()
    subscribeAccountsData()
    subscribeLoadingState()
    subscribeAppLanguage()
    createMapTiles()

    return () => {
      unsubscribeSchedulerProject()
      unsubscribeMap()
    }
  }, [])

  return null
}
