import type { DayProps as Props } from '../../typings'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'

import { Card } from '../Card'
import { CardContainer } from '../CardContainer'

import { Dot } from './Dot'
import { LeftSeparator } from './LeftSeparator'
import { computeTestId, computeTestLabel } from './utils'

export function Day(props: Props) {
  const {
    secondaryRangeStartRepresentation,
    dotColor = '$pigmentGreen',
    ghostSelectionStart,
    selectionStart,
    leftSeparator,
    roundedBorder,
    selectionEnd,
    tooltipText,
    onMouseOver,
    firstOfRow,
    underline,
    cosmetic,
    disabled,
    testId,
    label,
    bold,
    dot,
    id,
  } = props

  const resetSelection = cosmetic === 'grayed-out' ? '1' : '0'
  const separatorInSelection = cosmetic === 'selected' || cosmetic === 'edge-of-selection'

  return (
    <CardContainer
      cosmetic={cosmetic}
      firstOfRow={firstOfRow}
      onMouseOver={onMouseOver}
      selectionEnd={selectionEnd}
      roundedBorder={roundedBorder}
      selectionStart={selectionStart}
      ghostSelectionStart={ghostSelectionStart}
      forceUnselected={secondaryRangeStartRepresentation}
    >
      <Tooltip title={tooltipText || ''} disabled={!tooltipText} placement="bottom">
        <Card
          cosmetic={cosmetic}
          data-reset-selection={resetSelection}
          data-day={id}
          disabled={disabled}
          data-testid={testId || computeTestId(underline)}
          data-testlabel={computeTestLabel(cosmetic)}
          ghostSelectionStart={ghostSelectionStart}
          secondaryRangeStartRepresentation={secondaryRangeStartRepresentation}
          className="o-calendar__card"
        >
          <Text size="$m" underline={underline} weight={bold ? '$semiBold' : '$light'}>
            {label}
          </Text>
          {dot && (
            <Dot
              background={dotColor}
              cosmetic={cosmetic}
              data-testid="calendar-card-dot"
              data-trackid="calendar-card-dot"
            />
          )}
          {leftSeparator && (
            <LeftSeparator data-testid="first-ops-day" inSelection={separatorInSelection} />
          )}
        </Card>
      </Tooltip>
    </CardContainer>
  )
}
