import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { Payments, Pentagon, Timelapse } from '@mui/icons-material'
import { memo, useCallback } from 'react'

import {
  ListItem,
  ListItemAvatarRounded,
  ListItemContent,
  ListItemRow,
  ListItemTitle,
  ListItemVisibilityToggler,
} from '@/components/List'
import { FormatCurrency } from '@/components/smartUtils/currency/FormatCurrency'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { getColorValue } from '@/styles'

import { useVisibility } from '../hooks/useVisibility'

type Props = Extract<RenderItemProps<'regions'>, { type: 'item' }>

export const RegionItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const [visible, toggleVisibility] = useVisibility(item.id)

  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])
  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])

  return (
    <ListItem
      invisibleOnMap={!visible}
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      testId="region-list-item"
      avatar={
        <ListItemAvatarRounded color={getColorValue(item.color)}>
          <Pentagon />
        </ListItemAvatarRounded>
      }
      right={<ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />}
    >
      <ListItemTitle>{item.name}</ListItemTitle>

      <ListItemRow testId="region-additional-cost-row">
        <ListItemContent icon={<Payments />}>
          {item.enterCost ? <FormatCurrency amount={item.enterCost} /> : '-'}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="region-additional-driving-time-row">
        <ListItemContent icon={<Timelapse />}>
          {item.enterTimeSec > 0 ? (
            <FormatSeconds seconds={item.enterTimeSec} format="SHORT" />
          ) : (
            '-'
          )}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

RegionItem.displayName = 'RegionItem'
