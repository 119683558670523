import type { TrafficAreaFormValues } from '../types'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useCrudSelection } from '@/atoms'
import { selectTrafficTimeWindows } from '@/features/domain/traffic'
import { geo, gis } from '@/server-data'

export function useFormInitialValue(
  trafficProfile: uui.domain.client.rm.TrafficProfile,
  creating?: boolean,
) {
  const { id, regions } = trafficProfile
  const [trafficAreaSelection] = useCrudSelection('trafficArea')
  const timeWindowsCount = useSelector(selectTrafficTimeWindows).length

  const trafficArea = creating
    ? createTrafficRegion(id, timeWindowsCount, regions.length)
    : getSelectedTrafficRegion(trafficProfile, trafficAreaSelection)

  const [initialValues] = useState<TrafficAreaFormValues>(() => {
    const { level, name, timeWindowLevels, points } = trafficArea

    return {
      level: getSliderInitialValue(level),
      points,
      name,
      firstTimeWindowValue:
        timeWindowLevels[0] !== undefined ? getSliderInitialValue(timeWindowLevels[0]) : undefined,
      secondTimeWindowValue:
        timeWindowLevels[1] !== undefined ? getSliderInitialValue(timeWindowLevels[1]) : undefined,
    }
  })

  return initialValues
}

// old UI can set this to 500, but VRE consider values until 400
function getSliderInitialValue(level: number) {
  return -Math.abs(level)
}

export const getValueForModel = (level: number) => Math.abs(level)

function getSelectedTrafficRegion(
  trafficProfile: uui.domain.client.rm.TrafficProfile,
  selection: workwave.DeepReadonly<string[]>,
) {
  if (selection.length !== 1) {
    throw new Error('trafficAreaSelection has to have length 1')
  }

  const trafficArea = trafficProfile.regions.find(r => r.id === selection[0])
  if (!trafficArea) {
    throw new Error('Cannot find traffic area')
  }
  return trafficArea
}

function computeTimeWindowLevels(timeWindowLevelsCount: number) {
  switch (timeWindowLevelsCount) {
    case 1:
      return [100]

    case 2:
      return [100, 100]

    case 0:
    default:
      return []
  }
}

function createTrafficRegion(
  recurrence: string,
  timeWindowLevelsCount: number,
  index: number,
): uui.domain.client.rm.TrafficRegion {
  const poly = []

  const computedPoints = poly.map(latLng => geo.latLngFromJSON(latLng))
  const bounds = gis.computePolyBoundaries(computedPoints)

  return {
    id: `${recurrence}-${index}`,
    name: '',
    timeWindowLevels: computeTimeWindowLevels(timeWindowLevelsCount),
    poly,
    level: 100,
    bounds,
    points: computedPoints,
    recurrence,
    index,
  }
}
