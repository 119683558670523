import { NoDispatch } from '@/icons'
import { Popover, Stack, styled } from '@mui/material'

export const StyledPopover = styled(Popover)({
  pointerEvents: 'none',
  '& .paper': {
    borderRadius: 4,
  },
})

export const StyledStack = styled(Stack)({
  minWidth: 200,
})

export const StyledNoDispatchIcon = styled(NoDispatch)({
  position: 'absolute',
  top: 21,
  right: 13,
})
