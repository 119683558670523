import type { ReactElement } from 'react'
import { styled } from '@mui/material/styles'

interface Props {
  children: ReactElement | ReactElement[]
  spacing?: number
}

const StyledFieldset = styled('fieldset')<Props>(({ theme, spacing }) => ({
  padding: 0,
  margin: 0,
  paddingBottom: theme.spacing(spacing ?? 4),
  borderWidth: 0,
}))

export function FieldSet(props: Props) {
  return <StyledFieldset {...props}>{props.children}</StyledFieldset>
}
