import type { FormErrors, FormFields } from '../formFields'
import { useFormField } from '@workwave-tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

type Props = {
  disabled?: boolean
  helperText?: string
}

export function CheckoutField(props: Props) {
  const { disabled: fieldDisabled, helperText } = props
  const texts = useTexts()
  const { formApi } = useFormField<'checkOut', FormFields, FormErrors>('checkOut')
  const { submitting, disabled } = formApi.getMeta()
  const disableField = submitting || disabled || fieldDisabled

  return (
    <Checkbox<'checkOut', FormFields, FormErrors>
      label={texts.checkout}
      name="checkOut"
      validateOn="blur change"
      disabled={disableField}
      helperText={helperText}
      testId="driver-assistance-form-check-out-field"
    />
  )
}
