import { Box, Button } from '@mui/material'

import { useAutoScroll } from '@/atoms'
import { LayoutComposer, ListFooter, NoEntries, NoResults } from '@/components/List'
import { ComponentSize, FormColumn, ListOverlayEditCover } from '@/components/layout'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { StructuredVirtualList } from '@/components/StructuredVirtualList'

import { Item } from './Item'
import { Header } from './components/Header'
import { useDataForList } from './hooks/useDataForList'
import { useListHandlers } from './hooks/useListHandlers'
import { useTexts } from './hooks/useTexts'

interface Props {
  depots: Record<string, uui.domain.client.rm.Depot>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
}

const getItemHeight = () => 79

export function List(props: Props) {
  const { depots, selection, editing } = props

  const { handleOnSelect, handleOnSelectAll, handleOnDoubleClick, handleOnClick } =
    useListHandlers(selection)
  const { structure, lastSelectedDepot } = useDataForList()

  const texts = useTexts()

  useAutoScroll('depots', lastSelectedDepot)

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Box width="100%" height="100%" flex="0 1 auto" position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries
              title={texts.noEntries.title}
              description={texts.noEntries.description}
              action={
                <ReadOnlyTooltip
                  render={preventEditing => (
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={handleOnClick}
                      disabled={preventEditing || editing}
                      data-testid="no-entries-add-item"
                      data-trackid="no-entries-add-item"
                    >
                      {texts.noEntries.action}
                    </Button>
                  )}
                />
              }
            />
          }
          list={
            <ComponentSize
              render={size => {
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={depots}
                      structure={structure}
                      listApiCategory="depots"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={selection}
                      onSelect={handleOnSelect}
                      onDoubleClick={handleOnDoubleClick}
                      buffer={50}
                    />
                    {editing && <ListOverlayEditCover />}
                  </>
                )
              }}
            />
          }
        />
      </Box>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={handleOnSelectAll}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
