import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'

import { approvedRouteFilter } from '../filters'
import { project } from '../project'

let lastShowApprovedRoutesValue = getSchedulerPreferences().showApprovedRoutes

export function syncApprovedRoutesVisibility() {
  const nextShowApprovedRoutesValue = getSchedulerPreferences().showApprovedRoutes
  // If the showApprovedRoutes value hasn't changed, don't do anything
  if (lastShowApprovedRoutesValue === nextShowApprovedRoutesValue) return

  // Update the showApprovedRoutes value
  lastShowApprovedRoutesValue = nextShowApprovedRoutesValue

  if (nextShowApprovedRoutesValue) {
    project.eventStore.removeFilter('approvedRouteFilter')
  } else {
    project.eventStore.addFilter(approvedRouteFilter)
  }
}
