import { Entry } from '../Entry'

export function Entry20250213() {
  return (
    <Entry title="2025 Feb 13 - v3.10.3">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/17455/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
