import type { FormField, FormError } from '@workwave-tidal/form-fairy'

type BulkFieldValue<T> =
  | {
      status: 'mixed'
    }
  | {
      status: 'exact'
      value: T
    }

const summarizeFutureMobileRoute: FormField<BulkFieldValue<boolean>> = {
  value: { status: 'mixed' },
}

const mobileFutureDaysVisibility: FormField<BulkFieldValue<string>> = {
  value: { status: 'mixed' },
}

export const formFields = {
  mobileFutureDaysVisibility,
  summarizeFutureMobileRoute,
} as const

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
