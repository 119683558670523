import { styled } from '@mui/material/styles'
import { normalizeCssValue, ValidColor, theme } from '@/local/components'

type StyleProps = {
  alignItems?: 'center' | 'start' | 'end' | 'stretch'
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  shrink?: 1 | 0
  width?: number | string
  height?: number | string
  padding?: string
  marginTop?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  marginRight?: string | number
  background?: ValidColor
  paddingTop?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  paddingRight?: string | number
}

export const MonthContainer = styled('div')<StyleProps>(
  ({
    shrink = 0,
    width = '100%',
    height = '100%',
    alignItems = 'start',
    justifyContent = 'normal',
    padding = 0,
    paddingTop = 0,
    paddingBottom = 0,
    paddingLeft = 0,
    paddingRight = 0,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    background,
  }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexShrink: shrink,
    width: normalizeCssValue(width),
    height: normalizeCssValue(height),
    alignItems,
    justifyContent,
    padding,
    paddingTop: normalizeCssValue(paddingTop),
    paddingBottom: normalizeCssValue(paddingBottom),
    paddingLeft: normalizeCssValue(paddingLeft),
    paddingRight: normalizeCssValue(paddingRight),
    marginTop: normalizeCssValue(marginTop),
    marginBottom: normalizeCssValue(marginBottom),
    marginLeft: normalizeCssValue(marginLeft),
    marginRight: normalizeCssValue(marginRight),
    background: background ? theme.colors[background] : 'transparent',
  }),
)
