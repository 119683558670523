import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

// 23:59
const maxValue = 23 * 3600 + 59 * 60

export const createValidateServiceTimeSec =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField('serviceTimeSec')

    const invalidFormatError: FormError<keyof FormFields> = {
      id: 'invalid-format',
      field: 'serviceTimeSec',
      message: texts.invalidServiceTimeFormat,
      priority: 3,
    }

    const exceededMaxValueError: FormError<keyof FormFields> = {
      id: 'exceeded-max-value',
      field: 'serviceTimeSec',
      message: texts.invalidServiceTimeMaxValue,
      priority: 7,
    }

    const hasValidFormat =
      value.status === 'exact' ? isEmpty(value.value) || matchHHMMFormat(value.value) : true

    const exceededMaxCount =
      value.status === 'exact' && hasValidFormat ? parseSeconds(value.value) > maxValue : false

    return {
      [invalidFormatError.id]: !hasValidFormat ? invalidFormatError : null,
      [exceededMaxValueError.id]: exceededMaxCount ? exceededMaxValueError : null,
    }
  }

function isEmpty(value: string) {
  return value.trim().length === 0
}

function matchHHMMFormat(value: string) {
  const regex = /^([0-9]?[0-9]):([0-5]?[0-9])$/gm
  return value.match(regex) !== null
}

function parseSeconds(value: string) {
  if (isEmpty(value)) return 0

  const [hh, mm] = value.split(':')
  const hours = parseInt(hh)
  const minutes = parseInt(mm)

  // invalid format not handled here
  if (isNaN(hours) || isNaN(minutes)) {
    return -1
  }

  return hours * 3600 + minutes * 60
}
