import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from '../../../useTexts'
import { ActiveIcon } from './ActiveIcon'

import { StyledLink } from './hooks/useStyles'

export function RmGuestLinks() {
  const texts = useTexts()

  return (
    <>
      <StyledLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={'pendo-bluebar__orders-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-link"
          data-trackid="rm-guest-header__nav-orders-button-link"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </StyledLink>
    </>
  )
}
