import type { ReactNode } from 'react'

import {
  type TooltipProps as MUITooltipProps,
  Tooltip as MuiTooltip,
  Stack,
  type SxProps,
  type Theme,
  Typography,
  styled,
} from '@mui/material'

type Props = {
  sx?: SxProps<Theme>
  title: ReactNode
  open?: boolean
  children: MUITooltipProps['children']
  subtitle?: ReactNode
  disabled?: boolean
  maxWidth?: string | number
  textAlign?: 'left' | 'center' | 'right'
  placement: 'top' | 'bottom' | 'left' | 'right'
  textDirection?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
  textSpacing?: number
}

const TooltipChildrenContainer = styled('span')({})

export function Tooltip(props: Props) {
  const {
    sx,
    open,
    title,
    subtitle = null,
    maxWidth,
    children,
    disabled = false,
    placement,
    textAlign = 'center',
    textSpacing = 0,
    textDirection = 'column',
  } = props

  if (disabled) return props.children

  const titleElement = (
    <Stack
      direction={textDirection}
      alignItems="stretch"
      maxWidth={maxWidth}
      spacing={textSpacing}
      data-testid="tooltip-content"
    >
      <Typography
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        textAlign={textAlign}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          color="divider"
          fontSize="inherit"
          fontWeight="inherit"
          lineHeight="inherit"
          textAlign={textAlign}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  )

  return (
    <MuiTooltip title={titleElement} placement={placement} open={open} disableInteractive>
      <TooltipChildrenContainer sx={sx}>{children}</TooltipChildrenContainer>
    </MuiTooltip>
  )
}
