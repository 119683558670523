import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { FormHeader } from '@workwave-tidal/form-ui/layout'
import { useNavigate } from '@/routing'
import { selectUserConfiguration } from '@/features/domain/user'
import { ViewContainer, ViewContent, ViewFieldsLayout } from '@/formUi'

import { useTexts } from './hooks/useTexts'
import { EmailPlan } from './components/EmailPlan'
import { PlanDetails } from './components/PlanDetails'

import { useRefreshCommunicationPlanData } from './hooks/useRefreshCommunicationPlanData'
import { useCommunicationPlanData } from './hooks/useCommunicationPlanData'

export function CommunicationPlan() {
  // Refresh Communication Plan data every time a user accesses the Communication section
  useRefreshCommunicationPlanData()

  const communicationPlanData = useCommunicationPlanData()
  const userConfig = useSelector(selectUserConfiguration)
  const navigate = useNavigate()
  const texts = useTexts()

  const title = texts.headerTitle
  const subtitle = texts.headerDescription(
    communicationPlanData.settings.hasSubscription
      ? {
          planName: communicationPlanData.settings.smsSubscription.plan.name,
          hasSubscription: true,
        }
      : { hasSubscription: false },
  )

  useEffect(() => {
    if (userConfig.userType === 'gpsOnly' || userConfig.userType === 'rmOnly') {
      navigate('settings')
    }
  }, [navigate, userConfig])

  return (
    <ViewContainer testid="communication-plan-view">
      <ViewContent>
        <FormHeader title={title} subtitle={subtitle} />

        <ViewFieldsLayout>
          {communicationPlanData.settings.hasSubscription ? (
            <PlanDetails
              counters={communicationPlanData.counters}
              subscription={communicationPlanData.settings.smsSubscription}
            />
          ) : (
            <EmailPlan counters={communicationPlanData.counters} />
          )}
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
