import { useCallback } from 'react'
import { DriverAssignmentFormState } from '../typings'

export function useDriverAssignmentForm(initialValues: DriverAssignmentFormState) {
  const onInitialize = useCallback(
    () => ({
      initialValues,
    }),
    [],
  )
  return {
    onInitialize,
  }
}
