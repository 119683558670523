import { Block } from '@/forms-legacy'
import { H2, H4, H6 } from '@/local/components'

import { Stack } from '@mui/material'

import { useController } from '../../../../../../hooks/useController'
import { useTexts } from './useTexts'

export function Driver() {
  const texts = useTexts()
  const { data } = useController()

  if (data.messageType !== 'email') return null

  return (
    <Stack
      direction="column"
      sx={{ marginBottom: '15px' }}
      width="100%"
      data-testid="send-message-to-driver-message-type-panel"
    >
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.messageType}
        </H4>
      </Block>
      <Block data-testid="send-message-to-driver-email-panel">
        <H2>{texts.email}</H2>
        <H6 ellipsis color="$silver">
          {data.email}
        </H6>
      </Block>
    </Stack>
  )
}
