import type { FormFields, FormErrors } from '../../../../hooks/formFields'

import { TextField } from '@workwave-tidal/form-ui'
import { Form } from '@workwave-tidal/form-ui/layout'

import { useOnSubmit } from '../../../../hooks/useOnSubmit'
import { useController } from '../../../../hooks/useController'

import { useValidations } from './hooks/useValidations'
import { useTexts } from './useTexts'

export function ReadyBody() {
  const {
    data: { notification, values },
  } = useController()
  const submit = useOnSubmit(notification, values)

  const texts = useTexts()
  useValidations()

  return (
    <Form disableScroll onEnter={submit}>
      <TextField<'phoneNumber', FormFields, FormErrors>
        label={texts.phoneLabel}
        validateOn="blur focus"
        name="phoneNumber"
        autoFocus
        helperText={texts.phoneHelper}
      />
    </Form>
  )
}
