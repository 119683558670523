import type { MultipleOrderStatus, OrderStatus } from '../../../../typings'

import { Text } from '@/local/components'

import { Stack } from '@mui/material'
import { useTexts } from './useTexts'

interface Props {
  status: MultipleOrderStatus
}

export function StatusDetail(props: Props) {
  const { status } = props
  const texts = useTexts()

  return (
    <Stack direction="column" marginTop={1}>
      <Text size="$s" color="$shadyLady" weight="$bold" uppercase>
        {texts.statuses}
      </Text>

      {Object.keys(status).map(
        (key: OrderStatus) =>
          status[key].count > 0 && (
            <Stack direction="row" key={key}>
              <Text key={key} size="$m" color="$nightRider">
                {`${status[key].count} ${texts[key]}`}
              </Text>

              {status[key].withViolations > 0 && (
                <Text key={key} size="$m" color="$darkOrange">
                  {`(${status[key].withViolations})`}
                </Text>
              )}
            </Stack>
          ),
      )}
    </Stack>
  )
}
