import { Calendar } from '@/components/Calendar'
import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useTexts } from '../useTexts'

export function Body() {
  const { onModeChange, onSelectionChange } = useControllerActions()
  const texts = useTexts()
  const {
    status,
    data: {
      firstNonArchivedDay,
      lastSelectableDate,
      daysWithOrders,
      intervalLength,
      selectionStart,
      selectionEnd,
      disabledDays,
      planType,
      minDate,
      maxDate,
      today,
      mode,
    },
  } = useController()

  const submitting = status === 'submitting'

  const warningCardTitle = texts.areYouSure
  const warningCardDescription =
    mode === 'replace'
      ? // "Replace" texts
        planType === 'simulation'
        ? texts.warningSimulationReplace
        : texts.warningArchivedReplace
      : planType === 'simulation'
        ? // "Add" texts
          texts.warningSimulationAdd
        : texts.warningArchivedAdd

  return (
    <Stack direction="column" height="100%" marginTop={4} width={580}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{texts.mode}</FormLabel>

        <RadioGroup aria-label="gender" name="gender1" value={mode} onChange={onModeChange}>
          <FormControlLabel
            value="add"
            disabled={submitting}
            control={<Radio />}
            label={texts.add}
            data-testid="copy-to-operation-add"
          />
          <FormControlLabel
            value="replace"
            disabled={submitting}
            control={<Radio />}
            label={texts.replace}
            data-testid="copy-to-operation-replace"
          />
        </RadioGroup>
      </FormControl>

      <Calendar
        months={2}
        today={today}
        focusOnSelection
        minDate={minDate}
        maxDate={maxDate}
        layout="fixed-interval"
        selectionEnd={selectionEnd}
        disabledDays={disabledDays}
        onChange={onSelectionChange}
        selectionStart={selectionStart}
        intervalLength={intervalLength}
        highlightedDays={daysWithOrders}
        lastSelectableDate={lastSelectableDate}
        tooltipIntlText={texts.numberOfOrders}
        firstNonArchivedDay={firstNonArchivedDay}
      />

      <Box marginTop={2}>
        <WarningCard
          preset="warning"
          title={warningCardTitle}
          description={warningCardDescription}
          Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        />
      </Box>
    </Stack>
  )
}
