import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

import { useAppDispatch } from '@/store'
import { useNotification, useIsUnmounted } from '@/hooks'
import { deleteCompany } from '@/features/domain/company'

import { type FormFields, type FormErrors } from '../formFields'
import { useTexts } from './useTexts'

import { useController } from './useController'

export function useControllerActions() {
  const toast = useNotification()
  const dispatch = useAppDispatch()
  const {
    close,
    data: {
      company: { id: companyId },
    },
  } = useController()
  const formApi = useFormApi<FormFields, FormErrors>()
  const texts = useTexts()

  const isUnmounted = useIsUnmounted()

  const onSubmit = useCallback(async () => {
    try {
      formApi.setSubmitting(true)

      const valid = await formApi.validate()
      if (!valid) {
        formApi.setSubmitting(false)
        return
      }

      const result = await dispatch(deleteCompany(companyId))

      if (deleteCompany.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      const usersLeftWithoutCompany = result.payload ?? {}
      const usersLeftWithoutCompanyCount = Object.keys(usersLeftWithoutCompany).length

      if (usersLeftWithoutCompanyCount > 0) {
        toast.warning(texts.toast.usersLeftWithoutCompany(usersLeftWithoutCompanyCount), {
          autoClose: false,
        })
      }

      toast.success(texts.toast.success)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      close?.()
    } catch (e) {
      toast.error(e.message)
    }
  }, [toast, close, dispatch, formApi, isUnmounted, companyId, texts])

  return {
    onSubmit,
  }
}
