import { Entry } from '../Entry'

export function Entry20250409() {
  return (
    <Entry title="2025 Apr 09 - v3.10.7">
      Link to the{' '}
      <a
        href="https://workwave.atlassian.net/projects/RM/versions/17460/tab/release-report-all-issues"
        target="_blank"
        rel="noreferrer"
      >
        Jira Release
      </a>
    </Entry>
  )
}
