import type { FormErrors, FormFields } from '../formFields'
import { TextField } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

export function Username() {
  const texts = useTexts()

  return (
    <TextField<'username', FormFields, FormErrors>
      name="username"
      testId="username"
      label={texts.labels.username}
      validateOn="blur change"
      required
    />
  )
}
