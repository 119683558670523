import { useEffect, useState } from 'react'

export function usePaperWidth($root: HTMLElement | null) {
  const [width, setWidth] = useState(() => 0)

  useEffect(() => {
    if (!$root) return

    const updateWidth = () => {
      setWidth($root.offsetWidth ?? 0)
    }

    // Initial width update
    updateWidth()

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(updateWidth)
    observer.observe($root, { attributes: true, childList: true, subtree: true })

    // Cleanup observer on unmount
    return () => {
      observer.disconnect()
    }
  }, [$root])

  return width
}
