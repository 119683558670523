import { styled } from '@mui/material/styles'

import { theme } from '@/styles'

import { Stack } from '@mui/material'
import { RouteCircle } from './RouteCircle'

type Color = string
interface Props {
  routeColors: Record<string, Color>
}

const StyledStack = styled(Stack)({
  '& > div': { marginRight: -6 },
  '& > div:last-child': { marginRight: -2 },
})

const maxCircles = 4

export function RoutesColors(props: Props) {
  const { routeColors } = props
  const colors = Object.values(routeColors)
  const circles = Object.values(colors).slice(0, maxCircles)

  return (
    <StyledStack direction="row" width="auto" justifyContent="end">
      {circles.map((color, index) => (
        <RouteCircle color={color} key={index} />
      ))}

      {colors.length > maxCircles && <RouteCircle color={theme.colors.$pureBlack} withPlus />}
    </StyledStack>
  )
}
