import { Radio } from '@/components/primitives/Radio'
import { Block } from '@/forms-legacy'
import { H4, Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useController } from '../../../../../../hooks/useController'
import { useControllerActions } from '../../../../../../hooks/useControllerActions'
import { useTexts } from './useTexts'

export function MessageTypeSelector() {
  const texts = useTexts()
  const {
    data: { messageType },
  } = useController()
  const actions = useControllerActions()

  return (
    <Stack
      direction="column"
      sx={{ marginBottom: '15px' }}
      width="100%"
      data-testid="send-message-to-driver-message-type-selector"
    >
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.messageType}
        </H4>
      </Block>

      <Block>
        <Stack direction="row" alignItems="center" width="100%">
          <Radio
            checked={messageType === 'sms'}
            onClick={actions.setMessageTypeToSms}
            data-testid="send-message-to-driver-sms-message-option"
          />
          <Text size="$h3" weight="$semiBold" marginLeft={5} ellipsis>
            {texts.sms}
          </Text>
        </Stack>
        <Stack direction="row" alignItems="center" width="100%">
          <Radio
            checked={messageType === 'email'}
            onClick={actions.setMessageTypeToEmail}
            data-testid="send-message-to-driver-email-message-option"
          />
          <Text size="$h3" weight="$semiBold" marginLeft={5} ellipsis>
            {texts.email}
          </Text>
        </Stack>
      </Block>
    </Stack>
  )
}
