import type { FormFields, FormErrors } from '../formFields'
import type { FormConfig } from '@workwave-tidal/form-fairy'

import { useState } from 'react'

import { createFormValidation } from '../validations/createFormValidation'
import { useTexts } from '../useTexts'

export function useFormConfig(
  tenantSource: uui.domain.server.gps.telematics.TenantSource,
): FormConfig<FormFields, FormErrors> {
  const texts = useTexts()

  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    overrideValues: {
      tenantSourceId: tenantSource.id,
      label: tenantSource.label,
    },
    validations: createFormValidation(texts),
  }))

  return formConfig
}
