import { useEffect } from 'react'
import { type SchedulerPro } from '@bryntum/schedulerpro'
import Mousetrap from 'mousetrap'
import { getOperatingSystem } from '@/utils'

interface UseKeyboardShortcutsProps {
  schedulerInstance: { instance?: SchedulerPro | null }
  setAllExpanded: (expanded: boolean) => void
}

export const useKeyboardShortcuts = ({
  schedulerInstance,
  setAllExpanded,
}: UseKeyboardShortcutsProps) => {
  const handleExpand = () => {
    schedulerInstance.instance?.expandAll()
    setAllExpanded(true)
  }

  const handleCollapse = () => {
    schedulerInstance.instance?.collapseAll()
    setAllExpanded(false)
  }

  useEffect(() => {
    const os = getOperatingSystem()

    switch (os) {
      case 'MacOS':
        Mousetrap.bind('ctrl+command+e', handleExpand)
        Mousetrap.bind('ctrl+command+c', handleCollapse)
        break
      default:
        Mousetrap.bind('alt+ctrl+e', handleExpand)
        Mousetrap.bind('alt+ctrl+c', handleCollapse)
        break
    }

    return () => {
      switch (os) {
        case 'MacOS':
          Mousetrap.unbind('ctrl+command+e')
          Mousetrap.unbind('ctrl+command+c')
          break
        default:
          Mousetrap.unbind('alt+ctrl+e')
          Mousetrap.unbind('alt+ctrl+c')
          break
      }
    }
  })
}
