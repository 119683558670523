import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'
import { RadioGroup } from '@workwave-tidal/form-ui'
import { useTexts } from '../../useTexts'

export function EtaRange() {
  const texts = useTexts()
  const {
    field: { value },
  } = useFormField<'windowOfCommitmentToleranceMins', FormFields, FormErrors>(
    'windowOfCommitmentToleranceMins',
    {
      fieldsOfInterest: ['value'],
    },
  )

  const helperText = useMemo(() => {
    let startTime = '',
      endTime = ''

    switch (value) {
      case '15':
        startTime = '9.00 AM'
        endTime = '9.30 AM'
        break
      case '30':
        startTime = '8.45 AM'
        endTime = '9.45 AM'
        break
      case '60':
        startTime = '8.15 AM'
        endTime = '10.15 AM'
        break
      case '120':
        startTime = '7.15 AM'
        endTime = '11.15 AM'
        break
    }

    return texts.etaRangeHelperText('9.12 AM', startTime, endTime)
  }, [texts, value])

  const options = useMemo(
    () => [
      {
        value: '15',
        label: texts.etaRangeMinutes(30),
      },
      {
        value: '30',
        label: texts.etaRangeHours(1),
      },
      {
        value: '60',
        label: texts.etaRangeHours(2),
      },
      {
        value: '120',
        label: texts.etaRangeHours(4),
      },
    ],
    [texts],
  )

  return (
    <RadioGroup<'windowOfCommitmentToleranceMins', string, FormFields, FormErrors>
      name="windowOfCommitmentToleranceMins"
      label={texts.etaRangeLabel}
      helperText={helperText}
      validateOn="blur focus"
      testId="windowOfCommitmentToleranceMins"
      options={options}
    />
  )
}
