import type { FormErrors, FormFields } from '../../formFields'
import type { LazyCreateFormConfig } from '@workwave-tidal/form-fairy'

import { useState } from 'react'

import { configuredFieldsMutation } from './mutations/configuredFieldsMutation'
import { typeMutation } from './mutations/typeMutation'

import { computeInitialValues } from './utils/computeInitialValues'

export function useCreateFormConfig(
  overrideValues: uui.domain.server.rm.MobileAppInteraction,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const initValues = computeInitialValues(overrideValues)

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues: initValues,
      formMutations: [
        { id: 'type-change', mutation: typeMutation },
        { id: 'configured-field-change', mutation: configuredFieldsMutation },
      ],
    })
  })

  return createFormConfig
}
