import { RouteManager } from '@workwave-tidal/core/icons/logos'
import { Link } from '@/routing'

import { HeaderSlice } from '../../elements/HeaderSlice'
import { Hamburger } from './components/Hamburger'

type MainSections = uui.routing.MainSections

const logoStyle = { marginLeft: 3, display: 'block' }

export function LogoArea() {
  return (
    <HeaderSlice width={320} paddingX={32}>
      <Hamburger />

      <Link<MainSections> to="/" data-testid="header__rm-logo" data-trackid="header__rm-logo">
        <RouteManager mode="contrast" sx={logoStyle} width={148} />
      </Link>
    </HeaderSlice>
  )
}
