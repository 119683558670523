import { Text } from '@/local/components'
import { Stack } from '@mui/material'

interface Props {
  place: uui.domain.client.gps.wwgps.Place
}

export function Single(props: Props) {
  const { place } = props

  return (
    <Stack
      direction="column"
      height="100%"
      width="100%"
      data-trackid="navigo-places-overview-details-single"
      data-testid="navigo-places-overview-details-single"
      justifyContent="space-between"
      flexShrink={0}
    >
      <Text size="$p2" weight="$semiBold">
        {place.name}
      </Text>
      <Text size="$p3">{place.address}</Text>
    </Stack>
  )
}
