import type { FormFields, FormErrors } from '../formFields'

import { Checkbox } from '@workwave-tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'

export function ShowETARange() {
  const texts = useTexts()

  return (
    <Checkbox<'showEtaRange', FormFields, FormErrors>
      name="showEtaRange"
      label={texts.showEtaRange}
      testId="showEtaRange"
      validateOn="blur focus"
      helperText={texts.showEtaRangeHelperText}
    />
  )
}
