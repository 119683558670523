import { setupFormState } from '@/components/form/tidal-form'
import { RegionFormValues } from './typings'

export const form = setupFormState<RegionFormValues>({
  id: 'regionForm',
  initialValues: {
    id: '',
    name: '',
    color: '#23114F',
    enterCost: 0,
    enterTimeSec: 0,
    points: [],
  },
})
