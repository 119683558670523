import { is } from '@/utils'

/**
 * @private
 * Generates the absolute path from a relative one.
 * ATTENTION: every nested path should be unique.
 */
export const generateRelativePath = (absolutePath: string) => {
  if (absolutePath === '/') return 'home'

  const fragments = absolutePath.split('/').splice(1).filter(Boolean)

  if (fragments.length === 1) {
    if (is.routing.MainSections(fragments[0])) return fragments[0]
  }

  if (fragments.length === 2) {
    if (
      is.routing.Setup(fragments[1]) ||
      is.routing.Settings(fragments[1]) ||
      is.routing.Error(fragments[1]) ||
      is.routing.Routing(fragments[1])
    )
      return fragments[1]
  }
}
