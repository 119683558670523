import type { FormErrors, FormFields } from '../formFields'
import { Box } from '@mui/material'
import { useFormField } from '@workwave-tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'
import { OrdersType } from './OrdersType'

export function NoteField() {
  const texts = useTexts()
  const { formApi } = useFormField<'includeNote', FormFields, FormErrors>('includeNote')
  const {
    field: { value: isChecked },
  } = useFormField<'includeNoteChecked', FormFields, FormErrors>('includeNoteChecked')

  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  return (
    <>
      <Checkbox<'includeNoteChecked', FormFields, FormErrors>
        name="includeNoteChecked"
        label={texts.note}
        testId="driver-assistance-form-note-field"
        disabled={formDisabled}
        validateOn="blur change"
      />
      {isChecked && (
        <Box marginLeft={2}>
          <OrdersType name="includeNote" disabled={formDisabled} />
        </Box>
      )}
    </>
  )
}
