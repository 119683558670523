import { intl } from '@/intl'
import { durationUtils } from '@/server-data'

import { NullValue } from './NullValue'

interface Props {
  children: number
}

export function DurationValue(props: Props) {
  const { children } = props

  return children !== 0 ? (
    durationUtils.formatSeconds(intl.translate)(children, 'SHORT')
  ) : (
    <NullValue />
  )
}
