import { FormActionButton } from '@workwave-tidal/core/form'

import { useEditingStateWithCtx } from '@/atoms'

import { form } from '../formState'
import { useTexts } from '../hooks/useTexts'

export function Footer() {
  const { resetEditing } = useEditingStateWithCtx('region')
  const { field: idField } = form.useFormField('id')
  const texts = useTexts()

  const {
    meta: { submitting, validating, status },
  } = form.useFormMeta()

  // When creating a new entity the submit button should be enabled also when the form is in a pristine status
  const saveDisabled =
    idField.value === ''
      ? validating || submitting
      : status === 'pristine' || validating || submitting

  return (
    <>
      <FormActionButton
        label={texts.cancel}
        size="medium"
        testId="footer-secondary-button"
        data-trackid="footer-secondary-button"
        onClick={resetEditing}
      />
      <FormActionButton
        size="medium"
        type="submit"
        label={texts.submit}
        testId="footer-primary-button"
        data-trackid="footer-primary-button"
        variant="contained"
        loading={submitting || validating}
        disabled={saveDisabled}
      />
    </>
  )
}
