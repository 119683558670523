import { FormHelperText, Stack, Typography } from '@mui/material'
import { Checkbox } from '@workwave-tidal/form-ui'
import { useForm } from '@workwave-tidal/form-fairy'

import { type FormFields, type FormErrors } from '../formFields'
import { useTexts } from '../hooks/useTexts'

export function Body() {
  const texts = useTexts()

  const {
    meta: { status },
  } = useForm<FormFields, FormErrors>()

  return (
    <Stack pt={3} pb={1}>
      <Typography variant="body2">{texts.form.title}</Typography>
      <Stack gap={1} pt={2}>
        <Checkbox<'c1', FormFields, FormErrors>
          label={texts.form.c1}
          validateOn="blur focus change"
          name="c1"
          testId="c1"
          errorVisibility="none"
        />
        <Checkbox<'c2', FormFields, FormErrors>
          label={texts.form.c2}
          validateOn="blur focus change"
          name="c2"
          testId="c2"
          errorVisibility="none"
        />
        <Checkbox<'c3', FormFields, FormErrors>
          label={texts.form.c3}
          validateOn="blur focus change"
          name="c3"
          testId="c3"
          errorVisibility="none"
        />
        <Checkbox<'c4', FormFields, FormErrors>
          label={texts.form.c4}
          validateOn="blur focus change"
          name="c4"
          testId="c4"
          errorVisibility="none"
        />
      </Stack>
      {status === 'invalid' && (
        <FormHelperText error>{texts.validation.notAllChecked}</FormHelperText>
      )}
    </Stack>
  )
}
