import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'
import type { ValidationTexts } from './useTexts'

import { createValidateTopic } from './createValidateTopic'
import { createValidateMessage } from './createValidateMessage'

export function createFormValidation(
  texts: ValidationTexts,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'topic',
      fields: 'topic',
      validator: createValidateTopic(texts),
    },
    {
      id: 'message',
      fields: 'message',
      validator: createValidateMessage(texts),
    },
  ]
}
