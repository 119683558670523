import { DropdownMenuItem } from '@/components/DropdownMenu'

import { useTexts } from '../../../../useTexts'
import { ActiveIcon } from '../ActiveIcon'
import { StyledLink } from '../hooks/useStyles'
import { useRmLinks } from './hooks/useRmLinks'

export function RmLinks() {
  const texts = useTexts()
  const { canShow } = useRmLinks()

  return (
    <>
      <StyledLink
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={'pendo-bluebar__home-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-home-button-content"
          data-trackid="rm-header__nav-home-button-content"
        >
          <ActiveIcon />
          {texts.home}
        </DropdownMenuItem>
      </StyledLink>

      <StyledLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={'pendo-bluebar__orders-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-content"
          data-trackid="rm-header__nav-orders-button-content"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </StyledLink>

      {canShow.simulations && (
        <StyledLink
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={'pendo-bluebar__simulations-list-menu'}
        >
          <DropdownMenuItem
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-header__nav-simulations-button-content"
          >
            <ActiveIcon />
            {texts.simulations}
          </DropdownMenuItem>
        </StyledLink>
      )}
    </>
  )
}
