import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { timeFormats } from '../../../../formats'
import { useTexts } from './useTexts'

export function TimeFormat() {
  const texts = useTexts()

  const options = useMemo(
    () =>
      Object.entries(timeFormats).map(([value, label]) => ({
        value,
        label,
      })),
    [],
  )

  return (
    <RadioGroup<'timeFormat', string, FormFields, FormErrors>
      validateOn="blur focus"
      options={options}
      testId="timeFormat"
      label={texts.timeFormat}
      name="timeFormat"
    />
  )
}
