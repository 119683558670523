import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../../hooks/useController'
import { useTexts } from './hooks/useTexts'

export function Header() {
  const { close } = useController()

  const texts = useTexts()

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={texts.title}
      subtitle={texts.subtitle}
    />
  )
}
