import { Text } from '@/local/components'

import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

interface Props {
  depots: uui.domain.client.rm.Depot[]
}

export function Bulk(props: Props) {
  const { depots } = props
  const texts = useTexts()

  return (
    <Stack
      direction="column"
      height="100%"
      width="100%"
      flexShrink={0}
      justifyContent="space-between"
    >
      <Text size="$l" weight="$semiBold">
        {texts.depots(depots.length)}
      </Text>

      <Text size="$p3" italic>
        {texts.multipleValues}
      </Text>
    </Stack>
  )
}
