import { createSelector } from '@reduxjs/toolkit'
import type { PartialStoreState } from '../typings'

import { getDefaultGpsOnlyData, getDefaultUiData } from '@/server-data'

export const selectUserProfile = (state: PartialStoreState) => state.domain.publicData.profile

export const selectUserUiSettings = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.uiData

export const selectUserConfiguration = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration

export const selectUserConfigurationWeekStartsOn = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.weekStartsOn

export const selectUserProfileExportOrders = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.type === 'gpsonly'
    ? undefined
    : state.domain.publicData.profile.user.uiData.exportOrders

export const selectUserProfileUiData = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.type === 'gpsonly'
    ? undefined
    : state.domain.publicData.profile.user.uiData

export const selectUserProfileExportRoutes = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.type === 'gpsonly'
    ? undefined
    : state.domain.publicData.profile.user.uiData.exportRoutes

export const isUserAuthenticated = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.authenticated

export const hasGps = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.userType !== 'rmOnly'

export const isGpsOnly = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.userType === 'gpsOnly'

export const isRm = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.userType !== 'gpsOnly'

export const selectUserConfigurationType = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.userType

export const isAdmin = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.rmAdmin

export const selectIsFilteredGuest = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.userType === 'rmGuest' &&
  state.domain.publicData.profile.user.isCompanyFiltered

export const selectUserAccount = (state: PartialStoreState) =>
  state.domain.publicData.profile.account

export const selectGpsUser = (state: PartialStoreState) => state.domain.publicData.profile.gpsUser

export const selectUserType = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.type

export const selectLicensingLimits = (state: PartialStoreState) =>
  state.domain.publicData.profile.account.licensingLimits

export const selectUserPrivileges = (state: PartialStoreState) =>
  state.domain.publicData.profile.account.licensingLimits.privileges

export const selectLanguage = (state: PartialStoreState) =>
  state.domain.publicData.profile.user.settings.language

export const selectUserAllowGps = (state: PartialStoreState) =>
  state.domain.publicData.profile.account.licensingLimits.allowGps

export const selectGpsTrackingProvider = (state: PartialStoreState) => {
  return state.domain.publicData.profile.account.credentials.trackingProvider
}

export const hasDevToolsPrivileges = (state: PartialStoreState) => {
  const privileges = state.domain.publicData.profile.account.licensingLimits.privileges
  return privileges.includes('DEMO') || privileges.includes('DEV')
}

export const selectUserAccountPreferences = (state: PartialStoreState) =>
  state.domain.publicData.profile.account.preferences

export const selectStartOfToday = (state: PartialStoreState) =>
  state.domain.publicData.userConfiguration.startOfToday

export const selectBigCustomer = (state: PartialStoreState) =>
  !!state.domain.publicData.profile.account.credentials.bigCustomer

export const selectFullname = (state: PartialStoreState) => state.domain.publicData.profile.fullName

export const selectAccountApiKey = (state: PartialStoreState) =>
  state.domain.publicData.domain.rm.apiKey

export const selectDefaultEmailAddress = createSelector(
  [selectGpsUser, selectUserProfile, isGpsOnly],
  (gpsUser, userProfile, isGpsOnly) => {
    return isGpsOnly ? gpsUser?.email : userProfile.account.details.email
  },
)

export const selectUsers = (state: PartialStoreState) => state.domain.publicData.domain.rm.users

const defaultUiData = getDefaultUiData() as uui.domain.server.rm.UserUISettingsV2
const defaultGpsUiData = getDefaultGpsOnlyData()

export const selectSchedulerPreferences = (state: PartialStoreState) => {
  if (state.domain.publicData.profile.user.type === 'gpsonly')
    return defaultUiData.schedulerPreferences

  const uiData = state.domain.publicData.profile.user.uiData

  // this can happen only if the user is a GPS user
  // and somehow the UI is trying to call the scheduler selectors
  if (!uiData || uiData?.version === 1) {
    if (process.env.NODE_ENV === 'development') {
      // console.error('User UI data is not available, defaulting to default UI data')
    }

    return defaultUiData.schedulerPreferences
  }

  return uiData.schedulerPreferences
}

export const selectRoutingLayout = (state: PartialStoreState) => {
  if (state.domain.publicData.profile.user.type === 'gpsonly') {
    return defaultUiData.routingLayout
  }

  const uiData = state.domain.publicData.profile.user.uiData

  // this can happen only if the user is a GPS user
  // and somehow the UI is trying to call the scheduler selectors
  if (!uiData || uiData?.version !== 2) {
    if (process.env.NODE_ENV === 'development') {
      // console.error('User UI data is not available, defaulting to default UI data')
    }

    return defaultUiData.routingLayout
  }

  return uiData.routingLayout
}

export const selectMapConfig = (state: PartialStoreState) => {
  const uiData = state.domain.publicData.profile.user.uiData

  if (state.domain.publicData.profile.user.type === 'gpsonly') {
    if (!uiData || uiData.version !== 2) {
      if (process.env.NODE_ENV === 'development') {
        // console.error('User UI data is not available, defaulting to default UI data')
      }

      return defaultGpsUiData.mapConfig
    }

    return uiData.mapConfig
  }

  if (!uiData || uiData?.version === 1) {
    if (process.env.NODE_ENV === 'development') {
      // console.error('User UI data is not available, defaulting to default UI data')
    }

    return defaultUiData.mapConfig
  }

  return uiData.mapConfig
}
