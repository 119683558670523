import { Check } from '@/icons'
import { WarningCard } from '@/local/components'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

export function SuccessBody() {
  const texts = useTexts()
  const { data } = useController()

  const address = data.messageType === 'email' ? data.email : data.phone
  if (!address) return null

  return (
    <Stack direction="column" width="100%" height="100%" flexShrink={0} marginTop={4}>
      <WarningCard
        preset="success"
        title={texts.resultSuccessTitle}
        style={{ wordBreak: 'break-word' }}
        Icon={<Check size={14} color="$pigmentGreen" />}
        description={texts.resultSuccessDescription(address)}
      />
    </Stack>
  )
}
