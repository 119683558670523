import { useState, useEffect, useRef } from 'react'

import { FormError, FormField, useFormMeta } from '@workwave-tidal/form-fairy'
import { SuperCarIndicator } from './SuperCarIndicator'

export interface Props {
  size: number
  duration?: number
}

export function FormValidationIndicator<
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>(props: Props) {
  const { size, duration } = props
  const {
    meta: { validating },
  } = useFormMeta<FIELDS, ERROR>()

  const [canBeShown, setCanBeShown] = useState(false)
  const rTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    // If is validating let's show the indicator after 500ms
    if (validating) {
      rTimer.current = setTimeout(() => {
        setCanBeShown(true)
      }, 500)

      return
    }

    // If is not validating let's hide the indicator and clear the current timer
    setCanBeShown(false)

    if (!rTimer.current) return

    clearTimeout(rTimer.current)
    rTimer.current = null
  }, [validating])

  // Let's clear the timer on unmount
  useEffect(
    () => () => {
      if (rTimer.current) {
        clearTimeout(rTimer.current)
      }
    },
    [],
  )

  return canBeShown ? <SuperCarIndicator size={size} duration={duration} /> : null
}
