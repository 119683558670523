import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

import { useIsUnmounted, useNotification } from '@/hooks'
import { editTenantSourceLabel } from '@/features/domain/gps'
import { useAppDispatch } from '@/store'

import { useTexts } from '../useTexts'
import { useController } from './useController'

export function useOnSubmit() {
  const toast = useNotification()
  const formApi = useFormApi<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const {
    update,
    close,
    data: { tenantSource },
  } = useController()
  const isUnmounted = useIsUnmounted()
  const texts = useTexts()

  return useCallback(async () => {
    try {
      formApi.setSubmitting(true)
      update({ status: 'submitting' })

      await formApi.waitForValidation()

      const valid = await formApi.validate()

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      if (valid) {
        try {
          const { label } = formApi.getValues()

          // If the label is the same as the current one, close the modal
          if (label === tenantSource.label) {
            formApi.setSubmitting(false)
            close?.()
            return
          }

          const thunkResult = await dispatch(
            editTenantSourceLabel({ tenantSourceId: tenantSource.id, label }),
          )

          if (!editTenantSourceLabel.fulfilled.match(thunkResult)) {
            throw new Error(thunkResult.payload?.message ?? 'Internal error')
          }

          // Clear FormState submitting mode
          formApi.setSubmitting(false)
          close?.()

          toast.success(texts.successToast)
        } catch (error) {
          formApi.setSubmitting(false)
        }
      } else {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        update({ status: 'ready' })
      }
    } catch (e) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })

      toast.error(e.message)
    }
  }, [formApi, toast, tenantSource, texts, update, close, dispatch, isUnmounted])
}
