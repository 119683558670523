import type { FormValues } from '@workwave-tidal/form-fairy'
import type { FormFields } from '../../formFields'
import type { BulkLoads, BulkTags } from '../../types'

import { timeWindowUtils } from '@/utils'
import { geo } from '@/server-data'
import { store } from '@/store'
import { NONE_OPTION } from '../../../../constants'

export function computeBulkDataFromFormValues(
  formData: Readonly<FormValues<FormFields>>,
  orders: uui.domain.client.rm.ExtendedOrderStep[],
): uui.domain.ui.forms.BulkOrderUpdate {
  const {
    eligibility,
    forceVehicleId,
    priority,
    loads,
    notes,
    type,
    serviceTimeSec,
    tagsIn,
    tagsOut,
    email,
    location,
    phone,
    timeWindows,
    companyId,
  } = formData

  const { workingDayStartSec } = store.getState().domain.publicData.territory

  const ids: uui.domain.ui.forms.BulkOrderUpdate['ids'] = orders.map(o => ({
    orderId: o.order.id,
    type: o.orderStepType === 'p' ? 'pickup' : 'delivery',
  }))

  const orderData: uui.domain.ui.forms.BulkOrderData = {
    eligibility: eligibility.status === 'exact' ? eligibility.value : undefined,
    forceVehicleId:
      forceVehicleId.status === 'exact'
        ? forceVehicleId.value === ''
          ? null
          : forceVehicleId.value
        : undefined,
    priority:
      priority.status === 'exact'
        ? (parseInt(priority.value) as uui.domain.server.rm.Order['priority'])
        : undefined,
    loadsDelta: computeLoadsDelta(loads),
    companyId:
      companyId.status === 'exact'
        ? companyId.value === NONE_OPTION
          ? null
          : companyId.value
        : undefined,
  }

  const serverTimeWindows =
    timeWindows.status === 'exact'
      ? (timeWindows.value.map((tw: uui.domain.rm.TimeWindow | undefined) => {
          if (!tw) return undefined

          return timeWindowUtils.formatTimeWindowForRPC(
            timeWindowUtils.normalizeTimeWindow(tw, workingDayStartSec),
            workingDayStartSec,
          )
        }) as uui.domain.ui.forms.BulkOrderStepData['timeWindows'])
      : undefined

  const orderStepData: uui.domain.ui.forms.BulkOrderStepData = {
    notes: notes.status === 'exact' ? notes.value : undefined,
    email: email.status === 'exact' ? email.value : undefined,
    phone: phone.status === 'exact' ? phone.value : undefined,
    timeWindows: serverTimeWindows,
    serviceTimeSec:
      serviceTimeSec.status === 'exact' ? parseServiceTime(serviceTimeSec.value) : undefined,
    type: type.status === 'exact' ? type.value : undefined,
    location: location.status === 'exact' ? toServerLocation(location.value) : undefined,
    tagsIn: computeTags(tagsIn),
    tagsOut: computeTags(tagsOut),
  }

  return {
    ids,
    orderData,
    orderStepData,
  }
}

function computeLoadsDelta(loads: BulkLoads) {
  return Object.values(loads.loads).reduce<Record<string, number | null>>((acc, curr) => {
    if (curr.status === 'exact') {
      if (curr.mixed) return acc

      const numericValue = parseFloat(curr.value)
      if (isNaN(numericValue)) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Load must have numeric value')
        }
        return acc
      }
      acc[curr.name] = Math.trunc(numericValue * 100) // decimals over the 2nd figure will be cut out
    }

    if (curr.status === 'deleted') {
      acc[curr.name] = null
    }

    return acc
  }, {})
}

function toServerLocation(clientLocation: uui.domain.client.Location): uui.domain.server.Location {
  const latLng = geo.latLngToJSON(clientLocation.latLng)

  return {
    ...clientLocation,
    latLng,
    status: 'OK',
    rooftopLatLng: clientLocation.rooftopLatLng
      ? geo.latLngToJSON(clientLocation.rooftopLatLng)
      : undefined,
  }
}

function computeTags(tags: BulkTags) {
  return Object.values(tags.tags).reduce<Record<string, string | null>>((acc, curr) => {
    if (curr.status === 'exact') {
      acc[curr.name] = curr.name
    }

    if (curr.status === 'deleted') {
      acc[curr.name] = null
    }

    return acc
  }, {})
}

function parseServiceTime(value: string, noneValue: string = '') {
  if (value === noneValue) return 0
  const [hours, minutes] = value.split(':')
  return parseInt(hours) * 3600 + parseInt(minutes) * 60
}
