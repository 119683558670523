import { Model } from '@bryntum/schedulerpro'
import { schedulerInstance } from '../../../atoms/project/project'
/*
 * If all groups are collapsed, we expand them all
 * If at least one group is collapsed, we expand them all
 */
export const checkAllExpanded = (): boolean => {
  return !schedulerInstance.instance?.resourceStore.records.some(
    r => (r as Model & { isCollapsed: boolean }).isCollapsed,
  )
}
