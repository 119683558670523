import { Stack } from '@mui/material'
import { type GridCellParams } from '@mui/x-data-grid-pro'
import { DoNotDisturb, Check } from '@mui/icons-material'

import { Chip } from '@workwave-tidal/core/components'

import { useTexts } from '../hooks/useTexts'
import { Menu } from '../components/Menu'

export function StatusCell(params: GridCellParams<boolean, uui.domain.client.rm.Company>) {
  const texts = useTexts()
  const enabled = params.value
  const company = params.row

  const label = enabled ? texts.status.enabled : texts.status.disabled
  const color = enabled ? 'success' : undefined
  const icon = enabled ? <Check /> : <DoNotDisturb />

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" flex="1 1 auto">
      <Chip label={label} color={color} icon={icon} variant="outlined" />
      <Menu company={company} />
    </Stack>
  )
}
