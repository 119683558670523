import { useCallback, useMemo } from 'react'

import { Footer as FooterComp } from '@/forms-legacy'

import { formState } from './formState'
import { useTexts } from './hooks/useTexts'
import { DriverAssignmentFormState } from './typings'

interface Props {
  handleSubmit: (formData: DriverAssignmentFormState) => Promise<any> | void
  submitBtnClassName?: string
  fieldsWithError: string[]
  onCancel: () => void
  submitting: boolean
  recurrence: uui.domain.rm.Recurrence
}

export function Footer(props: Props) {
  const { submitBtnClassName, fieldsWithError, handleSubmit, submitting, onCancel, recurrence } =
    props

  const texts = useTexts()

  const formApi = formState.useFormApi()
  const initialFormData = formApi.getValues()

  const {
    meta: { disabled, status },
  } = formState.useFormMeta()

  const creating = Object.keys(initialFormData.assignments).length === 0
  const pristine = status === 'pristine'
  const valid = status === 'valid'
  const saveTitle = texts.saveTitle(valid, pristine)
  const save = texts.save(recurrence, creating)
  const errorTitle = texts.errorTitle
  const cancel = texts.cancel

  const onSubmit = useCallback(() => {
    const formData = formApi.getValues()
    handleSubmit(formData)
  }, [formApi, handleSubmit])

  const primary = useMemo(
    () => ({
      className: submitBtnClassName,
      disabled,
      onClick: onSubmit,
      title: saveTitle,
      text: save,
    }),
    [save, saveTitle, disabled, onSubmit, submitBtnClassName],
  )

  const secondary = useMemo(
    () => ({
      disabled: submitting,
      onClick: onCancel,
      text: cancel,
    }),
    [onCancel, cancel, submitting],
  )

  return (
    <FooterComp
      errors={fieldsWithError}
      errorTitle={errorTitle}
      submitting={submitting}
      secondary={secondary}
      primary={primary}
    />
  )
}
