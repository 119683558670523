import { useRef } from 'react'

import { RenderFrom } from '@/components/Navigo/components/RenderFrom'
import { useComponentSize } from '@/local/hooks'

import { DistanceBlock } from './components/DistanceBlock'
import { LoadsBlock } from './components/LoadsBlock'
import { TimeBlock } from './components/TimeBlock'

import { Stack } from '@mui/material'

import { useApprovedLength } from './hooks/useApprovedLength'

interface Props {
  extRoute: uui.domain.client.rm.ExtendedRoute
}

export function RouteStats(props: Props) {
  const {
    extRoute: {
      route,
      routeStats: { remainingMileage, startTimeSec, endTimeSec },
    },
  } = props

  const approvedLength = useApprovedLength(route)

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  return (
    <Stack
      direction="row"
      width="100%"
      height="100%"
      data-trackid="navigo-routes-overview-details-single-routeStats"
      data-testid="navigo-routes-overview-details-single-routeStats"
      ref={ref}
    >
      <RenderFrom width={width} renderFrom={140}>
        <DistanceBlock approvedLength={approvedLength} distanceLeft={remainingMileage} />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={290}>
        <TimeBlock
          violations={route.violations}
          routeId={route.id}
          start={startTimeSec}
          end={endTimeSec}
        />
      </RenderFrom>

      <RenderFrom width={width} renderFrom={440}>
        <LoadsBlock extRoute={props.extRoute} />
      </RenderFrom>
    </Stack>
  )
}
