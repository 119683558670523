import type { ChangeEvent } from 'react'
import type { FormErrors, FormFields } from '../formFields'
import { useCallback } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'

export function useActions(index: number) {
  const {
    field: { value },
    fieldApi: { change: apiChange, validate },
  } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )

  const editReason = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const updated = [...value]
      updated[index] = event.target.value
      apiChange(updated)
      validate()
    },
    [apiChange, index, validate, value],
  )

  const moveReasonUp = useCallback(() => {
    if (index === 0) return

    const updated = [...value]
    const reason = updated.splice(index, 1)[0]
    updated.splice(index - 1, 0, reason)
    apiChange(updated)
    validate()
  }, [apiChange, index, validate, value])

  const moveReasonDown = useCallback(() => {
    if (index === value.length - 1) return

    const updated = [...value]
    const reason = updated.splice(index, 1)[0]
    updated.splice(index + 1, 0, reason)
    apiChange(updated)
    validate()
  }, [apiChange, index, validate, value])

  const removeReason = useCallback(() => {
    const updated = [...value]
    updated.splice(index, 1)
    apiChange(updated)
    validate()
  }, [apiChange, index, validate, value])

  return {
    editReason,
    moveReasonUp,
    moveReasonDown,
    removeReason,
  }
}
