import { type PopperProps, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'
import type { DurationInputState } from '../../types'

export function usePopperProps(state: DurationInputState, $root: HTMLElement | null) {
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(() => null)
  const {
    zIndex: { tooltip: zIndex },
  } = useTheme()

  const { open } = state

  const popperProps = useMemo<PopperProps>(
    () => ({
      ref: setPopperRef,
      open,
      anchorEl: $root,
      style: {
        zIndex,
      },
    }),
    [$root, open, zIndex],
  )

  return {
    popperProps,
    popperRef,
  }
}
