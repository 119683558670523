import type { FormValidator, FormField, FormError } from '@workwave-tidal/form-fairy'
import { useMemo } from 'react'
import { useTexts } from '../useTexts'

interface Validation<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
> {
  id: string
  validator: FormValidator<FORM_FIELDS, FORM_ERRORS>
  fields?: keyof FORM_FIELDS
}

export function useOldPasswordValidations<
  FORM_FIELDS extends Record<string, FormField>,
  FORM_ERRORS extends FormError<keyof FORM_FIELDS>,
>(): Validation<FORM_FIELDS, FORM_ERRORS>[] {
  const texts = useTexts()

  return useMemo(() => {
    const field = 'oldPassword'
    const validateOldPassword: Validation<FORM_FIELDS, FORM_ERRORS> = {
      id: 'validateOldPassword',
      validator: formApi => {
        const { value } = formApi.getField(field)

        if (typeof value !== 'string')
          throw new Error('The provided password field should be a string')

        const requiredID = `${field}_required`
        const required = value.trim().length === 0

        const requiredError = {
          id: requiredID,
          field,
          message: texts.oldPasswordRequired,
          priority: 0,
        }

        return {
          [requiredID]: required ? requiredError : null,
        }
      },
      fields: field,
    }

    return [validateOldPassword]
  }, [texts])
}
