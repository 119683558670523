import { Box, Button } from '@mui/material'

import { LayoutComposer, ListFooter, NoEntries, NoResults } from '@/components/List'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { ComponentSize, ListOverlayEditCover } from '@/components/layout'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { useEditing } from '../../../../../../hooks/useEditing'

import { Item } from './Item'
import { useDataForList } from './hooks/useDataForList'
import { useListHandlers } from './hooks/useListHandlers'
import { useTexts } from './hooks/useTexts'
import { useTrafficAreas } from './hooks/useTrafficAreas'

const getItemHeight = () => 78

export function List() {
  const trafficAreas = useTrafficAreas()
  const { onSelect, handleOnSelectAll, onCreateTrafficArea } = useListHandlers()
  const { structure, trafficAreaSelection } = useDataForList()
  const { editingEntity } = useEditing('trafficArea')

  const editing = editingEntity !== 'none'

  const texts = useTexts()

  const allSelected = trafficAreaSelection.length >= structure.shownItems
  const multiSelect = trafficAreaSelection.length > 1

  return (
    <>
      <Box width="100%" height="100%" flex="0 1 auto" position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries
              title={texts.noEntries.title}
              description={texts.noEntries.description}
              action={
                <ReadOnlyTooltip
                  render={preventEditing => (
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={onCreateTrafficArea}
                      disabled={preventEditing || editing}
                      data-testid="no-entries-add-item"
                      data-trackid="no-entries-add-item"
                    >
                      {texts.noEntries.action}
                    </Button>
                  )}
                />
              }
            />
          }
          list={
            <ComponentSize
              render={size => {
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={trafficAreas}
                      structure={structure}
                      listApiCategory="trafficAreas"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={trafficAreaSelection}
                      onSelect={onSelect}
                      buffer={50}
                    />
                    {editing && <ListOverlayEditCover />}
                  </>
                )
              }}
            />
          }
        />
      </Box>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={handleOnSelectAll}
          checked={allSelected}
        />
      )}
    </>
  )
}
