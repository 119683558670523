import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { ModalHeader } from '@/components/Modal'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const texts = useTexts()
  const {
    data: { regionIds },
    close,
  } = useController()

  return (
    <ModalHeader
      title={texts.title(regionIds.length)}
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
    />
  )
}
