import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Stack } from '@mui/material'
import { Chip } from '@workwave-tidal/core/components'

import { selectTerritories } from '@/features/domain/territory'

import { useTexts } from './hooks/useTexts'

interface Props {
  territoryFilter: string[]
}

export function Territories(props: Props) {
  const { territoryFilter } = props
  const territories = useTerritories(territoryFilter)

  const texts = useTexts()

  const noRestriction = territoryFilter[0] === '*'

  if (noRestriction) return null

  return (
    <Box paddingBottom={4} data-testid="territories">
      <Typography variant="caption" color="textSecondary">
        {texts.labels.territories}
      </Typography>

      <Stack pt={0.5} gap={0.5} direction="row" flexWrap="wrap">
        {territories.map(territory => (
          <Chip size="small" variant="filledOutline" label={territory.name} key={territory.id} />
        ))}
      </Stack>
    </Box>
  )
}

function useTerritories(territoryFilter: string[]): uui.domain.client.rm.Territory[] {
  const allTerritories = useSelector(selectTerritories)

  return useMemo(() => {
    return territoryFilter[0] === '*'
      ? []
      : territoryFilter.map(territoryId => allTerritories[territoryId]).sort(sortTerritories)
  }, [allTerritories, territoryFilter])
}

function sortTerritories(a: uui.domain.client.rm.Territory, b: uui.domain.client.rm.Territory) {
  return a.name.localeCompare(b.name)
}
