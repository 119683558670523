import { type MouseEvent, cloneElement } from 'react'

import {
  ActionBar as TidalActionBar,
  type ActionBarItemProps,
} from '@workwave-tidal/core/components/ActionBar'

import { type ModalHeaderProps } from '../types'

type ActionBarProps = Pick<ModalHeaderProps, 'actions'>

export function ActionBar(props: ActionBarProps) {
  const { actions } = props

  if (!Array.isArray(actions)) return null
  if (actions.length === 0) return null

  return (
    <TidalActionBar>
      {actions?.map(action => {
        const props: Partial<ActionBarItemProps> = {
          mode: 'default',
          color: 'default',
          divider: false,
        }

        // the onClick function has to stop the propagation of the click otherwise
        // it will bubble up and interfere with the close of the menu
        if (typeof action.props.onClick === 'function') {
          props.onClick = createOnClickWithStopPropagation(action.props.onClick)
        }

        return cloneElement(action, { ...props, key: action.props.id })
      })}
    </TidalActionBar>
  )
}

function createOnClickWithStopPropagation(onClick?: ActionBarItemProps['onClick']) {
  return (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onClick?.(event)
  }
}
