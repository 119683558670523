import { Link } from '@/routing'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StyledButtonProps extends React.ComponentProps<typeof Button> {
  href?: string
  target?: string
}

export const FixLinkInsideTooltip = styled('div')({
  '& a': {
    display: 'block',
    width: '100%',
  },
})

export const StyledLink = styled(Link)({
  padding: 0,
  width: '100%',
  '&.active $tick': {
    opacity: 1,
  },
})

export const StyledButton = styled(Button)<StyledButtonProps>({
  padding: 0,
  width: '100%',
  '&.active $tick': {
    opacity: 1,
  },
})

export const StyledTick = styled('span')({
  opacity: 0,
})
