import type { Option } from './types'

import { useCallback } from 'react'
import { Box, Stack, Typography, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Select } from '@workwave-tidal/core/components'

import { useTexts } from './hooks/useTexts'

interface Props {
  options: Option[]
  onAddTerritory: (territoryId: string) => void
  onClose: () => void
}

function getOptionLabel(opt: Option) {
  return opt.label
}

function getOptionDisabled(opt: Option) {
  return opt.disabled
}

export function AddTerritory(props: Props) {
  const { options, onAddTerritory, onClose } = props

  const texts = useTexts()

  const onChange = useCallback(
    (selectedOption: Option | null) => {
      if (!selectedOption) return

      onAddTerritory(selectedOption.id)
    },
    [onAddTerritory],
  )

  return (
    <Box width={360} padding={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pb: 3 }}
      >
        <Typography noWrap variant="h6">
          {texts.title}
        </Typography>

        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>

      <Select
        value={null}
        items={options}
        label={texts.searchTerritory}
        texts={{ noOptions: texts.noOptions }}
        onChange={onChange}
        getLabel={getOptionLabel}
        optionMode="normal"
        getOptionDisabled={getOptionDisabled}
      />
    </Box>
  )
}
