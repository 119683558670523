import {
  FormConfig,
  TidalFormState,
  setupFormState as tidalSetupFormState,
} from '@workwave-tidal/core/form'
import { setupFormStateRmAdapter } from './setupFormStateRmAdapter'

export function setupFormState<State extends TidalFormState>(userConfig: FormConfig<State>) {
  const coreForm = tidalSetupFormState<State, ''>('', userConfig)
  const adapter = setupFormStateRmAdapter<State>()
  const userForm = { ...coreForm, ...adapter } as const
  return userForm
}
