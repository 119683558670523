import type { ReactNode } from 'react'

import { ApplyPendingFitMapOnMount } from '@/components/smartUtils/form/ApplyPendingFitMapOnMount'
import { Stack } from '@mui/material'
import { FormColumn } from '../../_internals/FormColumn'

interface Props {
  width?: number
  testId?: string
  header?: ReactNode
  children?: ReactNode
  applyPendingFitMapOnMount: boolean
}

const DEFAULT_WIDTH = 484
const DEFAULT_TESTID = 'form__column-secondary'

export function Root(props: Props) {
  const {
    children,
    width = DEFAULT_WIDTH,
    testId = DEFAULT_TESTID,
    header,
    applyPendingFitMapOnMount,
  } = props

  return (
    <FormColumn width={width} testid={testId}>
      <Stack paddingTop={2} paddingLeft={3} spacing={4} width="100%" height="100%">
        {applyPendingFitMapOnMount && <ApplyPendingFitMapOnMount />}
        {header}

        {/* overflow=auto is required otherwise the Stack won't scroll if the content is big */}
        <Stack spacing={2} overflow="auto" paddingRight={3} paddingBottom={4}>
          {children}
        </Stack>
      </Stack>
    </FormColumn>
  )
}
