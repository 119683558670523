import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  trackingData: uui.domain.client.rm.OrderStepTrackingData
}

export function TrackDataBlock(props: Props) {
  const texts = useTexts()

  return (
    <Stack
      direction="column"
      width="auto"
      height="100%"
      justifyContent="space-between"
      data-testid="TrackDataBlock"
    >
      <Stack direction="row" height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeIn}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          {props.trackingData.timeInSec !== -1 ? (
            <ConvertTime seconds={props.trackingData.timeInSec} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </Stack>

      <Stack direction="row" height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeOut}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          {props.trackingData.timeOutSec !== -1 ? (
            <ConvertTime seconds={props.trackingData.timeOutSec} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </Stack>
    </Stack>
  )
}
