import { Delete, Edit } from '@workwave-tidal/core/icons'

import { preview } from '@/components/form/preview'
import { DownloadKML } from '@/icons'
import { getColorValue } from '@/styles'

import { useDeleteRegionModal } from '../../../../../modals/DeleteRegionModal'
import { RegionAvatar } from '../../../components/RegionAvatar'
import { useActions } from '../hooks/useActions'
import { useTexts } from '../hooks/useTexts'

interface Props {
  region: uui.domain.client.rm.Region
}

export function Header(props: Props) {
  const { region } = props
  const actions = useActions(region)
  const texts = useTexts()
  const { Modal: DeleteRegionModal } = useDeleteRegionModal()

  return (
    <preview.Header
      title={region.name}
      testId="region-view-header"
      avatar={<RegionAvatar color={getColorValue(region.color)} />}
      onClose={actions.onReset}
    >
      <preview.ActionBar>
        <preview.ActionBarItem
          icon={<Edit />}
          onClick={actions.onEdit}
          label={texts.edit}
          disableWhenReadOnly
          tooltip={texts.edit}
          testId="setup-region__edit"
        />
        <preview.ActionBarItem
          icon={<DownloadKML size={24} />}
          onClick={actions.onExport}
          label={texts.exportKml}
          tooltip={texts.exportKml}
          testId="setup-region__exportkml"
          disableWhenReadOnly={false}
        />
        <preview.ActionBarItem
          icon={<Delete />}
          onClick={actions.onDelete}
          label={texts.delete}
          tooltip={texts.delete}
          testId="setup-region__delete"
          disableWhenReadOnly
        />
      </preview.ActionBar>

      <DeleteRegionModal regions={[region.id]} />
    </preview.Header>
  )
}
