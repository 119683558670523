import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    deselectAll: (numberOfRoutes: number) =>
      translate({
        id: 'components.schedulerHeader.contextualMenu.deselectAllRoutes',
        values: { numberOfRoutes },
      }),
    selectAll: (numberOfRoutes: number) =>
      translate({
        id: 'components.schedulerHeader.contextualMenu.selectAllRoutes',
        values: { numberOfRoutes },
      }),
    collapseAll: (numberOfRoutes: number) =>
      translate({
        id: 'components.schedulerHeader.contextualMenu.collapseAllGroups',
        values: { numberOfRoutes },
      }),
    expandAll: (numberOfRoutes: number) =>
      translate({
        id: 'components.schedulerHeader.contextualMenu.expandAllGroups',
        values: { numberOfRoutes },
      }),
    editColumns: translate({ id: 'global.editColumns' }),
    dispatchedRoutes: translate({
      id: 'components.schedulerHeader.contextualMenu.dispatchedRoutes',
    }),
    unavailableVehicles: (numberOfUnavailableVehicles: number) =>
      translate({
        id: 'components.schedulerHeader.contextualMenu.unavailableVehicles',
        values: { numberOfUnavailableVehicles },
      }),
    values: translate({ id: 'components.schedulerHeader.contextualMenu.values' }),
    group: translate({ id: 'components.schedulerHeader.contextualMenu.group' }),
    byDate: translate({ id: 'components.schedulerHeader.contextualMenu.byDate' }),
    absolute: translate({ id: 'components.schedulerHeader.contextualMenu.absolute' }),
    byVehicle: translate({ id: 'components.schedulerHeader.contextualMenu.byVehicle' }),
    percentage: translate({ id: 'components.schedulerHeader.contextualMenu.percentage' }),
  }))

  return api
}
