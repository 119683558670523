import { Button, Stack } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../hooks/useTexts'
import { Footer } from './Footer'

export function ConfigureColumnsFooter() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    close,
    status,
    invalid,
    data: { approvedRoutesAmount, filter, selectedRoutes },
  } = useController()

  const amount = filter === 'selected' ? selectedRoutes.length : approvedRoutesAmount

  return (
    <Footer>
      <Button
        onClick={handlers.onPrevButtonClick}
        data-testid="export-route-back-button"
        data-trackid="export-route-back-button"
      >
        <KeyboardArrowLeft />
        {texts.prevStepButton}
      </Button>
      <Stack gap={1} direction="row">
        <Button
          onClick={close}
          data-testid="export-route-cancel-button"
          data-trackid="export-route-cancel-button"
        >
          {texts.cancelButton}
        </Button>
        <ModalFooterButton
          disabled={invalid || status !== 'ready'}
          onClick={handlers.onExport}
          variant="contained"
          loading={handlers.isLoading}
          testid="export-routes-export-button"
        >
          {texts.exportButton(amount)}
        </ModalFooterButton>
      </Stack>
    </Footer>
  )
}
