import type { DurationDropdownOption, DurationFieldValue } from '../types'

export function computeDropdownSelectedIndex(
  value: DurationFieldValue,
  dropdownOptions: DurationDropdownOption[],
): number {
  if (value === null) return -1

  const deltaFromSelected = dropdownOptions.map(option => Math.abs(option.value - value))
  const minDelta = Math.min(...deltaFromSelected)

  return deltaFromSelected.indexOf(minDelta)
}
