import type { AsyncThunkApiConfig } from '@/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

export type OrderStepsIdentifierCode = {
  id: string
  type: 'p' | 'd' | 'pd' | 's'
}

type Payload = {
  steps: string[]
}

/**
 * Validate orders against territory border restrictions
 *
 * @private
 */
export const validateBorderRestrictions = createAsyncThunk<
  // Return type of the payload creator
  string[],
  // First argument to the payload creator
  Payload,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('rpc/order/borderRestrictionsValidate', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()
    const response = await rpc('rpc/order/borderRestrictionsValidate', {
      category: 'rpc',
      type: 'rpc/order/borderRestrictionsValidate',
      steps: payload.steps,
    })
    if (response.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: response.errorMessage,
        error: response,
      })
    }
    return response.result
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {validateBorderRestrictions}`,
      { tags: ['rpc', 'order'], info: payload },
      'error',
    )
    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Border restrictions validation failed`,
      error,
    })
  }
})
