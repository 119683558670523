import { useState, type CSSProperties } from 'react'
import { FormHelperText, Stack, Typography } from '@mui/material'

import { FormContainer } from '@workwave-tidal/form-ui/core'
import { useFormMeta } from '@workwave-tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/form-ui'

import { ModalContent } from '@/components/Modal'

import { FormErrors, type FormFields } from '../formFields'
import { useOnSubmit } from '../hooks/useOnSubmit'
import { useTexts } from '../useTexts'

import { Footer } from './Footer'
import { Header } from './Header'

const formStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'block',
  position: 'relative',
}

export function Form() {
  const [firstSubmitDone, setFirstSubmitDone] = useState(false)

  // Callback for handling submit
  const { meta, api } = useFormMeta<FormFields, FormErrors>()
  const onFormSubmit = useOnSubmit(setFirstSubmitDone)

  const texts = useTexts()

  // Resolve the form meta states to improve code readability
  const formInvalid = meta.status === 'invalid'
  const formPristine = meta.status === 'pristine'
  const formIndeterminate = meta.status === 'indeterminate'
  const formHasError =
    formInvalid ||
    (formPristine && firstSubmitDone) ||
    ((formIndeterminate || formPristine) && Object.keys(api.getErrors()).length > 0)

  return (
    <ModalContent header={<Header />} footer={<Footer onSubmit={onFormSubmit} />}>
      <form style={formStyle} onSubmit={onFormSubmit}>
        <Stack paddingTop={2} spacing={2} width="100%">
          <Typography variant="body2" fontWeight={400}>
            {texts.formDescription}
          </Typography>

          <FormContainer testId="delete-telematics-tenant-source-form" width={452}>
            <Stack spacing={2} width="100%">
              <Checkbox<'disclaimerDataNoMoreCollecting', FormFields>
                validateOn="blur"
                testId="delete-telematics-tenant-source-form-disclaimerDataNoMoreCollecting"
                label={texts.disclaimerDataNoMoreCollecting}
                name="disclaimerDataNoMoreCollecting"
              />

              <Checkbox<'disclaimerVehicleAssociationDeletion', FormFields>
                validateOn="blur"
                testId="delete-telematics-tenant-source-form-disclaimerVehicleAssociationDeletion"
                label={texts.disclaimerVehicleAssociationDeletion}
                name="disclaimerVehicleAssociationDeletion"
              />

              <Checkbox<'disclaimerPermanentAction', FormFields>
                validateOn="blur"
                testId="delete-telematics-tenant-source-form-disclaimerPermanentAction"
                label={texts.disclaimerPermanentAction}
                name="disclaimerPermanentAction"
              />
              {formHasError ? <FormHelperText error>{texts.formError}</FormHelperText> : null}
            </Stack>
          </FormContainer>
        </Stack>
      </form>
    </ModalContent>
  )
}
