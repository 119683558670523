import React from 'react'
import { WarningCard } from '@/local/components'
import { useTexts } from '../useTexts'
import { Preset } from '@/local/components/molecules/WarningCard/typings'
import { OrderStepsIdentifierCode } from '@/features/domain/order/actions/borderRestrictionsValidate'
import { ErrorOutline } from '@mui/icons-material'

interface OrderWarningCardProps {
  isForceFitting: boolean
  outOfBorder: {
    status: boolean
    count: number
  }
  borderRestrictionFullName: string
  orderSteps: OrderStepsIdentifierCode[]
  style: React.CSSProperties
}

export const OrderWarningCard: React.FC<OrderWarningCardProps> = ({
  isForceFitting,
  outOfBorder,
  borderRestrictionFullName,
  orderSteps,
  style,
}) => {
  const texts = useTexts()
  const hasMultipleSteps = orderSteps.length > 1

  const getWarningProps = () => {
    if (!isForceFitting) {
      return {
        preset: 'info' as Preset,
        Icon: <ErrorOutline color="info" />,
        description: hasMultipleSteps ? texts.multipleFittingOptionsText : texts.fittingOptionsText,
        style: style,
      }
    }

    if (!outOfBorder.status) {
      return {
        preset: 'warning' as Preset,
        Icon: <ErrorOutline color="warning" />,
        description: hasMultipleSteps
          ? texts.multipleForceAssignmentText
          : texts.forceAssignmentText,
        style: style,
      }
    }

    const { count: outOfBorderCount } = outOfBorder
    const totalOrders = orderSteps.length

    return {
      preset: 'alert' as Preset,
      Icon: <ErrorOutline color="error" />,
      description: texts.outOfBorderText(outOfBorderCount, totalOrders, borderRestrictionFullName),
      style: style,
    }
  }

  const warningProps = getWarningProps()

  return <WarningCard {...warningProps} />
}
