import type { CSSProperties } from 'react'
import type { ActionPermissions } from '../../hooks/useDevicesNavigoMachine/types'

import { useSelector } from 'react-redux'

import { Separator } from '@/components/Separator'
import { Tooltip } from '@/components/primitives/Tooltip'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { MapStyle } from '@/icons'

import { CloseButton } from '../../../../elements/CloseButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'
import { useDevicesNavigator } from '../../hooks/useDevicesNavigator'

import { Stack } from '@mui/material'

import { Actions } from './components/Actions'
import { disabledByPermission } from './components/Actions/utils'
import { Details } from './components/Details'
import { DeviceInfo } from './components/DeviceInfo'
import { useTexts } from './useTexts'

interface Props {
  expanded: boolean
  goToStyle: () => void
  toggleExpanded: () => void
  permissions: ActionPermissions
}

const growStyle: CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  height: 46,
  gap: 16,
}

export function Overview(props: Props) {
  const { expanded, permissions, toggleExpanded, goToStyle } = props

  const { currentDevices } = useDevicesNavigator()
  const calendarRange = useSelector(selectCalendarRange)
  const userConfig = useSelector(selectUserConfiguration)

  const texts = useTexts()

  if (currentDevices.length === 0) return null

  const isToday =
    calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === userConfig.today

  const stylerDisabled = disabledByPermission(permissions.style)

  return (
    <>
      <Stack width="100%" style={growStyle}>
        <Details devices={currentDevices} />

        {currentDevices.length === 1 && isToday && (
          <>
            <Separator />
            <DeviceInfo device={currentDevices[0]} />
          </>
        )}
      </Stack>

      <Actions
        toggleExpanded={toggleExpanded}
        devices={currentDevices}
        permissions={permissions}
        expanded={expanded}
      />

      <Tooltip placement="top" disabled={!stylerDisabled} title={texts.notAvailableInThePast}>
        <NavigoIconButton
          disabled={stylerDisabled}
          Icon={<MapStyle size={20} color={stylerDisabled ? '$silver' : '$nightRider'} />}
          onClick={goToStyle}
          text={texts.style}
        />
      </Tooltip>
      <CloseButton />
    </>
  )
}
