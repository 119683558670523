import type { Notifications } from '../../../../types'

import { Scrollbar } from '@workwave-tidal/core/components'

import { useQueryState } from '@/routing'

import { useLiveEtaStatus } from '../../../../../sections/setup/pages/notifications/hooks/useLiveEtaStatus'
import { ListItem } from './ListItem'

import { useStatusMessages } from './hooks/useStatusMessages'
import { StyledBox, StyledPaper } from './hooks/useStyles'

export interface Props {
  notificationStatus: uui.domain.client.rm.NotificationConfig
  inEditing: boolean
}

export const notificationsOrder: Notifications[] = [
  'twoDaysSettings',
  'tomorrowSettings',
  'todaySettings',
  'earlySettings',
  'delayedSettings',
  'almostThereSettings',
  'canceledSettings',
  'notCompletedSettings',
  'completedSettings',
]

/**
 * Link all the available notifications.
 */
export function NotificationsList(props: Props) {
  const { notificationStatus, inEditing } = props

  const [query, setQuery] = useQueryState<Notifications>('notification', 'twoDaysSettings', {
    clearOnUnmount: true,
  })
  const liveEtaStatus = useLiveEtaStatus()

  const statuses = useStatusMessages(notificationStatus, liveEtaStatus)

  return (
    <StyledPaper elevation={4}>
      <Scrollbar hideOverflow="x">
        <StyledBox>
          {notificationsOrder.map(notification => (
            <ListItem
              details={statuses[notification]}
              active={query === notification}
              notification={notification}
              disabled={inEditing}
              setQuery={setQuery}
              key={notification}
              testId={`notification-${notification}`}
            />
          ))}
        </StyledBox>
      </Scrollbar>
    </StyledPaper>
  )
}
