import type { ItemWithPosition, Props, ScrollUpdateMode } from './typings'
import type { ScrollbarApi, ScrollbarOffset } from '@workwave-tidal/core/components/Scrollbar'

import { useCallback, useMemo, useRef, useState, useEffect } from 'react'

import { Scrollbar } from '@workwave-tidal/core/components'

import { useSubscribeListApi } from '@/atoms'

import { ItemsRenderer } from './ItemsRenderer'
import { getNewScrollValue } from './core/getNewScrollValue'
import { isRenderGroupHeader } from './core/isRenderGroupHeader'
import { getItemsWithPosition } from './core/getItemsWithPosition'
import { getStringifiedItemIds } from './core/stringifiedItemIdsHelpers'
import { getItemsInterestedByScroll } from './core/getItemsInterestedByScroll'

type PrevValues<
  Category extends uui.domain.ui.list.Category,
  T = uui.domain.ui.list.EntityForListCategory[Category],
> = {
  stringifiedIds: string
  visibleItems: ItemWithPosition<T>[]
}

const noop = () => {}
const noSelection = []
const defaultScrollUpdateMode: ScrollUpdateMode = { type: 'doNothing' }

const defaultGetItemId = (item: unknown) => {
  const id = (item as { id: string }).id
  if (process.env.NODE_ENV === 'development') {
    if (id === undefined || id === null) {
      throw new Error(
        `[StructuredVirtualList] - unknown item id for ${JSON.stringify(item, null, 2)}`,
      )
    }
  }
  return (item as { id: string }).id
}

export function StructuredVirtualList<
  Category extends uui.domain.ui.list.Category,
  T = uui.domain.ui.list.EntityForListCategory[Category],
>(props: Props<Category, T>) {
  const {
    itemsById,
    listHeight,
    buffer = 0,
    getItemHeights,
    structure: { list },
    getItemId = defaultGetItemId,
    scrollUpdateMode = defaultScrollUpdateMode,
  } = props

  const [scrollY, setScrollY] = useState<number>(0)

  // ----------------------
  // HEAVY CALCULATIONS
  // ----------------------

  // To avoid unnecessary calculations in case of a high number of items, an array of items with
  // their position is stored and used from now on
  const { itemsTotalHeight, itemsWithPosition } = useMemo(
    () => getItemsWithPosition({ itemsById, list, getItemHeights }),
    [itemsById, list, getItemHeights],
  )

  // The items to be rendered (both the visible ones and the buffered ones) are stored
  const visibleItems = useMemo<ItemWithPosition<T>[]>(
    () =>
      getItemsInterestedByScroll<T>({
        buffer,
        scrollY,
        listHeight,
        itemsTotalHeight,
        itemsWithPosition,
      }),
    [buffer, scrollY, listHeight, itemsTotalHeight, itemsWithPosition],
  )

  // ----------------------
  // SCROLL MAINTAINING
  // ----------------------

  const api = useRef({ getItemId, scrollY, listHeight, itemsWithPosition, scrollUpdateMode })
  useEffect(() => {
    api.current = { getItemId, scrollY, listHeight, itemsWithPosition, scrollUpdateMode }
  }, [getItemId, scrollY, listHeight, itemsWithPosition, scrollUpdateMode])

  // When the items update, the list tries to update the scroll position to maintain the old visible
  // items in the same position. To avoid to refer the old items array (which could be huge) a
  // string representation is used
  useEffect(() => {
    const prevVisibleItems = prevValues.current?.visibleItems
    const prevStringifiedIds = prevValues.current?.stringifiedIds
    const { getItemId, scrollY, listHeight, itemsWithPosition, scrollUpdateMode } = api.current

    if (prevVisibleItems && prevStringifiedIds) {
      rScrollbarApi.current?.scrollTo(
        0,
        getNewScrollValue({
          getItemId,
          prevVisibleItems,
          scrollUpdateMode,
          prevStringifiedIds,
          currentScroll: scrollY,
          scrollHeight: listHeight,
          newItems: itemsWithPosition,
        }),
      )
    }
  }, [itemsById, list])

  // ----------
  // Updating the previous values must be the last step
  const prevValues = useRef<PrevValues<Category, T> | undefined>()
  useEffect(() => {
    prevValues.current = {
      visibleItems,
      stringifiedIds: getStringifiedItemIds(itemsWithPosition, api.current.getItemId),
    }
  }, [itemsWithPosition, visibleItems])

  // ----------------------
  // IMPERATIVE API
  // ----------------------

  const listApiCallback = useCallback<uui.domain.ui.list.SubscriptionCallback>(
    (...args) => {
      const [action] = args

      switch (action) {
        case 'reset':
          rScrollbarApi.current?.scrollTo(0, 0)
          break

        case 'scrollTo':
          const [, id] = args
          if (!id) {
            throw new Error(`Item id not provided`)
          }

          // LIST_IMPERATIVE_API: flow1, step4
          // The StructuredVirtualList scrolls to the item

          const item = api.current.itemsWithPosition.find(
            item => !isRenderGroupHeader(item.item) && getItemId(item.item) === id,
          )

          if (item) {
            let counter = 0
            const target = item
            function tryToScroll() {
              counter++
              if (rScrollbarApi.current) {
                rScrollbarApi.current.scrollTo(0, target.y)
              } else {
                if (counter < 20) {
                  requestAnimationFrame(tryToScroll)
                } else {
                  console.warn(`impossible to autoScroll`)
                }
              }
            }
            tryToScroll()
          }

          // "active" subscribers (like this StructuredVirtualList) clear the scrollTo buffer by returning true
          return true
      }
    },
    [getItemId],
  )

  // LIST_IMPERATIVE_API: flow1, step1
  // Normal behavior: the StructuredVirtualList renders/subscribes before the List calls `scrollTo`
  // The StructuredVirtualList subscribes to the imperative APIs actions

  // LIST_IMPERATIVE_API: flow2, step3
  // The StructuredVirtualList subscribes to the imperative APIs actions

  const unsubscribe = useSubscribeListApi(props.listApiCategory, listApiCallback)
  useEffect(() => unsubscribe, [unsubscribe])

  // ----------------------
  // SMOOTH SCROLLBAR MANAGEMENT
  // ----------------------

  const handleOnScroll = useCallback((offset: ScrollbarOffset) => {
    setScrollY(offset.top)
  }, [])

  const rScrollbarApi = useRef<ScrollbarApi>()

  // ----------------------
  // RENDER
  // ----------------------

  return (
    <div style={{ width: '100%', height: listHeight }}>
      <Scrollbar ref={rScrollbarApi} onScroll={handleOnScroll} hideOverflow="x">
        <div
          style={{
            height: itemsTotalHeight,
          }}
        >
          <ItemsRenderer<Category, T>
            getItemId={getItemId}
            visibleItems={visibleItems}
            RenderItem={props.RenderItem}
            onSelect={props.onSelect || noop}
            onDoubleClick={props.onDoubleClick || noop}
            itemsWithPosition={itemsWithPosition}
            selectedItemIds={props.selectedItemIds || noSelection}
          />
        </div>
      </Scrollbar>
    </div>
  )
}
