import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'
import { unavailableRouteFilter } from '../filters'
import { project } from '../project'

let lastShowUnavailableRoutesValue = getSchedulerPreferences().showUnavailableRoutes

export function syncUnavailableRoutesVisibility() {
  const nextShowUnavailableRoutesValue = getSchedulerPreferences().showUnavailableRoutes

  // If the showUnavailableRoutes value hasn't changed, don't do anything
  if (lastShowUnavailableRoutesValue === nextShowUnavailableRoutesValue) return

  // Update the showUnavailableRoutes value
  lastShowUnavailableRoutesValue = nextShowUnavailableRoutesValue

  if (nextShowUnavailableRoutesValue) {
    project.resourceStore.removeFilter('unavailableRouteFilter')
  } else {
    project.resourceStore.addFilter(unavailableRouteFilter)
  }
}
