import { Block, InputFieldText } from '@/forms-legacy'
import { WarningTriangle } from '@/icons'
import { H4, WarningCard } from '@/local/components'

import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

export function Body() {
  const texts = useTexts()
  const actions = useControllerActions()
  const {
    status,
    data: { newName },
  } = useController()

  return (
    <Stack direction="column" height="100%" width="100%" marginTop={4}>
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.inputFieldTitle}
        </H4>
      </Block>
      <Block>
        <InputFieldText name="territory-name" value={newName} onChange={actions.onNameChange} />
      </Block>

      {status === 'pending' && (
        <Block>
          <WarningCard
            preset="warning"
            title={texts.transactionWarningCardTitle}
            description={texts.transactionWarningCardDescription}
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
          />
        </Block>
      )}
    </Stack>
  )
}
