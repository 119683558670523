import { useFormField } from '@workwave-tidal/form-fairy'

import { useMemo } from 'react'
import { FormHelperText, MenuItem, Typography, Box, Divider } from '@mui/material'
import { Checkbox } from '@workwave-tidal/form-ui'

import { type Option } from '@/components/formUi/fields/Select'
import { Select } from '@/components/formUi/fields/Select'
import { Tooltip } from '@/components/primitives/Tooltip'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

interface Props {
  gpsDeviceOptions: Option<string>[]
}

const menuProps = {
  sx: { maxWidth: 500 },
}

export function TrackDriver(props: Props) {
  const { gpsDeviceOptions } = props
  const texts = useTexts()

  const canAssignGpsDevice = useMemo(
    () => Boolean(gpsDeviceOptions.find(opt => opt.preset !== 'divider' && !opt.disabled)),
    [gpsDeviceOptions],
  )

  const trackDriverField = useFormField<'trackDriver', FormFields, FormErrors>('trackDriver')
  const trackDriverVisible = trackDriverField.field.visible

  if (!trackDriverVisible) return null

  return (
    <Box>
      <Tooltip
        title={texts.tooltips.noAvailableVirtualDevices}
        disabled={canAssignGpsDevice}
        placement="top"
      >
        <Checkbox<'trackDriver', FormFields>
          name="trackDriver"
          testId="trackDriver"
          label={texts.labels.trackDriver}
          validateOn="change blur"
          disabled={!canAssignGpsDevice}
        />
      </Tooltip>
      <Box sx={{ pl: 4 }}>
        <FormHelperText sx={{ pb: 2 }}>{texts.helpers.trackDriver}</FormHelperText>
        <Select<'gpsDeviceId', string, FormFields, FormErrors>
          name="gpsDeviceId"
          testId="gpsDeviceId"
          label={texts.labels.gpsDeviceId}
          validateOn="change blur"
          options={gpsDeviceOptions}
          renderOptions={renderOptions}
          MenuProps={menuProps}
        />
      </Box>
    </Box>
  )
}

const mainTextSx = { marginRight: 2 }
const secondaryTextSx = { textAlign: 'right', marginLeft: 'auto' }

function renderOptions(option: Option<string>, index: number) {
  switch (option.preset) {
    case 'divider':
      return <Divider key={index} />

    case 'secondaryText':
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          <Typography noWrap sx={mainTextSx}>
            {option.label}
          </Typography>

          <Typography sx={secondaryTextSx} noWrap>
            {option.secondaryText}
          </Typography>
        </MenuItem>
      )

    default:
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      )
  }
}
