import { styled } from '@mui/material/styles'
import { theme } from '@/styles'
import { useMemo } from 'react'

interface ContainerProps {
  size: number
  backgroundColor: string
  color: string
  marginRight: number
}

export function useStyledContainer() {
  return useMemo(
    () =>
      styled('div', {
        shouldForwardProp: prop =>
          !['size', 'backgroundColor', 'color', 'marginRight'].includes(prop as string),
      })<ContainerProps>(({ size, backgroundColor, color, marginRight }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        color: color,
        backgroundColor: backgroundColor,
        overflow: 'hidden',
        width: size,
        height: size,
        marginRight: marginRight,
        border: `1px solid ${theme.colors.$pureWhite}`,
      })),
    [],
  )
}
