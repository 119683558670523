import type { FormErrors, FormFields } from '../../formFields'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Alert, Typography, Stack } from '@mui/material'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { useTexts } from '../../useTexts'

interface Props {
  notificationBehavior: uui.domain.client.rm.NotificationBehavior
}

export function NotificationWay(props: Props) {
  const { notificationBehavior } = props

  const texts = useTexts()

  const options = useMemo(() => {
    return [
      {
        value: 'EMAIL' as uui.domain.client.rm.NotificationWay,
        label: texts.email,
        // EMAIL option should, theoretically, never be disabled because
        // if the notification behavior is NO_NOTIFICATIONS, the entire section won't be visible.
        disabled: notificationBehavior === 'NO_NOTIFICATIONS',
      },
      {
        value: 'PHONE' as uui.domain.client.rm.NotificationWay,
        label: texts.sms,
        disabled: notificationBehavior !== 'EMAIL_AND_PHONE',
      },
    ]
  }, [texts, notificationBehavior])

  return (
    <Stack>
      <RadioGroup<'notificationWay', uui.domain.client.rm.NotificationWay, FormFields, FormErrors>
        label={texts.notificationWayLabel}
        validateOn="blur focus"
        name="notificationWay"
        testId="notificationWay"
        options={options}
      />

      {notificationBehavior === 'ONLY_EMAIL' ? (
        <Alert severity="info">
          <FormattedMessage
            id={texts.smsEnableConditionMessage}
            values={{
              b: chunks => (
                <Typography fontWeight={600} display="inline" fontSize="inherit">
                  {chunks}
                </Typography>
              ),
            }}
          />
        </Alert>
      ) : (
        <></>
      )}
    </Stack>
  )
}
