import { FormHeader } from '@workwave-tidal/form-ui/layout'

import { ViewContainer, ViewContent, ViewFieldsLayout, ViewField } from '@/formUi'

import { useGpsAccountData } from '../hooks/useGpsAccountData'
import { useTexts } from './hooks/useTexts'

import { GpsAccountViewActions } from './components/GpsAccountViewActions'

type Props = {
  onEdit: () => void
}

export function GpsAccountView(props: Props) {
  const { onEdit } = props

  const texts = useTexts()
  const [account] = useGpsAccountData()

  return (
    <ViewContainer testid="preferences-view">
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />

        <ViewFieldsLayout>
          {/* View MAin Actions */}
          <GpsAccountViewActions onEdit={onEdit} />

          {/* View Fields */}
          <ViewField label={texts.email}>{account.email}</ViewField>
          <ViewField label={texts.username}>{account.username}</ViewField>
          <ViewField label={texts.timezone}>{account.timezone}</ViewField>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
