import type { BestOptionOr, RouteStep, Vehicle, ExtendedOrderStep } from '../typings'

import { useState, useEffect } from 'react'

import { useReadOnly } from '@/hooks'
import { useCloseModal, useModalController, useConfigureModalController } from '@/atoms'

import { isBestOption } from '../typings'
import { useData } from './useData'
import { isOrderAssigned } from './utils'

type ModalState =
  | 'ready'
  | 'pending'
  | 'submitting'
  | 'invalidForEmptyOrders'
  | 'invalidForAlreadyAssigned'
export interface ModalData {
  today: string
  dates: Date[]
  vehicles: Vehicle[]
  isForceFitting: boolean
  closedRouteIds: Set<string>
  totalServiceTime: number
  areDateAndVehicleSet: boolean
  activeDate: BestOptionOr<Date>
  orderSteps: ExtendedOrderStep[]
  activeStep: BestOptionOr<RouteStep>
  activeVehicle: BestOptionOr<Vehicle>
  userConfig: uui.domain.UserConfiguration
  routes: Record<string, uui.domain.client.rm.ExtendedRoute>
  outOfBorder: {
    status: boolean
    count: number
  }
}

export const modalId = 'fitInOrder'
export const useController = () => useModalController<ModalData>(modalId)

export const useConfigureController = (orderStepIds: string[]) => {
  const close = useCloseModal()
  const { readonly } = useReadOnly()
  const {
    today,
    dates,
    vehicles,
    routes,
    orderSteps,
    userConfig,
    closedRouteIds,
    totalServiceTime,
  } = useData(orderStepIds)

  const [options] = useState(() => ({
    createInitialState: () => ({
      close,
      invalid: false,
      data: {
        today,
        dates,
        routes,
        vehicles,
        userConfig,
        orderSteps,
        closedRouteIds,
        totalServiceTime,
        isForceFitting: false,
        areDateAndVehicleSet: false,
        activeDate: 'best' as BestOptionOr<Date>,
        activeStep: 'best' as BestOptionOr<RouteStep>,
        activeVehicle: 'best' as BestOptionOr<Vehicle>,
        outOfBorder: { status: false, count: 0 },
      },
    }),
  }))

  const ctrl = useConfigureModalController<ModalData, ModalState>(modalId, options)
  const {
    update,
    updateData,
    data: { activeStep, activeDate, activeVehicle },
    status,
  } = ctrl

  // EFFECTS

  // External transaction
  useEffect(() => {
    switch (readonly) {
      case true:
        update({ status: 'pending' })
        break

      case false:
        if (status !== 'pending') return

        update({ status: 'ready' })
        break
    }
  }, [readonly, status, update])

  // Force fitting
  useEffect(() => {
    updateData({ isForceFitting: !isBestOption(activeStep) })
  }, [activeStep, updateData])

  // Remove step selection when date or vehicle are not set
  useEffect(() => {
    const areDateAndVehicleSet = !isBestOption(activeDate) && !isBestOption(activeVehicle)
    const update = { areDateAndVehicleSet }

    updateData(areDateAndVehicleSet ? update : { ...update, activeStep: 'best' })
  }, [activeDate, activeVehicle, updateData])

  // Updates orderSteps value
  useEffect(() => {
    updateData({ orderSteps, totalServiceTime })
  }, [orderSteps, totalServiceTime, updateData])

  // Invalid state management
  useEffect(() => {
    if (!orderSteps || orderSteps.length === 0) {
      update({ invalid: true, status: 'invalidForEmptyOrders' })
      return
    }

    for (const order of orderSteps) {
      if (isOrderAssigned(order, routes)) {
        update({ invalid: true, status: 'invalidForAlreadyAssigned' })
        return
      }
    }

    update({ invalid: false, status: 'ready' })
  }, [orderSteps, routes, update])

  return ctrl
}
