import type { FormFields, FormErrors } from '../formFields'
import type { FormSingleField } from '@workwave-tidal/form-fairy'

import { useEffect } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'

export function useUpdatePhoneRelatedFields() {
  const api = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    function updateDisableState(phoneNumberField: Readonly<FormSingleField<string>>) {
      const empty = isPhoneNumberEmpty(phoneNumberField.value)
      api.setFieldDisabledState('showContactPhone', empty)
    }

    const phoneNumberField = api.getField('companyPhone')
    updateDisableState(phoneNumberField)

    return api.subscribeToField('companyPhone', updateDisableState)
  }, [api])
}

function isPhoneNumberEmpty(email: string) {
  return email.trim().length === 0
}
