import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { CalendarToday, Timelapse } from '@mui/icons-material'
import { memo, useCallback } from 'react'

import { ListItem, ListItemContent, ListItemRow, ListItemTitle } from '@/components/List'
import { useTexts } from './hooks/useTexts'

type Props = Extract<RenderItemProps<'trafficAreas'>, { type: 'item' }>

export const TrafficAreaItem = memo((props: Props) => {
  const { selected, item, onItemClick } = props
  const { name, level, timeWindowLevels } = item

  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])
  const texts = useTexts()

  return (
    <ListItem selected={selected} onClick={onClick} testId="traffic-area-list-item">
      <ListItemTitle>{name}</ListItemTitle>

      <ListItemRow testId="traffic-area-time-windows-row">
        <ListItemContent icon={<CalendarToday />}>
          {texts.timeWindowsCount(timeWindowLevels.length)}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="traffic-area-driving-time-factor-row">
        <ListItemContent icon={<Timelapse />}>
          {`${getDrivingTimeFactorFromSpeedFactor(level)}x`}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})
TrafficAreaItem.displayName = 'TrafficAreaItem'

function getDrivingTimeFactorFromSpeedFactor(speedFactor: number): number {
  return parseFloat((speedFactor / 100).toFixed(2))
}
