import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'

import { timeWindowUtils } from '@/utils'

export const createValidateTimeWindows =
  (texts: ValidationTexts, workingDayStartSec: number): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField('timeWindows')

    const invalidTimeWindowIndexes =
      value.status === 'exact'
        ? value.value.reduce<number[]>((acc, tw, index) => {
            if (!isValidTimeWindow(tw, workingDayStartSec)) {
              acc.push(index)
            }
            return acc
          }, [])
        : []

    const invalidTimeWindowsError: FormErrors = {
      id: 'invalidTimeWindowsError',
      field: 'timeWindows',
      message: texts.invalidTimeWindows,
      invalidTimeWindowIndexes,
      priority: 3,
    }

    const valid = invalidTimeWindowIndexes.length === 0

    return {
      [invalidTimeWindowsError.id]: !valid ? invalidTimeWindowsError : null,
    }
  }

function isValidTimeWindow(
  timeWindow?: uui.domain.rm.TimeWindow,
  workingDayStartSec: number = 0,
): boolean {
  if (!timeWindow) return true

  const normalizedTw = timeWindowUtils.normalizeTimeWindow(timeWindow, workingDayStartSec)

  return timeWindowUtils.isValid(normalizedTw, workingDayStartSec)
}
