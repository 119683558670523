// see: Browser features detection -> https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769

import { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles'

import { useMount } from '@/local/hooks'

import { clsx } from '@/utils'
import { useWarningOverlayShown } from '@/atoms'

import { isMobileBrowser } from './utils/isMobileBrowser'
import { useTexts } from './useTexts'
import { Button } from './Button'
import DialogTitle from './elements/DialogTitle'
import DialogContainer from './elements/DialogContainer'
import DialogBox from './elements/DialogBox'
import DialogMessage from './elements/DialogMessage'
import OverlayBackground from './elements/OverlayBackground'
import MobileStoreLinks from './components/StoreLinks'

type WarningOverlayProps = {
  className?: string
}

const StyledContainer = styled('div')({
  '& > *': {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    left: 0,
  },
})

export function WarningOverlay(props: WarningOverlayProps) {
  const { className = '' } = props

  const texts = useTexts()
  const [shown, setShown] = useWarningOverlayShown()

  const [showResolutionMessage, setShowResolutionMessage] = useState(false)
  const [showBrowserMessage, setShowBrowserMessage] = useState(false)
  const [showUnsupportedBrowserMessage, setShowUnsupportedBrowserMessage] = useState(false)
  const [showMobileBrowserMessage, setShowMobileBrowserMessage] = useState(false)
  const [size, setSize] = useState([0, 0])

  useMount(() => {
    const { width, height } = window.screen

    setSize([width, height])

    if (width < 1440) {
      setShowResolutionMessage(true)
    }

    if (navigator.userAgent.indexOf('Chrome') === -1) {
      setShowBrowserMessage(true)
    }

    if (isMobileBrowser()) {
      setShowMobileBrowserMessage(true)
    }
  })

  const handleOnConfirm = useCallback(() => {
    setShowResolutionMessage(false)
    setShowBrowserMessage(false)
    setShowUnsupportedBrowserMessage(false)
    setShown(true)
  }, [setShown])

  if (
    !showUnsupportedBrowserMessage &&
    (shown || (!showResolutionMessage && !showBrowserMessage && !showMobileBrowserMessage))
  ) {
    return null
  }

  return (
    <StyledContainer className={clsx(className, 'o-warning-overlay')}>
      <OverlayBackground width={size[0]} height={size[1]} />
      <DialogContainer>
        <DialogBox>
          <DialogTitle>{texts.titleText}</DialogTitle>

          <OverlayBody
            showResolutionMessage={showResolutionMessage}
            showBrowserMessage={showBrowserMessage}
            showUnsupportedBrowserMessage={showUnsupportedBrowserMessage}
            showMobileBrowserMessage={showMobileBrowserMessage}
          />

          {!showUnsupportedBrowserMessage && <Button onClick={handleOnConfirm}>Ok</Button>}
        </DialogBox>
      </DialogContainer>
    </StyledContainer>
  )
}

type renderOverlayBodyProps = {
  showResolutionMessage?: boolean
  showBrowserMessage?: boolean
  showUnsupportedBrowserMessage?: boolean
  showMobileBrowserMessage?: boolean
}
function OverlayBody(props: renderOverlayBodyProps) {
  const {
    showResolutionMessage,
    showBrowserMessage,
    showUnsupportedBrowserMessage,
    showMobileBrowserMessage,
  } = props

  const texts = useTexts()

  if (showMobileBrowserMessage) {
    return <MobileStoreLinks />
  } else if (showUnsupportedBrowserMessage) {
    return <DialogMessage>{texts.unsupportedBrowserWarningText}</DialogMessage>
  } else if (showBrowserMessage && showResolutionMessage) {
    return <DialogMessage>{texts.browserResolutionWarningText}</DialogMessage>
  } else {
    return (
      <DialogMessage>
        {showBrowserMessage ? texts.browserWarningText : texts.resolutionWarningText}
      </DialogMessage>
    )
  }
}
