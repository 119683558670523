import { Alert } from '@mui/material'

import { form } from '../../formState'
import { useTexts } from '../../hooks/useTexts'
import { InfoBanner } from './components/InfoBanner'

export function DrawArea() {
  const texts = useTexts()
  const { errors } = form.useFormField('points')

  form.useAddFieldValidation<'points'>('points', (field, _formApi) => {
    if (field.value.length < 3) {
      return texts.errorPoints
    }
  })

  return (
    <form.Fieldset>
      <form.DrawAreaField
        name="points"
        drawAreaProps={{
          id: 'regionArea',
          color: form.useFormField('color').field.value,
          initialPoints: form.useFormField('points').field.value,
        }}
      />

      <InfoBanner />

      {errors.length > 0 && (
        <Alert severity="error" component="div">
          {errors[0].message}
        </Alert>
      )}
    </form.Fieldset>
  )
}
