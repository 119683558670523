import { colorsUtils } from '@/utils'

import { NearMeDisabled } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'

import VehicleColor from '../VehicleColor'

import { useAssignmentsInfo } from './hooks/useAssignmentsInfo'
import { useTexts } from './hooks/useTexts'

interface Props {
  assignments: uui.domain.client.rm.DriverAssignments
}

export function ViewDetails(props: Props) {
  const { assignments } = props

  const assignmentsInfo = useAssignmentsInfo(assignments)
  const texts = useTexts()

  return (
    <Box mt={2} mx={4}>
      {assignmentsInfo.map(info => {
        const { driverLabel, driverId, id, vehicleLabel, hasDevice, color } = info

        return (
          <Stack direction="column" key={id} mb={3}>
            <Stack direction="row" alignItems="center" mb={1}>
              <VehicleColor size={14} color={colorsUtils.getValidHexColor(color)} />
              <Typography variant="subtitle2">{vehicleLabel}</Typography>

              {!hasDevice && (
                <Tooltip title={texts.notTracked} placement="bottom">
                  <NearMeDisabled
                    sx={{
                      fontSize: 16,
                      display: 'inline-block',
                      verticalAlign: 'bottom',
                      ml: 0.5,
                      flexShrink: 0,
                    }}
                  />
                </Tooltip>
              )}
            </Stack>

            <Typography
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              variant="body1"
            >
              {driverId ? driverLabel : '-'}
            </Typography>
          </Stack>
        )
      })}
    </Box>
  )
}
