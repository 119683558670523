import type { FormFields, FormErrors } from '../../formFields'

import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from './useTexts'

export function Surname() {
  const texts = useTexts()

  return (
    <TextField<'surname', FormFields, FormErrors>
      name="surname"
      testId="surname"
      label={texts.surname}
      validateOn="blur change"
    />
  )
}
