import type { FormErrors, FormFields } from '../formFields'
import type { FormConfig } from '@workwave-tidal/form-fairy'

import { useStandardPasswordValidations, useValidateEmail } from '@/hooks'

import { useTexts } from './useTexts'
import { useIsUsernameAvailable } from './useIsUsernameAvailable'
import { createValidations } from './validations/createValidations'

export function useValidations(
  creating: boolean,
): FormConfig<FormFields, FormErrors>['validations'] {
  const texts = useTexts()
  const isAvailableUsername = useIsUsernameAvailable()
  const validateEmail = useValidateEmail()

  const validations = createValidations(texts, validateEmail, isAvailableUsername)

  const { validations: passwordValidations } = useStandardPasswordValidations<
    FormFields,
    FormErrors
  >('new-password')

  return creating ? [...(validations ?? []), ...passwordValidations] : validations
}
