import { type Dispatch, type MouseEvent, useCallback } from 'react'
import type { DurationInputAction } from '../../types'

export function useDropdownProps(dispatch: Dispatch<DurationInputAction>) {
  const onClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()

      dispatch({ type: 'dropdownItem:click', event })
    },
    // dispatch is a stable function, putting as a deps has no effect
    [dispatch],
  )

  return { onClick }
}
