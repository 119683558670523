import type { FormError, FormValues as FormValuesType } from '@workwave-tidal/form-fairy'

export const formFields = {
  name: {
    value: '',
    required: true,
  },
  email: {
    value: '',
    required: true,
  },
  surname: {
    value: '',
    required: true,
  },
  username: {
    value: '',
    required: true,
  },
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
