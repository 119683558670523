import type { ReactElement } from 'react'
import type { PopoverOrigin } from '@mui/material'

import { useCallback, useRef } from 'react'
import { Menu } from '@mui/material'
import { InvisibleButton } from '@/local/components'

import { useFixPopupPosition } from './hooks/useFixPopupPosition'
import { DropdownMenuList } from './DropdownMenuList'

type Props = {
  open: boolean
  disabled?: boolean
  setOpen: (open: boolean) => void
  trigger: ReactElement
  children: ReactElement | ReactElement[]
  autoWidth?: boolean
  menuTestid?: string
  anchorOrigin?: PopoverOrigin
  triggerTestid?: string
  transformOrigin?: PopoverOrigin
  enableHoverEffect?: boolean
}

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
} as const

const defaultTransformOrigin = {
  vertical: -14,
  horizontal: 0,
} as const

export function DropdownMenu(props: Props) {
  const {
    open,
    setOpen,
    trigger,
    children,
    disabled,
    autoWidth,
    menuTestid,
    anchorOrigin = defaultAnchorOrigin,
    triggerTestid,
    transformOrigin = defaultTransformOrigin,
    enableHoverEffect = false,
  } = props
  const ref = useRef<HTMLButtonElement | null>(null)

  const actionsRef = useFixPopupPosition()

  const onToggleMenu = useCallback(() => {
    if (disabled) return

    setOpen(!open)
  }, [setOpen, disabled, open])

  return (
    <>
      <InvisibleButton
        disabled={disabled}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={onToggleMenu}
        ref={ref}
        data-testid={triggerTestid}
        enableHoverEffects={enableHoverEffect}
      >
        {trigger}
      </InvisibleButton>
      <Menu
        open={open}
        id="dropdown-menu"
        action={actionsRef}
        anchorEl={ref.current}
        onClose={onToggleMenu}
        variant="selectedMenu"
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <DropdownMenuList autoWidth={autoWidth} data-testid={menuTestid}>
          <>{children}</>
        </DropdownMenuList>
      </Menu>
    </>
  )
}
