import { Tooltip } from '@/components/primitives/Tooltip'
import { Freeze, NoFreeze } from '@/icons'
import { useLockMap } from '@/map'

import { StyledButton } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

export function FreezeMapToggle() {
  const [locked, , toggleLockMap] = useLockMap()
  const texts = useTexts()

  const testid = 'map-controls-freeze-map'

  return (
    <Tooltip placement="bottom" title={locked ? texts.freezeMapOff : texts.freezeMap}>
      <StyledButton onClick={toggleLockMap} data-testid={testid} data-trackid={testid}>
        {locked ? (
          <NoFreeze size={18} color="$nightRider" />
        ) : (
          <Freeze size={18} color="$nightRider" />
        )}
      </StyledButton>
    </Tooltip>
  )
}
