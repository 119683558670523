import type { ReactElement } from 'react'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

interface Props {
  children: ReactElement | ReactElement[]
}

const StyledContainer = styled('div')(({ theme }) => ({
  paddingTop: '5px',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

export function FieldsContainer(props: Props) {
  return (
    <StyledContainer>
      <Stack spacing={3}>{props.children}</Stack>
    </StyledContainer>
  )
}
