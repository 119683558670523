import type {
  FormFields,
  FormErrors,
} from '../../../../../../../notificationForms/sections/notifications/components/NotificationForm/formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

/**
 * That hook will manage visibility/mandatory fields for EarlyNotification
 */
export function useEarlyNotificationInitForm() {
  const api = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    api.setFieldVisibleState('toleranceMins', true)
  }, [api])
}
