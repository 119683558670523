import type { MovePairedOrdersPayload } from '../typings'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'
import { selectRoutes } from '@/features/domain/route'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { selectTerritory } from '@/features/domain/territory'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import * as orderHelpers from '@/local/server-data/domain/helpers/order'

import { computeModalDataValues } from '../utils/computeModalDataValues'

export const useControllerData = (payload: MovePairedOrdersPayload) => {
  const { rescheduleBehavior } = useSelector(selectTerritory)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehiclesMap = useSelector(selectVehicles)
  const routesInfo = useSelector(selectRoutesInfo)
  const routesMap = useSelector(selectRoutes)
  const ordersMap = useSelector(selectOrderSteps)

  return useMemo(() => {
    const { orderStepId, pairedOrderStepId, sourceRouteId } = payload

    const orderStep = ordersMap[orderStepId]?.orderStep
    const order = ordersMap[orderStepId]?.order
    const extendedPairedOrderStep = ordersMap[pairedOrderStepId]

    const vehicleId = routesInfo[sourceRouteId]?.unifiedVehicleId
    const date = routesInfo[sourceRouteId]?.dateAsString

    const unifiedVehicle = vehiclesMap[vehicleId]
    const vehicle = unifiedVehicle?.hasRoutingLicense ? unifiedVehicle.vehicle : undefined

    const pairedRoute = extendedPairedOrderStep
      ? extendedPairedOrderStep.isUnassigned
        ? undefined
        : routesMap[extendedPairedOrderStep.routeId]
      : undefined

    const extPairedVehicle =
      pairedRoute && vehiclesMap[vehiclesByVehicleId[pairedRoute.route.vehicleId]]

    const pairedVehicle = extPairedVehicle?.hasRoutingLicense ? extPairedVehicle.vehicle : undefined

    const isExecuted = orderHelpers.isOrderStepExecuted(orderStep, rescheduleBehavior)
    const gotExecutedPaired = orderHelpers.isOrderStepExecuted(
      extendedPairedOrderStep.orderStep,
      rescheduleBehavior,
    )

    return computeModalDataValues(
      payload,
      date,
      isExecuted,
      gotExecutedPaired,
      order,
      orderStep,
      extendedPairedOrderStep.orderStep,
      vehicle,
      pairedRoute?.route,
      pairedVehicle,
    )
  }, [payload, ordersMap, vehiclesMap, routesMap, routesInfo])
}
