import { Text } from '@/local/components'

import { RoutesColors } from './components/RoutesColors'

import { Stack } from '@mui/material'
import { useRouteColorsByOrders } from './hooks/useRouteColorsByOrders'
import { useTexts } from './useTexts'

interface Props {
  extOrders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function RoutesDetail(props: Props) {
  const { extOrders } = props

  const routeColors = useRouteColorsByOrders(extOrders)

  const texts = useTexts()

  return (
    <Stack direction="row" marginTop={1} justifyContent="space-between">
      <Text size="$s" color="$shadyLady" weight="$bold" uppercase>
        {texts.routes(Object.keys(routeColors).length)}
      </Text>

      <RoutesColors routeColors={routeColors} />
    </Stack>
  )
}
