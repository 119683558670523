import type { FormFields, FormErrors } from './formFields'

import { useCallback } from 'react'
import { parse } from 'date-fns/esm'
import { useSelector } from 'react-redux'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { setBreadcrumbTimeRange } from '@/features/domain/breadcrumb'
import { selectCalendarRange } from '@/features/domain/ui'

import { useController } from './useController'

export function useControllerActions() {
  const { minDate, maxDate } = useSelector(selectCalendarRange)
  const formApi = useFormApi<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const { close } = useController()

  const onConfirm = useCallback(async () => {
    try {
      if (minDate !== maxDate) {
        throw new Error('This should never happen')
      }

      const currentDayMidnightTimeStamp = parse(minDate, 'yyyyMMdd', new Date()).getTime()

      // Set FormState into submitting mode
      formApi.setSubmitting(true)

      await formApi.waitForValidation()

      const valid =
        formApi.getMeta().status === 'indeterminate'
          ? await formApi.validate()
          : formApi.getMeta().status === 'valid'

      if (!valid) {
        // Clear FormState submitting mode
        formApi.setSubmitting(false)
        return
      }

      const formValues = formApi.getValues()

      const payload: uui.domain.BreadcrumbTimeRange =
        formValues.type === 'live'
          ? 'live'
          : {
              start: formValues.timeRangeStart * 60 * 1000 + currentDayMidnightTimeStamp,
              end: formValues.timeRangeEnd * 60 * 1000 + currentDayMidnightTimeStamp,
            }

      const result = await dispatch(setBreadcrumbTimeRange(payload))

      if (setBreadcrumbTimeRange.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      close?.()
    } catch (e) {
      toast.error(e.message)
    }
  }, [dispatch, close, toast, formApi, minDate, maxDate])

  return { onConfirm }
}
