import type { DurationFormat, DurationOptions } from '../../types'
import { formatValue } from '../../utils/formatValue'

/**
 * @param format in which the duration will be displayed
 * @param customOptions list of options provided by the user
 * @returns options for dropdown
 */
export function computeDropdownOptions(format: DurationFormat, customOptions?: DurationOptions) {
  if (customOptions) {
    return generateOptionsFromValues(customOptions, format)
  }

  return generateOptionsFromValues(defaultOptionsByFormat[format], format)
}

function generateOptionsFromValues(values: DurationOptions, format: DurationFormat) {
  return values.map(value => ({
    value,
    label: formatValue(value, format),
  }))
}

const s = 1
const m = s * 60
const h = m * 60

const defaultOptionsByFormat: Record<DurationFormat, DurationOptions> = {
  ss: [5 * s, 10 * s, 15 * s, 30 * s, 45 * s, 60 * s],
  mm: [5 * m, 10 * m, 15 * m, 30 * m, 45 * m, 60 * m],
  hh: [1 * h, 2 * h, 3 * h, 4 * h, 6 * h, 8 * h, 12 * h, 24 * h],
  'hh:mm': generateDefaultHHMMOptions(),
  'hh:mm:ss': generateDefaultHHMMOptions(),
}

/**
 * generates options that will cover 24 hours with 15 minutes interval
 */
function generateDefaultHHMMOptions() {
  const lastValidTimeOption = 24 * h
  const nextValues = []

  for (let time = 0; time <= lastValidTimeOption; time += 15 * m) {
    nextValues.push(time)
  }

  return nextValues
}
