import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { validateUpdateTenantSource } from '@/features/domain/gps'
import { type AppDispatch } from '@/store'

import { useTexts } from '../useTexts'

export function createVerizonConnectFormValidation(
  tenantSourceId: string,
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'credentialsValidator',
      fields: 'credentials',
      validator: async formApi => {
        const { value: wrongCredentials } = formApi.getField('wrongCredentials')
        const { value: credentials } = formApi.getField('credentials')

        const { value } = formApi.getField('credentials')

        const usernameRequiredError: FormErrors = {
          id: 'usernameRequired',
          field: 'credentials',
          index: 0,
          message: texts.verizonConnect.errors.usernameRequired,
        }

        const passwordRequiredError: FormErrors = {
          id: 'passwordRequired',
          field: 'credentials',
          index: 1,
          message: texts.verizonConnect.errors.passwordRequired,
        }

        const username = value.find(v => v.fieldName === 'username')
        const password = value.find(v => v.fieldName === 'password')

        const usernameEmpty = username?.value.trim() === ''
        const passwordEmpty = password?.value.trim() === ''

        const wrongCredentialsUsernameError: FormErrors = {
          id: 'wrongCredentialsUsernameError',
          field: 'credentials',
          index: 0,
          message: texts.verizonConnect.errors.usernameIncorrect,
        }

        const wrongCredentialsPasswordError: FormErrors = {
          id: 'wrongCredentialsPasswordError',
          field: 'credentials',
          index: 1,
          message: texts.verizonConnect.errors.passwordIncorrect,
        }

        if (usernameEmpty || passwordEmpty || wrongCredentials) {
          return {
            [usernameRequiredError.id]: usernameEmpty ? usernameRequiredError : null,
            [passwordRequiredError.id]: passwordEmpty ? passwordRequiredError : null,
            [wrongCredentialsUsernameError.id]: wrongCredentials
              ? wrongCredentialsUsernameError
              : null,
            [wrongCredentialsPasswordError.id]: wrongCredentials
              ? wrongCredentialsPasswordError
              : null,
            credentialsAlreadyInUse: null,
          }
        }

        const response = await dispatch(
          validateUpdateTenantSource({
            tenantSourceId,
            credentials,
          }),
        )

        if (validateUpdateTenantSource.rejected.match(response)) {
          const errorCode =
            response.payload?.type === 'rpcFailure' ? response.payload.error.errorCode : undefined

          if (errorCode === 101) {
            // An integration to this account already exist
            return {
              [usernameRequiredError.id]: null,
              [passwordRequiredError.id]: null,
              [wrongCredentialsUsernameError.id]: null,
              [wrongCredentialsPasswordError.id]: null,
              credentialsAlreadyInUse: {
                id: 'credentialsAlreadyInUse',
                field: 'credentials',
                index: 0,
                message: texts.verizonConnect.errors.credentialsAlreadyInUse,
              },
            }
          }

          throw new Error(response.payload?.message ?? 'Internal error')
        }

        return {
          [usernameRequiredError.id]: null,
          [passwordRequiredError.id]: null,
          [wrongCredentialsUsernameError.id]: null,
          [wrongCredentialsPasswordError.id]: null,
          credentialsAlreadyInUse: null,
        }
      },
    },
  ]
}
