import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getPercentageColor } from '../../../styles/colors'
import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'

import { LinearProgress } from '../../Scheduler/components/LinearProgress'
import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'

interface Props {
  record: ResourceModel
  field: string
  value: { absolute: number; percentage: number }
}

export function TotalTimeCell(props: Props) {
  const { value, record } = props

  const { showAbsoluteValues, showApprovedRoutes } = getSchedulerPreferences()

  const approvedTotalTime = record.getData(
    'approvedTotalTime',
  ) as uui.domain.client.rm.SchedulerResource['totalTime']

  if (approvedTotalTime && showApprovedRoutes) {
    return (
      <SingleColumnContainer>
        {showAbsoluteValues ? (
          <Typography fontSize={12}>
            {value.absolute > 0 ? (
              <FormatSeconds seconds={value.absolute} format="EXTRA_SHORT" />
            ) : (
              '-'
            )}
          </Typography>
        ) : (
          <>
            <LinearProgress
              color={getPercentageColor(value.percentage)}
              value={
                value.percentage > 100
                  ? 100
                  : value.percentage <= 0
                    ? 0
                    : (value.percentage * 100) / 100
              }
            />
            <Typography fontSize={10}>
              {value.percentage >= 0 ? `${Math.round(value.percentage * 100) / 100}%` : '-'}
            </Typography>
          </>
        )}
      </SingleColumnContainer>
    )
  }

  if (showAbsoluteValues) {
    return (
      <SingleColumnContainer>
        <Typography fontSize={12}>
          {value.absolute > 0 ? (
            <FormatSeconds seconds={value.absolute} format="EXTRA_SHORT" />
          ) : (
            '-'
          )}
        </Typography>
      </SingleColumnContainer>
    )
  }

  return (
    <SingleColumnContainer>
      <LinearProgress
        color={getPercentageColor(value.percentage)}
        value={
          value.percentage > 100 ? 100 : value.percentage <= 0 ? 0 : (value.percentage * 100) / 100
        }
      />

      <Typography noWrap fontSize={10}>
        {value.percentage <= 0 ? '-' : `${Math.round(value.percentage * 100) / 100}%`}
      </Typography>
    </SingleColumnContainer>
  )
}
