import { NumericStepperField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  step: 5,
  min: 0,
}

export function MaxStops() {
  const texts = useTexts()

  return (
    <NumericStepperField
      name="maxStops"
      testid="maxStops"
      half
      label={texts.maxStops}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
