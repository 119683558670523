import { useMemo } from 'react'

import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  type: uui.domain.client.rm.OrderStepIdentifier
  serviceTimeSec: number
}

export function TypeBlock(props: Props) {
  const texts = useTexts()
  const { type, serviceTimeSec } = props

  const typeLabel = useMemo(() => {
    switch (type) {
      case 'p':
        return texts.pickup

      case 'd':
        return texts.delivery

      case 's':
        return texts.service
    }
  }, [type, texts])

  return (
    <Stack
      direction="column"
      height="100%"
      width="auto"
      justifyContent="space-between"
      data-testid="TypeBlock"
      flexShrink={0}
    >
      <Stack direction="row" width="100%" height="auto">
        <Text size="$p3">{`${texts.type}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          {typeLabel}
        </Text>
      </Stack>

      <Stack direction="row" width="100%" height="auto">
        <Text size="$p3">{`${texts.serviceTime}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          <FormatSeconds seconds={serviceTimeSec} format="SHORT" />
        </Text>
      </Stack>
    </Stack>
  )
}
