import type { UpdateCompanyDetailsPayload } from '../../../../../sections/setup/pages/notifications/forms/TrackingPage/types'
import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

const delay = (duration: number) => new Promise(resolve => window.setTimeout(resolve, duration))

export function useOnSubmit(
  updateTrackingAppData: (payload: UpdateCompanyDetailsPayload) => Promise<boolean>,
  clearEditingState: () => void,
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  // track the mounted state of the parent component
  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    formApi.setSubmitting(true)

    // Let's add a fake delay to let the user understand that a validation is in progress
    await delay(300)

    const valid = await formApi.validate()
    if (!valid) {
      formApi.setSubmitting(false)
      return
    }

    const formValues = formApi.getValues()
    const result = await updateTrackingAppData(formValues)

    // Clear FormState submitting mode
    formApi.setSubmitting(false)

    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    // if the update has been completed successfully let's close the form
    if (result) clearEditingState()
  }, [formApi, updateTrackingAppData, clearEditingState, isUnmounted])
}
