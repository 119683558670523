import { Text } from '@/local/components'

import { Stack } from '@mui/material'

interface Props {
  depot: uui.domain.client.rm.Depot
}

export function Single(props: Props) {
  const { depot } = props

  return (
    <Stack
      direction="column"
      height="100%"
      width="100%"
      flexShrink={0}
      justifyContent="space-between"
    >
      <Text size="$p2" weight="$semiBold">
        {depot.name}
      </Text>
      <Text size="$p3">{depot.location.address}</Text>
    </Stack>
  )
}
