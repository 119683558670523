import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    multipleRegions: translate({ id: 'setup.regions.multipleRegionsSelected' }),
    duplicate: translate({ id: 'global.duplicateAll' }),
    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.editAll' }),
    exportKml: translate({ id: 'global.exportKML' }),
    confirmPanelTitle: (count: number) =>
      translate({ id: 'setup.regions.delete.title', values: { count } }),
    confirmPanelSubtitle: translate({ id: 'setup.regions.delete.subtitle' }),
    title: translate({ id: 'setup.regions.view.single.title' }),
    cost: translate({ id: 'setup.regions.view.single.additionaCost' }),
    time: translate({ id: 'setup.regions.view.single.additionalDrivingTime' }),
    bulkTitle: (count: number) => `${count} ${translate({ id: 'setup.regions.view.bulk.title' })}`,
  }))

  return api
}
