import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'
import type { ValidationTexts } from '../useTexts'
import { createValidateCurrency } from './createValidateCurrency'

export function createValidations(
  texts: ValidationTexts,
): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'currency',
      fields: 'currency',
      validator: createValidateCurrency(texts),
    },
  ]
}
