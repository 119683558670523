import type { FormErrors, FormFields } from '../../formFields'

import { TextField } from '@workwave-tidal/form-ui'
import { useTexts } from '../../useTexts'

export function NotificationBackupSubject() {
  const texts = useTexts()

  return (
    <TextField<'backupSubjectCustom', FormFields, FormErrors>
      label={texts.backupSubjectLabel}
      name="backupSubjectCustom"
      testId="backupSubjectCustom"
      validateOn="blur focus change"
    />
  )
}
