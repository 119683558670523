import { useMemo } from 'react'

import { Block, InputFieldText } from '@/forms-legacy'
import { H4 } from '@/local/components'

import { useController } from '../../../../../../hooks/useController'
import { useControllerActions } from '../../../../../../hooks/useControllerActions'
import { useValidations } from '../../../../../../hooks/useValidations'
import { ErrorMessage } from '../ErrorMessage'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'

export function Message() {
  const texts = useTexts()
  const { onMessageChange } = useControllerActions()
  const {
    data: { message = '' },
  } = useController()
  const { message: validation } = useValidations()

  const errorMessage = useMemo(() => {
    switch (validation) {
      case 'empty':
        return texts.messageErrorEmpty

      case 'tooLong':
        return texts.messageErrorLength

      case 'valid':
      case 'pristine':
      default:
        return ''
    }
  }, [texts, validation])

  return (
    <Stack
      direction="column"
      sx={{ marginBottom: '5px' }}
      width="100%"
      data-testid="send-message-to-driver-message-panel"
    >
      <Block>
        <H4 color="$paleSky" uppercase>
          {texts.messageTitle}
        </H4>
      </Block>

      <Block>
        <InputFieldText
          tabIndex={2}
          multi
          showCounter
          name="message"
          value={message}
          onChange={onMessageChange}
        />
      </Block>

      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Stack>
  )
}
