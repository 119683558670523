import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { useFormatLanguage } from '../../../hooks/useFormatLanguage'
import { useTexts } from './useTexts'

const languages: uui.domain.Language[] = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt']

export function Language() {
  const formatLanguage = useFormatLanguage()
  const texts = useTexts()

  const options = useMemo(
    () =>
      languages.map(value => ({
        value,
        label: formatLanguage(value),
      })),
    [formatLanguage],
  )

  return (
    <RadioGroup<'language', string, FormFields, FormErrors>
      validateOn="blur focus"
      options={options}
      testId="language"
      label={texts.language}
      name="language"
    />
  )
}
