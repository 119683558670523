import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

interface ModalContainerProps {
  minWidth?: number
  maxWidth?: number
}

export const StyledModalContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'minWidth' && prop !== 'maxWidth',
})<ModalContainerProps>(({ minWidth = 484, maxWidth = 484 }) => ({
  flexDirection: 'column',
  position: 'relative',
  maxHeight: '75%',
  display: 'flex',
  minWidth: `${minWidth}px`,
  maxWidth: `${maxWidth}px`,
  top: '50%',
  left: 0,
}))

export const DragHandle = styled('div')<{ dragging: boolean }>(({ dragging }) => ({
  width: '100%',
  cursor: dragging ? 'grabbing' : 'grab',
}))
