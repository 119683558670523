import { type ResourceModel, type TemplateColumnConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'

import { intl } from '@/intl'

import { ApprovedTimeCell, ApprovedTimeTooltip } from '../../../components/cells/ApprovedTimeCell'

import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'
import { approvedTimeSorter } from '../sorters/approvedTimeSorter'

export function createApprovedTimeColumn(
  width: string | number,
  hidden: boolean,
): Partial<TemplateColumnConfig> {
  return {
    text: intl.translate({ id: 'rm.scheduler.column.approvedTime' }),
    type: 'template',
    width,
    field: 'approvedTime',
    editor: false,
    region: 'cols',
    hidden,

    template: ({ record, field, value }) =>
      renderToStaticMarkup(
        <ApprovedTimeCell
          showAbsoluteValues={getSchedulerPreferences().showAbsoluteValues}
          record={record as ResourceModel}
          field={field}
          value={value}
        />,
      ),

    sortable: approvedTimeSorter,
    resizable: true,
    groupable: false,

    tooltipRenderer: ({ record }) => {
      const available = record.getData(
        'available',
      ) as uui.domain.client.rm.SchedulerResource['available']

      if (!available) return ''

      return renderToStaticMarkup(<ApprovedTimeTooltip record={record as ResourceModel} />)
    },
  }
}
