import type { DepotFormValues } from './typings'

import { intl } from '@/intl'

import { colorsUtils, locationUtils } from '@/utils'

const newDepotText = () => intl.translate({ id: 'setup.depots.newDepot' })

const getEmptyDepot = (mapCenter: uui.domain.LatLng): uui.domain.client.rm.Depot => {
  const color = '387608' //TODO: should this imported from the colors of server-data?
  const depotLocation = locationUtils.createEmptyLocation({
    latLng: mapCenter,
  })

  if (!depotLocation && process.env.NODE_ENV === 'development') {
    throw new Error('Impossible to initialize a default location for the depot')
  }

  return {
    id: '',
    name: newDepotText(),
    location: depotLocation,
    color,
    setupCost: 0,
    setupTimeSec: 0,
    orderSteps: {},
  }
}

const cloneDepot = (depot: uui.domain.client.rm.Depot): uui.domain.client.rm.Depot => {
  return {
    ...depot,
    location: locationUtils.cloneLocation(depot.location),
  }
}

export const computeFormInitialValues = (
  mapCenter: uui.domain.LatLng,
  source?: uui.domain.client.rm.Depot,
): DepotFormValues => {
  const depot = source ? cloneDepot(source) : getEmptyDepot(mapCenter)
  const { id, name, location, color, setupCost, setupTimeSec } = depot

  return {
    id,
    name,
    location,
    color: colorsUtils.getValidHexColor(color),
    setupCost: setupCost / 100,
    setupTime: setupTimeSec / 60,
  }
}

export const parseDepotFromFormValues = ({
  id,
  name,
  location,
  color,
  setupCost,
  setupTime,
}: DepotFormValues): uui.domain.client.rm.Depot => ({
  id,
  name,
  location,
  color: color.slice(1),
  setupCost: setupCost > 0 ? setupCost * 100 : -1,
  setupTimeSec: setupTime > 0 ? setupTime * 60 : -1,
  orderSteps: {},
})
