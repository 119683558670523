import { theme } from '@/styles'
import { styled } from '@mui/material/styles'

const StyledSeparator = styled('hr')({
  border: 0,
  height: 1,
  width: '100%',
  background: theme.colors.$whiteSmoke,
})

export function DropdownListSeparator() {
  return <StyledSeparator />
}
