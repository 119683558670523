import type { FormErrors, FormFields } from '../../formFields'

import { Alert, Button } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFormField } from '@workwave-tidal/form-fairy'

import {
  selectNotificationPlanDetails,
  selectTerritoryNotificationBehavior,
} from '@/features/domain/territory'

import { useIsForeignUser } from '../../hooks/useIsForeignUser'
import { useTexts } from './hooks/useTexts'

export function VoiceAlert() {
  const {
    field: { value },
  } = useFormField<'status', FormFields, FormErrors>('status')

  const territoryNotificationBehavior = useSelector(selectTerritoryNotificationBehavior)
  const isForeignUser = useIsForeignUser()
  const planDetails = useSelector(selectNotificationPlanDetails)
  const texts = useTexts()

  const advancedManagementDisabled = territoryNotificationBehavior !== 'EMAIL_AND_PHONE'
  const gotCommunicationPlan = planDetails.settings.hasSubscription

  const { showLearnMore, severity, description } = useMemo(() => {
    const isOff = value === 'OFF'
    const isAdvanced = value === 'ADVANCED'

    const showLearnMore = isAdvanced && (advancedManagementDisabled || isForeignUser)
    const severity = isOff || (isAdvanced && !advancedManagementDisabled) ? 'info' : 'warning'

    // Exceptions for Advanced users
    if (isAdvanced) {
      // If for the current country is not possible to receive calls
      if (isForeignUser) return { showLearnMore, severity, description: texts.foreign } as const

      if (advancedManagementDisabled) {
        const description = gotCommunicationPlan
          ? // If got a Communication Plan
            texts.advancedDisabledOnTerritory
          : // If got NO Communication Plan
            texts.advancedDisabled

        return { showLearnMore, severity, description } as const
      }
    }

    return { showLearnMore, severity, description: texts[value.toLocaleLowerCase()] } as const
  }, [texts, advancedManagementDisabled, gotCommunicationPlan, isForeignUser, value])

  return (
    <Alert severity={severity}>
      {description}
      {showLearnMore && (
        <>
          <br />
          <Button
            href="https://workwave.force.com/routemanager/s/article/Enhanced-Voice-Communication"
            target="_blank"
          >
            {texts.learnMore}
          </Button>
        </>
      )}
    </Alert>
  )
}
