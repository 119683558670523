import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    notSet: translate({ id: 'global.notSet' }),
    vehicleAvailable: (available: boolean) =>
      translate({
        id: available
          ? 'vehicles.form.exceptions.vehicle.available'
          : 'vehicles.form.exceptions.vehicle.unavailable',
      }),
    startLocation: translate({ id: 'vehicles.form.exceptions.vehicle.startLocation' }),
    endLocation: translate({ id: 'vehicles.form.exceptions.vehicle.endLocation' }),
    workingHours: translate({ id: 'vehicles.form.exceptions.vehicle.workingHours' }),
    workingDayStart: (flexibleStart: boolean) =>
      translate({
        id: flexibleStart
          ? 'vehicles.form.exceptions.vehicle.workingDayStart.flexible'
          : 'vehicles.form.exceptions.vehicle.workingDayStart',
      }),
    workingDayEnd: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayEnd' }),
    workingDayEndInfo: translate({ id: 'vehicles.form.exceptions.vehicle.workingDayEnd.info' }),
    breaks: translate({ id: 'vehicles.form.exceptions.vehicle.breaks' }),
    availableRegions: (count: number, hasDefinedRegions: boolean) => {
      if (!hasDefinedRegions) {
        return translate({ id: 'vehicles.form.exceptions.vehicle.regions.noDefined' })
      }

      if (count === 0) {
        return translate({
          id: 'vehicles.form.exceptions.vehicle.regions.noAccessible',
        })
      }

      return translate({
        id: 'vehicles.form.exceptions.vehicle.regions',
        values: { count },
      })
    },
    collapse: (collapsed: boolean) =>
      translate({ id: collapsed ? 'global.showAll' : 'global.hideAll' }),
    constraints: translate({ id: 'vehicles.form.exceptions.vehicle.constraints' }),
    maxOrders: translate({ id: 'vehicles.form.exceptions.vehicle.maxOrders' }),
    maxStops: translate({ id: 'vehicles.form.exceptions.vehicle.maxStops' }),
    maxLocations: translate({ id: 'vehicles.form.exceptions.vehicle.maxLocations' }),
    max: translate({ id: 'global.max' }),
    min: translate({ id: 'global.min' }),
    maxMileage: translate({
      id: 'vehicles.form.exceptions.vehicle.maxMileage',
      values: { extra: '' },
    }),
    maxDrivingHours: translate({
      id: 'vehicles.form.exceptions.vehicle.maxDrivingHours',
      values: { extra: '' },
    }),
    maxWorkingHours: translate({
      id: 'vehicles.form.exceptions.vehicle.maxWorkingHours',
      values: { extra: '' },
    }),
    minWorkingHoursInfo: translate({ id: 'vehicles.form.exceptions.vehicle.minWorkingHours.info' }),
    speed: translate({ id: 'vehicles.form.exceptions.vehicle.speed' }),
    costs: translate({ id: 'vehicles.form.exceptions.vehicle.costs' }),
    fixedCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.fixed',
      values: { extra: '' },
    }),
    serviceTimeCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.serviceTime',
      values: { extra: '' },
    }),
    drivingTimeCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.drivingTime',
      values: { extra: '' },
    }),
    idleTimeCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.idleTime',
      values: { extra: '' },
    }),
    breakTimeCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.breakTime',
      values: { extra: '' },
    }),
    costMeasure: (metric: boolean) =>
      translate({
        id: metric
          ? 'vehicles.form.exceptions.vehicle.costs.perKm'
          : 'vehicles.form.exceptions.vehicle.costs.perMile',
        values: {
          extra: '',
        },
      }),
    stopCosts: translate({
      id: 'vehicles.form.exceptions.vehicle.costs.perStop',
      values: { extra: '' },
    }),
    notes: translate({ id: 'vehicles.form.exceptions.vehicle.notes' }),
    none: translate({ id: 'global.none' }),
    loads: translate({ id: 'vehicles.form.exceptions.vehicle.loads' }),
    loadsInfo: translate({ id: 'vehicles.form.exceptions.vehicle.loads.info' }),
    tags: translate({ id: 'vehicles.form.exceptions.vehicle.tags' }),
    noRegions: translate({ id: 'vehicles.form.exceptions.vehicle.regions.noAccessible' }),
    preparationTimeSec: translate({ id: 'vehicles.form.exceptions.vehicle.preparationTime' }),
    closeOutTimeSec: translate({ id: 'vehicles.form.exceptions.vehicle.closeOutTime' }),
  }))

  return api
}
