import styled from 'styled-components'

import { StopWatch } from '@/icons'
import { Text } from '@/local/components'
import { buttonReset } from '@/styles'

import { Stack } from '@mui/material'

import { useTexts } from '../useTexts'

interface Props {
  onClick: () => void
}

const ButtonContainer = styled.button`
  ${buttonReset}
`

ButtonContainer.displayName = 'ButtonContainer'

export function ExecutionDetailsButton(props: Props) {
  const texts = useTexts()

  const testid = 'navigoorder-execution-details-button'

  return (
    <ButtonContainer
      onClick={props.onClick}
      data-testid={testid}
      data-trackid={testid}
      className="pendo-navigoorder-execution-details-button"
    >
      <Stack
        direction="column"
        height="auto"
        width={50}
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
      >
        <StopWatch color="$nightRider" size={17} />

        <Text size="$s" color="$nightRider" textAlign="center">
          {texts.executionDetails}
        </Text>
      </Stack>
    </ButtonContainer>
  )
}
