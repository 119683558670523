import { TipsAndUpdates } from '@mui/icons-material'
import { Alert, Stack, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { useModalData } from '../../../hooks/useModalData'
import { useTexts } from '../../../useTexts'

export function InfoCard() {
  const texts = useTexts()
  const data = useModalData()

  const { orderStepStatus, pairedOrderStepStatus } = data

  const showAlert =
    ((orderStepStatus === 'not-executed' || orderStepStatus === 'not-completed') &&
      pairedOrderStepStatus !== 'not-assigned' &&
      pairedOrderStepStatus !== 'in-a-locked-route') ||
    orderStepStatus === 'executed'

  const severity =
    (orderStepStatus === 'not-executed' || orderStepStatus === 'not-completed') &&
    (pairedOrderStepStatus === 'not-executed' || pairedOrderStepStatus === 'not-completed')
      ? 'warning'
      : 'error'

  return (
    <Stack spacing={2} paddingBottom={1}>
      <Typography variant="body1">
        {texts.cardTitle(orderStepStatus, data.orderStep.type, pairedOrderStepStatus)}
      </Typography>

      {pairedOrderStepStatus === 'in-a-locked-route' && (
        <Stack direction="row" spacing={1} alignItems="center">
          <TipsAndUpdates />
          <Typography variant="body2" component="div">
            <FormattedMessage
              id={texts.lockedRouteSuggestion}
              values={{
                b: chunks => (
                  <Typography variant="body2" fontWeight={500} display="inline">
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </Stack>
      )}

      {showAlert && (
        <Alert severity={severity}>
          {texts.cardText(orderStepStatus, data.orderStep.type, pairedOrderStepStatus)}
        </Alert>
      )}
    </Stack>
  )
}
