import type { ActionPermissions } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useIsLoading } from '@/atoms'
import { selectTelematicsDevices } from '@/features/domain/device'
import { selectDrivers } from '@/features/domain/driver'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectGpsTrackingProvider, selectUserConfiguration } from '@/features/domain/user'
import { getDeviceId, getDriver } from '@/server-data'

import { useStylerData } from '../../useStylerData'
import { useVehiclesNavigator } from '../../useVehiclesNavigator'

import { computeBreadcrumbsPermission } from './utils/computeBreadcrumbsPermission'
import { computeCenterOnMapPermission } from './utils/computeCenterOnMapPermission'
import { computeEditPermission } from './utils/computeEditPermission'
import { computeEventsPermission } from './utils/computeEventsPermission'
import { computeReportPermission } from './utils/computeReportPermission'
import { computeSendMessagePermission } from './utils/computeSendMessagePermission'
import { computeStylePermission } from './utils/computeStylePermission'

export function usePermissions(): ActionPermissions {
  const { today, userType } = useSelector(selectUserConfiguration)
  const { currentVehicles } = useVehiclesNavigator()
  const { activeMapStyle } = useStylerData()
  const telematicsDevices = useSelector(selectTelematicsDevices)
  const isLoadingGpsData = useIsLoading('fetchGpsData')
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const calendarRange = useSelector(selectCalendarRange)
  const drivers = useSelector(selectDrivers)

  return useMemo(() => {
    const vehiclesCount = currentVehicles.length
    const isSingleDay = calendarRange.minDate === calendarRange.maxDate
    const isToday = isSingleDay && calendarRange.minDate === today

    // If in a single day, retrieve all the device ids
    const deviceIds = isSingleDay
      ? currentVehicles.reduce<string[]>((acc, vehicle) => {
          const deviceId = getDeviceId(drivers, vehicle, trackingProvider, calendarRange.minDate)

          if (!deviceId) return acc

          acc.push(deviceId)

          return acc
        }, [])
      : undefined

    // Retrieve all the physical device ids
    const physicalDeviceIds = currentVehicles.reduce<string[]>((acc, uv) => {
      if (!uv.isPhysicalDevice) return acc

      acc.push(uv.device.deviceId)
      return acc
    }, [])

    // If in a single day, and a single vehicle, retrieve the driver id
    const driver =
      isSingleDay && vehiclesCount === 1
        ? getDriver(drivers, currentVehicles[0], calendarRange.minDate)
        : undefined

    const breadcrumbsParams =
      trackingProvider === 'telematics'
        ? {
            trackingProvider,
            vehiclesCount,
            isLoadingGpsData,
            deviceIds,
            devices: telematicsDevices,
          }
        : {
            trackingProvider,
            isLoadingGpsData,
            vehiclesCount,
            deviceIds,
          }

    return {
      sendMessage: computeSendMessagePermission(
        vehiclesCount,
        isToday,
        isLoadingGpsData,
        trackingProvider,
        driver,
        deviceIds,
      ),
      breadcrumbs: computeBreadcrumbsPermission(breadcrumbsParams),
      centerOnMap: computeCenterOnMapPermission(
        isToday,
        activeMapStyle,
        isLoadingGpsData,
        deviceIds,
      ),
      report: computeReportPermission(
        physicalDeviceIds,
        isLoadingGpsData,
        userType,
        trackingProvider,
        deviceIds,
      ),
      events: computeEventsPermission(vehiclesCount, isLoadingGpsData, trackingProvider, deviceIds),
      style: computeStylePermission(isToday, isLoadingGpsData, deviceIds),
      edit: computeEditPermission(vehiclesCount, isLoadingGpsData),
    }
  }, [
    isLoadingGpsData,
    trackingProvider,
    currentVehicles,
    activeMapStyle,
    calendarRange,
    userType,
    drivers,
    today,
  ])
}
