import {
  type DurationFieldValue,
  DurationFormat,
  DurationInput as DurationInputBase,
  type DurationInputProps as DurationInputBaseProps,
} from './DurationInputBase'

import {
  type FormFieldDescriptor,
  type NarrowFieldsByValueType,
  type TidalFormBridgeOptions,
  type TidalFormState,
  useTidalFormBridge,
} from '@workwave-tidal/core/form'

type AcceptedDurationInputProps = Omit<
  DurationInputBaseProps,
  'value' | 'name' | 'label' | 'onChange' | 'error' | 'helperText' | 'format'
>

export type DurationInputValue = DurationFieldValue
const defaultComponentValue = null

export type DurationInputProps<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DurationInputValue,
> = {
  placeholder?: string
  durationFormat?: DurationFormat
  durationProps?: Partial<AcceptedDurationInputProps>
} & TidalFormBridgeOptions<DurationInputValue, TargetField, State, AcceptedFormValue>

export function DurationInput<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DurationInputValue,
>(props: DurationInputProps<TargetField, State, AcceptedFormValue>) {
  const {
    placeholder,
    durationProps,
    initialValue = defaultComponentValue as FormFieldDescriptor<State>[TargetField]['value'],
    validateOn = 'change',
    debounce = null,
    durationFormat = 'hh:mm',
    ...bridgeOptions
  } = props

  const {
    config: { label, name, testId = 'durationInput-root' },
    fieldInfo: {
      errorOrHelperText,
      field: { visible },
      fieldDisabled,
      fieldHasError,
      onBlur,
      onChange,
      onFocus,
      showErrorAccent,
      value,
    },
  } = useTidalFormBridge<DurationInputValue, TargetField, State, AcceptedFormValue>({
    ...bridgeOptions,
    debounce,
    validateOn,
    initialValue,
  })

  if (!visible) return null

  return (
    <DurationInputBase
      label={label}
      format={durationFormat}
      slotProps={{
        textfield: {
          onBlur,
          onFocus,
          placeholder,
        },
      }}
      testId={testId}
      disabled={fieldDisabled}
      error={showErrorAccent && fieldHasError}
      helperText={errorOrHelperText}
      name={name}
      onChange={onChange}
      value={value}
      data-tidal-form-field-name={bridgeOptions.name}
    />
  )
}
