import type { FormFields, FormErrors } from './formFields'
import type { NotificationFormValues } from './types'
import type { NotificationFormType } from '@/notificationForms'

import { Box } from '@mui/material'

import { useFormField } from '@workwave-tidal/form-fairy'
import { FormHeader, Form } from '@workwave-tidal/form-ui/layout'

import { useStopEditingOnUnmount } from '@/hooks'

import { NotificationBackupSubject } from './components/fields/NotificationBackupSubject'
import { UnsubscribeInstructions } from './components/fields/UnsubscribeInstructions'
import { NotificationBackup } from './components/fields/NotificationBackup'
import { NotificationTime } from './components/fields/NotificationTime'
import { NotificationWay } from './components/fields/NotificationWay'
import { TrackingLink } from './components/fields/TrackingLink'
import { FormFooter } from './components/FormFooter'
import { Tolerance } from './components/fields/Tolerance'
import { Threshold } from './components/fields/Threshold'
import { EtaRange } from './components/fields/EtaRange'
import { Subject } from './components/fields/Subject'
import { Message } from './components/fields/Message'

import { useBackupNotification } from './hooks/useBackupNotification'
import { useNotificationWay } from './hooks/useNotificationWay'
import { useTemplateCustom } from './hooks/useTemplateCustom'
import { useNotificationBehavior } from './hooks/useNotificationBehavior'

interface Props {
  onSubmit: (formValues: Readonly<NotificationFormValues>) => Promise<boolean>
  notification: NotificationFormType
  initHook: () => void
  subtitle: string
  cancel: () => void
  title: string
}

export function NotificationForm(props: Props) {
  const { title, subtitle, initHook, cancel, onSubmit, notification } = props

  const {
    field: { value: notificationWay },
  } = useFormField<'notificationWay', FormFields, FormErrors>('notificationWay', {
    fieldsOfInterest: ['value'],
  })

  // Retrieves notification behavior to check which notification ways are allowed
  const notificationBehavior = useNotificationBehavior()

  // Manage the visibility of the fields related to the Notification Type value (like subject, backup, etc...)
  useNotificationWay()

  // Manage the visibility of the fields related to the Backup Notification value (Backup Subject)
  useBackupNotification()

  // Manage the visibility of the fields related to the Text value (ETA Range)
  useTemplateCustom()

  // Initialize the visibility of the fields based on the form type
  initHook()

  // Stop editing the form when the component unmounts
  useStopEditingOnUnmount()

  return (
    <Box width={360} data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        fieldsPadding="0 32px"
        fieldsSpacing={4}
        testId={`${notification}-form`}
        header={<FormHeader title={title} subtitle={subtitle} />}
        footer={
          <FormFooter<FormFields, FormErrors>
            notification={notification}
            onSubmit={onSubmit}
            cancel={cancel}
          />
        }
      >
        <NotificationTime />

        <Tolerance />

        <Threshold />

        <NotificationWay notificationBehavior={notificationBehavior} />

        <Subject />

        <Message notification={notification} notificationWay={notificationWay} />

        <EtaRange />

        <TrackingLink />

        <UnsubscribeInstructions />

        <NotificationBackup />

        <NotificationBackupSubject />
      </Form>
    </Box>
  )
}
