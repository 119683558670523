import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  DropdownMenu,
  DropdownMenuItem,
  MenuTrigger,
  SingleDropdownMenuItem,
} from '@/components/DropdownMenu'
import { type ExtendedPrivilege } from '@/hooks'

import { CollapseText } from '@/components/CollapseText'
import { HasPrivileges } from '@/components/HasPrivileges'
import { Tooltip } from '@/components/primitives/Tooltip'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { TransactionInProgressTooltip } from '@/components/smartUtils/plan/TransactionInProgressTooltip'
import { selectTotalTelematicsDemoEnable } from '@/features/domain/account'
import { BusinessUnit } from '@/icons'

import { selectData } from '../../cursors/selectData'

import { ChangeTerritoryMenuItem } from './components/ChangeTerritoryMenuItem'
import { useActions } from './hooks/useActions'
import { useCanSeeTerritoryMenu } from './hooks/useCanSeeTerritoryMenu'
import { useCanSeeTerritorySettings } from './hooks/useCanSeeTerritorySettings'
import { useTerritoryModals } from './hooks/useTerritoryModals'
import { useTexts } from './useTexts'

interface Props {
  disabled?: boolean
}
const adminUserType: uui.domain.rm.RouteManagerUserType[] = ['admin']
const devOrDemoPrivilege: ExtendedPrivilege[] = ['demo', 'dev']

export function RmDropdown(props: Props) {
  const { disabled } = props

  const [open, setOpen] = useState(false)

  const telematicsDemoEnabled = useSelector(selectTotalTelematicsDemoEnable)
  const { territoryName } = useSelector(selectData)
  const texts = useTexts()

  const { navigateToTerritorySettings } = useActions()
  const {
    showStartTelematicsDemo,
    showImportDemoTerritory,
    showImportNewTerritory,
    showDeleteTerritory,
    showRenameTerritory,
    showTimeShiftPlan,
    modals,
  } = useTerritoryModals()

  const canSeeTerritorySettings = useCanSeeTerritorySettings()
  const canSeeTerritoryMenu = useCanSeeTerritoryMenu(devOrDemoPrivilege) || canSeeTerritorySettings

  const handleTimeShiftPlan = useCallback(() => {
    showTimeShiftPlan()
    setOpen(false)
  }, [showTimeShiftPlan])

  const handleDeleteTerritory = useCallback(() => {
    showDeleteTerritory()
    setOpen(false)
  }, [showDeleteTerritory])

  const handleRenameTerritory = useCallback(() => {
    showRenameTerritory()
    setOpen(false)
  }, [showRenameTerritory])

  const handleImportDemoTerritory = useCallback(() => {
    showImportDemoTerritory()
    setOpen(false)
  }, [showImportDemoTerritory])

  const handleImportNewTerritory = useCallback(() => {
    showImportNewTerritory()
    setOpen(false)
  }, [showImportNewTerritory])

  const handleNavigateToTerritorySettings = useCallback(() => {
    navigateToTerritorySettings()
    setOpen(false)
  }, [navigateToTerritorySettings])

  const handleStartTelematicsDemo = useCallback(() => {
    showStartTelematicsDemo()
    setOpen(false)
  }, [showStartTelematicsDemo])

  return (
    <>
      <>
        {canSeeTerritoryMenu ? (
          <DropdownMenu
            autoWidth
            open={open}
            setOpen={setOpen}
            disabled={props.disabled}
            triggerTestid="header__territory-button"
            trigger={
              <Tooltip
                placement="bottom"
                title={texts.tooltipRm(territoryName)}
                disabled={disabled}
              >
                <MenuTrigger
                  menuOpen={open}
                  disabled={disabled}
                  className="pendo-bluebar__territory-menu"
                  testid="rm-territory-dropdown"
                  Icon={<BusinessUnit size={16} marginRight={10} />}
                  showDownArrow={false}
                >
                  <CollapseText maxWidth={150}>{territoryName}</CollapseText>
                </MenuTrigger>
              </Tooltip>
            }
          >
            <div data-testid="territoryDropdown__container">
              <ChangeTerritoryMenuItem
                setOpen={setOpen}
                hasTerritoryMenuOptions={canSeeTerritorySettings}
              />

              <HasPrivileges accountPrivileges={devOrDemoPrivilege} userTypes={adminUserType}>
                <ReadOnlyTooltip
                  placement="left"
                  render={preventEditing => (
                    <DropdownMenuItem
                      onClick={handleRenameTerritory}
                      disabled={preventEditing}
                      className="pendo-bluebar__territory-rename-territory-button"
                      testid="rename-territory-button"
                    >
                      {texts.renameTerritory}
                    </DropdownMenuItem>
                  )}
                />
              </HasPrivileges>

              <HasPrivileges accountPrivileges={devOrDemoPrivilege} userTypes={adminUserType}>
                <TransactionInProgressTooltip
                  placement="left"
                  render={preventEditing => (
                    <DropdownMenuItem
                      disabled={preventEditing}
                      onClick={handleImportDemoTerritory}
                      className="pendo-bluebar__territory-import-demo-territory-button"
                      testid="import-demo-territory-button"
                    >
                      {texts.importDemoTerritory}
                    </DropdownMenuItem>
                  )}
                />
              </HasPrivileges>

              <HasPrivileges accountPrivileges={devOrDemoPrivilege} userTypes={adminUserType}>
                <TransactionInProgressTooltip
                  placement="left"
                  render={preventEditing => (
                    <DropdownMenuItem
                      disabled={preventEditing}
                      onClick={handleImportNewTerritory}
                      className="pendo-bluebar__territory-import-new-territory-button"
                      testid="import-new-territory-button"
                    >
                      {texts.importNewTerritory}
                    </DropdownMenuItem>
                  )}
                />
              </HasPrivileges>

              <HasPrivileges accountPrivileges={devOrDemoPrivilege} userTypes={adminUserType}>
                <ReadOnlyTooltip
                  render={disableEdit => (
                    <DropdownMenuItem
                      onClick={handleTimeShiftPlan}
                      disabled={disableEdit}
                      className="pendo-bluebar__territory-time-shift-plan-button"
                      testid="timeshift-plan"
                    >
                      {texts.timeShiftPlan}
                    </DropdownMenuItem>
                  )}
                />
              </HasPrivileges>

              {telematicsDemoEnabled && (
                <HasPrivileges accountPrivileges={devOrDemoPrivilege}>
                  <ReadOnlyTooltip
                    render={disableEdit => (
                      <DropdownMenuItem
                        onClick={handleStartTelematicsDemo}
                        disabled={disableEdit}
                        className="pendo-bluebar__territory-start-telematics-demo-button"
                        testid="start-telematics-demo-button"
                      >
                        {texts.startTelematicsDemo}
                      </DropdownMenuItem>
                    )}
                  />
                </HasPrivileges>
              )}

              {canSeeTerritorySettings && (
                <DropdownMenuItem
                  external
                  className="pendo-bluebar__territory-territory-settings-button"
                  testid="territory-settings-button"
                  onClick={handleNavigateToTerritorySettings}
                >
                  {texts.territorySettings}
                </DropdownMenuItem>
              )}

              <HasPrivileges accountPrivileges={devOrDemoPrivilege} userTypes={adminUserType}>
                <TransactionInProgressTooltip
                  placement="left"
                  render={preventEditing => (
                    <DropdownMenuItem
                      warn
                      disabled={preventEditing}
                      onClick={handleDeleteTerritory}
                      className="pendo-bluebar__territory-delete-territory-button"
                      testid="territory-delete-button"
                    >
                      {texts.deleteTerritory}
                    </DropdownMenuItem>
                  )}
                />
              </HasPrivileges>
            </div>
          </DropdownMenu>
        ) : (
          <Tooltip placement="bottom" title={texts.tooltipRm(territoryName)} disabled={disabled}>
            <SingleDropdownMenuItem
              testId="rm-single-territory"
              icon={<BusinessUnit size={16} marginRight={10} />}
              text={territoryName}
              maxWidth={150}
            />
          </Tooltip>
        )}
      </>
      {modals}
    </>
  )
}
