import type { Option } from '@workwave-tidal/form-ui/RadioGroup'
import type { FormErrors, FormFields } from '../formFields'

import { useRef } from 'react'
import { Stack, Typography } from '@mui/material'
import { RadioGroup } from '@workwave-tidal/form-ui'

import { useTexts } from './useTexts'

type Options = Option<'yes' | 'no'>[]

export function StartEndRoute() {
  const texts = useTexts()

  const rOptions = useRef<Options>([
    {
      value: 'yes',
      label: texts.yes,
    },
    {
      value: 'no',
      label: texts.no,
    },
  ])

  return (
    <Stack sx={{ gap: 1 }}>
      <div>
        <Typography variant="body1">{texts.header.title}</Typography>
        <Typography variant="caption" color="textSecondary">
          {texts.header.description}
        </Typography>
      </div>

      <RadioGroup<'collectStartEndRoute', string, FormFields, FormErrors>
        label={texts.collect}
        name="collectStartEndRoute"
        options={rOptions.current}
        validateOn="blur change"
        testId="collect-start-end-route"
      />
    </Stack>
  )
}
