import { Outlet } from 'react-router'

import { Stack } from '@mui/material'

export function Routing() {
  return (
    <Stack direction="row" height="100%" width="100%">
      <Outlet />
    </Stack>
  )
}
