import { collectListOptions } from './entities/collectListOptions'
import { collectMapConfig } from './entities/collectMapConfig'
import { collectMapStyles } from './entities/collectMapStyles'
import { collectRoutingLayout } from './entities/collectRoutingLayout'
import { collectSchedulerPreferences } from './entities/collectSchedulerPreferences'

/**
 * Collects data from local storage to migrate to the new UI data structure.
 * @returns An object with the data to migrate and a function to clear the local storage.
 */
export async function collectDataToMigrate(gpsOnly: boolean) {
  const { rawData, clearLocalStorage } = await getDataAndCleanFunction(gpsOnly)

  const dataWithValues = filterUndefinedValues(rawData)

  const updatesRequired = Object.keys(dataWithValues).length > 0

  const data = {
    ...dataWithValues,
    version: 2 as const,
  }

  return {
    data,
    updatesRequired,
    clearLocalStorage,
  }
}

function filterUndefinedValues(obj: Record<string, any>) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }

    return acc
  }, {})
}

async function getDataAndCleanFunction(gpsOnly: boolean) {
  return gpsOnly ? await getGpsOnlyData() : await getRmData()
}

async function getRmData() {
  const [schedulerPreferences, clearSchedulerPreferences] = collectSchedulerPreferences()
  const [routingLayout, clearRoutingLayout] = collectRoutingLayout()
  const [mapConfig, clearMapConfig] = collectMapConfig()

  const { mapStyles, clearMapStyles } = await collectMapStyles()
  const { listOptions, clearListOptions } = await collectListOptions()

  const rawData = { mapConfig, mapStyles, listOptions, schedulerPreferences, routingLayout }
  const clearLocalStorage = () => {
    clearMapConfig()
    clearMapStyles()
    clearListOptions()
    clearRoutingLayout()
    clearSchedulerPreferences()
  }

  return { rawData, clearLocalStorage }
}

async function getGpsOnlyData() {
  const [mapConfig, clearMapConfig] = collectMapConfig()
  const { mapStyles, clearMapStyles } = await collectMapStyles()
  const { listOptions, clearListOptions } = await collectListOptions()

  const rawData = { mapConfig, mapStyles, listOptions }
  const clearLocalStorage = () => {
    clearMapConfig()
    clearMapStyles()
    clearListOptions()
  }

  return { rawData, clearLocalStorage }
}
