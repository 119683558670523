import type { Notifications } from '../../../../../types'
import { useCallback } from 'react'
import { Box } from '@mui/material'
import { Thick } from '@/icons'
import { useTexts } from './useTexts'
import { StyledButton, StyledContainer, Title, Subtitle, IconContainer } from './hooks/useStyles'

type Props = {
  setQuery: (notification: Notifications) => void
  details: { subtitle: string; status?: string; enabled: boolean }
  notification: Notifications
  disabled: boolean
  active: boolean
  testId?: string
}

export function ListItem(props: Props) {
  const { notification, active, details, setQuery, disabled, testId } = props
  const texts = useTexts()

  const onClick = useCallback(() => setQuery(notification), [setQuery, notification])
  const title = texts.notificationTitle(notification)

  return (
    <StyledButton
      $disabled={disabled}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
      data-trackid={testId}
    >
      <StyledContainer $active={active}>
        <Box display="flex" flexDirection="column">
          <Title>{title}</Title>
          <Subtitle $active={active}>{details.subtitle}</Subtitle>
          {details.status && <Subtitle $active={active}>{details.status}</Subtitle>}
        </Box>
        <IconContainer $active={active}>{details.enabled && <Thick size={14} />}</IconContainer>
      </StyledContainer>
    </StyledButton>
  )
}
