import { ReactElement } from 'react'
import { styled } from '@mui/material/styles'

interface Props {
  children: ReactElement | ReactElement[]
}

const Container = styled('div')(({ theme }) => ({
  // Spacer that allow the label to not be cut (outlined label will overflow the container)
  paddingTop: '5px',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  // this rule will remove the spacing in the last field
  // this because of the "scrolling" footer that requires some padding
  // if the last field has this padding too the result will be doubling the space
  '& > *:last-child > *': {
    paddingBottom: theme.spacing(0),
  },
}))

export function FieldsContainer(props: Props) {
  return <Container>{props.children}</Container>
}
