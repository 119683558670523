import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { selectApprovedVersionRoutes } from '@/features/domain/scheduler'
import { Text } from '@/local/components'

import { Delta } from './Delta'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  routeId: string
  orderStepId: string
  stepStartSec: number
}

export function PlannedEtaBlock(props: Props) {
  const { routeId, orderStepId, stepStartSec } = props

  const texts = useTexts()
  const routes = useSelector(selectApprovedVersionRoutes)

  const plannedArrival = useMemo(() => {
    return routes[routeId]?.steps.find(s => s.id === orderStepId)?.startSec
  }, [routeId, orderStepId, routes])

  return (
    <Stack
      direction="column"
      height="100%"
      width="auto"
      justifyContent="space-between"
      data-testid="PlannedEtaBlock"
      flexShrink={0}
    >
      <Stack direction="row" width="100%" height="auto">
        <Text size="$p3">{`${texts.plannedTime}:`}&nbsp;</Text>

        <Text size="$p3" weight="$semiBold">
          {plannedArrival ? (
            <ConvertTime seconds={plannedArrival} showSeconds={false} />
          ) : (
            texts.none
          )}
        </Text>
      </Stack>

      <Stack direction="row" width="100%" height="auto">
        <Text size="$p3">{`${texts.eta}:`}&nbsp;</Text>

        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={stepStartSec} showSeconds={false} />
          &nbsp;
        </Text>

        <Delta planned={plannedArrival || stepStartSec} estimated={stepStartSec} />
      </Stack>
    </Stack>
  )
}
