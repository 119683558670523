import type { FormErrors, FormFields } from '../formFields'
import { useMemo } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'

export function useShowPhoneAlert() {
  const { field: status } = useFormField<'status', FormFields, FormErrors>('status')
  const { field: callbackConfigStatus } = useFormField<
    'callbackConfigStatus',
    FormFields,
    FormErrors
  >('callbackConfigStatus')
  const { field: automatedMessageEnabled } = useFormField<
    'automatedMessageEnabled',
    FormFields,
    FormErrors
  >('automatedMessageEnabled')

  return useMemo(
    () =>
      status.value === 'ADVANCED' &&
      callbackConfigStatus.value === 'OFF' &&
      automatedMessageEnabled.value === 'ON',
    [automatedMessageEnabled.value, callbackConfigStatus.value, status.value],
  )
}
