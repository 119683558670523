import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'

import { useTexts } from '../useTexts'

const companyNameMaxLength = 30

export const createValidateCompanyName =
  (texts: ReturnType<typeof useTexts>): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const field = formApi.getField('companyName')

    const error: FormError<keyof FormFields> = {
      id: 'companyName-max-length',
      field: 'companyName',
      message: texts.companyNameMaxLength(companyNameMaxLength),
    }

    const invalid = field.value.length > companyNameMaxLength

    return {
      'companyName-max-length': invalid ? error : null,
    }
  }
