import { getDataFromLocalStorage } from '../utils'

export function collectMapConfig() {
  const mapConfig = getDataFromLocalStorage(mapConfigStorageId)

  // apply defaults since showTraffic is not set programmatically
  if (mapConfig !== undefined && typeof mapConfig.showTraffic !== 'boolean') {
    mapConfig.showTraffic = false
  }

  return [
    mapConfig,
    () => {
      localStorage.removeItem(mapConfigStorageId)
    },
  ]
}

const mapConfigStorageId = 'routemanager/v1/map/config'
