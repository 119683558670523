import { Box, CircularProgress, Stack } from '@mui/material'

import { Polyline, PolylineHide } from '@/icons'
import { Text } from '@/local/components'

import { NavigoIconButton } from '../../../../../../../../elements/NavigoIconButton'

import { ButtonsContainer } from '../ButtonsContainer'
import { useTexts } from '../useTexts'

import { useActiveRoutePathMapStyle } from './hooks/useActiveRoutePathMapStyle'
import { useRoutePathActions } from './hooks/useRoutePathActions'

interface Props {
  routeIds: string[]
}

export function RoutePathStyler(props: Props) {
  const { routeIds } = props

  const { methodInExecution, actions } = useRoutePathActions(routeIds)
  const activeRoutePathMapStyle = useActiveRoutePathMapStyle(routeIds)
  const texts = useTexts()

  return (
    <Box
      data-trackid="navigo-routes-mapStyles-routePathStyle"
      data-testid="navigo-routes-mapStyles-routePathStyle"
      height="auto"
      width="100%"
      flex="0 1 auto"
    >
      <Stack direction="row" alignItems="center">
        <Stack
          direction="column"
          justifyContent="space-between"
          height="100%"
          width="auto"
          marginRight={3}
          marginLeft={3}
        >
          <Text size="$p3" weight="$semiBold">
            {texts.polylineTitle}
          </Text>

          <Text size="$p3">{texts.polylineSubtitle}</Text>
        </Stack>

        <ButtonsContainer>
          <NavigoIconButton
            Icon={
              methodInExecution === 'showRoutePaths' ? (
                <CircularProgress size={21} color="primary" />
              ) : (
                <Polyline size={20} color="$nightRider" />
              )
            }
            testid="navigo-routes-mapStyles-routePathStyle-on"
            active={activeRoutePathMapStyle === 'on'}
            onClick={actions.showRoutePaths}
            text={texts.polylineShow}
          />

          <NavigoIconButton
            testid="navigo-routes-mapStyles-routePathStyle-off"
            Icon={
              methodInExecution === 'hideRoutePaths' ? (
                <CircularProgress size={21} color="primary" />
              ) : (
                <PolylineHide size={20} color="$nightRider" />
              )
            }
            active={activeRoutePathMapStyle === 'off'}
            onClick={actions.hideRoutePaths}
            text={texts.polylineHide}
          />
        </ButtonsContainer>
      </Stack>
    </Box>
  )
}
