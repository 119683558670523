import { useRef } from 'react'

import { RenderFrom } from '@/components/Navigo/components/RenderFrom'
import { useComponentSize } from '@/local/hooks'

import { BreaksBlock } from './components/BreaksBlock'
import { WorkingHoursBlock } from './components/WorkingHoursBlock'

import { Stack } from '@mui/material'
import { useVehicleSettings } from './hooks/useVehicleSettings'

interface Props {
  routeInfo: uui.domain.client.rm.SchedulerRowRouteOrUnavailable
}

export function VehicleStats(props: Props) {
  const { routeInfo } = props
  const settings = useVehicleSettings(routeInfo.vehicleId, routeInfo.date)
  const { breaks, timeWindow } = settings

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  return (
    <Stack
      direction="row"
      height="100%"
      width="100%"
      data-trackid="navigo-routes-overview-details-single-vehicleStats"
      data-testid="navigo-routes-overview-details-single-vehicleStats"
      ref={ref}
    >
      <RenderFrom width={width} renderFrom={140}>
        <WorkingHoursBlock timeWindow={timeWindow} />
      </RenderFrom>

      {breaks.length > 0 && (
        <RenderFrom width={width} renderFrom={140}>
          <BreaksBlock breaks={breaks} />
        </RenderFrom>
      )}
    </Stack>
  )
}
