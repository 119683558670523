import { FileCopy } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useIsLoading } from '@/atoms'
import { useCopyOrdersModal } from '@/components/modals/CopyOrdersModal'
import { Container, Separator } from '@/components/primitives/ControlStrip'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Button, IconButton } from '@/components/primitives/buttons'
import { Redo, Scheduler, Undo } from '@/icons'
import { theme } from '@/styles'

import { useSimulationControls } from '../hooks/useSimulationControls'
import { useTexts } from '../useTexts'

export const StyledFileCopy = styled(FileCopy)({
  color: theme.colors.$pureWhite,
  fontSize: 15,
})

export function SimulationControls() {
  const { show: showCopyOrdersModal, Modal: CopyOrdersModal } = useCopyOrdersModal()
  const texts = useTexts()
  const { handleUndo, handleRedo, disableUndo, disableRedo, disableCopy, handleOpenOperations } =
    useSimulationControls()

  const isGotoPrevLoading = useIsLoading('goToPreviousRevision')
  const isGotoNextLoading = useIsLoading('goToNextRevision')

  const copyToOpsTestId = 'footer-simulations-copy-to-operations'
  const undoTestId = 'footer-simulations-undo'
  const redoTestId = 'footer-simulations-redo'
  const operationsTestId = 'footer-simulations-operations'

  return (
    <Container>
      <Tooltip
        title={disableCopy ? texts.emptyPlanTitle : texts.copyToOperations}
        subtitle={disableCopy ? texts.emptyPlanSubtitle : undefined}
        placement="top"
      >
        <IconButton
          onClick={showCopyOrdersModal}
          disabled={disableCopy}
          data-testid={copyToOpsTestId}
          data-trackid={copyToOpsTestId}
        >
          <StyledFileCopy color={disableCopy ? 'disabled' : undefined} />
        </IconButton>
      </Tooltip>

      <Separator />

      <Tooltip
        placement="top"
        title={texts.undo}
        subtitle={disableUndo ? texts.undoDisabled : undefined}
      >
        {isGotoPrevLoading ? (
          <CircularProgress size={16} color="inherit" sx={{ marginLeft: 2, marginRight: 2 }} />
        ) : (
          <IconButton
            data-testid={undoTestId}
            data-trackid={undoTestId}
            disabled={disableUndo}
            onClick={handleUndo}
          >
            <Undo size={14} color="$pureWhite" />
          </IconButton>
        )}
      </Tooltip>

      <Tooltip
        placement="top"
        title={texts.redo}
        subtitle={disableRedo ? texts.redoDisabled : undefined}
      >
        {isGotoNextLoading ? (
          <CircularProgress size={16} color="inherit" sx={{ marginLeft: 2, marginRight: 2 }} />
        ) : (
          <IconButton
            data-testid={redoTestId}
            data-trackid={redoTestId}
            disabled={disableRedo}
            onClick={handleRedo}
          >
            <Redo size={14} color="$pureWhite" />
          </IconButton>
        )}
      </Tooltip>

      <Separator />
      <Button
        autoWidth={true}
        preset="footerButton"
        color="$pureWhite"
        Icon={<Scheduler size={14} color="$pureWhite" />}
        onClick={handleOpenOperations}
        paddingLeft={16}
        paddingRight={16}
        data-testid={operationsTestId}
        data-trackid={operationsTestId}
      >
        {texts.openOperations}
      </Button>

      <CopyOrdersModal />
    </Container>
  )
}
