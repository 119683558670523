import { Box, Button } from '@mui/material'

import { useAutoScroll } from '@/atoms'
import { LayoutComposer, ListFooter, NoEntries, NoResults } from '@/components/List'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { ComponentSize, FormColumn, ListOverlayEditCover } from '@/components/layout'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { type TabValues } from '../typings'

import { Header } from './Header'
import { itemHeight, useOrderListItem } from './Item'
import { useTexts } from './hooks/useTexts'
import { useDataForList } from './useDataForList'

type Props = {
  orders: Record<string, uui.domain.client.rm.ExtendedOrderStep>
  selection: workwave.DeepReadonly<string[]>
  editing: boolean
  tab: TabValues
}

const getItemHeight = () => itemHeight

export function List(props: Props) {
  const { tab, orders, selection, editing } = props
  const {
    routes,
    onSelect,
    structure,
    onSelectAll,
    onDoubleClick,
    onClick,
    userConfiguration,
    workingDayStartSec,
    lastSelectedOrderStep,
  } = useDataForList(tab)

  useAutoScroll('orderSteps', lastSelectedOrderStep)

  const Item = useOrderListItem({
    userConfiguration,
    workingDayStartSec,
    routes,
  })

  const texts = useTexts()

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Box width="100%" height="100%" flex="0 1 auto" position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={
            <NoEntries
              title={texts.noEntries.title}
              description={texts.noEntries.description}
              action={
                <ReadOnlyTooltip
                  render={preventEditing => (
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={onClick}
                      disabled={preventEditing || editing}
                      data-testid="no-entries-add-item"
                      data-trackid="no-entries-add-item"
                    >
                      {texts.noEntries.action}
                    </Button>
                  )}
                />
              }
            />
          }
          list={
            <ComponentSize
              render={size => {
                // TODO: reset the scroll when the list options change
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={orders}
                      structure={structure}
                      listApiCategory="orderSteps"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      selectedItemIds={selection}
                      onSelect={onSelect}
                      onDoubleClick={onDoubleClick}
                      buffer={100}
                    />
                    {editing && <ListOverlayEditCover />}
                  </>
                )
              }}
            />
          }
        />
      </Box>

      {multiSelect && (
        <ListFooter
          label={texts.selectBtn(allSelected)}
          onToggleSelection={onSelectAll}
          checked={allSelected}
        />
      )}
    </FormColumn>
  )
}
