import type { ReactElement } from 'react'
import type { TransactionInProgressReason } from './hooks/useTransactionInProgressTooltipData'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useTransactionInProgressTooltipData } from './hooks/useTransactionInProgressTooltipData'

type Props = {
  render: (preventEditing: boolean, mode: TransactionInProgressReason) => ReactElement

  // Tooltip props
  placement?: 'top' | 'bottom' | 'left' | 'right'

  /**
   * The component accepts a `subtitle` string to enrich the "transaction in progress" message for the user.
   * The title is not being exposed since should be always the same for every component and there's no custom conditions.
   */
  subtitle?: string
}

/**
 * Connect an edit action to the read-only providers, and render a tooltip that tell the user
 * why the edit action can't be performed. This is a custom version of the <ReadOnlyTooltip /> component
 *
 * @example
 *
 * <TransactionInProgressTooltip
 *   render={preventEditing => (
 *     <button disabled={preventEditing} >
 *       Edit
 *     </button>
 *   )}
 * />
 */
export function TransactionInProgressTooltip(props: Props) {
  const { placement = 'top', render, subtitle } = props

  const { mode, preventEditing, title } = useTransactionInProgressTooltipData()

  return (
    <Tooltip
      // The tooltip is visible (enabled) when the users can't perform the edit action.
      // If the users can edit, the tooltip is hidden (disabled)
      disabled={!preventEditing}
      subtitle={subtitle}
      title={title}
      // Tooltip props
      placement={placement}
    >
      {render(preventEditing, mode)}
    </Tooltip>
  )
}
