import { type FormControlLabelProps } from '@mui/material/FormControlLabel'
import {
  type FormFieldDescriptor,
  type NarrowFieldsByValueType,
  type TidalFormBridgeOptions,
  type TidalFormState,
  useTidalFormBridge,
} from '@workwave-tidal/core/form'
import { useConnectDrawRoadSegmentField } from './hooks/useConnectDrawRoadSegmentField'

export interface DrawRoadSegmentProps {
  id: uui.domain.ui.map.EditableRoadSegment['id']
  type: 'blocked' | 'delayed' | 'disabled'
  initialCoords?: DrawRoadSegmentFieldValue
}

export type DrawRoadSegmentFieldValue =
  | {
      start: uui.domain.LatLng
      end: uui.domain.LatLng
    }
  | undefined
const defaultComponentValue: DrawRoadSegmentFieldValue = undefined

export type DrawRoadSegmentFieldProps<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DrawRoadSegmentFieldValue,
> = {
  drawRoadSegmentProps: DrawRoadSegmentProps
  formControlLabelProps?: Partial<FormControlLabelProps>
} & TidalFormBridgeOptions<DrawRoadSegmentFieldValue, TargetField, State, AcceptedFormValue>

export function DrawRoadSegmentField<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DrawRoadSegmentFieldValue,
>(props: DrawRoadSegmentFieldProps<TargetField, State, AcceptedFormValue>) {
  const {
    drawRoadSegmentProps: { id, type, initialCoords },
    formControlLabelProps = {},
    initialValue = defaultComponentValue as FormFieldDescriptor<State>[TargetField]['value'],
    validateOn = 'change',
    ...bridgeOptions
  } = props

  const {
    fieldInfo: { onChange },
  } = useTidalFormBridge<DrawRoadSegmentFieldValue, TargetField, State, AcceptedFormValue>({
    ...bridgeOptions,
    validateOn,
    initialValue,
  })

  // connect and initialize to the EditableRoadSegment atom!
  useConnectDrawRoadSegmentField(onChange, id, type, initialCoords)

  return null
}
