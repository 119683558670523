import { Dropdown } from '@/components/Dropdown'
import { H4, WarningCard } from '@/local/components'

import { Box, Stack } from '@mui/material'

import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useTerritoryDropdownItems } from '../hooks/useTerritoryDropdownItems'
import { useWarningCardData } from '../hooks/useWarningCardData'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    invalid,
    data: { territories, id },
  } = useController()

  const dropdownItems = useTerritoryDropdownItems(territories, id)
  const { title, description, preset, Icon } = useWarningCardData(territories[id])

  return (
    <Stack marginTop={4} width="100%" minHeight={200}>
      <Box mb={2} width="100%">
        <H4 color="$paleSky" uppercase>
          {texts.territoryDropdownTitle}
        </H4>
      </Box>

      <Box mb={4} width="100%">
        <Stack direction="row" alignItems="center">
          <Box width="100%" height="auto" flex="0 1 auto">
            <Dropdown
              options={dropdownItems}
              onChange={handlers.onTerritoryChange}
              disabled={!dropdownItems || invalid}
              maxListHeight={180}
            />
          </Box>
        </Stack>
      </Box>
      <Box width="100%">
        <WarningCard Icon={Icon} title={title} preset={preset} description={description} />
      </Box>
    </Stack>
  )
}
