import type { TextFieldProps } from '@mui/material'
import {
  type ChangeEvent,
  type Dispatch,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
} from 'react'
import type { DurationFormat, DurationInputAction, DurationInputProps, DurationInputState } from '../../types'
import { useTexts } from './useTexts'

export function useTextfieldProps(
  dispatch: Dispatch<DurationInputAction>,
  state: DurationInputState,
  props: DurationInputProps,
): TextFieldProps {
  const { error: propsError, slotProps, format, label, localeText, helperText, disabled } = props
  const { displayValue, error: stateError } = state

  const texts = useTexts(localeText)

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'textfield:change', event })
    },
    // dispatch is a stable function, putting as a deps has no effect
    [dispatch],
  )

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      dispatch({ type: 'textfield:blur', event })
    },
    // dispatch is a stable function, putting as a deps has no effect
    [dispatch],
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      dispatch({ type: 'textfield:keydown', event })
    },
    // dispatch is a stable function, putting as a deps has no effect
    [dispatch],
  )

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      dispatch({ type: 'textfield:click', event })
    },
    // dispatch is a stable function, putting as a deps has no effect
    [dispatch],
  )

  return {
    ...slotProps?.textfield,
    label,
    placeholder: computePlaceholder(format),
    onChange,
    onKeyDown,
    onClick,
    onBlur,
    fullWidth: true,
    value: displayValue,
    error: stateError !== null || propsError,
    helperText: stateError ? texts.error.invalidFormat : helperText,
    disabled,
  }
}

function computePlaceholder(format: DurationFormat) {
  switch (format) {
    case 'hh':
    case 'mm':
    case 'ss':
      return '00'
    case 'hh:mm':
      return '00:00'
    case 'hh:mm:ss':
      return '00:00:00'
  }
}
