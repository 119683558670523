import type { ReactElement, ReactFragment } from 'react'

import type {
  FormField,
  FormError,
  FormConfig,
  LazyFields,
  FormResetMode,
  LazyCreateFormConfig,
} from '@workwave-tidal/form-fairy'

import { FormRoot, useCreateForm } from '@workwave-tidal/form-fairy'

type Props<FIELDS extends Record<string, FormField>, ERROR extends FormError<keyof FIELDS>> = {
  name: string
  fields?: FIELDS | LazyFields<FIELDS>
  config?: FormConfig<FIELDS, ERROR> | LazyCreateFormConfig<FIELDS, ERROR>
  verbose?: boolean
  children: ReactElement | ReactFragment
  resetMode?: FormResetMode
}

export function FormProvider<
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS>,
>(props: Props<FIELDS, ERROR>) {
  const { name, config, fields, verbose, children, resetMode } = props

  //  Initialize the Form API
  const formState = useCreateForm<FIELDS, ERROR>(name, fields, config, resetMode, verbose)

  return <FormRoot formState={formState}>{children}</FormRoot>
}
