import type { ReactNode } from 'react'

import { Stack } from '@mui/material'

type Props = {
  width: number
  children?: ReactNode
  testid?: string
}

export function FormColumn(props: Props) {
  return (
    <Stack
      position="relative"
      direction="column"
      height="100%"
      flexShrink={0}
      width={props.width}
      borderRight={theme => `1px solid ${theme.palette.divider}`}
      alignItems="center"
      data-testid={props.testid}
    >
      {props.children}
    </Stack>
  )
}
