import { styled } from '@mui/material/styles'

import { Link } from '@/routing'

import { useTexts } from '../../../../useTexts'
import { HeaderLink } from '../HeaderLink'
import { useRmLinks } from './hooks/useRmLinks'

const FullHeightLink = styled(Link)({
  height: '100%',
})

export function RmLinks() {
  const texts = useTexts()
  const { canShow } = useRmLinks()

  return (
    <>
      <FullHeightLink
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={'pendo-bluebar__home-menu'}
      >
        <HeaderLink
          data-testid="header__nav-home-button-content"
          data-trackid="rm-header__nav-home-button-content"
        >
          {texts.home}
        </HeaderLink>
      </FullHeightLink>

      <FullHeightLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={'pendo-bluebar__orders-menu'}
      >
        <HeaderLink
          data-testid="header__nav-orders-button-content"
          data-trackid="rm-header__nav-orders-button-content"
        >
          {texts.orders}
        </HeaderLink>
      </FullHeightLink>

      {canShow.simulations && (
        <FullHeightLink
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={'pendo-bluebar__simulations-list-menu'}
        >
          <HeaderLink
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-header__nav-simulations-button-content"
          >
            {texts.simulations}
          </HeaderLink>
        </FullHeightLink>
      )}
    </>
  )
}
