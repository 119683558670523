import { Typography } from '@mui/material'
import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const {
    data: { regionIds },
  } = useController()

  return <Typography>{texts.body(regionIds.length)}</Typography>
}
