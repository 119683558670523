import type { FormFields, FormErrors } from '../formFields'

import { useState } from 'react'
import { Box } from '@mui/material'

import { FormProvider } from '@workwave-tidal/form-ui/layout/FormProvider'

import { ModalContent } from '@/components/Modal'

import { useConfigureController } from '../../hooks/useController'
import { formFields } from '../formFields'

import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Form } from './components/Form'

export function Content() {
  const { ready } = useConfigureController()

  // create a memoized configuration
  const { createFormConfig, validationSummaryItems } = useCreateFormConfig()

  const [firstSubmitDone, setFirstSubmitDone] = useState(false)

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors>
      config={createFormConfig}
      name="rm-account-form"
      fields={formFields}
      verbose={false}
    >
      <ModalContent
        header={<Header />}
        footer={<Footer setFirstSubmitDone={setFirstSubmitDone} />}
        maxWidth={600}
      >
        <Box paddingTop={2} width={568}>
          <Form
            validationSummaryItems={validationSummaryItems}
            setFirstSubmitDone={setFirstSubmitDone}
            firstSubmitDone={firstSubmitDone}
          />
        </Box>
      </ModalContent>
    </FormProvider>
  )
}
