import { Text } from '@/local/components'
import { useTexts } from '../useTexts'

import { Box } from '@mui/material'

interface Props {
  count: number
}

const growStyle = {
  padding: '2px 0',
  maxWidth: 260,
  minWidth: 260,
  height: '100%',
  alignItems: 'center',
}

export function Bulk(props: Props) {
  const { count } = props

  const texts = useTexts()

  return (
    <Box
      data-trackid="navigo-vehicle-overview-details-bulk"
      data-testid="navigo-vehicle-overview-details-bulk"
      sx={growStyle}
      width="100%"
      flex="0 1 auto"
    >
      <Text
        data-trackid="navigo-vehicle-overview-details-bulk-number"
        data-testid="navigo-vehicle-overview-details-bulk-number"
        weight="$semiBold"
        size="$xl"
      >
        {texts.vehiclesCounter(count)}
      </Text>
    </Box>
  )
}
