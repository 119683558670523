import { NumericStepperField } from '@/forms-legacy'

import { useTexts } from '../hooks/useTexts'

const wrappedInputProps = {
  step: 5,
  min: 0,
}

export function MaxLocations() {
  const texts = useTexts()

  return (
    <NumericStepperField
      name="maxLocations"
      testid="maxLocations"
      half
      label={texts.maxLocations}
      wrappedInputProps={wrappedInputProps}
    />
  )
}
