import { LayoutComposer, NoResults } from '@/components/List'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { ComponentSize, FormColumn } from '@/components/layout'

import { Item } from './Item'
import { Header } from './components/Header'
import { useDataForList } from './hooks/useDataForList'
import { useListHandlers } from './hooks/useListHandlers'
import { useTexts } from './hooks/useTexts'
import { useTrafficProfiles } from './hooks/useTrafficProfiles'

import { Box } from '@mui/material'

const getItemHeight = () => 58

export function List() {
  const trafficProfiles = useTrafficProfiles()

  const { onSelect } = useListHandlers()
  const { structure } = useDataForList()
  const texts = useTexts()

  return (
    <FormColumn width={320} testid="form__column-primary">
      <Header />

      <Box width="100%" height="100%" flex="0 1 auto" position="relative">
        <LayoutComposer
          totalItems={structure.totalItems}
          shownItems={structure.shownItems}
          noResults={
            <NoResults title={texts.noResults.title} description={texts.noResults.description} />
          }
          noEntries={null}
          list={
            <ComponentSize
              render={size => {
                return (
                  <>
                    <StructuredVirtualList
                      itemsById={trafficProfiles}
                      structure={structure}
                      listApiCategory="trafficProfiles"
                      getItemHeights={getItemHeight}
                      RenderItem={Item}
                      listHeight={size.height}
                      // as soon an item get selected the list will be replaced by the ListOfTrafficAreas
                      selectedItemIds={[]}
                      onSelect={onSelect}
                      buffer={50}
                    />
                  </>
                )
              }}
            />
          }
        />
      </Box>
    </FormColumn>
  )
}
