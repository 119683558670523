import { Stack } from '@mui/material'
import type { PropsWithChildren, ReactNode } from 'react'

import { Label } from './Label'
import { Value } from './Value'

interface Props {
  label: ReactNode
  color?: string
  testId?: string
}

export function Field(props: PropsWithChildren<Props>) {
  const { label, children, color, testId = 'rm-viewField-root' } = props

  return (
    <Stack spacing={1} data-testid={testId}>
      <Label>{label}</Label>
      <Value color={color}>{children}</Value>
    </Stack>
  )
}
