import { type MouseEvent, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  type PopoverOrigin,
  IconButton,
  Typography,
  MenuItem,
  Divider,
  Menu,
  Stack,
} from '@mui/material'
import { MoreHoriz } from '@workwave-tidal/core/icons'

import { selectUsedTelematicsLicensesByTenantSource } from '@/features/domain/account'
import { selectUserType } from '@/features/domain/user'

import { useTexts } from '../../../hooks/useTexts'
import { useDeleteTenantSourceModal } from '../../../modals/DeleteTenantSourceModal'
import { useEditTenantSourceLabelModal } from '../../../modals/EditTenantSourceLabelModal'
import { useEditTenantSourceCredentialsModal } from '../../../modals/EditTenantSourceCredentialsModal'

type Props = {
  tenantSource: uui.domain.server.gps.telematics.TenantSource
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

export function StatsAndActions(props: Props) {
  const { tenantSource } = props

  const usedLicensesByTenantSource = useSelector(selectUsedTelematicsLicensesByTenantSource)
  const userType = useSelector(selectUserType)
  const isAdmin = userType === 'admin'

  const { show: showDeleteTenantSourceModal, Modal: DeleteTenantSourceModal } =
    useDeleteTenantSourceModal()

  const { show: showEditTenantSourceLabelModal, Modal: EditTenantSourceLabelModal } =
    useEditTenantSourceLabelModal()

  const { show: showEditTenantSourceCredentialsModal, Modal: EditTenantSourceCredentialsModal } =
    useEditTenantSourceCredentialsModal()
  const texts = useTexts()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onChangeLabel = useCallback(() => {
    showEditTenantSourceLabelModal()
    setAnchorEl(null)
  }, [showEditTenantSourceLabelModal])

  const onChangeCredentials = useCallback(() => {
    showEditTenantSourceCredentialsModal()
    setAnchorEl(null)
  }, [showEditTenantSourceCredentialsModal])

  const onDeleteIntegration = useCallback(() => {
    showDeleteTenantSourceModal()
    setAnchorEl(null)
  }, [showDeleteTenantSourceModal])

  return (
    <>
      <Stack alignItems="flex-end">
        {isAdmin && (
          <>
            <IconButton onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            >
              <MenuItem onClick={onChangeLabel}>{texts.editLabel}</MenuItem>
              <MenuItem onClick={onChangeCredentials}>{texts.editCredentials}</MenuItem>
              <Divider />
              <MenuItem onClick={onDeleteIntegration} sx={{ color: 'red' }}>
                {texts.deleteConnection}
              </MenuItem>
            </Menu>
          </>
        )}

        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography variant="body2">{texts.integrationSource}</Typography>
          <Typography variant="subtitle2" fontWeight={500}>
            {tenantSource.sourceLabel}
          </Typography>
        </Stack>

        {isAdmin && (
          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="body2">{texts.licensesInUse}</Typography>
            <Typography variant="subtitle2" fontWeight={500}>
              {usedLicensesByTenantSource[tenantSource.id]}
            </Typography>
          </Stack>
        )}
      </Stack>
      <div style={{ position: 'absolute' }}>
        {tenantSource && <DeleteTenantSourceModal tenantSource={tenantSource} />}
        {tenantSource && <EditTenantSourceLabelModal tenantSource={tenantSource} />}
        {tenantSource && <EditTenantSourceCredentialsModal tenantSource={tenantSource} />}
      </div>
    </>
  )
}
