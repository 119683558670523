import { DoubleArrow } from '@/icons'
import { IconButton } from '@mui/material'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
}

const rotateIcon = { transform: 'rotate(-180deg)' }

export function ExpandButton(props: Props) {
  const { expanded, toggleExpanded } = props

  const testId = expanded
    ? 'navigo-expand-actions-collapse-trigger'
    : 'navigo-expand-actions-expand-trigger'

  return (
    <IconButton onClick={toggleExpanded} data-trackid={testId} data-testid={testId}>
      <DoubleArrow size={10} color="$nightRider" style={expanded ? undefined : rotateIcon} />
    </IconButton>
  )
}
