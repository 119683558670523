import { type FormControlLabelProps } from '@mui/material/FormControlLabel'
import {
  type FormFieldDescriptor,
  type NarrowFieldsByValueType,
  type TidalFormBridgeOptions,
  type TidalFormState,
  useTidalFormBridge,
} from '@workwave-tidal/core/form'
import { useConnectDrawAreaField } from './hooks/useConnectDrawAreaField'

export interface DrawAreaProps {
  id: uui.domain.ui.map.EditableArea['id']
  color: string
  initialPoints?: uui.domain.LatLng[]
}

export type DrawAreaFieldValue = uui.domain.LatLng[]
const defaultComponentValue: DrawAreaFieldValue = []

export type DrawAreaFieldProps<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DrawAreaFieldValue,
> = {
  drawAreaProps: DrawAreaProps
  formControlLabelProps?: Partial<FormControlLabelProps>
} & TidalFormBridgeOptions<DrawAreaFieldValue, TargetField, State, AcceptedFormValue>

export function DrawAreaField<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = DrawAreaFieldValue,
>(props: DrawAreaFieldProps<TargetField, State, AcceptedFormValue>) {
  const {
    drawAreaProps: { id, color, initialPoints },
    formControlLabelProps = {},
    initialValue = defaultComponentValue as FormFieldDescriptor<State>[TargetField]['value'],
    validateOn = 'change',
    ...bridgeOptions
  } = props

  const {
    fieldInfo: { onChange },
  } = useTidalFormBridge<DrawAreaFieldValue, TargetField, State, AcceptedFormValue>({
    ...bridgeOptions,
    validateOn,
    initialValue,
  })

  useConnectDrawAreaField(onChange, id, color, initialPoints)

  return null
}
