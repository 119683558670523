export const getVersion = (): string => process.env.appVersion as string

let logged: boolean = false
export const logVersionInfo = (): void => {
  if (logged) return

  try {
    console.group()
    console.log('%c WorkWave RouteManager ', 'background: blue; color: white; display: block;')
    console.log(`%c Version: @${getVersion()} `, 'background: blue; color: white; display: block;')
    console.groupEnd()
  } catch (e) {
    console.warn('Failed to print version')
    console.warn(e)
  }

  logged = true
}
