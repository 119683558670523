import { timeUtils } from '@/server-data'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'

import { DoubleColumnContainer } from '../../Scheduler/components/DoubleColumnContainer'
import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../Scheduler/components/Typography'
import { useTexts } from './useTexts'

interface Props {
  userConfig: uui.domain.UserConfiguration
  record: ResourceModel
  value: uui.domain.client.rm.SchedulerResource['routeEnd']
}

export function RouteEnd(props: Props) {
  const { value, userConfig } = props
  const texts = useTexts()

  const { showApprovedRoutes } = getSchedulerPreferences()

  if (!value) return '-'

  const formatTime = timeUtils.formatTime(userConfig)

  const end = value.current ? formatTime(new Date(value.current.value)) : '-'

  const endSuffix =
    value.current && value.current.extraDays >= 1
      ? `(+${value.current.extraDays} ${value.current.extraDays > 1 ? texts.days : texts.day})`
      : ''

  const endTimeString = `${end} ${endSuffix}`.trim()

  if (showApprovedRoutes && value.approved) {
    const approvedEnd = value.approved ? formatTime(new Date(value.approved.value)) : '-'

    const approvedEndSuffix =
      value.approved && value.approved.extraDays >= 1
        ? `(+${value.approved.extraDays} ${value.approved.extraDays > 1 ? texts.days : texts.day})`
        : ''

    const approvedEndString = `${approvedEnd} ${approvedEndSuffix}`.trim()
    return (
      <DoubleColumnContainer
        firstRow={<Typography fontSize={12}>{endTimeString}</Typography>}
        secondRow={<Typography fontSize={10}>{approvedEndString}</Typography>}
      />
    )
  }

  return (
    <SingleColumnContainer>
      <Typography fontSize={12}>{endTimeString}</Typography>
    </SingleColumnContainer>
  )
}
