import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { useCreateDate, useFormatDateTime } from '@/hooks'
import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../hooks/useTexts'

export function Header() {
  const texts = useTexts()
  const { close, data } = useController()

  const date = useCreateDate(data.sentTs)
  const timeStamp = useFormatDateTime(date)

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={texts.headerTitle(data.kind === 'EMAIL')}
      subtitle={texts.sentOn(timeStamp, data.recipient)}
    />
  )
}
