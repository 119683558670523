import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

import { Box } from '@mui/material'

interface Props {
  vehicles: uui.domain.client.UnifiedVehicle[]
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Details(props: Props) {
  const { vehicles } = props

  if (vehicles.length === 0) return null

  return (
    <Box
      data-trackid="navigo-vehicle-overview-details"
      data-testid="navigo-vehicle-overview-details"
      sx={growStyle}
      width="100%"
      flex="0 1 auto"
    >
      {vehicles.length > 1 ? <Bulk count={vehicles.length} /> : <Single uv={vehicles[0]} />}
    </Box>
  )
}
