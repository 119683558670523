import { useRef } from 'react'

import { RenderFrom } from '@/components/Navigo/components/RenderFrom'
import { Text } from '@/local/components'
import { useComponentSize } from '@/local/hooks'

import { Stack } from '@mui/material'

import { useApprovedRoutesCount } from './hooks/useApprovedRoutesCount'
import { useRoutesWithExecutionEventsCount } from './hooks/useRoutesWithExecutionEventsCount'
import { useTexts } from './useTexts'

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

export function Bulk(props: Props) {
  const { routeInfos } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const routesWithExecutionEventsCount = useRoutesWithExecutionEventsCount(routeInfos)
  const approvedCount = useApprovedRoutesCount(routeInfos)
  const texts = useTexts()

  const title = texts.approvedRoutes(approvedCount, routeInfos.length - approvedCount)
  const subtitle = texts.routesWithExecutionEvents(routesWithExecutionEventsCount)

  return (
    <Stack
      direction="row"
      height="100%"
      data-trackid="navigo-routes-overview-stats-bulk"
      data-testid="navigo-routes-overview-stats-bulk"
      ref={ref}
    >
      <RenderFrom width={width} renderFrom={250}>
        <Stack direction="column" width="100%" ref={ref} justifyContent="space-between">
          <Text testid="navigo-routes-overview-stats-approvedRoutes" size="$p3">
            {title}
          </Text>
          <Text testid="navigo-routes-overview-stats-routesWithExecutionEvents" size="$p3">
            {subtitle}
          </Text>
        </Stack>
      </RenderFrom>
    </Stack>
  )
}
