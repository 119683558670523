import { Text } from '@/local/components'
import { Gears } from '@/icons'

import { useController } from '../hooks/useController'
import { useActions } from '../hooks/useActions'
import { useTexts } from '../useTexts'
import {
  Container,
  TitleContainer,
  ProgressBarContainer,
  ProgressBar,
  CancelButton,
  useProgressBarStyle,
} from '../hooks/useStyles'

import { FlashingDots } from './FlashingDots'

export function Body() {
  const {
    data: { progress, canCancel, canceling },
  } = useController()
  const cancelBuild = useActions()
  const texts = useTexts()
  const progressBarStyle = useProgressBarStyle(progress)

  return (
    <Container>
      <Gears size={60} marginBottom={30} />

      <TitleContainer>
        <Text size="$h1">{texts.optimizationInProgress}</Text>
        <FlashingDots dimension={6} speed="fast" />
      </TitleContainer>

      <ProgressBarContainer>
        <ProgressBar
          data-trackid="buildModal-progress"
          data-testid="buildModal-progress"
          style={progressBarStyle}
        />
      </ProgressBarContainer>

      <Text size="$h2">{texts.buildingYourRoutes}</Text>

      {canCancel && (
        <CancelButton
          disabled={canceling}
          onClick={cancelBuild}
          data-trackid="buildModal-cancel"
          data-testid="buildModal-cancel"
        >
          {texts.cancelOptimization}
        </CancelButton>
      )}
    </Container>
  )
}
