import type { ValidateOn } from '@/formUi'
import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/form-fairy'
import type { BulkFieldValue } from '../../../types'

import { useCallback, useRef, useState } from 'react'

import { useFormField } from '@workwave-tidal/form-fairy'
import { useValidateOn, useDebounceText } from '@/formUi'

import { MixedField } from './components/MixedField'
import { ExactField } from './components/ExactField/ExactField'

type RequiredFormField = BulkFieldValue<string>

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  name: FIELD_NAME
  validateOn?: ValidateOn
  mixedLabel: string
  helperText?: string
  placeholder?: string
  testId?: string
  debounce?: number
  multiline?: boolean
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkTextField<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const {
    placeholder,
    helperText,
    validateOn,
    label,
    name,
    debounce,
    mixedLabel,
    multiline,
    testId,
    ...options
  } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const inputRef = useRef<HTMLInputElement | undefined>()
  const [autoFocus, setAutofocus] = useState(false)

  const {
    field,
    errors,
    fieldApi: { change: apiChange, validate },
    formApi,
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const { required, status, disabled, visible } = field

  const { validateOnBlur, validateOnChange, validateOnFocus } = useValidateOn(validate, validateOn)

  const changeAndValidate = useCallback(
    (value: string) => {
      apiChange({ status: 'exact', value })
      validateOnChange()
    },
    [validateOnChange, apiChange],
  )

  const onClear = useCallback(() => {
    changeAndValidate('')
  }, [changeAndValidate])

  const onChangeMixedField = useCallback(
    (value: string) => {
      changeAndValidate(value)
      setAutofocus(true)
    },
    [changeAndValidate, setAutofocus],
  )

  const valueAsString = field.value.status === 'mixed' ? '' : field.value.value
  const [value, onChange] = useDebounceText(valueAsString, changeAndValidate, debounce)

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  if (!visible) return null

  switch (field.value.status) {
    case 'mixed':
      return (
        <MixedField
          inputRef={inputRef}
          value={mixedLabel}
          label={label}
          onChange={onChangeMixedField}
          testId={testId}
        />
      )
    case 'exact':
      return (
        <ExactField
          autoFocus={autoFocus}
          inputRef={inputRef}
          name={name}
          value={value}
          testId={testId}
          label={label}
          onChange={onChange}
          onClear={onClear}
          helperText={helperText}
          placeholder={placeholder}
          error={fieldHasError}
          errorText={errorText}
          disabled={fieldDisabled}
          required={required}
          multiline={multiline}
          validateOnBlur={validateOnBlur}
          validateOnFocus={validateOnFocus}
        />
      )
  }
}
