import type { Props } from '../typings'

import { ModalContent } from '@/components/Modal'
import { useConfigureController } from '../hooks/useController'

import { Body } from './Body'
import { Footer } from './Footer'
import { Header } from './Header'

export function Content(props: Props) {
  const { ready } = useConfigureController(props.payload)

  if (!ready) return null

  return (
    <ModalContent header={<Header />} footer={<Footer />} maxWidth={560}>
      <Body />
    </ModalContent>
  )
}
