import type { FormField, FormError, FormValues as FormValuesType } from '@workwave-tidal/form-fairy'

const nameField: FormField<string> = {
  value: '',
  required: true,
}

export const formFields = {
  name: nameField,
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
