import type { FormMutation } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from '../../../formFields'

// Define a form mutation that determines whether the "check in" and "check out" fields should be disabled based on the current form values
export const typeMutation: FormMutation<FormFields, FormErrors> = (
  api,
  changed,
  _initialChanged,
) => {
  // If the "type" field hasn't changed, do nothing
  if (!changed.type) return

  // Initialize variables for whether the "check in" and "check out" fields should be disabled
  let checkIn = false
  let checkOut = false

  // Enable the "check in" and "check out" fields
  api.setFieldDisabledState('checkIn', false)
  api.setFieldDisabledState('checkOut', false)

  // Depending on the new value of the "type" field, enable or disable the "check in" and "check out" fields
  switch (changed.type) {
    case 'ASSISTED_TIME_IN_OUT':
    case 'ASSISTED_TIME_OUT':
      api.setFieldDisabledState('checkOut', true)
      checkOut = true
      break

    case 'DRIVER_REQUIRED_FLOW':
      api.setFieldDisabledState('checkIn', true)
      checkIn = true
      break
  }

  // Return the new values for the "check in" and "check out" fields
  return { checkIn, checkOut }
}
