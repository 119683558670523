import type { CSSProperties } from 'react'
import { styled } from '@mui/material/styles'
import { theme } from '@/styles'

export const Container = styled('div')({
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  padding: 50,
})

export const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
})

export const ProgressBarContainer = styled('div')({
  background: theme.colors.$nightRider,
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 10,
  margin: '20px 0',
  minWidth: 400,
  width: '100%',
  height: 10,
})

export const ProgressBar = styled('div')({
  background: theme.colors.$pigmentGreen,
  transition: 'width 180ms ease-in-out',
  width: 'var(--bar-width)',
  height: '100%',
})

export const CancelButton = styled('button')({
  background: theme.colors.$nightRider,
  color: theme.colors.$pureWhite,
  fontSize: theme.fonts.$p2,
  padding: '0 26px',
  borderRadius: 10,
  borderWidth: 0,
  marginTop: 30,
  outline: 0,
  height: 26,

  '&:disabled': {
    pointerEvents: 'none',
    opacity: 0.6,
  },
})

// Hook for progress bar width style
export function useProgressBarStyle(progress: number | 'completed') {
  return {
    '--bar-width': progress === 'completed' ? '100%' : `${progress}%`,
  } as CSSProperties
}
