import type { GridPanelProps } from '@mui/x-data-grid-pro'

import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { GridPanel, useGridApiRef } from '@mui/x-data-grid-pro'

export interface PanelProps extends GridPanelProps {}

const StyledGridPanel = styled(GridPanel, {
  shouldForwardProp: prop => prop !== 'height',
})<{ height: number | null }>(({ height }) => ({
  '& .MuiPaper-root': {
    maxHeight: height ? height : 450,
  },
}))

export const Panel = forwardRef<HTMLDivElement, PanelProps>(function MyPanel(props, ref) {
  const apiRef = useGridApiRef()

  return (
    <StyledGridPanel
      ref={ref}
      {...props}
      height={apiRef?.current?.windowRef?.current?.offsetHeight ?? 0}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      placeholder={undefined}
    />
  )
})
