import type { FormErrors, FormFields } from '../formFields'
import { TextField } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'

export function Surname() {
  const texts = useTexts()
  return (
    <TextField<'surname', FormFields, FormErrors>
      name="surname"
      testId="surname"
      label={texts.labels.surname}
      placeholder={texts.placeholders.surname}
      validateOn="change blur"
      required
    />
  )
}
