import type { FormError, FormField } from '@workwave-tidal/form-fairy'

const reasonsForNotCompletedOrders: FormField<string[]> = {
  value: [],
}

const collectStartEndRoute: FormField<'yes' | 'no'> = {
  value: 'no',
}

const enableDynamicLoads: FormField<boolean> = {
  value: false,
}

const enableDynamicCustomFields: FormField<boolean> = {
  value: false,
}

export const formFields = {
  reasonsForNotCompletedOrders,
  collectStartEndRoute,
  enableDynamicLoads,
  enableDynamicCustomFields,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {
  invalidReasonsIndexes: number[]
}
