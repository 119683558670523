import { Outlet } from 'react-router'

import { useResetOrdersFilterOnMount } from '@/hooks'

import { SetupSidebar } from './components/SetupSidebar'

import { Box, Stack } from '@mui/material'

export function Setup() {
  useResetOrdersFilterOnMount()

  return (
    <Stack direction="row" height="100%" width="100%">
      <SetupSidebar />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Outlet />
      </Box>
    </Stack>
  )
}
