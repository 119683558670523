import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { OrderChip } from '../../../components/Navigator/typings'

import { Stack } from '@mui/material'

import { Navigator } from '../../../components/Navigator'
import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import { useOrderStepsNavigator } from '../hooks/useOrderStepsNavigator'
import { OrderItemRenderer } from './OrderItemRenderer'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'squared',
}

export function OrderStepsNavigator() {
  const { chips, page, setPage } = useOrderStepsNavigator()

  return (
    <Stack
      direction="column"
      flexShrink={0}
      data-trackid="navigo-orders-navigator"
      data-testid="navigo-orders-navigator"
      justifyContent="center"
      width="auto"
      height="100%"
    >
      <Navigator<OrderChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        onChangeIndex={index => setPage(index)}
        ItemRenderer={OrderItemRenderer}
        LayoutRenderer={CollapsedLayoutRenderer}
        extraLayoutRendererProps={renderProps}
        extraItemRendererProps={renderProps}
      />
    </Stack>
  )
}
