import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'
import type { Texts } from '../useTexts'

export const createValidateName =
  (texts: Texts): FormValidator<FormFields, FormErrors> =>
  async formApi => {
    const { value } = formApi.getField('name')
    const name = value.trim()

    const nameMaxLengthError: FormErrors = {
      id: 'nameMaxLengthError',
      message: texts.errors.maxLength(30),
      field: 'name',
      priority: 15,
    }

    const nameRequiredError: FormErrors = {
      id: 'nameRequiredError',
      message: texts.errors.required,
      field: 'name',
      priority: 10,
    }

    const requiredError = name.length === 0
    const maxLengthError = name.length > 30

    return {
      [nameRequiredError.id]: requiredError ? nameRequiredError : null,
      [nameMaxLengthError.id]: maxLengthError ? nameMaxLengthError : null,
    }
  }
