import { useMemo } from 'react'
import { defaultLocaleText } from '../../defaultLocaleText'
import type { DurationInputProps, DurationInputTexts } from '../../types'

export function useTexts(localeText: DurationInputProps['localeText']) {
  return useMemo<DurationInputTexts>(
    () => ({
      ...defaultLocaleText,
      ...localeText,
    }),
    [localeText],
  )
}
