import { useCallback } from 'react'
import { styled } from '@mui/material/styles'

import { useToggle } from '@/hooks'

import { ToggleButton } from './components/ToggleButton'
import { SearchBar } from './components/SearchBar'
import { useTexts } from './useTexts'

const StyledRoot = styled('div')({
  width: 335,
  top: '0.5em',
  right: '24px',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
})

export function SearchOnMap() {
  const [open, toggle] = useToggle()
  const texts = useTexts()

  const close = useCallback(() => {
    if (open) {
      toggle()
    }
  }, [toggle, open])

  return (
    <StyledRoot>
      {open && <SearchBar closeSearchOnMap={close} />}
      <ToggleButton open={open} toggle={toggle} title={texts.searchOnMap} />
    </StyledRoot>
  )
}
