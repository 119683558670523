import type { ValidateOn } from '@/formUi'
import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/form-fairy'
import type { BulkFieldValue } from '../../../types'

import { useCallback } from 'react'

import { Autocomplete, TextField } from '@mui/material'
import { Clear as ClearIcon } from '@mui/icons-material'
import { useFormField } from '@workwave-tidal/form-fairy'
import { useValidateOn } from '@/formUi'

import { useInputValue } from './hooks/useInputValue'

type RequiredFormField = BulkFieldValue<string>

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  name: FIELD_NAME
  validateOn: ValidateOn
  mixedLabel: string
  helperText?: string
  testId?: string
  placeholder?: string
  debounce?: number
  options: string[]
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkAutocomplete<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const {
    placeholder,
    helperText,
    validateOn,
    label,
    name,
    debounce,
    testId,
    mixedLabel,
    options: autocompleteOptions,
    ...options
  } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const {
    field,
    errors,
    fieldApi: { change: apiChange, validate },
    formApi,
  } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const { required, status, disabled, visible, value: fieldValue } = field

  const { validateOnChange } = useValidateOn(validate, validateOn)

  const changeAndValidate = useCallback(
    (value: string) => {
      // when value changes from outside (e.g. form update the initial values)
      // the inner callbacks will call the change with the same value
      // since the value is an object a new reference will be generate
      // to preserve the `pristine` status this has to be handled
      if (fieldValue.status === 'exact' && fieldValue.value === value) return

      apiChange({ status: 'exact', value })
      validateOnChange()
    },
    [validateOnChange, apiChange, fieldValue],
  )

  const valueAsString = field.value.status === 'mixed' ? '' : field.value.value
  const { inputValue, onInputChange } = useInputValue(changeAndValidate)
  const { validateOnBlur, validateOnFocus } = useValidateOn(validate, validateOn)

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  const onChange = useCallback(
    (_, newValue: string | null) => {
      changeAndValidate(newValue ?? '')
    },
    [changeAndValidate],
  )
  const onBlur = useCallback(() => {
    validateOnBlur()
  }, [validateOnBlur])

  const onFocus = useCallback(() => {
    validateOnFocus()
  }, [validateOnFocus])

  if (!visible) return null

  switch (field.value.status) {
    case 'mixed':
      return (
        <Autocomplete
          freeSolo
          openOnFocus
          disabled={fieldDisabled}
          value=""
          data-testid={testId}
          onChange={onChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          clearIcon={<ClearIcon />}
          options={autocompleteOptions}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              error={fieldHasError}
              helperText={fieldHasError ? errorText : helperText}
              required={required}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={mixedLabel}
            />
          )}
        />
      )

    case 'exact':
      return (
        <Autocomplete
          freeSolo
          openOnFocus
          disabled={fieldDisabled}
          value={valueAsString}
          data-testid={testId}
          onChange={onChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          options={autocompleteOptions}
          clearIcon={<ClearIcon />}
          onBlur={onBlur}
          onFocus={onFocus}
          renderInput={params => (
            <TextField
              {...params}
              name={name}
              error={fieldHasError}
              helperText={fieldHasError ? errorText : helperText}
              required={required}
              label={label}
              placeholder={placeholder}
            />
          )}
        />
      )
  }
}
