import type {
  CalendarClickType,
  WeekCalendarProps as Props,
  WeeklyValidSelection,
  YYYYMMDD,
} from '../../typings'

import { getDay } from 'date-fns/esm'
import { useCallback } from 'react'

import { Month } from '../../components/Month'
import { parseDate } from '../../utils/parseDate'

import { Stack } from '@mui/material'
import { useSelectionData } from './useSelectionData'
import { useWeeklyDays } from './useWeeklyDays'

export function Weekly(props: Props) {
  const { highlightedDays, onChange, selection, allowAutoMonthHeight, dotColor } = props

  const selectionData = useSelectionData(selection)

  const days = useWeeklyDays({
    selection: selectionData,
    highlightedDays,
  })

  const handleOnClick = useCallback(
    (day?: YYYYMMDD, type: CalendarClickType = 'click') => {
      if (day) {
        const dayDate = getDay(parseDate(day)) as WeeklyValidSelection
        onChange(dayDate, selection, type)
      }
    },
    [onChange, selection],
  )

  return (
    <Stack direction="column" height="auto" alignItems="center" flexShrink={0}>
      <Stack direction="row" width="auto">
        <Month
          index={0}
          days={days}
          dotColor={dotColor}
          onClick={handleOnClick}
          allowAutoMonthHeight={allowAutoMonthHeight}
        />
      </Stack>
    </Stack>
  )
}
