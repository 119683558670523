import { type PropsWithChildren } from 'react'
import { FormProvider as TidalFormProvider } from '@workwave-tidal/form-ui/layout'

import { type FormFields, type FormErrors, formFields } from '../formFields'
import { useFormConfig } from '../hooks/useFormConfig'

export function FormProvider(props: PropsWithChildren) {
  const { children } = props

  const formConfig = useFormConfig()

  return (
    <TidalFormProvider<FormFields, FormErrors>
      fields={formFields}
      name="addCompanyForm"
      config={formConfig}
    >
      {children}
    </TidalFormProvider>
  )
}
