import type { FormErrors, FormFields } from './formFields'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useCallback, useMemo, useState } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'
import { Tooltip } from '@/components/primitives/Tooltip'
import { useTexts } from './hooks/useTexts'
import { useActions } from './hooks/useActions'

const fullWidthStyle = { width: '100%' }

export function ReasonsForNotCompletedOrdersMenu() {
  const texts = useTexts()
  const {
    field: { value },
    formApi,
  } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )

  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  const isASCSorted = useMemo(() => {
    const base = JSON.stringify(value)
    const sorted = JSON.stringify([...value].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)))

    return base === sorted
  }, [value])
  const actions = useActions()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(() => null)
  }, [])

  const handleSort = useCallback(() => {
    if (isASCSorted) actions.sortDESC()
    else actions.sortASC()

    handleClose()
  }, [actions, handleClose, isASCSorted])

  const handleRemoveAll = useCallback(() => {
    actions.removeAll()
    handleClose()
  }, [actions, handleClose])

  return (
    <>
      <Tooltip title={texts.more} placement="top">
        <IconButton onClick={handleClick} data-testid="reason-for-orders_menu-btn">
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Tooltip title={texts.noReasons} placement="top" disabled={!!value.length}>
          <MenuItem
            sx={fullWidthStyle}
            onClick={handleSort}
            disabled={formDisabled || !value.length}
            data-testid="reason-for-orders_sort-btn"
          >
            {isASCSorted ? texts.desc : texts.asc}
          </MenuItem>
        </Tooltip>
        <Tooltip title={texts.noReasons} placement="top" disabled={!!value.length}>
          <MenuItem
            sx={fullWidthStyle}
            onClick={handleRemoveAll}
            disabled={formDisabled || !value.length}
            data-testid="reason-for-orders_remove-all-btn"
          >
            <Typography color="red">{texts.removeAll}</Typography>
          </MenuItem>
        </Tooltip>
      </Menu>
    </>
  )
}
