import { Dropdown } from '@/components/Dropdown'
import { Block } from '@/forms-legacy'
import { Spinner, WarningTriangle } from '@/icons'
import { H4, WarningCard } from '@/local/components'
import { Box, Stack } from '@mui/material'

import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useTerritoryDropdownItems } from '../hooks/useTerritoryDropdownItems'
import { useTexts } from '../useTexts'

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    status,
    invalid,
    data: { territories, id },
  } = useController()
  const dropdownItems = useTerritoryDropdownItems(territories, id)

  return (
    <Stack direction="column" minHeight="200px" width="100%" marginTop={4}>
      {status !== 'failed' && (
        <>
          <Block>
            <H4 color="$paleSky" uppercase>
              {texts.territoryDropdownTitle}
            </H4>
          </Block>
          <Block>
            <Stack direction="row" alignItems="center" width="100%" height="100%">
              <Box width="100%" height="auto" flex="0 1 auto">
                <Dropdown
                  options={dropdownItems}
                  onChange={handlers.onTerritoryChange}
                  disabled={!dropdownItems || status !== 'ready' || invalid}
                  maxListHeight={180}
                />
              </Box>
              <Spinner
                marginLeft={5}
                style={{ opacity: status === 'loading' ? 1 : 0 }}
                size={18}
                animation="spin"
              />
            </Stack>
          </Block>
        </>
      )}
      {status === 'failed' && (
        <Block>
          <WarningCard
            title={texts.failedDataFetchingWarningCardTitle}
            description={texts.failedDataFetchingWarningCardDescription}
            preset="warning"
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
          />
        </Block>
      )}
    </Stack>
  )
}
