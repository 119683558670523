import type { ReactFragment, ReactElement } from 'react'
import type { FormErrors, FormFields } from './formFields'

import { FormProvider } from '@workwave-tidal/form-ui/layout/FormProvider'

import { useController } from '../../hooks/useController'

import { useFormConfig } from './hooks/useFormConfig'
import { formFields } from './formFields'

interface Props {
  children: ReactFragment | ReactElement
}

export function TicketFormProvider(props: Props) {
  const {
    data: { ticket },
  } = useController()

  const formConfig = useFormConfig(ticket)

  return (
    <FormProvider<FormFields, FormErrors>
      name="open-support-ticket-form"
      fields={formFields}
      verbose={false}
      config={formConfig}
    >
      {props.children}
    </FormProvider>
  )
}
