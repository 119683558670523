import { Switch } from '@mui/material'
import { type ChangeEvent, useCallback } from 'react'
import { useField } from 'react-final-form'

interface Props {
  disabled?: boolean
  testId?: string
}

export function TrackingSwitch(props: Props) {
  const { disabled, testId = 'setup-vehicle__telematics-tracking-switch' } = props
  const {
    input: { value, onChange },
  } = useField<boolean>('telematics.gpsTracking')

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked)
    },
    [onChange],
  )

  return (
    <Switch
      checked={value}
      onChange={handleChange}
      disabled={disabled}
      data-testid={testId}
      data-trackid={testId}
    />
  )
}
