import { useEffect, useReducer } from 'react'
import type { DurationInputProps, DurationInputState } from '../../types'
import { computeDropdownSelectedIndex } from '../../utils/computeDropdownSelectedIndex'
import { formatValue } from '../../utils/formatValue'
import { parseDuration } from '../../utils/parseDuration/parseDuration'
import { durationInputStateReducer } from './durationInputStateReducer'
import { computeDropdownOptions } from './useDropdownOptions'

type InitialStateArgs = Pick<DurationInputProps, 'value' | 'format' | 'optionValues'>

export function useDurationInputState(props: DurationInputProps) {
  const { value, format, onChange, optionValues } = props
  const [state, dispatch] = useReducer(durationInputStateReducer, { value, format, optionValues }, computeInitialState)

  const { fieldValue, dirty, initialValue } = state

  // when a new field value is set, call the onChange callback
  useEffect(() => {
    // this will prevent the onChange callback from being called when the state is initialized
    if (!dirty && fieldValue === initialValue) return

    onChange(fieldValue)
  }, [fieldValue, onChange, dirty, initialValue])

  // update the state when the value prop changes
  useEffect(() => {
    dispatch({ type: 'reaction/change:value', value })
  }, [value])

  // update the state when the format prop changes
  useEffect(() => {
    dispatch({ type: 'reaction/change:format', format })
  }, [format])

  // update the dropdown options when the format or optionValues prop changes
  useEffect(() => {
    dispatch({ type: 'reaction/change:dropdownOptions', format, optionValues })
  }, [format, optionValues])

  return { state, dispatch }
}

function computeInitialState(props: InitialStateArgs): DurationInputState {
  const { value, format, optionValues } = props

  const displayValue = formatValue(value, format)

  const parsedValue = parseDuration(displayValue, format)
  const error = parsedValue === 'invalidFormat' ? 'invalidFormat' : null

  const dropdownOptions = computeDropdownOptions(format, optionValues)

  return {
    error,
    open: false,
    format,
    fieldValue: value,
    initialValue: value,
    dirty: false,
    displayValue: formatValue(value, format),
    dropdownOptions,
    dropdownSelectedIndex: computeDropdownSelectedIndex(value, dropdownOptions),
  }
}
