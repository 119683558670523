import type { Model, SchedulerPro, SchedulerProConfig } from '@bryntum/schedulerpro'
import { checkAllExpanded } from '../../../components/Scheduler/utils/checkAllExpanded'

// @ts-ignore - detail property is available on PointerEvent but TypeScript doesn't recognize it in this context
let clickCount = 0
let singleClickTimer: NodeJS.Timeout | null = null

export const onBeforeToggleGroup: SchedulerProConfig['onBeforeToggleGroup'] = event => {
  const { domEvent } = event

  // @ts-ignore - the source parameter is always passed but it's not in the type definition of event
  const source = event.source as SchedulerPro

  const groupRecord = event.groupRecord as Model & { isCollapsed: boolean }

  // If there is no domEvent, it means that the group was toggled programmatically
  if (!domEvent) return true

  // If the domEvent is not a PointerEvent, we don't want to handle it
  if (!(domEvent instanceof PointerEvent)) return false

  switch (domEvent.detail) {
    /**
     * At the first click, we start a timer to wait for a second click
     * If the second click doesn't happen, we toggle the clicked group
     **/
    case 1:
      new Promise(() => {
        singleClickTimer = setTimeout(() => {
          if (singleClickTimer !== null) {
            clearTimeout(singleClickTimer)
          }

          clickCount = 0

          // We need to convert the group record to a parent record to be able to expand or collapse it
          groupRecord.convertToParent()

          switch (groupRecord.isCollapsed) {
            case true:
              // @ts-ignore - the expand method is private but we need to use it
              source.resourceStore.expand(groupRecord)
              break

            case false:
              // @ts-ignore - the collapse method is private but we need to use it
              source.resourceStore.collapse(groupRecord)
              break
          }
        }, 250)
      })
      break

    // At the second click, we clear the timer and expand or collapse all groups
    case 2:
      if (singleClickTimer !== null) {
        clearTimeout(singleClickTimer)
      }
      checkAllExpanded() ? source.collapseAll() : source.expandAll()
      break
  }

  // We need to return false to prevent the default behavior of the scheduler
  return false
}
