type Params = {
  vehiclesCount: number
  isLoadingGpsData: boolean
  deviceIds?: string[]
} & (
  | {
      trackingProvider: 'telematics'
      devices: Record<string, uui.domain.server.gps.telematics.DeviceInfo>
    }
  | {
      trackingProvider: 'WWGPS' | 'none'
    }
)
export function computeBreadcrumbsPermission(params: Params) {
  if (params.isLoadingGpsData) return 'disabledLoadingGpsData'
  if (!params.deviceIds || params.deviceIds.length === 0) return 'disabledNoDevice'
  if (params.vehiclesCount !== 1) return 'disabledMultipleVehicles'

  if (params.trackingProvider === 'telematics') {
    for (const deviceId of params.deviceIds) {
      if (!params.devices[deviceId]) {
        return 'disabledTelematicsDeviceInActivation'
      }
    }
  }

  return 'enabled'
}
