import type { FormErrors, FormFields } from '../formFields'
import { Box } from '@mui/material'
import { useFormField } from '@workwave-tidal/form-fairy'
import { Checkbox } from '@workwave-tidal/form-ui'
import { useTexts } from '../hooks/useTexts'
import { OrdersType } from './OrdersType'

export function PictureField() {
  const texts = useTexts()
  const { formApi } = useFormField<'includePicture', FormFields, FormErrors>('includePicture')
  const {
    field: { value: isChecked },
  } = useFormField<'includePictureChecked', FormFields, FormErrors>('includePictureChecked')
  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  return (
    <>
      <Checkbox<'includePictureChecked', FormFields, FormErrors>
        label={texts.picture}
        name="includePictureChecked"
        validateOn="blur change"
        disabled={formDisabled}
        testId="driver-assistance-form-picture-field"
      />
      {isChecked && (
        <Box marginLeft={2}>
          <OrdersType name="includePicture" disabled={formDisabled} />
        </Box>
      )}
    </>
  )
}
