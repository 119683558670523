import { getDataFromLocalStorage } from '../utils'

export function collectRoutingLayout() {
  const routingLayout = getDataFromLocalStorage(routingLayoutStorageId)?.layout

  return [
    routingLayout,
    () => {
      localStorage.removeItem(routingLayoutStorageId)
    },
  ]
}

const routingLayoutStorageId = 'routemanager/v1/atoms/routingLayout'
