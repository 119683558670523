import { selectRoutesLoads } from '@/features/domain/scheduler'
import { selectLiveEta } from '@/features/domain/territory'
import { selectUserConfiguration } from '@/features/domain/user'
import { store } from '@/store'
import { type TemplateColumnConfig } from '@bryntum/schedulerpro'

import { type SchedulerColumn, getDynamicLoadsPrefix } from '../../../../atoms/columns'
import { createApprovedDistanceColumn } from '../../../../atoms/columns/defs/approvedDistance'
import { createOrdersExecutedColumn } from '../../../../atoms/columns/defs/ordersExecuted'
import { createOrdersNotCompletedColumn } from '../../../../atoms/columns/defs/ordersNotCompleted'
import { createOrdersUndeclaredColumn } from '../../../../atoms/columns/defs/ordersUndeclared'

import { createApprovedTimeColumn } from '../../../../atoms/columns/defs/approvedTime'
import { createCostColumn } from '../../../../atoms/columns/defs/cost'
import { createDateColumn } from '../../../../atoms/columns/defs/date'
import { createDistanceLeftColumn } from '../../../../atoms/columns/defs/distanceLeft'
import { createDriverColumn } from '../../../../atoms/columns/defs/driver'
import { createDynamicLoadColumn } from '../../../../atoms/columns/defs/dynamicLoad'
import { createIdColumn } from '../../../../atoms/columns/defs/id'
import { createLiveEtaColumn } from '../../../../atoms/columns/defs/liveEta'
import { createLockedColumn } from '../../../../atoms/columns/defs/locked'
import { createOrdersColumn } from '../../../../atoms/columns/defs/orders'
import { createOrdersDoneColumn } from '../../../../atoms/columns/defs/ordersDone'
import { createOrdersToDoColumn } from '../../../../atoms/columns/defs/ordersToDo'
import { createRouteEndColumn } from '../../../../atoms/columns/defs/routeEnd'
import { createRouteStartColumn } from '../../../../atoms/columns/defs/routeStart'
import { createStopsColumn } from '../../../../atoms/columns/defs/stops'
import { createTimeLeftColumn } from '../../../../atoms/columns/defs/timeLeft'
import { createTotalTimeColumn } from '../../../../atoms/columns/defs/totalTime'
import { createVehicleColumn } from '../../../../atoms/columns/defs/vehicle'
import { createViolationsColumn } from '../../../../atoms/columns/defs/violations'
import { createVisibilityColumn } from '../../../../atoms/columns/defs/visibility'
import { getSchedulerPreferences } from '../../../getSchedulerPreferences'

export const DEFAULT_COLUMN_WIDTH = 140

export function getColumnsFromConfig(
  columnsConfig: SchedulerColumn[],
): Partial<TemplateColumnConfig>[] {
  const state = store.getState()

  const liveEtaActive = selectLiveEta(state)
  const calendarizedLoads = selectRoutesLoads(state)
  const { planType } = selectUserConfiguration(state)

  let vehicleNameColumnAdded = false
  const addedDynamicLoads = new Set<string>()
  const columns = columnsConfig.reduce<Partial<TemplateColumnConfig>[]>((acc, column) => {
    switch (column.id) {
      case 'vehicleName':
      case 'dateAsString':
        if (vehicleNameColumnAdded) break

        vehicleNameColumnAdded = true

        switch (getSchedulerPreferences().groupBy) {
          case 'dateAsString':
            acc.push(createVehicleColumn(column.width, !column.active))
            break

          case 'vehicleId':
            acc.push(createDateColumn(column.width, !column.active))
            break
        }
        break

      case 'driverName':
        acc.push(createDriverColumn(column.width, !column.active))
        break

      case 'warnings':
        acc.push(createViolationsColumn(column.width, !column.active))
        break

      case 'orders':
        acc.push(createOrdersColumn(column.width, !column.active))
        break

      case 'stopsCount':
        acc.push(createStopsColumn(column.width, !column.active))
        break

      case 'approvedTime':
        acc.push(createApprovedTimeColumn(column.width, !column.active))

        break

      case 'totalTime':
        acc.push(createTotalTimeColumn(column.width, !column.active))
        break

      case 'approvedMileage':
        acc.push(createApprovedDistanceColumn(column.width, !column.active))
        break

      case 'distanceLeft':
        acc.push(createDistanceLeftColumn(column.width, !column.active))
        break

      case 'timeLeft':
        acc.push(createTimeLeftColumn(column.width, !column.active))
        break

      case 'ordersToDo':
        acc.push(createOrdersToDoColumn(column.width, !column.active))
        break

      case 'ordersExecuted':
        acc.push(createOrdersExecutedColumn(column.width, !column.active))
        break

      case 'ordersExecutedDone':
        acc.push(createOrdersDoneColumn(column.width, !column.active))
        break

      case 'ordersExecutedReschedule':
        acc.push(createOrdersNotCompletedColumn(column.width, !column.active))
        break

      case 'ordersExecutedUndeclared':
        acc.push(createOrdersUndeclaredColumn(column.width, !column.active))
        break

      case 'routeCost':
        acc.push(createCostColumn(column.width, !column.active))
        break

      case 'liveEta':
        acc.push(createLiveEtaColumn(column.width, !column.active))
        break

      case 'routeStart':
        acc.push(createRouteStartColumn(column.width, !column.active))
        break

      case 'routeEnd':
        acc.push(createRouteEndColumn(column.width, !column.active))
        break

      default:
        const prefix = getDynamicLoadsPrefix()
        if (!column.id.startsWith(prefix)) break

        const loadName = column.id.replace(prefix, '')
        addedDynamicLoads.add(loadName)
        acc.push(
          createDynamicLoadColumn(
            `dynamic-load-${encodeURIComponent(loadName)}`,
            loadName,
            column.width,
            !column.active,
          ),
        )
    }
    return acc
  }, [])

  // Verify if needed to add dynamic load columns
  for (const load of calendarizedLoads) {
    if (addedDynamicLoads.has(load)) continue

    // If the column is not present in the store, add it
    columns.push(
      createDynamicLoadColumn(
        `dynamic-load-${encodeURIComponent(load)}`,
        load,
        DEFAULT_COLUMN_WIDTH,
        true,
      ),
    )
  }

  // Verify if needed to add Live ETA column

  const liveEtaColumnIndex = columns.findIndex(c => c.field === 'liveEta')
  if (liveEtaActive && liveEtaColumnIndex === -1) {
    columns.push(createLiveEtaColumn(DEFAULT_COLUMN_WIDTH, false))
  }

  if (!liveEtaActive && liveEtaColumnIndex !== -1) {
    columns.splice(liveEtaColumnIndex, 1)
  }

  // Verify if needed to add/remove Driver name column
  const isSimulation = planType === 'simulation'
  const driverNameColumnIndex = columns.findIndex(c => c.field === 'driverName')
  if (!isSimulation && driverNameColumnIndex === -1) {
    columns.splice(2, 0, createDriverColumn(DEFAULT_COLUMN_WIDTH, false))
  }

  if (isSimulation && driverNameColumnIndex !== -1) {
    columns.splice(driverNameColumnIndex, 1)
  }

  // Add id column at the beginning
  columns.unshift(createIdColumn())

  // Add visibility and locked columns at the end
  columns.push(createLockedColumn(), createVisibilityColumn())

  // Add vehicle name / date column if required
  if (!vehicleNameColumnAdded) {
    switch (getSchedulerPreferences().groupBy) {
      case 'dateAsString':
        columns.splice(1, 0, createVehicleColumn(DEFAULT_COLUMN_WIDTH, false))
        break
      case 'vehicleId':
        columns.splice(1, 0, createDateColumn(DEFAULT_COLUMN_WIDTH, false))
        break
    }
  }

  return columns
}
