import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Chip, type AutocompleteProps, FormHelperText } from '@mui/material'

import { MultiSelect } from '@workwave-tidal/core/components'

import { selectTerritories, selectTerritory } from '@/features/domain/territory'
import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../hooks/useTexts'

interface Props {
  onTerritoriesSelectionChange: (territoryIds: string[]) => void
}

type RenderTags<OPTION> = Required<AutocompleteProps<OPTION, true, false, false>>['renderTags']

const autocompleteProps = {
  isOptionEqualToValue: (option, value) => {
    return option.id === value.id
  },
  ListboxProps: {
    sx: {
      maxHeight: 152,
    },
  },
}
const getLabel = (e: uui.domain.client.rm.Territory) => e.name
const getId = (e: uui.domain.client.rm.Territory) => e.id

export function Body(props: Props) {
  const { onTerritoriesSelectionChange } = props

  const {
    data: { territoriesErrorText, territoryIds },
  } = useController()

  const currentTerritory = useSelector(selectTerritory)
  const territories = useSelector(selectTerritories)
  const texts = useTexts()

  const initialTerritories = useMemo(() => {
    if (territoryIds.length === 0) return [currentTerritory]
    return territoryIds.map(id => territories[id])
  }, [currentTerritory, territories, territoryIds])

  const [selectedTerritories, setSelectedTerritories] =
    useState<uui.domain.client.rm.Territory[]>(initialTerritories)

  const items = useMemo(() => {
    return Object.keys(territories).map(id => {
      return territories[id]
    })
  }, [territories])

  const getOptionDisabled = useCallback(o => o.id === currentTerritory.id, [currentTerritory])

  const renderSummary = useCallback<RenderTags<uui.domain.client.rm.Territory>>(
    (selectedOptions, getTagProps, ownerState) => {
      // Check if selected items are more than the limit an slice the array if necessary
      const limit = ownerState.focused ? 10 : 10
      const length = limit > 0 ? limit : selectedOptions.length

      const output = selectedOptions
        .slice(0, length)
        .map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            deleteIcon={option.id === currentTerritory.id ? <></> : undefined}
            disabled={option.id === currentTerritory.id}
            key={`chip-${index}`}
          />
        ))

      // If the selected item tags are not all shown, print a '+xxx' message
      const more = selectedOptions.length - limit

      if (more > 0) {
        output.push(<span key={output.length}>{texts.plusItems(more)}</span>)
      }

      return output
    },
    [currentTerritory, texts],
  )

  const onChange = useCallback(
    (territories: uui.domain.client.rm.Territory[]) => {
      setSelectedTerritories([
        currentTerritory,
        ...territories.filter(t => t.id !== currentTerritory.id),
      ])
    },
    [currentTerritory],
  )

  useEffect(() => {
    onTerritoriesSelectionChange(selectedTerritories.map(t => t.id))
  }, [onTerritoriesSelectionChange, selectedTerritories])

  const showError = selectedTerritories.length < 2 && !!territoriesErrorText

  return (
    <Stack height="100%" overflow="auto" padding={4} maxWidth={600}>
      <MultiSelect<uui.domain.client.rm.Territory>
        autocompleteProps={autocompleteProps}
        getOptionDisabled={getOptionDisabled}
        renderSummary={renderSummary}
        placeholder={texts.multiterritoryDropdownPlaceholder}
        optionMode="normal"
        inputMode="summary"
        getLabel={getLabel}
        onChange={onChange}
        density="compact"
        testId="export-routes-select-territories"
        error={showError}
        getId={getId}
        items={items}
        label={texts.multiterritoryDropdownLabel}
        value={selectedTerritories}
      />
      <FormHelperText error={showError}>{territoriesErrorText}</FormHelperText>
    </Stack>
  )
}
