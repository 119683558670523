import { type ReactNode, useCallback, useMemo, useState, useEffect } from 'react'
import { type SelectChangeEvent, Typography, Stack, Button } from '@mui/material'

import { useSelector } from 'react-redux'
import { SimpleSelect, TextField } from '@workwave-tidal/core/components'
import { selectTelematicsDevicesAsArrayWithActiveStatus } from '@/features/domain/device'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'

/**
 * 
 *    deviceId: string
      errorMessage: string
      statusCode: 'OK' | 'ERROR' | 'PENDING'
      active: boolean
 */
export function DeviceStatusTester() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [activeStatus, setActiveStatus] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<'OK' | 'ERROR' | 'PENDING' | 'DELETING' | undefined>(
    undefined,
  )
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined)

  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const devices = useSelector(selectTelematicsDevicesAsArrayWithActiveStatus)

  const options = useMemo(() => {
    return Object.values(devices)
      .map(device => {
        const vehicle = vehicles[vehiclesByVehicleId[device.correlationHandle]]
        return {
          label: `${device.deviceLabel ?? device.deviceId} (${
            vehicle.hasRoutingLicense ? vehicle.vehicle.externalId : 'Unknown vehicle'
          })`,
          value: device.deviceId,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [vehicles, vehiclesByVehicleId, devices])

  const onChangeDevice = useCallback((event: SelectChangeEvent<string>, _child: ReactNode) => {
    setDeviceId(event.target.value)
  }, [])

  const onChangeActiveStatus = useCallback(
    (event: SelectChangeEvent<string>, _child: ReactNode) => {
      setActiveStatus(event.target.value)
    },
    [],
  )

  const onChangeStatus = useCallback(event => {
    setStatus(event.target.value)
  }, [])

  const onChangeErrorMessage = useCallback(event => {
    setErrorMessage(event.target.value)
  }, [])

  const onSubmit = useCallback(() => {
    globalThis.testActions.telematics.setTelematicsDeviceStatus({
      errorMessage,
      deviceId,
      statusCode: status,
      active: activeStatus === 'true' ? true : false,
    })
  }, [deviceId, activeStatus, status, errorMessage])

  const device = useMemo(() => {
    return devices.find(device => device.deviceId === deviceId)
  }, [devices, deviceId])

  const uv = useMemo(() => {
    if (!device) return

    const uv = vehicles[vehiclesByVehicleId[device.correlationHandle]]

    if (!uv.hasRoutingLicense) {
      throw new Error('Vehicle does not have a routing license')
    }

    return uv
  }, [device, vehiclesByVehicleId, vehicles])

  useEffect(() => {
    if (!device) return

    const uv = vehicles[vehiclesByVehicleId[device.correlationHandle]]

    if (!uv.hasRoutingLicense) {
      throw new Error('Vehicle does not have a routing license')
    }

    setActiveStatus(device.active ? 'true' : 'false')
    setStatus(uv.vehicle.gpsDeviceStatus)
    setErrorMessage(uv.vehicle.gpsDeviceErrorMessage)
  }, [vehiclesByVehicleId, vehicles, device])

  return (
    <>
      <Typography variant="h6">Device status tester</Typography>

      <Stack spacing={3} margin={2}>
        <SimpleSelect value={deviceId} options={options} onChange={onChangeDevice} label="Device" />

        <hr />

        {deviceId && (
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Stack spacing={1} margin={2} flex={1}>
              <Typography variant="h6">Current values</Typography>

              <Typography variant="body1">Id: {deviceId}</Typography>
              <Typography variant="body1">
                Active: {device ? (device.active ? `Yes` : `No`) : ''}
              </Typography>

              <Typography variant="body1">Status: {uv?.vehicle.gpsDeviceStatus}</Typography>

              <Typography variant="body1">
                Error message: {uv?.vehicle.gpsDeviceErrorMessage}
              </Typography>
            </Stack>

            <Stack spacing={2} flex={1}>
              <Typography variant="h6">New values</Typography>

              <SimpleSelect
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={onChangeActiveStatus}
                value={activeStatus}
                label="Active"
              />

              <SimpleSelect
                options={[
                  { label: 'OK', value: 'OK' },
                  { label: 'ERROR', value: 'ERROR' },
                  { label: 'PENDING', value: 'PENDING' },
                ]}
                onChange={onChangeStatus}
                value={status}
                label="Active"
              />
              <TextField
                value={errorMessage}
                label="Error message"
                onChange={onChangeErrorMessage}
              />
              <Button onClick={onSubmit} variant="contained">
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}
