import { padLeft } from '@workwave-tidal/core/javascript-utils/string/padLeft'

import type { DurationDisplayValue } from '../../types'
import { SEPARATOR_CHARACTER } from './const'

export function parseDurationHHMMSS(rawDisplayValue: DurationDisplayValue) {
  if (rawDisplayValue.length === 0) return null

  // 6 is the maximum digits for hh:mm:ss
  if (rawDisplayValue.length > 8) return 'invalidFormat'

  // required to match 10000 as 1hr 0min 0sec instead of 10hr 0min 0sec
  const displayValue = rawDisplayValue.includes(SEPARATOR_CHARACTER)
    ? rawDisplayValue
    : padLeft(rawDisplayValue.trim(), 6, '0')

  const regexBody = `^(?<hh>[0-9][0-9]?)(${SEPARATOR_CHARACTER}?(?<mm>[0-9]|[0-5][0-9])?)?(${SEPARATOR_CHARACTER}?(?<ss>[0-9]|[0-5][0-9])?)?$`
  const regex = new RegExp(regexBody)

  const regRes = regex.exec(displayValue)

  if (regRes === null || regRes.groups === undefined) {
    return 'invalidFormat'
  }

  return Number(regRes.groups.hh ?? 0) * 3600 + Number(regRes.groups.mm ?? 0) * 60 + Number(regRes.groups.ss ?? 0)
}
