import type { ActionPermission } from '../../../../../hooks/useVehiclesNavigoMachine/types'

import { CircularProgress } from '@mui/material'
import { useMemo } from 'react'

import { useIsLoading } from '@/atoms'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Breadcrumbs, NoBreadcrumbs } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission:
    | ActionPermission
    | 'disabledNoDevice'
    | 'disabledLoadingGpsData'
    | 'disabledMultipleVehicles'
    | 'disabledTelematicsDeviceInActivation'
  vehicles: uui.domain.client.UnifiedVehicle[]
  status: uui.domain.ui.map.markers.MapMode
  onClick: () => void
}

export function BreadcrumbsButton(props: Props) {
  const { permission, status, vehicles, onClick } = props

  const isLoadingBreadcrumbs = useIsLoading('fetchBreadcrumbs')
  const isLoadingGpsData = permission === 'disabledLoadingGpsData'
  const texts = useTexts()

  const disabled = permission.startsWith('disabled')

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledNoDevice':
        return vehicles.length === 1
          ? texts.disabledNoDevice(
              vehicles[0].hasRoutingLicense ? vehicles[0].vehicle.externalId : '',
            )
          : ''

      case 'disabledTelematicsDeviceInActivation':
        return texts.disabledTelematicsDeviceInActivation

      case 'disabledLoadingGpsData':
        return texts.disabledLoadingGpsData

      case 'disabledMultipleVehicles':
        return texts.disabledMultipleVehicles

      case 'disabled':
        return texts.disabledNotAvailableInThePast

      default:
        return ''
    }
  }, [texts, permission, vehicles])

  if (permission === 'hidden') return null

  const iconColor = disabled ? '$silver' : '$nightRider'

  const Icon =
    isLoadingBreadcrumbs || isLoadingGpsData ? (
      <CircularProgress size={16} color="inherit" />
    ) : status === 'on' ? (
      <Breadcrumbs size={16} color={iconColor} />
    ) : (
      <NoBreadcrumbs size={16} color={iconColor} />
    )

  return (
    <Tooltip placement="top" title={tooltipTitle} disabled={!disabled}>
      <NavigoIconButton
        Icon={Icon}
        onClick={onClick}
        disabled={disabled}
        text={texts.breadcrumbs}
        testid="navigo-vehicle-overview-actions-breadcrumbs"
        data-breadcrumbs-enabled={status === 'on'}
      />
    </Tooltip>
  )
}
