import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import { useFormMeta } from '@workwave-tidal/form-fairy'

import { useTexts } from '../hooks/useTexts'

interface Props {
  onDiscard: () => void
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}

export function RouteDetailsFormFooter(props: Props) {
  const { onDiscard, onSubmit } = props

  const {
    meta: { submitting },
  } = useFormMeta()

  const texts = useTexts()

  return (
    <Stack
      spacing={2}
      direction="row"
      component="footer"
      alignItems="flex-start"
      paddingTop={4}
      paddingLeft={4}
      paddingRight={4}
    >
      <LoadingButton
        size="medium"
        color="primary"
        variant="contained"
        onClick={onSubmit}
        loading={submitting}
        data-testid="routeDetailFormSaveButton"
        data-trackid="routeDetailFormSaveButton"
      >
        {texts.save}
      </LoadingButton>

      <Button
        type="button"
        size="medium"
        color="primary"
        onClick={onDiscard}
        variant="text"
        data-testid="routeDetailFormCancelButton"
        data-trackid="routeDetailFormCancelButton"
      >
        {texts.cancel}
      </Button>
    </Stack>
  )
}
