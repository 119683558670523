import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { RoadsRoot } from './components/form'

import { useEnsureRoadSegmentVisibility } from './hooks/useEnsureRoadSegmentVisibility'

import { Box, Stack } from '@mui/material'

export function Roads() {
  useEnsureRoadSegmentVisibility()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="setup-road-editor-root">
      <RoadsRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%" flexShrink={0}>
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
