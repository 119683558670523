import type { DrivingTimeStep } from '@/forms-legacy'

function getBadgeText(value: number): string {
  return `${parseFloat(Math.abs(value / 100).toFixed(2))}x`
}

const steps: DrivingTimeStep[] = [
  {
    label: '0.5x',
    value: -50,
  },
  {
    label: '1x',
    value: -100,
  },
  {
    label: '2x',
    value: -200,
  },
  {
    label: '3x',
    value: -300,
  },
  {
    label: '4x',
    value: -400,
  },
  {
    label: '5x',
    value: -500,
  },
  {
    label: '6x',
    value: -600,
  },
]

function getCoordinatesStyle(val: number, max: number) {
  const left = `${(1 - Math.abs(val + 50) / (max - 50)) * 100}%`

  return {
    left,
  }
}

export const drivingTimeSliderFieldWrappedProps = {
  min: -600,
  max: -50,
  steps,
  scaleMax: 600,
  getBadgeText,
  getCoordinatesStyle,
  step: 1,
}
