import type { DurationDisplayValue, DurationFormat, DurationParseResult } from '../../types'

type NoGroupsDurationFormat = Extract<DurationFormat, 'hh' | 'mm' | 'ss'>

export function parseDurationWithNoGroups(
  displayValue: DurationDisplayValue,
  format: NoGroupsDurationFormat,
): DurationParseResult {
  if (displayValue.length === 0) return null

  const isOnlyNumbersRegex = /^[0-9]*$/
  if (!isOnlyNumbersRegex.test(displayValue)) return 'invalidFormat'

  return Number.parseInt(displayValue) * multipliers[format]
}

const multipliers: Record<NoGroupsDurationFormat, number> = {
  hh: 3600,
  mm: 60,
  ss: 1,
}
