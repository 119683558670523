import { Box } from '@mui/material'

import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

const growStyle = { height: 42 }

interface Props {
  routeInfos: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[]
}

export function Stats(props: Props) {
  const { routeInfos } = props

  if (routeInfos.length === 0) return null

  return (
    <Box
      data-trackid="navigo-routes-overview-stats"
      data-testid="navigo-routes-overview-stats"
      sx={growStyle}
      width="100%"
      flex="0 1 auto"
    >
      {routeInfos.length > 1 ? (
        <Bulk routeInfos={routeInfos} />
      ) : (
        <Single routeInfo={routeInfos[0]} />
      )}
    </Box>
  )
}
