import { useState } from 'react'

import { SingleEdit } from './SingleEdit'
import { SelectRecurrencePanel } from './components/SelectRecurrencePanel'

interface Props {
  selectedDriverAssignment?: uui.domain.client.rm.DriverAssignmentsWithId
  driverAssignments: Record<string, uui.domain.client.rm.DriverAssignmentsWithId>
}

export function SingleEditProvider(props: Props) {
  const { selectedDriverAssignment, driverAssignments } = props

  const [driverAssignment, setDriverAssignment] = useState<
    uui.domain.client.rm.DriverAssignmentsWithId | undefined
  >(() => selectedDriverAssignment)

  if (driverAssignment) {
    return <SingleEdit driverAssignment={driverAssignment} initialFormData={{ assignments: {} }} />
  }

  return (
    <SelectRecurrencePanel
      setDriverAssignment={setDriverAssignment}
      driverAssignments={driverAssignments}
    />
  )
}
