import type { DurationDisplayValue, DurationFormat, DurationParseResult } from '../../types'
import { createParseDurationCache } from './createParseDurationCache'
import { parseDurationHHMM } from './parseDurationHHMM'
import { parseDurationHHMMSS } from './parseDurationHHMMSS'
import { parseDurationWithNoGroups } from './parseDurationNoGroups'

const { getValue, setValue } = createParseDurationCache()

export function parseDuration(rawDisplayValue: DurationDisplayValue, format: DurationFormat): DurationParseResult {
  const displayValue = rawDisplayValue.trim()

  const cachedValue = getValue(displayValue, format)
  if (cachedValue) return cachedValue

  const result = computeResult(displayValue, format)
  setValue(displayValue, format, result)
  return result
}

function computeResult(displayValue: DurationDisplayValue, format: DurationFormat): DurationParseResult {
  switch (format) {
    case 'hh':
    case 'mm':
    case 'ss':
      return parseDurationWithNoGroups(displayValue, format)

    case 'hh:mm':
      return parseDurationHHMM(displayValue)

    case 'hh:mm:ss':
      return parseDurationHHMMSS(displayValue)
  }
}
