import type { FormValues, LazyCreateFormConfig } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from '../formFields'
import type { Creation } from '../types'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectTerritories } from '@/features/domain/territory'
import { selectCompanies } from '@/features/domain/company'

import { useValidations } from './useValidations'
import { territoryFilterMutation } from './mutations/territoryFilterMutation'

export function useFormConfig(creation: Creation) {
  const { creating } = creation
  const validations = useValidations(creating)
  const territories = useSelector(selectTerritories)
  const companies = useSelector(selectCompanies)

  const [formConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      validations,
      formMutations: [{ id: 'territoryFilterMutation', mutation: territoryFilterMutation }],
      overrideValues: creation.creating
        ? {
            limitedAccess: false,
            type: 'planner',
          }
        : computeEditOverrideValues(creation.user, territories, companies),
    })
  })

  return { formConfig }
}

function computeEditOverrideValues(
  user: uui.domain.client.rm.RouteManagerUser,
  territories: ReturnType<typeof selectTerritories>,
  companies: ReturnType<typeof selectCompanies>,
): Partial<FormValues<FormFields>> {
  const territoryFilter = [...user.territoryFilter]
  const { name, surname, username, email, id, type, enabled } = user

  const companiesValue =
    user.companies.length > 0 ? user.companies.map(companyId => companies[companyId]) : []

  const values = {
    id,
    name,
    type,
    email,
    limitedAccess: false,
    surname,
    enabled,
    username,
    territoryFilter: [] as uui.domain.client.rm.Territory[],
    courierFilter: user.type === 'courier' ? user.courierFilter : {},
    isCompanyFiltered: user.type === 'guest' ? user.isCompanyFiltered : false,
    companies: companiesValue,
  }

  if (territoryFilter[0] !== '*') {
    values.limitedAccess = true
    values.territoryFilter = Object.values(territories).filter(territory =>
      territoryFilter.includes(territory.id),
    )
  }

  return values
}
