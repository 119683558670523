import { type FormControlLabelProps } from '@mui/material/FormControlLabel'
import {
  type Color,
  type ColorValue,
  Palette,
  SelectTrigger,
} from '@workwave-tidal/core/components'
import {
  type FormFieldDescriptor,
  type NarrowFieldsByValueType,
  ColorPicker as TidalColorPicker,
  type ColorPickerProps as TidalColorPickerProps,
  type TidalFormBridgeOptions,
  type TidalFormState,
} from '@workwave-tidal/core/form'
import { useMemo, useState } from 'react'

import { colorPalette } from '@/server-data'
import { colorsUtils } from '@/utils'
import { colorNames } from './colorNames'

export type ColorPickerValue = string

export type ColorPickerProps<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = ColorPickerValue,
> = {
  formControlLabelProps?: Partial<FormControlLabelProps>
} & TidalColorPickerProps<TargetField, State, AcceptedFormValue> &
  TidalFormBridgeOptions<ColorPickerValue, TargetField, State, AcceptedFormValue>

export function ColorPicker<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = ColorPickerValue,
>(props: ColorPickerProps<TargetField, State, AcceptedFormValue>) {
  const { colorPickerProps: componentColorPickerProps } = props
  const [open, setOpen] = useState(false)

  const palette: Color[] = useMemo(() => {
    return colorPalette.map((c: string) => {
      return {
        color: colorsUtils.getValidHexColor(c) as ColorValue,
        label: colorNames[c],
      }
    })
  }, [colorPalette])

  /**
   * Get the user configuration from the store to use the colorPicker and language
   * to format the colorPicker input.
   */
  const defaultColorPickerProps: Partial<
    ColorPickerProps<TargetField, State, AcceptedFormValue>['colorPickerProps']
  > = {
    trigger: {
      Component: SelectTrigger,
      open,
      onSetOpen: setOpen,
      sx: {
        width: 85,
      },
    },
  }

  // Merge the default colorPicker props with the props passed to the component.
  const colorPickerProps = useMemo(() => {
    return {
      ...defaultColorPickerProps,
      ...componentColorPickerProps,
    }
  }, [defaultColorPickerProps, componentColorPickerProps])

  return (
    <TidalColorPicker<TargetField, State, AcceptedFormValue>
      palette={<Palette palette={palette} />}
      {...props}
      colorPickerProps={colorPickerProps}
    />
  )
}
