import { Typography } from '@mui/material'
import type { PropsWithChildren } from 'react'

interface Props {
  color?: string
}

export function Value(props: PropsWithChildren<Props>) {
  const { children, color = 'text.primary' } = props

  return (
    <Typography variant="body1" color={color}>
      {children}
    </Typography>
  )
}
