import type { FormErrors, FormFields } from '../formFields'
import { useState } from 'react'
import { FormValues, LazyCreateFormConfig } from '@workwave-tidal/form-fairy'

import { useAutomatedMessageValidation } from '../components/fields/hooks/useAutomatedMessageValidation'
import { usePhoneValidation } from '../components/fields/hooks/usePhoneValidation'

export function useFormConfig(
  overrideValues: uui.domain.server.rm.VoiceConfig,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const initValues: Partial<FormValues<FormFields>> = {}

  initValues.status = overrideValues?.status

  if (overrideValues?.status === 'ADVANCED') {
    initValues.forbidDriverCallsToCompletedOrderSteps =
      overrideValues.forbidDriverCallsToCompletedOrderSteps
    initValues.forbidDriverCallsToIncompleteOrderSteps =
      overrideValues.forbidDriverCallsToIncompleteOrderSteps
    initValues.callbackConfigStatus = overrideValues.callbackConfig.status || 'BACK_OFFICE'

    if (overrideValues?.callbackConfig.status === 'BACK_OFFICE') {
      initValues.backOfficePhone = overrideValues.callbackConfig.backOfficePhone
    } else {
      initValues.automatedMessageEnabled = overrideValues.callbackConfig?.automatedMessageEnabled
        ? 'ON'
        : 'OFF'
      initValues.automatedMessage = overrideValues.callbackConfig?.automatedMessage || ''
      initValues.automatedMessageVoice =
        overrideValues.callbackConfig?.automatedMessageVoice || 'FEMALE'
    }
  }

  const phoneValidation = usePhoneValidation()
  const automatedMessageValidation = useAutomatedMessageValidation()
  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues: initValues,
      validations: [
        {
          id: 'phoneValidation',
          fields: 'backOfficePhone',
          validator: phoneValidation,
        },
        {
          id: 'automatedMessageValidation',
          fields: 'automatedMessage',
          validator: automatedMessageValidation,
        },
      ],
    })
  })

  return createFormConfig
}
