import { useCallback } from 'react'
import { resetCrudSelection, useEditingStateWithCtx } from '@/atoms'
import { useOnExport } from './useOnExport'
import { useDeleteRegionModal } from '../../../../../modals/DeleteRegionModal'

export function useActions(region: uui.domain.client.rm.Region) {
  const { setEditing } = useEditingStateWithCtx('region')
  const { show: showDeleteRegionModal } = useDeleteRegionModal()

  const onEdit = useCallback(() => {
    setEditing([region.id])
  }, [setEditing, region])

  const onExport = useOnExport(region)

  const onDelete = useCallback(() => {
    showDeleteRegionModal()
  }, [showDeleteRegionModal])

  const onReset = useCallback(() => {
    resetCrudSelection('regions')
  }, [])

  return {
    onDelete,
    onEdit,
    onExport,
    onReset,
  }
}
