import { FormConfig } from '@workwave-tidal/core/form'
import { useMemo } from 'react'

import { FormContent } from './Form'
import { Footer } from './components/Footer'
import { form } from './formState'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'
import type { RegionFormValues } from './typings'
import { computeFormInitialValues } from './utils'

interface Props {
  region: uui.domain.client.rm.Region
  regions: Record<string, uui.domain.client.rm.Region>
}

export function SingleEdit(props: Props) {
  const { region, regions } = props

  const onSubmit = useOnSubmit()
  const texts = useTexts()

  const config = useMemo<FormConfig<RegionFormValues>>(
    () => ({
      id: 'region-form',
      initialValues: computeFormInitialValues(regions, region),
    }),
    [regions, region],
  )

  return (
    <form.Form
      title={texts.editTitle}
      footer={<Footer />}
      onSubmit={onSubmit}
      config={config}
      requireTransaction
      stopEditingOnUnmount
      applyPendingFitMapOnMount
    >
      <FormContent />
    </form.Form>
  )
}
