import type { FormErrors, FormFields } from '../../formFields'

import { Checkbox } from '@workwave-tidal/form-ui'

import { useTexts } from '../../useTexts'

export function NotificationBackup() {
  const texts = useTexts()

  return (
    <Checkbox<'backupNotification', FormFields, FormErrors>
      helperText={texts.backupNotificationHelperText}
      label={texts.backupNotificationLabel}
      name="backupNotification"
      testId="backupNotification"
      validateOn="blur focus"
    />
  )
}
