import type { RemoteObject } from 'comlink'

import { proxy } from 'comlink'

import { getApplicationMode } from '@/atoms'
import { intl } from '@/intl'
import { create as createDomainStore, journal } from '@/server-data'

import { store } from '../store'

import { injectCookie, removeInjectedCookie } from './utils/cookie'
import { handleForcefulClose } from './utils/handleForcefulClose'
import { handleNewClientVersionAvailable } from './utils/handleNewClientVersionAvailable'
import { handleTooManySessionsError } from './utils/handleTooManySessionsError'
import { isActionAllowedInReadonly } from './utils/isActionAllowedInReadonly'

type DomainStore = RemoteObject<uui.domain.api.Store>

type Create = (
  apiServerUrl: string,
  profile: uui.domain.client.UserProfile,
  sessionTokenFromCookie?: string,
) => Promise<uui.domain.PublicData | 'tooManySessionsError' | false>

type Unsubscribe = () => void
type Disconnect = () => Promise<boolean>

export type DomainProxy = {
  silent: boolean
  create: Create
  preventNotifications: (silent: boolean) => void
  subscribeToDataChangeRequest: (cb: uui.domain.api.StateChangeHandler) => Unsubscribe

  subscribeToDataChangeSuccess: (cb: uui.domain.api.StateChangeHandler) => Unsubscribe
  subscribeToForcefulClose: (cb: uui.domain.api.ForcefulCloseHandler) => Unsubscribe
  notifyChangeDataSuccess: (action: uui.domain.api.StateChangeAction) => void

  subscribeToNotifications: (
    cb: uui.domain.api.NotificationHandler,
    filterBy: uui.domain.api.Notification['notificationType'][],
  ) => Unsubscribe

  subscribeToUiEvents: (
    cb: uui.domain.api.UiEventHandler,
    filterBy: uui.domain.api.UiEvent['uiEventType'][],
  ) => Unsubscribe

  disconnect: Disconnect
  getRpc: () => uui.domain.api.Rpc
  areNotificationsPrevented: () => boolean
}

let domainStore: DomainStore | null = null

const changeDataRequestSubscribers = new Set<uui.domain.api.StateChangeHandler>()

const changeDataSuccessSubscribers = new Set<uui.domain.api.StateChangeHandler>()

const notificationSubscribers = new Set<{
  cb: uui.domain.api.NotificationHandler
  filterBy: uui.domain.api.Notification['notificationType'][]
}>()

const uiEventSubscribers = new Set<{
  cb: uui.domain.api.UiEventHandler
  filterBy: uui.domain.api.UiEvent['uiEventType'][]
}>()

const forcefulCloseSubscribers = new Set<uui.domain.api.ForcefulCloseHandler>()

export const domainProxy: DomainProxy = {
  silent: false,
  create: async (
    apiServerUrl: string,
    profile: uui.domain.client.UserProfile,
    sessionTokenFromCookie?: string,
  ) => {
    // Navigate to the target page
    try {
      if (domainStore) {
        domainStore.disconnect()
        domainStore = null
      }

      domainProxy.silent = false

      domainStore = await createDomainStore()

      if (!domainStore) {
        throw new Error('Domain store creation error')
      }

      const initialDomainState = await domainStore.connect(
        apiServerUrl,
        profile,
        sessionTokenFromCookie,
      )

      switch (initialDomainState) {
        case 'tooManySessions':
          domainStore.disconnect()
          domainStore = null
          return 'tooManySessionsError'

        case 'loginFailed':
          journal.main('Impossible to connect to the Domain data WebWorker', {}, 'error')
          return false
      }

      domainStore.onChange(
        proxy((action: uui.domain.api.StateChangeAction) => {
          // TODO: it needs to react only to the disconnect event to properly cleanup subscribers
          // console.info(action)
          changeDataRequestSubscribers.forEach(sub => sub(action))
        }),
      )

      domainStore.onNotify(
        proxy((notification: uui.domain.api.Notification) => {
          switch (notification.notificationType) {
            case 'tooManySessions':
              handleTooManySessionsError()
              break

            case 'newClientVersionAvailable':
              handleNewClientVersionAvailable()
              break

            case 'forcefulClose':
              // Stop notifications and notify listeners for forceful close
              // They will take care to reset the state.
              domainProxy.preventNotifications(true)
              forcefulCloseSubscribers.forEach(sub => sub())

              // setTimeout required to not overlap subscribers and redirect
              setTimeout(() => {
                // Redirect the user to error/logged-out
                handleForcefulClose()

                // Reactivate notifications and disconnect domainProxy
                domainProxy.preventNotifications(false)
                domainProxy.disconnect()
              }, 0)

              break
          }

          handleNotification(notification)
        }),
      )

      domainStore.onUiEvent(
        proxy((uiEvent: uui.domain.api.UiEvent) => {
          handleUiEvent(uiEvent)
        }),
      )

      injectCookie(initialDomainState.profile.cookieUrl)

      return initialDomainState
    } catch (e) {
      journal.main(`WebWorker Unknown error`, { info: e }, 'error')
      return false
    }
  },

  subscribeToDataChangeRequest: (cb: uui.domain.api.StateChangeHandler): Unsubscribe => {
    changeDataRequestSubscribers.add(cb)

    return () => {
      changeDataRequestSubscribers.delete(cb)
    }
  },

  subscribeToDataChangeSuccess: (cb: uui.domain.api.StateChangeHandler): Unsubscribe => {
    changeDataSuccessSubscribers.add(cb)

    return () => {
      changeDataSuccessSubscribers.delete(cb)
    }
  },

  subscribeToForcefulClose: (cb: uui.domain.api.ForcefulCloseHandler): Unsubscribe => {
    forcefulCloseSubscribers.add(cb)

    return () => {
      forcefulCloseSubscribers.delete(cb)
    }
  },

  notifyChangeDataSuccess: (action: uui.domain.api.StateChangeAction) => {
    changeDataSuccessSubscribers.forEach(sub => sub(action))
  },

  subscribeToNotifications: (
    cb: uui.domain.api.NotificationHandler,
    filterBy: uui.domain.api.Notification['notificationType'][],
  ): Unsubscribe => {
    const sub = { cb, filterBy }
    notificationSubscribers.add(sub)

    return () => {
      notificationSubscribers.delete(sub)
    }
  },

  subscribeToUiEvents: (
    cb: uui.domain.api.UiEventHandler,
    filterBy: uui.domain.api.UiEvent['uiEventType'][],
  ): Unsubscribe => {
    const sub = { cb, filterBy }
    uiEventSubscribers.add(sub)

    return () => {
      uiEventSubscribers.delete(sub)
    }
  },

  preventNotifications: (silent: boolean) => {
    domainProxy.silent = silent
  },

  disconnect: async () => {
    if (!domainStore) {
      throw new Error('To disconnect a Domain Store it must be first created.')
    }

    if (!domainStore.initialized) {
      throw new Error('To disconnect a Domain Store it must be first initialized.')
    }

    domainProxy.silent = true

    const disconnected = await domainStore.disconnect()

    if (disconnected) {
      domainStore = null
    }

    removeInjectedCookie()

    return disconnected
  },

  getRpc: () => rpcHandler,

  areNotificationsPrevented: () => {
    return domainProxy.silent
  },
}

const rpcHandler: uui.domain.api.Rpc = (type, action) => {
  if (!domainStore) {
    throw new Error('To use the RPC API a Domain Store it must be first created.')
  }

  if (!domainStore.initialized) {
    throw new Error('To use the RPC API a Domain Store it must be first initialized.')
  }

  const readOnly = getApplicationMode() === 'readonly'
  const userType = store.getState().domain.publicData.profile.user.type

  // If the application is in readonly mode, checks the whitelist before proceed
  if (
    readOnly &&
    !isActionAllowedInReadonly(type) &&
    !isWhitelistedActionByUserType(action, userType)
  ) {
    return Promise.resolve({
      type: 'rpc-failure',
      id: 'rpc-prevented',
      errorCode: -979,
      errorMessage: intl.translate({ id: 'global.transactionInProgress.prevented' }),
    })
  }

  const rpc = domainStore.rpc as uui.domain.api.Rpc

  return rpc(type, action)
}

const handleNotification = (notification: uui.domain.api.Notification) => {
  if (domainProxy.areNotificationsPrevented()) return
  notificationSubscribers.forEach(({ cb, filterBy }) => {
    if (!filterBy || filterBy.length === 0 || filterBy.includes(notification.notificationType)) {
      cb(notification)
    }
  })

  // Forward event to Pendo
  if (notification.notificationType === 'pendo') {
    window.pendo?.track(notification.notificationType, notification.payload)
  }
}

const handleUiEvent = (event: uui.domain.api.UiEvent) => {
  if (domainProxy.areNotificationsPrevented()) return

  journal.main(`UI Event received ${event.uiEventType}`, {
    info: { event },
  })

  uiEventSubscribers.forEach(({ cb, filterBy }) => {
    if (!filterBy || filterBy.length === 0 || filterBy.includes(event.uiEventType)) {
      cb(event)
    }
  })
}

type ActionType = uui.domain.actions.rpc.RpcAction['type']
type UserTypes = uui.domain.rm.RouteManagerUserType[]

type WhiteListRecord = Partial<Record<ActionType, UserTypes>>

function isWhitelistedActionByUserType(
  action: uui.domain.actions.rpc.RpcAction,
  userType: uui.domain.rm.RouteManagerUserType,
) {
  const whiteList: WhiteListRecord = {
    'rpc/transaction/tryOpen': ['courier'],
    'rpc/transaction/tryClose': ['courier'],

    'rpc/driverAssignment/delete': ['courier'],
    'rpc/driverAssignment/update': ['courier'],
    'rpc/driverAssignment/createLocal': ['courier'],
    'rpc/driverAssignment/cancelCreateLocal': ['courier'],

    'rpc/transaction/goto': ['courier'],
    'rpc/transaction/commit': ['courier'],
    'rpc/transaction/rollback': ['courier'],
  }

  return whiteList[action.type]?.includes(userType) ?? false
}
