import type { DriverAssignmentFormState } from '../typings'

import { useCallback, useState } from 'react'

import { useResetEditingState } from '@/atoms'
import { updateDriverAssignments } from '@/features/domain/driverAssignments'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

export function useOnSubmit(recurrence: uui.domain.rm.Recurrence) {
  const stopEditing = useResetEditingState()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const isUnmounted = useIsUnmounted()

  const onSubmit = useCallback(
    async (formValues: DriverAssignmentFormState) => {
      try {
        setSubmitting(true)

        const assignments = { ...formValues.assignments }

        // clear all the null values before calling the updateDriverAssignments
        for (const [vehicleId, driverId] of Object.entries(assignments)) {
          if (driverId === null) {
            assignments[vehicleId] = null
          }
        }

        const thunkResult = await dispatch(updateDriverAssignments({ recurrence, assignments }))

        if (!isUnmounted()) {
          setSubmitting(false)
        }

        if (!updateDriverAssignments.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, stopEditing, toast, recurrence, isUnmounted],
  )

  return {
    submitting,
    onSubmit,
  }
}
