import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { DataGrid } from '@/components/DataGrid'

export const StyledDataGrid = styled(DataGrid)({
  width: '100%',
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
  '& .MuiBadge-badge': {
    top: 5,
    left: 12,
  },
})

export const StyledBox = styled(Box)({
  width: '100%',
})

export const StyledBoxContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexGrow: 1,
})
