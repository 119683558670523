import { theme } from '@/styles'

import { Stack, styled } from '@mui/material'

export const NavigoContainer = styled(Stack, { name: 'AppContent' })({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: ' center',
  width: '100%',
  height: '60px',
  padding: '7px 17px 7px 11px',
  backgroundColor: theme.colors.$pureWhite,

  '& > * + *': {
    marginLeft: '24px !important',
  },
})
