import type { FormErrors, FormFields } from '../formFields'

import { useFormApi } from '@workwave-tidal/form-fairy'

export function useFieldsVisibility(creating: boolean) {
  const formApi = useFormApi<FormFields, FormErrors>()
  const limitedAccess = formApi.getField('limitedAccess')
  const type = formApi.getField('type')

  const limitedAccessFieldVisible =
    type.value === 'viewer' || type.value === 'planner' || type.value === 'guest'

  const canShow = {
    password: creating,
    limitedAccess: limitedAccessFieldVisible,
    territoryFilter: limitedAccess.value && limitedAccessFieldVisible,
    courier: type.value === 'courier',
    companies: type.value === 'guest',
  }

  return { canShow }
}
