import type { FormErrors, FormFields } from '../../formFields'

import { useMemo } from 'react'

import { RadioGroup } from '@workwave-tidal/form-ui'
import { useTexts } from '../../useTexts'

export function Threshold() {
  const texts = useTexts()

  const options = useMemo(
    () => [
      {
        value: '10',
        label: texts.proximityThresholdMinsValue(10),
      },
      {
        value: '20',
        label: texts.proximityThresholdMinsValue(20),
      },
      {
        value: '30',
        label: texts.proximityThresholdMinsValue(30),
      },
    ],
    [texts],
  )

  return (
    <RadioGroup<'proximityThresholdMins', string, FormFields, FormErrors>
      label={texts.proximityThresholdMinsLabel}
      name="proximityThresholdMins"
      testId="proximityThresholdMins"
      validateOn="blur focus"
      options={options}
    />
  )
}
