import type { AsyncThunkApiConfig } from '@/store'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectError } from '../../typings'

/**
 * Update user UI settings on UserProfile (uiData)
 *
 * @private
 */
export const updateUserUiDataSettings = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Partial<uui.domain.server.rm.UserUiDataSettings>,
  // thunk API
  AsyncThunkApiConfig<RejectError>
>('ui/userUiDataSettings/update', async (payload, thunkApi) => {
  try {
    const rpc = thunkApi.extra.domainProxy.getRpc()

    const result = await rpc('rpc/ui/updateUserUiDataSettings', {
      category: 'rpc',
      type: 'rpc/ui/updateUserUiDataSettings',
      payload,
    })

    if (result.type === 'rpc-failure') {
      return thunkApi.rejectWithValue({
        type: 'rpcFailure',
        message: result.errorMessage,
        error: result,
      })
    }
  } catch (error) {
    thunkApi.extra.journal.main(
      `Unknown error on {updateUserUiDataSettings}`,
      { tags: ['rpc', 'ui', 'calendar'], info: payload },
      'error',
    )

    return thunkApi.rejectWithValue({
      type: 'unknown',
      message: `Update User UI settings Failed`,
      error,
    })
  }
})
