import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { NullValue } from './NullValue'

interface Props {
  children: number
}

export function CurrencyValue(props: Props) {
  const { children } = props
  const { currency, language } = useSelector(selectUserConfiguration)

  const formattedValue = useMemo(
    () => new Intl.NumberFormat(language).format(children),
    [children, language],
  )

  return children !== 0 ? `${formattedValue} ${currency.toUpperCase()}` : <NullValue />
}
