import { useMemo } from 'react'

import { Text } from '@/local/components'

import { StyledTitle } from './hooks/useStyles'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'
import { convertLoadValue, roundNumberToHundredths } from './utils'

interface Props {
  name: string
  value: number
  showTitle: boolean
  vehicleLoad?: number
}

export function Load(props: Props) {
  const { name, value, showTitle, vehicleLoad } = props
  const texts = useTexts()
  const title = `${texts.loads}:`

  const { loadValue, color } = useMemo(() => {
    const roundedValue = value === 0 ? '-' : roundNumberToHundredths(value)
    const color = roundedValue === '-' ? ('$silver' as const) : ('$nightRider' as const)

    return {
      loadValue: vehicleLoad
        ? `${roundedValue}/${convertLoadValue(vehicleLoad)} ${name}`
        : `${roundedValue} ${name}`,
      color,
    }
  }, [name, value, vehicleLoad])

  return (
    <Stack
      direction="row"
      data-trackid="navigo-routes-overview-details-single-routeStats-loadsBlock-load"
      data-testid="navigo-routes-overview-details-single-routeStats-loadsBlock-load"
      height="auto"
      width="100%"
    >
      <StyledTitle
        testid="navigo-routes-overview-details-single-routeStats-loadsBlock-load-name"
        showTitle={showTitle}
        color={color}
        size="$p3"
      >
        {title}&nbsp;
      </StyledTitle>

      <Text
        testid="navigo-routes-overview-details-single-routeStats-loadsBlock-load-value"
        weight="$semiBold"
        color={color}
        size="$p3"
      >
        {loadValue}
      </Text>
    </Stack>
  )
}
