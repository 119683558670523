import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { RouteChip } from '../../../components/Navigator/typings'

import { Navigator } from '../../../components/Navigator'
import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'

import { useRoutesNavigator } from '../hooks/useRoutesNavigator'
import { RouteItemRenderer } from './RouteItemRenderer'

import { Stack } from '@mui/material'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'rounded',
}

export function RoutesNavigator() {
  const { chips, page, setPage } = useRoutesNavigator()

  return (
    <Stack
      direction="column"
      height="100%"
      flexShrink={0}
      data-trackid="navigo-routes-navigator"
      data-testid="navigo-routes-navigator"
      justifyContent="center"
      width="auto"
    >
      <Navigator<RouteChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        onChangeIndex={index => setPage(index)}
        ItemRenderer={RouteItemRenderer}
        LayoutRenderer={CollapsedLayoutRenderer}
        extraLayoutRendererProps={renderProps}
        extraItemRendererProps={renderProps}
      />
    </Stack>
  )
}
