import type { FormField, FormError } from '@workwave-tidal/form-fairy'

const locationField: FormField<uui.domain.client.Location> = {
  required: true,
  value: {
    address: '',
    geoAddress: '',
    rawLatLngAsString: '',
    source: 'MANUAL',
    status: 'OK',

    latLng: { lat: 0, lng: 0 },
    rooftopLatLng: { lat: 0, lng: 0 },
  },
}

const labelField: FormField<string> = {
  required: true,
  value: '',
}

export const formFields = {
  originalLocation: { ...locationField },
  newLocation: { ...locationField },
  label: labelField,
}

export type FormFields = typeof formFields
export type FormErrors = FormError<keyof FormFields>
