import { FormError, FormField } from '@workwave-tidal/form-fairy'

const label: FormField<string> = {
  value: '',
  required: true,
}

const sourceId: FormField<string> = {
  value: '',
  required: true,
}

const credentials: FormField<uui.domain.server.gps.telematics.Credential[]> = {
  value: [],
  required: true,
}

// This is an hidden field that is used to display an error message when the credentials are wrong (e.g. wrong API key)
const wrongCredentials: FormField<boolean> = {
  value: false,
  required: false,
}

export const formFields = {
  label,
  sourceId,
  credentials,
  wrongCredentials,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {}
