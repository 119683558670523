import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    multipleValues: translate({ id: 'global.multipleValues' }),
  }))

  return api
}
