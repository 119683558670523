import { styled } from '@mui/material/styles'
import { Separator } from '@/components/Separator'
import { Stack } from '@mui/material'

export const StyledStack = styled(Stack)({
  '& > * + *': {
    marginLeft: 8,
  },
})

export const StyledSeparator = styled(Separator)({
  marginLeft: 14,
})
