import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { useMemo } from 'react'

import { ModalHeader } from '@/components/Modal'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const texts = useTexts()
  const { close, data } = useController()

  const title = useMemo(() => {
    if (data.issue !== 'none') return ''

    return texts.headerTitle(
      data.orderStepStatus,
      data.pairedOrderStepStatus,
      data.pairedOrderStep.type,
    )
  }, [data, texts])

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      title={title}
    />
  )
}
