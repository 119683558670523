import { form } from '../formState'
import { useTexts } from '../hooks/useTexts'

export function Color() {
  const texts = useTexts()

  return (
    <form.ColorPicker
      validateOn="change"
      testId="region-color"
      label={texts.labelColor}
      name="color"
    />
  )
}
