import { useCallback } from 'react'

import { Stack } from '@mui/material'

import { setMainSelection } from '@/atoms'
import { ViolationTag } from '@/components/Navigo/components/ViolationTag'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Text } from '@/local/components'
import * as gis from '@/local/server-data/utils/gis'
import { useNavigate } from '@/routing'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function AddressAndViolations(props: Props) {
  const {
    extOrder,
    extOrder: { id },
  } = props

  const navigate = useNavigate(true)
  const violations =
    !extOrder.isUnassigned && extOrder?.violations ? [...new Set(extOrder.violations)] : undefined

  const address =
    extOrder.atDepot && extOrder.orderStep.atDepot ? extOrder.depot.name : extOrder.location.address

  const latLng = extOrder.atDepot ? extOrder.depot.location.latLng : extOrder.location.latLng

  const onClick = useCallback(() => {
    setMainSelection('orderSteps', [id])
    navigate('orders')
  }, [navigate, id])

  return (
    <Stack direction="row" alignItems="center" height="auto" width="100%">
      {violations ? (
        <ViolationTag
          violations={violations}
          onClick={onClick}
          testId="navigo-order-violation-badge"
        />
      ) : null}

      <div style={{ overflow: 'hidden' }}>
        <Tooltip title={address} placement="top">
          <Text
            ellipsis
            color={gis.isNullIslandLatLng(latLng) ? '$outrageousRed' : undefined}
            size="$s"
          >
            {address}
          </Text>
        </Tooltip>
      </div>
    </Stack>
  )
}
