import { useState } from 'react'

import { type FormValidator, type FormConfig } from '@workwave-tidal/form-fairy'

import { type FormFields, type FormErrors } from '../formFields'

export function useFormConfig(): FormConfig<FormFields, FormErrors> {
  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    validations: createFormValidation(),
  }))

  return formConfig
}

function createFormValidation(): FormConfig<FormFields, FormErrors>['validations'] {
  return [
    {
      id: 'c1',
      fields: 'c1',
      validator: createValidateCheckField('c1'),
    },
    {
      id: 'c2',
      fields: 'c2',
      validator: createValidateCheckField('c2'),
    },
    {
      id: 'c3',
      fields: 'c3',
      validator: createValidateCheckField('c3'),
    },
    {
      id: 'c4',
      fields: 'c4',
      validator: createValidateCheckField('c4'),
    },
  ]
}

const createValidateCheckField =
  (field: keyof FormFields): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField(field)

    const uncheckedError: FormErrors = {
      id: `invalid${field}Error`,
      field,
      message: '',
      priority: 3,
    }

    const notChecked = value === false

    if (notChecked) {
      return {
        [uncheckedError.id]: uncheckedError,
      }
    }

    return {
      [uncheckedError.id]: null,
    }
  }
