import { Box, type BoxProps, styled } from '@mui/material'
import type { ComponentType } from 'react'

export type RootProps = BoxProps

export const Root = styled(Box, {
  slot: 'Root',
})<RootProps>(() => ({
  position: 'relative',
})) as ComponentType<RootProps>
