import { Form } from '@workwave-tidal/form-ui/layout'

import { ModalContent } from '@/components/Modal'

import { useControllerActions } from '../hooks/useControllerActions'
import { Body } from './Body'
import { Header } from './Header'
import { Footer } from './Footer'

export function Content() {
  const { onSubmit } = useControllerActions()

  return (
    <Form onEnter={onSubmit}>
      <ModalContent header={<Header />} footer={<Footer />}>
        <Body />
      </ModalContent>
    </Form>
  )
}
