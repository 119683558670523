import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelName: translate({ id: 'setup.traffic.edit.labels.name' }),
    labelPoints: translate({ id: 'setup.traffic.edit.labels.points' }),
    errorName: translate({ id: 'setup.traffic.edit.validation.name' }),
    errorPoints: translate({ id: 'setup.traffic.edit.validation.points' }),
    cancel: translate({ id: 'global.cancel' }),
    footerTitle: translate({ id: 'setup.traffic.edit.formErrorsTitle' }),
    footerMessage: (missingPolyline: boolean) =>
      missingPolyline
        ? [
            translate({ id: 'setup.traffic.edit.missingPolyRow1' }),
            translate({ id: 'setup.traffic.edit.missingPolyRow2' }),
          ]
        : undefined,
    save: translate({ id: 'setup.regions.edit.save' }),
    create: translate({ id: 'setup.regions.edit.save' }),
    saveTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid)
        return translate({
          id: 'traffic.form.submitButton.disabled.title.invalid',
        })

      if (planLocked)
        return translate({
          id: 'traffic.form.submitButton.disabled.title.planLocked',
        })

      if (pristine)
        return translate({
          id: 'traffic.form.submitButton.disabled.title.pristine',
        })

      return ''
    },
  }))

  return api
}
