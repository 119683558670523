import { Text } from '@/local/components'

import { Stack } from '@mui/material'
import { useTexts } from './useTexts'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
}

export function Bulk(props: Props) {
  const { places } = props

  const texts = useTexts()

  return (
    <Stack
      direction="column"
      height="100%"
      width="100%"
      data-trackid="navigo-places-overview-details-bulk"
      data-testid="navigo-places-overview-details-bulk"
      justifyContent="space-between"
      flexShrink={0}
    >
      <Text size="$l" weight="$semiBold" testid="navigo-places-overview-details-bulk-placesCount">
        {texts.places(places.length)}
      </Text>
      <Text size="$p3">{texts.multipleValues}</Text>
    </Stack>
  )
}
