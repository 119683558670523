import { readFromPersistentStorage, removeFromPersistentStorage } from '@/local/server-data'

export async function collectMapStyles() {
  const mapStyles =
    await readFromPersistentStorage<uui.domain.ui.map.markers.MapStyles>('mapStyles')

  const clearMapStyles = () => {
    try {
      removeFromPersistentStorage('mapStyles')
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error)
      }
    }
  }

  return { mapStyles, clearMapStyles }
}
