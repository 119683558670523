import type { PreferencesFormProps as Props } from '../types'
import type { FormFields, FormErrors } from './formFields'
import { FormProvider } from '@workwave-tidal/form-ui/layout'
import { formFields } from './formFields'
import { useFormConfig } from './hooks/useFormConfig'
import { PreferencesForm } from './PreferencesForm'

export function PreferencesFormProvider(props: Props) {
  const { formValues, ...rest } = props
  const formConfig = useFormConfig(formValues)

  return (
    <FormProvider<FormFields, FormErrors>
      fields={formFields}
      config={formConfig}
      name="preferencesForm"
    >
      <PreferencesForm {...rest} />
    </FormProvider>
  )
}
