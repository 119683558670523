import type { MouseEvent } from 'react'
import type { FormErrors, FormFields } from '../../formFields'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from '@workwave-tidal/form-fairy'

import { useNotification } from '@/hooks'
import { isDeepEqual } from '@/server-data'
import { useResetEditingState } from '@/atoms'
import { useAppDispatch } from '@/store'
import { updateUser, createUser } from '@/features/domain/user/actions'
import {
  selectUserConfiguration,
  selectUserConfigurationWeekStartsOn,
  selectUserProfile,
} from '@/features/domain/user'

import { useTexts } from '../useTexts'

import { createCreateUserPayload } from './createCreateUserPayload'
import { createUpdateUserPayload } from './createUpdateUserPayload'

export function useOnSubmit(creating: boolean, setFirstSubmitDone: (value: boolean) => void) {
  const onDiscard = useResetEditingState()
  const { api } = useForm<FormFields, FormErrors>()
  const dispatch = useAppDispatch()
  const userConfiguration = useSelector(selectUserConfiguration)
  const weekStartsOn = useSelector(selectUserConfigurationWeekStartsOn)
  const userProfile = useSelector(selectUserProfile)
  const toast = useNotification()
  const texts = useTexts()

  const onEnter = useCallback(async () => {
    try {
      const formValueUnchanged = isDeepEqual(api.getValues(), api.getInitialValues())

      if (api.getMeta().status === 'pristine' || formValueUnchanged) {
        onDiscard()
        return
      }

      api.setSubmitting(true)
      setFirstSubmitDone(true)

      await api.waitForValidation()

      const valid =
        api.getMeta().status === 'indeterminate'
          ? await api.validate()
          : api.getMeta().status === 'valid'

      if (!valid) {
        api.setSubmitting(false)
        return
      }

      const request = creating
        ? await dispatch(
            createUser(
              createCreateUserPayload({
                formValues: api.getValues(),
                userConfiguration,
                weekStartsOn,
              }),
            ),
          )
        : await dispatch(
            updateUser(
              createUpdateUserPayload({
                formValues: api.getValues(),
                userConfiguration,
                weekStartsOn,
                uiSettings:
                  userProfile.user.type === 'gpsonly' ? undefined : userProfile.user.uiData,
              }),
            ),
          )

      const action = creating ? createUser : updateUser

      if (action.fulfilled.match(request)) {
        onDiscard()
        return
      }

      toast.error(texts.errors.edit)
    } catch (error) {
      //
    } finally {
      api.setSubmitting(false)
    }
  }, [
    api,
    dispatch,
    creating,
    onDiscard,
    setFirstSubmitDone,
    userConfiguration,
    weekStartsOn,
    toast,
    userProfile,
    texts,
  ])

  const onSubmit = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      onEnter()
    },
    [onEnter],
  )

  return {
    onSubmit,
    onEnter,
  }
}
