import type { ScrollbarSize } from '@workwave-tidal/core/components/Scrollbar'

import { useCallback, useState } from 'react'

export function useComputeOutOfViewportHeight(container: HTMLDivElement | null) {
  // The Scrollbar notifies when the height of the content outside the visible port change.
  // We track the outside-screen height to determine when the Footer should be inside or outside
  // the scrollable container.
  const [outOfViewportHeight, setOutOfViewportHeight] = useState<number>(0)

  const onSizeChange = useCallback(
    (_: ScrollbarSize) => {
      if (!container) return

      const scroller = container.querySelector('[data-testid="scroller"]')
      const content = container.querySelector('[data-testid="scrollbar-content"]')

      if (!scroller || !content) {
        console.warn(
          'useComputeOutOfViewportHeight - cannot find any element with [data-testid="scroller"] or with [data-testid="scrollbar-content"]',
        )
        return
      }

      setOutOfViewportHeight((content as HTMLElement).offsetHeight - scroller.scrollHeight)
    },
    [container],
  )

  return {
    outOfViewportHeight,
    onSizeChange,
  }
}
