import type { FormErrors, FormFields } from '../formFields'
import { useCallback } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

export function useOnSubmit(
  updateDriverAssistance: (value: uui.domain.server.rm.MobileAppInteraction) => Promise<void>,
  clearEditingState: () => void,
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    try {
      const {
        type,
        checkIn,
        checkOut,
        includeNote,
        includeAudio,
        includePicture,
        includeSignature,
      } = formApi.getValues()

      formApi.setSubmitting(true)

      switch (type) {
        case 'NONE':
          await updateDriverAssistance({ type })
          break

        case 'ASSISTED_TIME_OUT':
          await updateDriverAssistance({
            type: checkIn ? 'ASSISTED_TIME_IN_OUT' : 'ASSISTED_TIME_OUT',
          })
          break

        default:
          await updateDriverAssistance({
            type,
            driverRequiredFlow: {
              includeNote,
              includeAudio,
              includePicture,
              includeSignature,
              mandatoryDeparture: checkOut,
            },
          })
      }

      // Clear FormState submitting mode
      formApi.setSubmitting(false)

      // if the component has been unmounted during the async operation stop here
      if (isUnmounted()) return

      clearEditingState()
    } catch (e) {
      formApi.setSubmitting(false)
    }
  }, [formApi, isUnmounted, clearEditingState, updateDriverAssistance])
}
