import { conversionUtils } from '@/server-data'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getSchedulerPreferences } from '../../utils/getSchedulerPreferences'

import { DoubleColumnContainer } from '../Scheduler/components/DoubleColumnContainer'
import { SingleColumnContainer } from '../Scheduler/components/SingleColumnContainer'
import { Typography } from '../Scheduler/components/Typography'

interface Props {
  language: uui.domain.Language
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['orders']
}

export function OrdersCell(props: Props) {
  const { value, language } = props

  const { showApprovedRoutes } = getSchedulerPreferences()

  const formatNumber = Intl.NumberFormat(conversionUtils.intlFormat[language]).format
  const orders = value.current >= 0 ? value.current : '-'

  const approvedOrders =
    value.approved === null || value.approved === -1 ? '-' : formatNumber(value.approved)

  if (showApprovedRoutes && value.approved) {
    return (
      <DoubleColumnContainer
        firstRow={<Typography fontSize={12}>{orders}</Typography>}
        secondRow={<Typography fontSize={10}>{approvedOrders}</Typography>}
      />
    )
  }

  return (
    <SingleColumnContainer>
      <Typography fontSize={12}>{orders}</Typography>
    </SingleColumnContainer>
  )
}
