import { useCallback } from 'react'
import { useDeleteRegionModal } from '../../../../../modals/DeleteRegionModal'
import { useOnExport } from './useOnExport'
import { resetCrudSelection } from '@/atoms'

export function useActions(regions: uui.domain.client.rm.Region[]) {
  const { show: showDeleteRegionModal } = useDeleteRegionModal()

  const onDelete = useCallback(() => {
    showDeleteRegionModal()
  }, [showDeleteRegionModal])

  const onReset = useCallback(() => {
    resetCrudSelection('regions')
  }, [resetCrudSelection])

  const onExport = useOnExport(regions)

  return {
    onDelete,
    onExport,
    onReset,
  }
}
