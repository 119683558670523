import { Box, type SxProps, type Theme, useTheme } from '@mui/material'
import {
  FormFooter,
  FormHeader,
  Form as TidalForm,
  FormProps as TidalFormProps,
  TidalFormState,
} from '@workwave-tidal/core/form'
import { useMemo } from 'react'

import { ApplyPendingFitMapOnMount } from '@/components/smartUtils/form/ApplyPendingFitMapOnMount'
import { RequireTransaction } from '@/components/smartUtils/form/RequireTransaction'
import { StopEditingOnUnmount } from '@/components/smartUtils/form/StopEditingOnUnmount'

import { FormColumn } from '../../_internals/FormColumn'
import type { FormProps } from './types'

const DEFAULT_WIDTH = 484
const DEFAULT_TESTID = 'form__column-primary'
const DEFAULT_SPACING = 3
const FOOTER_BOX_SHADOW = '0px -1px 4px 0px #0E283F24'
const FOOTER_DATA_TESTID = 'form__footer-container'

export function Form<State extends TidalFormState, ReturnedData>(
  props: FormProps<State, ReturnedData> & { requireNewWrapper?: boolean },
) {
  const theme = useTheme()

  const {
    sx: providedSx,
    title,
    footer: customFooter,
    header: customHeader,
    width = DEFAULT_WIDTH,
    testId = DEFAULT_TESTID,
    subtitle,
    fieldsSpacing = DEFAULT_SPACING,
    fieldsPadding,
    requireTransaction,
    stopEditingOnUnmount,
    applyPendingFitMapOnMount,
    ...tidalFormProps
  } = props

  // If a title is provided, use the FormHeader component to render it, otherwise use the provided custom Header
  const formHeader = title ? (
    <FormHeader
      sx={{ paddingX: 3, paddingY: 2 }}
      title={
        <Box width="100%" lineHeight={theme.spacing(5)}>
          {title}
        </Box>
      }
      subtitle={subtitle}
    />
  ) : (
    customHeader
  )

  // If a custom footer is provided, render it with the FormFooter component
  const footerSx = fieldsPadding
    ? { padding: fieldsPadding, boxShadow: FOOTER_BOX_SHADOW }
    : {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        boxShadow: FOOTER_BOX_SHADOW,
      }

  const formFooter = customFooter ? (
    <FormFooter align="right" sx={footerSx} testId={FOOTER_DATA_TESTID}>
      {customFooter}
    </FormFooter>
  ) : undefined

  const sx = useMemo<SxProps<Theme>>(() => {
    return {
      height: '100%',
      width: '100%',
      ['& [data-tidal="Scrollbar"] [data-testid="scroller"]']: {
        scrollbarGutter: 'stable both-edges',
      },
      [`& [data-tidal="Scrollbar"] [data-testid="${FOOTER_DATA_TESTID}"]`]: {
        boxShadow: 0,
        padding: 2,
      },
      ...(typeof providedSx === 'function' ? providedSx(theme) : providedSx),
    }
  }, [fieldsPadding, providedSx, theme])

  const formContent = (
    <TidalForm
      sx={sx}
      footer={formFooter}
      header={formHeader}
      fieldsSpacing={fieldsSpacing}
      fieldsPadding={fieldsPadding || 2}
      {...(tidalFormProps as TidalFormProps<State, ReturnedData>)}
    />
  )

  return (
    <FormColumn width={width} testid={testId}>
      {stopEditingOnUnmount && <StopEditingOnUnmount />}
      {applyPendingFitMapOnMount && <ApplyPendingFitMapOnMount />}
      {requireTransaction ? <RequireTransaction>{formContent}</RequireTransaction> : formContent}
    </FormColumn>
  )
}
