import { Note, Picture, Signature, Voice } from '@/icons'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { NavigoIconButton } from '../../../../../../../elements/NavigoIconButton'
import { useGoToPods } from './hooks/useGoToPods'
import { useTexts } from './useTexts'

interface Props {
  id: string
  pods: uui.domain.client.rm.PodContainer
}

export function PodBlock(props: Props) {
  const texts = useTexts()
  const goToPods = useGoToPods()

  const { pods } = props

  return (
    <Stack direction="row" height="100%">
      <Stack
        direction="column"
        width="auto"
        height="100%"
        justifyContent="space-between"
        flexShrink={0}
      >
        <Stack direction="row" height="auto">
          <Text size="$p3" weight="$semiBold">
            {texts.proofsOfDelivery}:
          </Text>
        </Stack>

        <Stack direction="row" height="auto">
          <Text size="$p3">{texts.clickToExpand}:</Text>
        </Stack>
      </Stack>

      <Stack direction="row" height="100%" marginLeft={3}>
        <NavigoIconButton
          disabled={!pods.note}
          text={texts.note}
          Icon={<Note size={18} color={pods.note ? '$nightRider' : '$silver'} />}
          onClick={goToPods}
          className="pendo-navigoorder-execution-details_notes-button"
          testid="navigoorder-execution-details_notes-button"
        />

        <NavigoIconButton
          disabled={!pods.pictures}
          text={texts.picture}
          Icon={<Picture size={18} color={pods.pictures ? '$nightRider' : '$silver'} />}
          onClick={goToPods}
          className="pendo-navigoorder-execution-details_pictures-button"
          testid="navigoorder-execution-details_pictures-button"
        />

        <NavigoIconButton
          disabled={!pods.signatures}
          text={texts.signature}
          Icon={<Signature size={17} color={pods.signatures ? '$nightRider' : '$silver'} />}
          onClick={goToPods}
          className="pendo-navigoorder-execution-details_signature-button"
          testid="navigoorder-execution-details_signature-button"
        />

        <NavigoIconButton
          disabled={!pods.audios}
          text={texts.voiceMemo}
          Icon={<Voice size={12} color={pods.audios ? '$nightRider' : '$silver'} />}
          onClick={goToPods}
          className="pendo-navigoorder-execution-details_voiceMemos-button"
          testid="navigoorder-execution-details_voiceMemos-button"
        />
      </Stack>
    </Stack>
  )
}
