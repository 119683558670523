import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'

import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

export function Body() {
  const texts = useTexts()

  return (
    <Stack direction="column" width="100%" height="100%" flexShrink={0} marginTop={4}>
      <WarningCard
        preset="warning"
        title={texts.cardTitle}
        style={{ wordBreak: 'break-word' }}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
        description={texts.cardDescription}
      />
    </Stack>
  )
}
