import { type ReactNode } from 'react'
import { Chip } from '@workwave-tidal/core/components'

import { Tooltip } from '../../primitives/Tooltip'
import { useTexts } from './hooks/useTexts'

type Props = {
  allSelected: boolean
  disabled?: boolean
  children: ReactNode
  onClick: () => void
  testId?: string
  readonly?: boolean
}

export function ListHeaderCounter(props: Props) {
  const {
    onClick,
    allSelected,
    children,
    disabled = false,
    testId = 'list-header-counter',
    readonly,
  } = props
  const texts = useTexts()

  const empty = children === 0
  const color = allSelected && !empty ? 'secondary' : 'default'

  return (
    <Tooltip
      disabled={readonly}
      placement="bottom"
      title={allSelected ? texts.deselectAll : texts.selectAll}
    >
      <Chip
        data-trackid={testId}
        data-testid={testId}
        clickable={!empty && !readonly}
        disabled={disabled}
        onClick={onClick}
        color={color}
        label={children}
        size="small"
      />
    </Tooltip>
  )
}
