import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { DepotChip } from '../../../components/Navigator/typings'

import { Stack } from '@mui/material'

import { Navigator } from '../../../components/Navigator'
import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import { useDepotsNavigator } from '../hooks/useDepotsNavigator'
import { DepotItemRenderer } from './DepotItemRenderer'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'rounded',
}

export function DepotsNavigator() {
  const { chips, page, setPage } = useDepotsNavigator()

  return (
    <Stack direction="column" height="100%" flexShrink={0} justifyContent="center" width="auto">
      <Navigator<DepotChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        ItemRenderer={DepotItemRenderer}
        extraItemRendererProps={renderProps}
        extraLayoutRendererProps={renderProps}
        onChangeIndex={index => setPage(index)}
        LayoutRenderer={CollapsedLayoutRenderer}
      />
    </Stack>
  )
}
