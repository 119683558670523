import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { OrderStatus } from '../../../../typings'

import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { FormatSeconds } from '@/components/smartUtils/duration/FormatSeconds'

import { selectUserConfiguration } from '@/features/domain/user'
import { getDayLabelFromIndex } from '@/intl'
import { Text } from '@/local/components'
import { timeUtils } from '@/server-data'

import { useTexts } from '../../useTexts'
import { useRouteData } from './hooks/useRouteData'

import { Stack } from '@mui/material'

interface Props {
  routeId: string
  status: OrderStatus
  orderStepId: string
  stepStartSec: number
}

export function OrderEta(props: Props) {
  const { stepStartSec, routeId, orderStepId, status } = props
  const userConfig = useSelector(selectUserConfiguration)
  const routeData = useRouteData(orderStepId, routeId)
  const texts = useTexts()

  const dateString = useMemo(() => {
    if (!routeData.date) return ''

    const { date } = routeData

    const today = new Date()
    // if the date is today, we show `Today, Jun 3`
    // if the date is yesterday, we show `Yesterday, Jun 3`
    // if the date is tomorrow, we show `Tomorrow, Jun 3`
    // else we show `Wednesday, Jun 3`
    // if the date is in another year, we show `Wednesday, Jun 3, 2022`

    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    const yearLabel = date.getFullYear() !== today.getFullYear() ? `, ${date.getFullYear()}` : ''

    switch (date.toDateString()) {
      case today.toDateString():
        return `${texts.today}, ${timeUtils.formatDate(userConfig)(date, true)} ${yearLabel}`

      case tomorrow.toDateString():
        return `${texts.tomorrow}, ${timeUtils.formatDate(userConfig)(date, true)} ${yearLabel}`

      case yesterday.toDateString():
        return `${texts.yesterday}, ${timeUtils.formatDate(userConfig)(date, true)} ${yearLabel}`

      default:
        return `${getDayLabelFromIndex(date.getDay(), 'long')}, ${timeUtils.formatDate(userConfig)(
          date,
          true,
        )} ${yearLabel}`
    }
  }, [routeData, userConfig, texts])

  const delta = routeData.plannedArrival ? stepStartSec - routeData.plannedArrival : 0
  const shouldShowTitle =
    status !== 'completed' && status !== 'notCompleted' && status !== 'undeclared'

  return (
    <Stack direction="column" marginTop={1}>
      {shouldShowTitle && (
        <Text size="$s" weight="$semiBold" color="$shadyLady" uppercase>
          {texts.eta}
        </Text>
      )}

      <Stack direction="row">
        <Text size="$m">
          <ConvertTime seconds={stepStartSec} showSeconds={false} />
        </Text>

        {delta !== 0 && (
          <Text size="$m" color={delta > 0 ? '$outrageousRed' : '$pigmentGreen'}>
            &nbsp;&nbsp;
            {delta > 0 ? '+' : '-'}
            <FormatSeconds seconds={delta} format="MINUTES_ONLY" />
          </Text>
        )}
      </Stack>

      <Text size="$s">{dateString}</Text>
    </Stack>
  )
}
