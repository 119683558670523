import type { CSSProperties } from 'react'

import { Box, Stack } from '@mui/material'

import { Dropdown } from '@/components/Dropdown'
import { WarningTriangle } from '@/icons'
import { H4, WarningCard } from '@/local/components'

import { useCanCopyToTerritory } from '../hooks/useCanCopyToTerritory'
import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useTerritoryDropdownItems } from '../hooks/useTerritoryDropdownItems'
import { useTexts } from '../useTexts'

const layoutStyle: CSSProperties = { minHeight: 200, width: '100%' }

export function Body() {
  const texts = useTexts()
  const handlers = useControllerActions()
  const {
    invalid,
    data: { territories, id },
  } = useController()

  const canCopyToTerritory = useCanCopyToTerritory(id)

  const dropdownItems = useTerritoryDropdownItems(territories, id)

  return (
    <Stack direction="column" marginTop={4} style={layoutStyle}>
      <Box mb={2} width="100%">
        <H4 color="$paleSky" uppercase>
          {texts.territoryDropdownTitle}
        </H4>
      </Box>

      <Box mb={4} width="100%">
        <Stack direction="row" alignItems="center">
          <Box height="auto" width="100%" flex="0 1 auto">
            <Dropdown
              options={dropdownItems}
              onChange={handlers.onTerritoryChange}
              disabled={!dropdownItems || invalid}
              maxListHeight={180}
            />
          </Box>
        </Stack>
      </Box>

      {canCopyToTerritory ? (
        <Box width="100%">
          <WarningCard
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            description={texts.replaceWarningCardDescription}
            title={texts.replaceWarningCardTitle}
            preset="warning"
          />
        </Box>
      ) : (
        <Box width="100%">
          <WarningCard
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            description={texts.noAdvancedVoiceSupportedDescription}
            title={texts.noAdvancedVoiceSupportedTitle}
            preset="alert"
          />
        </Box>
      )}
    </Stack>
  )
}
