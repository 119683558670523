import { WarningTriangle } from '@/icons'
import { WarningCard } from '@/local/components'

import { useTexts } from '../useTexts'

import { Stack } from '@mui/material'

export function PendingBody() {
  const texts = useTexts()

  return (
    <Stack direction="column" height="100%" width="100%" flexShrink={0} sx={{ marginTop: '22px' }}>
      <WarningCard
        preset="warning"
        title={texts.transactionWarningCardTitle}
        description={texts.transactionWarningCardDescription}
        Icon={<WarningTriangle size={14} color="$pureWhite" background="$outrageousRed" />}
      />
    </Stack>
  )
}
