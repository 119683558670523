import { type FormControlLabelProps } from '@mui/material/FormControlLabel'
import {
  type FormFieldDescriptor,
  type NarrowFieldsByValueType,
  CurrencyInput as TidalCurrencyInput,
  type CurrencyInputProps as TidalCurrencyInputProps,
  type TidalFormBridgeOptions,
  type TidalFormState,
} from '@workwave-tidal/core/form'
import { useSelector } from 'react-redux'

import { selectUserConfiguration } from '@/features/domain/user'
import { useMemo } from 'react'

export type CurrencyInputValue = number | undefined

export type CurrencyInputProps<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = CurrencyInputValue,
> = {
  formControlLabelProps?: Partial<FormControlLabelProps>
} & TidalCurrencyInputProps<TargetField, State, AcceptedFormValue> &
  TidalFormBridgeOptions<CurrencyInputValue, TargetField, State, AcceptedFormValue>

export function CurrencyInput<
  TargetField extends NarrowFieldsByValueType<AcceptedFormValue, State, FormFieldDescriptor<State>>,
  State extends TidalFormState,
  AcceptedFormValue = CurrencyInputValue,
>(props: CurrencyInputProps<TargetField, State, AcceptedFormValue>) {
  const { currencyProps: componentCurrencyProps } = props

  /**
   * Get the user configuration from the store to use the currency and language
   * to format the currency input.
   */
  const { currency, language } = useSelector(selectUserConfiguration)
  const defaultCurrencyProps: Partial<
    CurrencyInputProps<TargetField, State, AcceptedFormValue>['currencyProps']
  > = {
    disableAbbreviations: true,
    intlConfig: { locale: language },
    suffix: ` ${currency}`,
  }

  // Merge the default currency props with the props passed to the component.
  const currencyProps = useMemo(() => {
    return {
      ...defaultCurrencyProps,
      ...componentCurrencyProps,
    }
  }, [defaultCurrencyProps, componentCurrencyProps])

  return (
    <TidalCurrencyInput<TargetField, State, AcceptedFormValue>
      {...props}
      currencyProps={currencyProps}
    />
  )
}
