import { ProjectModel, type SchedulerPro } from '@bryntum/schedulerpro'

import {
  selectSchedulerEventsAsArray,
  selectSchedulerResourcesAsArray,
} from '@/features/domain/scheduler'
import { updateUserUiDataSettings } from '@/features/domain/ui'

import { domainProxy, store } from '@/store'

import { getSorterByField } from '../columns/utils/getSorterByField'

import { syncApprovedRoutesVisibility } from './sync/approvedRoutes'
import { syncProjectData } from './sync/data'
import { syncProjectFilter } from './sync/filter'
import { syncProjectGrouping } from './sync/grouping'
import { syncProjectSorting } from './sync/sorting'
import { computeTimeRanges, syncProjectTimeRanges } from './sync/timeRange'
import { syncUnavailableRoutesVisibility } from './sync/unavailableRoutes'

import { getSchedulerPreferences } from '../../utils/getSchedulerPreferences'
import { approvedRouteFilter, unavailableRouteFilter } from './filters'
import { dateGrouper, vehicleGrouper } from './groupers'

export type SchedulerInstance = {
  instance?: SchedulerPro
}

// -----------------------------------
// Scheduler atom
// ------------------------------------

const { showAbsoluteValues, groupBy, showUnavailableRoutes, sorters } = getSchedulerPreferences()

export const project = new ProjectModel({
  events: selectSchedulerEventsAsArray(store.getState()),
  resources: selectSchedulerResourcesAsArray(store.getState()),
  timeRanges: computeTimeRanges(),

  eventStore: {
    syncDataOnLoad: true,
    useRawData: true,
    filters: showAbsoluteValues ? [] : [approvedRouteFilter],
  },

  resourceStore: {
    reapplyFilterOnUpdate: true,
    reapplyFilterOnAdd: true,
    syncDataOnLoad: true,
    useRawData: true,
    onSort: ({ source }) => {
      const sorters = source.sorters.reduce<{ field: string; ascending: boolean }[]>(
        (acc, sorter) => {
          if (sorter.field === undefined || sorter.ascending === undefined) return acc
          acc.push({ field: sorter.field, ascending: sorter.ascending })

          return acc
        },
        [],
      )

      const updatedState = {
        ...getSchedulerPreferences(),
        sorters,
      }

      store.dispatch(
        updateUserUiDataSettings({
          schedulerPreferences: updatedState,
        }),
      )
    },
    filters: showUnavailableRoutes ? [] : [unavailableRouteFilter],
    groupers: [groupBy === 'vehicleId' ? vehicleGrouper : dateGrouper],

    sorters: [
      {
        ...sorters[0],
        fn: getSorterByField(sorters[0].field),
      },
    ],
  },

  timeRangeStore: {
    useRawData: true,
  },
})

export const schedulerInstance: SchedulerInstance = {
  instance: undefined,
}

// ------------------------------------
// Read functions
// ------------------------------------

export function getProject() {
  return project
}

export function subscribeSchedulerProject() {
  // start listening to server-data events
  const unsubscribeDataChange = domainProxy.subscribeToDataChangeSuccess(domainAction => {
    // Suspend refresh to avoid multiple refreshes
    schedulerInstance.instance?.suspendRefresh()

    // SYNC
    syncProjectTimeRanges()
    syncProjectFilter()
    syncProjectData(domainAction)

    // SYNC
    syncProjectSorting()
    syncProjectGrouping()
    syncApprovedRoutesVisibility()
    syncUnavailableRoutesVisibility()

    // Resume refresh
    schedulerInstance.instance?.resumeRefresh(true)
  })

  return () => {
    unsubscribeDataChange()
  }
}
