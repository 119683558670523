import type { ReactElement } from 'react'

import { styled } from '@mui/material/styles'

interface Props {
  children: ReactElement | ReactElement[]
  spacing?: number
  name: string
}

const Container = styled('div')<{ spacing?: number }>(({ theme, spacing }) => ({
  marginBottom: theme.spacing(spacing ?? 4),
}))

export function FieldBox(props: Props) {
  const { children, spacing, name } = props

  return (
    <Container spacing={spacing} data-testid={name}>
      {children}
    </Container>
  )
}
