import type { FormErrors, FormFields } from '../../formFields'

import { TextField } from '@workwave-tidal/form-ui'
import { useTexts } from '../../useTexts'

export function Subject() {
  const texts = useTexts()

  return (
    <TextField<'subjectCustom', FormFields, FormErrors>
      label={texts.subjectCustomLabel}
      validateOn="blur focus change"
      name="subjectCustom"
      testId="subjectCustom"
    />
  )
}
