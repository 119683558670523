import type { PreparationCloseoutEventData } from '../../../../../types'
import type { FormErrors, FormFields } from '../formFields'

import { useSelector } from 'react-redux'
import { FormHelperText, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { HistoryToggleOff, ArrowRightAlt, AccessTime } from '@mui/icons-material'

import { useFormField } from '@workwave-tidal/form-fairy'
import { TimePicker } from '@workwave-tidal/form-ui'

import { selectUserAccountPreferences } from '@/features/domain/user'
import { selectTerritory } from '@/features/domain/territory'
import { PreparationTime } from '@/icons'
import { timeUtils } from '@/server-data'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'

import { useComputedLocation } from '../hooks/useComputedLocation'
import { useTexts } from '../hooks/useTexts'

interface Props {
  data: PreparationCloseoutEventData
  timeFormat: '12' | '24'
  routeColor: string
  timeOptions: number[]
}

export function RoutePreparationEvent(props: Props) {
  const { data, timeFormat, routeColor, timeOptions } = props

  const planned =
    data.type === 'complete' || data.type === 'planned-only' ? data.planned : undefined
  const tracked =
    data.type === 'complete' || data.type === 'tracked-only' ? data.tracked : undefined

  const { errors: startErrors } = useFormField<'preparationTimeStart', FormFields, FormErrors>(
    'preparationTimeStart',
  )

  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const accountPreferences = useSelector(selectUserAccountPreferences)
  const computedLocation = useComputedLocation(tracked?.location ?? planned?.location)
  const texts = useTexts()

  const plannedStartTime = planned
    ? timeUtils.formatSecondsFromMidnight(planned.start, accountPreferences.timeFormat)
    : undefined
  const plannedEndTime = planned
    ? timeUtils.formatSecondsFromMidnight(planned.end, accountPreferences.timeFormat)
    : undefined

  const errorLabels = {
    emptyField: texts.formRouteStartEmpty,
    invalidFormat: texts.formRouteStartInvalid,
  }

  const iconColor =
    mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
      ? theme.colors.$pureBlack
      : tracked?.start && tracked?.end
        ? theme.colors.$shadyLady
        : routeColor

  const commonStartError = startErrors.find(
    error => error.id === 'preparationTimeStart_incoherence',
  )
  const commonErrorMessage = commonStartError ? commonStartError.message : undefined

  const plannedTimeTooltip = planned
    ? texts.preparationTimeTooltipPlanned
    : texts.preparationTimeTooltipNotDispatched

  const trackedTimeTooltip = texts.preparationTimeTooltipNotCollected

  return (
    <Grid maxWidth="100%" container spacing={1} data-testid="routePreparationEvent">
      <Grid item xs={2} sx={{ color: iconColor }}>
        <PreparationTime size={32} />
      </Grid>

      <Grid item xs={10}>
        <Typography variant="h6" data-testid="eventTitle">
          {texts.preparationTime}
        </Typography>
        {computedLocation ? (
          computedLocation !== '-' && (
            <Typography variant="caption" color="text.secondary" data-testid="eventLocation">
              {computedLocation}
            </Typography>
          )
        ) : (
          <Skeleton width={100} height={20} animation="wave" data-testid="eventLocationLoader" />
        )}

        <Stack spacing={1}>
          <Tooltip placement="top" title={plannedTimeTooltip}>
            <Stack direction="row" spacing={1} marginTop={1}>
              <HistoryToggleOff fontSize="small" />
              {planned ? (
                <>
                  <Typography fontSize={13} data-testid="eventPlannedStartTime">
                    {plannedStartTime}
                  </Typography>
                  <ArrowRightAlt fontSize="small" />
                  <Typography fontSize={13} data-testid="eventPlannedEndTime">
                    {plannedEndTime}
                  </Typography>
                </>
              ) : (
                <Typography fontSize={13} data-testid="plannedTimeNotAvailable" color="grey.600">
                  -
                </Typography>
              )}
            </Stack>
          </Tooltip>

          <Stack direction="row" spacing={1} alignItems="flex-end">
            {tracked ? (
              <Stack>
                <FormHelperText>{texts.formPreparationTimeTitle}</FormHelperText>
                <Stack direction="row" spacing={1}>
                  {tracked.start !== -1 && (
                    <TimePicker<'preparationTimeStart', FormFields, FormErrors>
                      name="preparationTimeStart"
                      label={texts.formPreparationTimeStart}
                      format={timeFormat}
                      testId="preparationTimeStart"
                      errorLabels={errorLabels}
                      optionValues={timeOptions}
                      errorVisibility="accent-only"
                      allowUnlistedValues
                      // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
                      componentsProps={{
                        popper: {
                          sx: {
                            zIndex: 9999,
                          },
                        },
                      }}
                    />
                  )}

                  {tracked.end !== -1 && (
                    <TimePicker<'preparationTimeEnd', FormFields, FormErrors>
                      name="preparationTimeEnd"
                      label={texts.formPreparationTimeEnd}
                      format={timeFormat}
                      testId="preparationTimeEnd"
                      errorLabels={errorLabels}
                      optionValues={timeOptions}
                      errorVisibility="accent-only"
                      allowUnlistedValues
                      // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
                      componentsProps={{
                        popper: {
                          sx: {
                            zIndex: 9999,
                          },
                        },
                      }}
                    />
                  )}
                </Stack>
                {commonErrorMessage && <FormHelperText error>{commonErrorMessage}</FormHelperText>}
              </Stack>
            ) : (
              <Tooltip placement="top" title={trackedTimeTooltip}>
                <Stack direction="row" spacing={1}>
                  <AccessTime fontSize="small" color="disabled" />
                  <Typography fontSize={13} data-testid="eventTrackedTimeNotCollected">
                    -
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
