import { ConvertTime } from '@/components/smartUtils/conversion/ConvertTime'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useTexts } from './useTexts'

interface Props {
  trackingData: uui.domain.client.rm.OrderStepTrackingData
}

export function AutoTimeBlock(props: Props) {
  const texts = useTexts()
  const { autoTimeInSec, autoTimeOutSec } = props.trackingData

  return (
    <Stack
      direction="column"
      height="100%"
      width="auto"
      justifyContent="space-between"
      data-testid="AutoTimeBlock"
      flexShrink={0}
    >
      <Stack direction="row" width="100%" height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeIn}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={autoTimeInSec} showSeconds={false} />
        </Text>
      </Stack>

      <Stack direction="row" width="100%" height="auto" justifyContent="end">
        <Text size="$p3">{`${texts.reportedTimeOut}:`}&nbsp;</Text>
        <Text size="$p3" weight="$semiBold">
          <ConvertTime seconds={autoTimeOutSec} showSeconds={false} />
        </Text>
      </Stack>
    </Stack>
  )
}
