import { formatCurrency } from '@/hooks'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getSchedulerPreferences } from '../../utils/getSchedulerPreferences'
import { DoubleColumnContainer } from '../Scheduler/components/DoubleColumnContainer'
import { SingleColumnContainer } from '../Scheduler/components/SingleColumnContainer'
import { Typography } from '../Scheduler/components/Typography'

interface Props {
  currency: string
  language: uui.domain.Language
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['routeCost']
}

export function CostCell(props: Props) {
  const { value, language, currency } = props

  const { showApprovedRoutes } = getSchedulerPreferences()

  if (!showApprovedRoutes || !value.approved) {
    return (
      <SingleColumnContainer>
        <Typography fontSize={12}>
          {value.current < 0 ? '-' : formatCurrency(value.current, currency, language)}
        </Typography>
      </SingleColumnContainer>
    )
  }

  return (
    <DoubleColumnContainer
      firstRow={
        <Typography fontSize={12}>
          {value.current < 0 ? '-' : formatCurrency(value.current, currency, language)}
        </Typography>
      }
      secondRow={
        <Typography fontSize={10}>
          {value.approved < 0 ? '-' : formatCurrency(value.approved, currency, language)}
        </Typography>
      }
    />
  )
}
