import type { FormFields, FormErrors } from '../../hooks/formFields'

import { FormControl } from '@mui/material'
import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from '../../useTexts'
import { useValidations } from './hooks/useValidations'

export function Body() {
  const texts = useTexts()
  useValidations()

  return (
    <FormControl>
      <TextField<'email', FormFields, FormErrors>
        label={texts.emailLabel}
        validateOn="blur focus"
        name="email"
        autoFocus
      />
    </FormControl>
  )
}
