import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { useController } from '../hooks/useController'
import { useDeleteRegions } from '../hooks/useDeleteRegions'
import { useTexts } from '../useTexts'

export function Footer() {
  const texts = useTexts()
  const { status, close } = useController()
  const [submitting, deleteRegion] = useDeleteRegions()

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          disabled={status !== 'ready'}
          loading={submitting}
          onClick={deleteRegion}
          variant="contained"
          testid="delete-modal-confirm-button"
          color="error"
        >
          {texts.confirmButtonTitle}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton onClick={close} testid="delete-modal-cancel-button">
          {texts.cancelButtonTitle}
        </ModalFooterButton>
      }
    />
  )
}
