import type { SxProps, Theme } from '@mui/material'
import type { ReactElement } from 'react'
import type { ReadOnlyReason } from './hooks/useReadOnlyTooltipData'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useReadOnlyTooltipData } from './hooks/useReadOnlyTooltipData'

type Props = {
  sx?: SxProps<Theme>
  ignoreReasons?: ReadOnlyReason[]
  render: (preventEditing: boolean, mode: ReadOnlyReason) => ReactElement

  // Tooltip props
  forceState?: 'enabled' | 'disabled'
  placement?: 'top' | 'bottom' | 'left' | 'right'
} & (
  | {
      // Commonly, the consumers don't need to prevent edit actions apart from the app-wide rules.
      canEdit?: never
    }
  | {
      // Allow the consumers to control the tooltip by preventing edit actions in particular circumstances.
      canEdit: boolean
      title: string
      subtitle?: string
    }
)

/**
 * Connect an edit action to the app-wide read-only providers, and render a tooltip that tell the user
 * why the edit action can't be performed. The two read-only levels considered are:
 *
 * 1. the global ones: in some conditions, the plan can't be edited (such as there is an ongoing external
 * transaction, the user doesn't have the writing permissions, etc.)
 *
 * @example
 *
 * <ReadOnlyTooltip
 *   render={preventEditing => (
 *     <button disabled={preventEditing} >
 *       Edit
 *     </button>
 *   )}
 * />
 *
 * 2. a provided rule: the consumer can pass a custom, context-aware, rule to prevent editing. The
 * rule is checked only if there aren't any app-wide read-only constraints
 * checked if there is not.
 *
 * @example
 *
 * <ReadOnlyTooltip
 *   canEdit={isWorkingDay}
 *   title="You cannot edit the plan during the weekends"
 *   subtitle="Please try again on Monday"
 *   render={(preventEditing, mode) =>
 *     mode === 'controlled' ? (
 *       <button disabled={preventEditing}>
 *         {isWorkingDay ? 'Edit' : 'Edit (starting from Monday)'}
 *       </button>
 *     ) : (
 *       <button disabled={preventEditing}>
 *         {preventEditing ? 'Edit (temporarily disabled)' : 'Edit'}
 *       </button>
 *     )
 *   }
 * />
 *
 * If you want to disable an edit action without a proper tooltip message, you can mix your own rules
 * with the `preventEditing` one.
 *
 * @example
 *
 * <ReadOnlyTooltip
 *   render={preventEditing => (
 *     <button disabled={preventEditing || isEditingTheForm} >
 *       Edit
 *     </button>
 *   )}
 * />
 *
 * Please note: this component shows a tooltip when the edit action cannot be performed, but it
 * could become the foundation for a showing a tooltip with more info when the edit action actually
 * can be performed.
 */
export function ReadOnlyTooltip(props: Props) {
  const { placement = 'top', render, ignoreReasons, forceState, sx, ...rule } = props

  const editRule = rule.canEdit !== undefined ? rule : undefined

  const { mode, preventEditing, title, subtitle } = useReadOnlyTooltipData(editRule, ignoreReasons)

  return (
    <Tooltip
      // The tooltip is visible (enabled) when the users can't perform the edit action.
      // If the users can edit, the tooltip is hidden (disabled)
      disabled={(!preventEditing && forceState !== 'enabled') || forceState === 'disabled'}
      title={title}
      subtitle={subtitle}
      // Tooltip props
      placement={placement}
      sx={sx}
    >
      {render(preventEditing, mode)}
    </Tooltip>
  )
}
