import { domainProxy } from '@/store'

import { type SchedulerPro } from '@bryntum/schedulerpro'

import { getSchedulerPreferences } from '../utils/getSchedulerPreferences'

let lastShowAbsoluteValue = getSchedulerPreferences().showAbsoluteValues

export function startShowAbsoluteValuesSync(scheduler: SchedulerPro) {
  return domainProxy.subscribeToDataChangeSuccess(async _domainAction => {
    const { showAbsoluteValues } = getSchedulerPreferences()

    if (showAbsoluteValues === lastShowAbsoluteValue) return

    lastShowAbsoluteValue = showAbsoluteValues
    scheduler.refreshRows()
  })
}
