import type { FormErrors, FormFields } from '../../../formFields'

import { useEffect } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'

export function useConnectedToBackOfficePhone() {
  const { field, formApi } = useFormField<'callbackConfigStatus', FormFields, FormErrors>(
    'callbackConfigStatus',
  )
  const { field: status } = useFormField<'status', FormFields, FormErrors>('status')

  useEffect(() => {
    if (status.value !== 'ADVANCED') {
      formApi.setFieldVisibleState('backOfficePhone', false)
      formApi.setFieldVisibleState('automatedMessageEnabled', false)
      formApi.setFieldVisibleState('automatedMessage', false)
      formApi.setFieldVisibleState('automatedMessageVoice', false)
    } else if (field.value === 'OFF') {
      formApi.setFieldVisibleState('backOfficePhone', false)
      formApi.setFieldVisibleState('automatedMessageEnabled', true)
      formApi.setFieldVisibleState('automatedMessage', true)
      formApi.setFieldVisibleState('automatedMessageVoice', true)
    } else {
      formApi.setFieldVisibleState('backOfficePhone', true)
      formApi.setFieldVisibleState('automatedMessageEnabled', false)
      formApi.setFieldVisibleState('automatedMessage', false)
      formApi.setFieldVisibleState('automatedMessageVoice', false)
    }
  }, [field.value, formApi, status.value])
}
