import { Stack } from '@mui/material'

import { Driver } from './blocks/Driver'
import { Message } from './blocks/Message'

export function RmBody() {
  return (
    <Stack direction="column" width="100%" marginTop={4}>
      <Driver />
      <Message />
    </Stack>
  )
}
