import type { ReactNode } from 'react'

import { Stack, styled } from '@mui/material'

interface Props {
  children?: ReactNode
}

const StyledStack = styled(Stack)({
  '& > * + *': {
    marginLeft: 8,
  },
})

export function ButtonsContainer(props: Props) {
  return (
    <StyledStack direction="row" height="100%" width="auto">
      {props.children}
    </StyledStack>
  )
}
