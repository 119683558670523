import type { FormError, FormField } from '@workwave-tidal/form-fairy'

const type: FormField<uui.domain.server.rm.MobileAppInteraction['type']> = {
  value: 'NONE',
}

const checkIn: FormField<boolean> = {
  value: false,
}

const checkOut: FormField<boolean> = {
  value: false,
}

const includePictureChecked: FormField<boolean> = {
  value: false,
}

const includePicture: FormField<uui.domain.server.rm.FlowRequirement> = {
  value: 'DO_NOT_REQUIRE',
}

const includeSignatureChecked: FormField<boolean> = {
  value: false,
}

const includeSignature: FormField<uui.domain.server.rm.FlowRequirement> = {
  value: 'DO_NOT_REQUIRE',
}

const includeAudioChecked: FormField<boolean> = {
  value: false,
}

const includeAudio: FormField<uui.domain.server.rm.FlowRequirement> = {
  value: 'DO_NOT_REQUIRE',
}

const includeNoteChecked: FormField<boolean> = {
  value: false,
}

const includeNote: FormField<uui.domain.server.rm.FlowRequirement> = {
  value: 'DO_NOT_REQUIRE',
}

const includeStatusChecked: FormField<boolean> = {
  value: true,
}

export const formFields = {
  type,
  checkIn,
  checkOut,
  includePicture,
  includePictureChecked,
  includeSignature,
  includeSignatureChecked,
  includeAudio,
  includeAudioChecked,
  includeNote,
  includeNoteChecked,
  includeStatusChecked,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {
  invalidReasonsIndexes: number[]
}
