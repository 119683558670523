import { useCallback } from 'react'

import { useGpsReportsLink } from '@/routing'

import { DropdownMenuItem } from '@/components/DropdownMenu'
import { Tooltip } from '@/components/primitives/Tooltip'

import { ActiveIcon } from '../ActiveIcon'

import { useTrackingDisabled } from '../../../../hooks/useTrackingDisabled'
import { useTrackingTooltip } from '../../../../hooks/useTrackingTooltip'
import { useTexts } from '../../../../useTexts'
import { getSelectedDeviceIds } from '../../../../utils/getSelectedDeviceIds'

import { FixLinkInsideTooltip, StyledButton, StyledLink } from '../hooks/useStyles'
import { useRm360Links } from './hooks/useRm360Links'

export function Rm360Links() {
  const texts = useTexts()
  const { canShow } = useRm360Links()
  const trackingTooltip = useTrackingTooltip()
  const trackingDisabled = useTrackingDisabled()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <StyledLink
        absolute
        to="home"
        data-testid="header__nav-home-button-link"
        className={'pendo-bluebar__home-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-home-button-content"
          data-trackid="rm-360-header__nav-home-button-content"
        >
          <ActiveIcon />
          {texts.home}
        </DropdownMenuItem>
      </StyledLink>

      <StyledLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={'pendo-bluebar__orders-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-orders-button-link"
          data-trackid="rm-360-header__nav-orders-button-link"
        >
          <ActiveIcon />
          {texts.orders}
        </DropdownMenuItem>
      </StyledLink>

      {canShow.tracking && (
        <FixLinkInsideTooltip>
          <Tooltip placement="top" title={trackingTooltip} disabled={trackingTooltip.length === 0}>
            <StyledLink
              to="tracking"
              disabled={trackingDisabled}
              data-testid="header__nav-tracking-button-link"
              className={'pendo-bluebar__tracking-list-menu'}
            >
              <DropdownMenuItem
                data-testid="header__nav-tracking-button-content"
                data-trackid="rm-360-header__nav-tracking-button-content"
                disabled={trackingDisabled}
              >
                <ActiveIcon />
                {texts.tracking}
              </DropdownMenuItem>
            </StyledLink>
          </Tooltip>
        </FixLinkInsideTooltip>
      )}

      {canShow.simulations && (
        <StyledLink
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={'pendo-bluebar__simulations-list-menu'}
        >
          <DropdownMenuItem
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm-360-header__nav-simulations-button-content"
          >
            <ActiveIcon />
            {texts.simulations}
          </DropdownMenuItem>
        </StyledLink>
      )}

      {canShow.reports && (
        <StyledButton
          href={gpsReportsLink}
          onClick={onGpsReportClick}
          target="_blank"
          data-testid="header__nav-reports-button-link"
          className={'pendo-bluebar__GPS-reports-menu'}
        >
          <DropdownMenuItem
            data-testid="header__nav-reports-button-content"
            data-trackid="rm-360-header__nav-reports-button-content"
          >
            <ActiveIcon />
            {texts.gpsReports}
          </DropdownMenuItem>
        </StyledButton>
      )}
    </>
  )
}
