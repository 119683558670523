import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'
import type { ValidationTexts } from '../useTexts'
import { currencies } from '../../../../currencies'

export const createValidateCurrency =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value: currency, required } = formApi.getField('currency')

    const requiredError: FormError<keyof FormFields> = {
      id: 'currency-required',
      field: 'currency',
      message: texts.currencyRequired,
      priority: 3,
    }

    const validError: FormError<keyof FormFields> = {
      id: 'currency-valid',
      field: 'currency',
      message: texts.currencyInvalid,
      priority: 7,
    }

    const empty = currency.value.trim().length === 0
    const invalidCurrency = !currencies.includes(currency.value.trim())

    return {
      [requiredError.id]: required && empty ? requiredError : null,
      [validError.id]: invalidCurrency ? validError : null,
    }
  }
