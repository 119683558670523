import { styled } from '@mui/material/styles'
import { theme } from '@/styles'

interface Props {
  color: string
  withPlus?: boolean
}

const Circle = styled('div')<Props>(({ color, withPlus }) => ({
  width: 15,
  height: 15,
  backgroundColor: color,
  borderRadius: 8,
  position: 'relative',
  border: `2px solid ${theme.colors.$alabaster}`,

  '&::after': {
    content: '"+"',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 15,
    lineHeight: 14,
    transform: 'translate(-50%,-50%)',
    display: withPlus ? 'block' : 'none',
  },
}))

export function RouteCircle(props: Props) {
  return <Circle {...props} />
}
