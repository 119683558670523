import { Text } from '@/local/components'

import { useTexts } from './useTexts'

import { Stack } from '@mui/material'

export function NotExecutedBlock() {
  const texts = useTexts()

  return (
    <Stack
      direction="column"
      height="100%"
      flexShrink={0}
      width="auto"
      justifyContent="space-between"
      alignItems="start"
    >
      <Text size="$s" weight="$semiBold" color="$nightRider">
        {texts.noExecutionDetailsTitle}
      </Text>

      <Text size="$s" color="$nightRider">
        {texts.noExecutionDetailsDescription}
      </Text>
    </Stack>
  )
}
