import type { FormError, FormValues as FormValuesType } from '@workwave-tidal/form-fairy'

export const formFields = {
  distanceFormat: { value: '' },
  dateFormat: { value: '' },
  timeFormat: { value: '' },
}

export type FormFields = typeof formFields
export type FormValues = FormValuesType<FormFields>
export type FormErrors = FormError<keyof FormFields>
