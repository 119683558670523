import type { DropDownItemDate, DropDownItemStep, DropDownItemVehicle } from '../../typings'

import { useCallback } from 'react'
import { format } from 'date-fns/esm'

import { useIsUnmounted, useNotification } from '@/hooks'
import { fitInOrderSteps } from '@/features/domain/order'
import { useAppDispatch } from '@/store'
import { unproxify } from '@/utils'

import { setEditingState } from '@/atoms'
import { useController } from '../useController'
import { useTexts } from './useTexts'
import { validateBorderRestrictions } from '@/features/domain/order/actions/borderRestrictionsValidate'

export const useControllerActions = () => {
  const isUnmounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()
  const {
    close,
    update,
    updateData,
    data: { orderSteps, activeDate, activeVehicle, activeStep },
  } = useController()

  const onChangeDateOption = useCallback(
    ({ date }: DropDownItemDate) => updateData({ activeDate: date }),
    [updateData],
  )

  const onChangeVehicleOption = useCallback(
    ({ vehicle }: DropDownItemVehicle) => updateData({ activeVehicle: vehicle }),
    [updateData],
  )

  const onChangeStepOption = useCallback(
    ({ routeStep }: DropDownItemStep) => updateData({ activeStep: routeStep }),
    [updateData],
  )

  const onValidateSteps = useCallback(async () => {
    if (orderSteps.length === 0) {
      updateData({ outOfBorder: { status: false, count: 0 } })
      return
    }

    try {
      const result = await dispatch(
        validateBorderRestrictions({
          steps: orderSteps.map(step => step.id),
        }),
      )

      if (validateBorderRestrictions.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      // Access outOfBorders through result
      const outOfBorders = result.payload || []

      updateData({
        outOfBorder: {
          status: outOfBorders.length > 0,
          count: outOfBorders.length,
        },
      })
    } catch (error) {
      updateData({ outOfBorder: { status: false, count: 0 } })
    }
  }, [dispatch, orderSteps, updateData])

  const onForceFitIn = useCallback(async () => {
    update({ status: 'submitting' })

    // Pause the transaction timer (if present) since the call could take a long time to complete
    setEditingState({ editing: true })

    const orderStepIds = orderSteps.map(o => o.id)

    try {
      // Let's call the force fit-in and check for errors
      const thunkResult = await dispatch(
        fitInOrderSteps({
          force: true,
          orderStepIds,
          date: activeDate === 'best' ? activeDate : format(activeDate, 'yyyyMMdd'),

          step:
            // Convert proxy (and nested ones) to plain object if required
            typeof activeStep === 'object' ? unproxify(activeStep) : activeStep,

          vehicle:
            // Convert proxy (and nested ones) to plain object if required
            typeof activeVehicle === 'object' ? unproxify(activeVehicle) : activeVehicle,
        }),
      )

      // Restart the transaction timer (if present)
      setEditingState({ editing: false })

      if (!fitInOrderSteps.fulfilled.match(thunkResult)) throw new Error(texts.error)

      // If no orderSteps have been assigned, show an error toast
      const { result } = thunkResult.payload
      if (!result.success) throw new Error(texts.noAssigned)

      const assignedOrderStepIds = result.orderIds

      // If all the orderSteps have been assigned, show a success toast, close the modal and return
      if (assignedOrderStepIds.length === orderStepIds.length) {
        toast.success(texts.allAssigned)

        if (isUnmounted()) return

        close?.()

        return
      }

      // If NOT all the orderSteps have been assigned, show a warning toast, and bring modal to 'ready'
      toast.warn(
        texts.someAssigned(
          assignedOrderStepIds.length,
          orderStepIds.length - assignedOrderStepIds.length,
        ),
      )

      if (isUnmounted()) return

      update({ status: 'ready' })
    } catch (e) {
      update({ status: 'ready' })
      toast.error(e.message)
    }
  }, [
    activeVehicle,
    isUnmounted,
    orderSteps,
    activeDate,
    activeStep,
    dispatch,
    update,
    texts,
    toast,
    close,
  ])

  const onBestFitIn = useCallback(async () => {
    update({ status: 'submitting' })

    // Pause the transaction timer (if present) since the call could take a long time to complete
    setEditingState({ editing: true })

    const orderStepIds = orderSteps.map(o => o.id)
    const orderIds = [...new Set(orderStepIds.map(orderStepId => orderStepId.slice(0, -2)))]

    try {
      // Let's call the best fit-in and check for errors
      const thunkResult = await dispatch(
        fitInOrderSteps({
          orderIds,
          force: false,
          step: activeStep,
          date: activeDate === 'best' ? activeDate : format(activeDate, 'yyyyMMdd'),
          // Convert proxy (and nested ones) to plain object if required
          vehicle: typeof activeVehicle === 'object' ? unproxify(activeVehicle) : activeVehicle,
        }),
      )

      // Restart the transaction timer (if present)
      setEditingState({ editing: false })

      // If no orders have been assigned, show an error toast
      if (!fitInOrderSteps.fulfilled.match(thunkResult)) throw new Error(texts.error)

      const { result } = thunkResult.payload

      // Throw an error if all orders are out of border
      if (
        !result.success &&
        result.outOfBorderOrderIds &&
        result.outOfBorderOrderIds.length === orderIds.length
      ) {
        throw new Error(texts.allOutOfBorder)
      }

      if (!result.success) throw new Error(texts.noAssigned)

      // Let's calculate the correct number of orderSteps that have been assigned
      const numberOfAssignedOrderStepIds = orderSteps.reduce((acc, orderStep) => {
        const assigned = result.orderIds.includes(orderStep.order.id)

        return assigned ? acc + 1 : acc
      }, 0)

      // If all the orders have been assigned, show a success toast, close the modal and return
      if (numberOfAssignedOrderStepIds === orderStepIds.length) {
        toast.success(texts.allAssigned)

        if (isUnmounted()) return

        close?.()

        return
      }

      // If NOT all the orders have been assigned, show a warning toast, and bring modal to 'ready'
      toast.warn(
        texts.someAssigned(
          numberOfAssignedOrderStepIds,
          orderStepIds.length - numberOfAssignedOrderStepIds,
        ),
      )

      if (isUnmounted()) return

      update({ status: 'ready' })
    } catch (e) {
      update({ status: 'ready' })
      toast.error(e.message)
    }
  }, [
    activeVehicle,
    isUnmounted,
    orderSteps,
    activeDate,
    activeStep,
    dispatch,
    update,
    texts,
    toast,
    close,
  ])

  return {
    onBestFitIn,
    onForceFitIn,
    onChangeDateOption,
    onChangeStepOption,
    onChangeVehicleOption,
    onValidateSteps,
  }
}
