import type { FormFields, FormErrors } from '../../../formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'

import { useControllerById } from '../../../../../hooks/useController'

/**
 * It handles the form submit logic
 * @param onChange
 */
export function useOnSubmit(
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery',
  onChange: (payload: uui.domain.client.Location) => void,
) {
  const { update, close } = useControllerById(pinId)
  const formApi = useFormApi<FormFields, FormErrors>()

  return useCallback(async () => {
    // Set FormState submitting mode
    formApi.setSubmitting(true)
    update({ status: 'submitting' })

    // Validate form
    const valid = await formApi.validate()

    // If form is invalid, clear FormState submitting mode
    if (!valid) {
      formApi.setSubmitting(false)
      update({ status: 'ready' })
      return
    }

    // Get form values
    const { newLocation, label } = formApi.getValues()

    // Update the form values
    onChange({ ...newLocation, address: label })

    // Clear FormState submitting mode
    formApi.setSubmitting(false)
    update({ status: 'ready' })

    // Close the modal
    close?.()
  }, [formApi, close, update, onChange])
}
