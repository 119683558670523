import { FormColumn } from '@/components/layout'
import { SecondaryColumnHeader } from '@/forms-legacy'

import { useFormTransaction } from '@/hooks'
import { useDriverAssignmentForm } from './hooks/useDriverAssignmentForm'
import { useFormRecurrence } from './hooks/useFormRecurrence'
import { useOnCancel } from './hooks/useOnCancel'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useTexts } from './hooks/useTexts'

import { Footer } from './Footer'
import { AssignmentsField } from './components/AssignmentsField'

import { formState } from './formState'
import { DriverAssignmentFormState } from './typings'

interface Props {
  driverAssignment: uui.domain.client.rm.DriverAssignmentsWithId
  initialFormData: DriverAssignmentFormState
}

const formSx = {
  '& [data-testid="fieldsContainer"]': {
    padding: 0,
  },
}

const emptyArray = []

export function SingleEdit(props: Props) {
  const { driverAssignment, initialFormData } = props

  const { onInitialize } = useDriverAssignmentForm(initialFormData)

  const recurrence = useFormRecurrence(driverAssignment.id)
  const texts = useTexts()

  const onCancel = useOnCancel(recurrence.source)
  const { submitting, onSubmit } = useOnSubmit(recurrence)

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  return (
    <FormColumn width={484} testid="form__column-secondary">
      <formState.Form onInitialize={onInitialize} sx={formSx}>
        <SecondaryColumnHeader
          title={texts.headerTitle(recurrence)}
          description={texts.headerDescription(recurrence)}
          avatar={null}
        />

        {transactionOpen ? (
          <AssignmentsField
            submitting={submitting}
            initialDriverAssignments={driverAssignment.assignments}
          />
        ) : undefined}

        <Footer
          recurrence={recurrence}
          fieldsWithError={emptyArray}
          handleSubmit={onSubmit}
          submitting={submitting}
          onCancel={onCancel}
        />
      </formState.Form>
    </FormColumn>
  )
}
