import type { MutableRefObject } from 'react'
import type { Geometry, Point } from 'ol/geom'
import type VectorSource from 'ol/source/Vector'
import type { Layer } from 'ol/layer'
import type OlMap from 'ol/Map'

import type { FormFields, FormErrors } from '../../../../formFields'

import { useCallback } from 'react'
import { Box, Button } from '@mui/material'
import { Cached } from '@mui/icons-material'

import { useFormField } from '@workwave-tidal/form-fairy'

import { gis } from '@/server-data'

import { useControllerById } from '../../../../../../hooks/useController'
import { MapContainer } from './components/MapContainer'
import { useTexts } from './hooks/useTexts'

type Props = {
  map: MutableRefObject<OlMap | undefined>
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
}

const resetButtonStyle = {
  transform: 'translateX(-50%)',
  position: 'absolute',
  zIndex: 1,
  left: '50%',
  top: 24,
}

export function Map(props: Props) {
  const { map, pinId } = props

  const texts = useTexts()

  // get the center from the controller
  const {
    data: { originalLatLng },
    updateData,
  } = useControllerById(pinId)

  // get the newLocation field
  const {
    fieldApi: newLocationFieldApi,
    field: { value: newLocation },
  } = useFormField<'newLocation', FormFields, FormErrors>('newLocation')

  // Reset the position to the center
  const resetPosition = useCallback(() => {
    const newLocation = newLocationFieldApi.getField().value as uui.domain.client.Location
    newLocationFieldApi.change({ ...newLocation, latLng: originalLatLng })

    // remove the proposed label
    updateData({ proposedLabel: undefined })

    // if the map is not ready, we don't want to do anything
    if (!map?.current) return

    // get the last layer on the map
    const layers = map.current.getLayers()
    const layer = layers.item(layers.getLength() - 1) as Layer<VectorSource<Geometry>>

    // return if layer is not found
    if (!layer) return

    // get the first feature on the layer
    const feature = layer?.getSource()?.getFeatures()?.[0]

    // return if feature is not found
    if (!feature) return

    // set the coordinates of the feature to the center
    const geometry = feature.getGeometry() as Point
    const coords = gis.fromLatLngToCoordinate(originalLatLng)
    geometry?.setCoordinates(coords)

    // reset the map center
    map.current.getView().setCenter(coords)
  }, [updateData, newLocationFieldApi, map, originalLatLng])

  // If the newLocation is the same as the center, we don't want to show the reset button
  const showResetPositionButton =
    newLocation.latLng.lat !== originalLatLng.lat || newLocation.latLng.lng !== originalLatLng.lng

  return (
    <Box position="relative">
      {showResetPositionButton && (
        <Button
          onClick={resetPosition}
          startIcon={<Cached />}
          variant="contained"
          color="primary"
          size="small"
          sx={resetButtonStyle}
        >
          {texts.resetPosition}
        </Button>
      )}

      <MapContainer id="modalMap" width="100%" height={400} paddingTop={2} />
    </Box>
  )
}
