import { Box, Hidden } from '@mui/material'

import { HeaderSlice } from '../../elements/HeaderSlice'
import { useHeader } from '../../hooks/useHeader'
import { DropdownNav } from './components/DropdownNav'
import { HorizontalNav } from './components/HorizontalNav'

export function MainNav() {
  const { userType } = useHeader()

  return (
    <Box width="100%" height="100%" flex="0 1 auto">
      <Hidden lgDown>
        <HeaderSlice data-testid="header__nav-menu-expanded" gutter={12} paddingX={10}>
          <HorizontalNav userType={userType} />
        </HeaderSlice>
      </Hidden>

      <Hidden lgUp>
        <HeaderSlice data-testid="header__nav-menu-collapsed" gutter={12} paddingX={10}>
          <DropdownNav userType={userType} />
        </HeaderSlice>
      </Hidden>
    </Box>
  )
}
