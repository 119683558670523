import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { updateUserUiDataSettings } from '@/features/domain/ui'
import { selectSchedulerPreferences } from '@/features/domain/user'
import { useAppDispatch } from '@/store'

export function useSchedulerPreferences() {
  const dispatch = useAppDispatch()
  const schedulerPreferences = useSelector(selectSchedulerPreferences)

  const setSchedulerPreferences = useCallback(
    async (preferences: Partial<uui.domain.server.rm.SchedulerPreferences>) => {
      dispatch(
        updateUserUiDataSettings({
          schedulerPreferences: { ...schedulerPreferences, ...preferences },
        }),
      )
    },
    [schedulerPreferences],
  )

  return [schedulerPreferences, setSchedulerPreferences] as const
}
