import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { updateUserUiDataSettings } from '@/features/domain/ui'
import { selectUserConfigurationWeekStartsOn } from '@/features/domain/user'
import { useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import { useTexts } from './useTexts'

type PersonalizationData = {
  weekStartsOn: uui.domain.WeekStartsOn
}

export function usePersonalizationData() {
  const weekStartsOn = useSelector(selectUserConfigurationWeekStartsOn)
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const texts = useTexts()

  const personalizationData = useMemo<PersonalizationData>(
    () => ({
      weekStartsOn,
    }),
    [weekStartsOn],
  )

  const updateAccountData = useCallback(
    async (data: PersonalizationData) => {
      try {
        const thunkResult = await dispatch(updateUserUiDataSettings(data))

        if (!updateUserUiDataSettings.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        toast.success(texts.updatePersonalizationSuccess, { autoClose: 5000 })

        return true
      } catch (e) {
        toast.error(texts.updatePersonalizationError, { autoClose: 5000 })
      }

      return false
    },
    [dispatch, toast, texts],
  )

  return [personalizationData, updateAccountData] as const
}
