import type { BoxProps } from '@mui/material'
import { useState } from 'react'
import type { DurationInputProps } from '../../types'

export function useRootProps(props: DurationInputProps) {
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(() => null)
  const { testId = 'TidalDurationInput', className = '', sx, slotProps } = props

  const rootProps: BoxProps = {
    ...slotProps?.root,
    className,
    // @ts-expect-error
    'data-testid': testId,

    'data-tidal': 'DurationInput',
    sx,
    ref: setRootRef,
  }

  return {
    rootProps,
    rootRef,
  }
}
