import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { GeofencesRoot } from './components/form'
import { useEnsureGeofenceVisibility } from './hooks/useEnsureGeofenceVisibility'

import { Box, Stack } from '@mui/material'

export function Geofences() {
  useEnsureGeofenceVisibility()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="setup-geofences-root">
      <GeofencesRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%">
          <MapControls />

          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
