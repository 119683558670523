import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    unNamedRegion: translate({ id: 'setup.regions.unnamedRegion' }),
    region: translate({ id: 'global.regions' }),
    confirmPanelTitle: translate({ id: 'setup.regions.delete.title', values: { count: 1 } }),
    confirmPanelSubtitle: translate({ id: 'setup.regions.delete.subtitle' }),
    delete: translate({ id: 'global.delete' }),
    edit: translate({ id: 'global.edit' }),
    exportKml: translate({ id: 'global.exportKML' }),
    multipleValues: translate({ id: 'global.multipleValues' }),
    none: translate({ id: 'global.none' }),
    title: translate({ id: 'setup.regions.view.single.title' }),
    cost: translate({ id: 'setup.regions.view.single.additionaCost' }),
    time: translate({ id: 'setup.regions.view.single.additionalDrivingTime' }),
  }))

  return api
}
