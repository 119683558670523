import { Box, Stack } from '@mui/material'

import { Text } from '@/local/components'

import { type GpsInfoWithColor } from '../../../../types'
import { Heading } from './components/Heading'
import { TrackingStatus } from './components/TrackingStatus'
import { useTexts } from './useTexts'

interface Props {
  point: GpsInfoWithColor
}

export function StatusAndHeading(props: Props) {
  const {
    point: { status, heading },
  } = props

  const texts = useTexts()

  return (
    <Box minWidth={260} height={46}>
      <Stack
        direction="column"
        height="100%"
        width="100%"
        justifyContent="space-between"
        flexShrink={0}
      >
        <Stack direction="row" height="100%" width="100%" alignItems="center">
          <TrackingStatus status={status} />

          <Text size="$p3" weight="$semiBold">
            {texts.status(status)}
          </Text>
        </Stack>

        {status === 'moving' ? <Heading heading={heading} /> : <span style={{ height: 30 }} />}
      </Stack>
    </Box>
  )
}
