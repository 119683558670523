import type { FormErrors, FormFields } from './formFields'
import type { SingleEditProps as Props } from './types'

import { FormProvider } from '@workwave-tidal/form-ui/layout/FormProvider'

import { FormColumn } from '@/components/layout'

import { useTerritoryNames } from '../hooks/useTerritoryNames'
import { useFetchTerritoriesDriversAndVehicles } from '../hooks/useFetchTerritoriesDriversAndVehicles'
import { LoadingTerritoriesData } from '../components/LoadingTerritoriesData'
import { LoadingTerritoriesDataError } from '../components/LoadingTerritoriesDataError'

import { Form } from './components/Form'
import { formFields } from './formFields'
import { useFormConfig } from './hooks/useFormConfig'

export function SingleEdit(props: Props) {
  const territoriesData = useFetchTerritoriesDriversAndVehicles()
  const territoryNames = useTerritoryNames()
  const { formConfig } = useFormConfig(props)

  const forceValidateOnMount = props.creating ? false : computeForceValidateOnMount(props.user)

  return (
    <FormColumn width={360} testid="form__column-secondary">
      <FormProvider<FormFields, FormErrors>
        name="user-management"
        fields={formFields}
        verbose={false}
        config={formConfig}
      >
        {territoriesData.status === 'error' && <LoadingTerritoriesDataError />}
        {territoriesData.status === 'loading' && <LoadingTerritoriesData />}
        {territoriesData.status === 'ready' && (
          <Form
            {...props}
            territoriesData={territoriesData.data}
            territoryNames={territoryNames}
            forceValidateOnMount={forceValidateOnMount}
          />
        )}
      </FormProvider>
    </FormColumn>
  )
}

function computeForceValidateOnMount(user: uui.domain.client.rm.RouteManagerUser) {
  // we want to force validate on mount if the user is a guest and has no companies
  // to highlight the fact that they need to add a company
  return user.type === 'guest' && user.isCompanyFiltered && user.companies.length === 0
}
