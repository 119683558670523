import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Tooltip } from '@/components/primitives/Tooltip'
import { Link, useGpsReportsLink } from '@/routing'
import { clsx } from '@/utils'
import { useCallback } from 'react'

import { HeaderLink } from '../HeaderLink'

import { useTrackingDisabled } from '../../../../hooks/useTrackingDisabled'
import { useTrackingTooltip } from '../../../../hooks/useTrackingTooltip'
import { useTexts } from '../../../../useTexts'
import { getSelectedDeviceIds } from '../../../../utils/getSelectedDeviceIds'

import { useRm360Links } from './hooks/useRm360Links'

const FullHeightLink = styled(Link)({
  height: '100%',
})

const FullHeightDiv = styled('div')({
  height: '100%',
  '& > *': {
    height: '100%',
  },
})

const NoHoverButton = styled(Button)<{
  href?: string
  target?: string
}>(({ theme }) => ({
  height: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

export function Rm360Links() {
  const texts = useTexts()
  const { canShow } = useRm360Links()
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()

  const trackingTooltip = useTrackingTooltip()
  const trackingDisabled = useTrackingDisabled()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <FullHeightLink
        absolute
        to="home"
        className={clsx('pendo-bluebar__home-menu')}
        data-testid="header__nav-home-button-link"
      >
        <HeaderLink
          data-testid="header__nav-home-button-content"
          data-trackid="rm360-header__nav-home-button-content"
        >
          {texts.home}
        </HeaderLink>
      </FullHeightLink>

      <FullHeightLink
        absolute
        to="orders"
        data-testid="header__nav-orders-button-link"
        className={clsx('pendo-bluebar__orders-menu')}
      >
        <HeaderLink
          data-testid="header__nav-orders-button-content"
          data-trackid="rm360-header__nav-orders-button-content"
        >
          {texts.orders}
        </HeaderLink>
      </FullHeightLink>

      {canShow.tracking && (
        <FullHeightDiv>
          <Tooltip
            placement="bottom"
            title={trackingTooltip}
            disabled={trackingTooltip.length === 0}
          >
            <FullHeightLink
              to="tracking"
              disabled={trackingDisabled}
              data-testid="header__nav-tracking-button-link"
              className={clsx('pendo-bluebar__tracking-list-menu')}
            >
              <HeaderLink
                disabled={trackingDisabled}
                data-testid="header__nav-tracking-button-content"
                data-trackid="rm360-header__nav-tracking-button-content"
              >
                {texts.tracking}
              </HeaderLink>
            </FullHeightLink>
          </Tooltip>
        </FullHeightDiv>
      )}

      {canShow.simulations && (
        <FullHeightLink
          absolute
          to="simulations"
          data-testid="header__nav-simulations-button-link"
          className={clsx('pendo-bluebar__simulations-list-menu')}
        >
          <HeaderLink
            data-testid="header__nav-simulations-button-content"
            data-trackid="rm360-header__nav-simulations-button-content"
          >
            {texts.simulations}
          </HeaderLink>
        </FullHeightLink>
      )}

      {canShow.reports && (
        <NoHoverButton
          href={gpsReportsLink}
          target="_blank"
          onClick={onGpsReportClick}
          data-testid="header__nav-reports-button-link"
          className={clsx('pendo-bluebar__GPS-reports-menu')}
        >
          <HeaderLink
            data-testid="header__nav-reports-button-content"
            data-trackid="rm360-header__nav-reports-button-content"
          >
            {texts.gpsReports}
          </HeaderLink>
        </NoHoverButton>
      )}
    </>
  )
}
