import type { FormFields, FormErrors } from '../../../../formFields'
import type { BulkTags } from '../../../../types'

import type { AddTagOption as Option } from '../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { selectTagsFromVehicles } from '@/features/domain/vehicle'

export function useAddTagOptions(bulkTags: BulkTags, bannedTagCategory: 'tagsIn' | 'tagsOut') {
  const vehicleTags = useSelector(selectTagsFromVehicles)
  const formApi = useFormApi<FormFields, FormErrors>()
  const bannedTags = formApi.getField(bannedTagCategory).value

  return useMemo(() => {
    const options = vehicleTags.reduce<Option[]>((acc, tagName) => {
      const ordersCount = bulkTags.affectedOrdersCount
      const bulkTag = bulkTags.tags[tagName]
      const bannedTag = bannedTags.tags[tagName]
      const warning = !!bannedTag && bannedTag.status !== 'deleted'

      // no order from the orders in edit has this tag
      // or this tag has been deleted
      if (!bulkTag || bulkTag.status === 'deleted') {
        acc.push({
          count: 0, // useful for sorting, for non shared tag the count is irrelevant
          label: tagName,
          ordersCount,
          warning,
        })
        return acc
      }

      if (bulkTag.status === 'partial') {
        acc.push({
          count: bulkTag.count,
          label: tagName,
          ordersCount,
          warning,
        })
      }

      return acc
    }, [])
    options.sort(sortOptions)
    return options
  }, [bulkTags, vehicleTags, bannedTags])
}

// options has to be sorted by count descending than alphabetically
function sortOptions(a: Option, b: Option) {
  const countDiff = a.count - b.count
  if (countDiff !== 0) return -countDiff
  return a.label.localeCompare(b.label)
}
