import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { PlaceChip } from '../../../components/Navigator/typings'

import { Navigator } from '../../../components/Navigator'
import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'

import { Stack } from '@mui/material'
import { usePlacesNavigator } from '../hooks/usePlacesNavigator'
import { PlaceItemRenderer } from './PlaceItemRenderer'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'rounded',
}

export function PlacesNavigator() {
  const { chips, page, setPage } = usePlacesNavigator()

  return (
    <Stack
      direction="column"
      data-trackid="navigo-places-navigator"
      data-testid="navigo-places-navigator"
      justifyContent="center"
      width="auto"
      height="100%"
      flexShrink={0}
    >
      <Navigator<PlaceChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        onChangeIndex={index => setPage(index)}
        ItemRenderer={PlaceItemRenderer}
        LayoutRenderer={CollapsedLayoutRenderer}
        extraLayoutRendererProps={renderProps}
        extraItemRendererProps={renderProps}
      />
    </Stack>
  )
}
