import type { OrderStatus } from '../../typings'

import { Text } from '@/local/components'
import { styled } from '@mui/material'

import { useTexts } from './useTexts'
import { getStatusColors } from './utils'

interface Props {
  status: OrderStatus
}

const Container = styled('div', { name: 'Container' })<Props>(({ status }) => ({
  float: 'left',
  marginTop: 10,
  width: 'fit-content',
  borderRadius: 2,
  display: 'block',
  padding: '2px 4px',
  ...getStatusColors(status),
}))

export function OrderStatusBadge(props: Props) {
  const { status } = props
  const texts = useTexts()

  return (
    <Container status={status}>
      <Text textAlign="left" size="$s" weight="$bold" uppercase>
        {texts[props.status]}
      </Text>
    </Container>
  )
}
