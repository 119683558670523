import { Form } from '@workwave-tidal/form-ui/layout'

import { useControllerActions } from '../../hooks/useControllerActions'

import { Message } from './fields/Message'

export function FeedbackForm() {
  const { sendFeedback } = useControllerActions()

  return (
    <Form sx={{ mt: 2 }} disableScroll onEnter={sendFeedback}>
      <Message />
    </Form>
  )
}
