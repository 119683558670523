import { ReactNode } from 'react'

import { NearMeDisabled } from '@mui/icons-material'
import { Box, MenuItem, Stack, Typography } from '@mui/material'
import { SimpleSelect } from '@workwave-tidal/core/components'

import { colorsUtils } from '@/utils'

import { Tooltip } from '@/components/primitives/Tooltip'
import VehicleColor from '../../../../components/VehicleColor'

import { DriverSelectOption } from '../../typings'

interface Props {
  id: string
  value: string
  label: ReactNode
  options: DriverSelectOption[]
  submitting: boolean
  color: string
  hasDevice: boolean
  onChange: (a: string | null, b: string) => void
}

function renderOptions(options: DriverSelectOption[]) {
  return options.map(option => {
    switch (option.value) {
      case 'unassigned-drivers':
        return (
          <Typography
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            variant="subtitle2"
            px={2}
            my={2}
          >
            {option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()}
          </Typography>
        )

      case 'drivers-already-in-use':
        return (
          <Box key={option.value} px={2} my={2}>
            <Tooltip
              title={option.tooltip?.title as string}
              subtitle={option.tooltip?.description}
              placement="top"
              textAlign="left"
            >
              <Typography
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                variant="subtitle2"
              >
                {option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()}
              </Typography>
            </Tooltip>
          </Box>
        )

      default:
        return (
          <MenuItem key={option.value} value={option.value}>
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              <Typography
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                variant="body1"
              >
                {option.label}
              </Typography>
              <Typography
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                variant="caption"
              >
                {option.extraLabel}
              </Typography>
            </Stack>
          </MenuItem>
        )
    }
  })
}

export function DriverSelection(props: Props) {
  const { id, value, label, options, submitting, color, hasDevice, onChange } = props

  const hasAssignedDriver = value !== ''

  return (
    <SimpleSelect
      label={
        hasAssignedDriver ? (
          <Box component="span" display="flex" alignItems="center" gap={0}>
            <VehicleColor size={16} color={colorsUtils.getValidHexColor(color)} />
            {label}
            {!hasDevice && (
              <NearMeDisabled
                sx={{
                  fontSize: 20,
                  marginLeft: 0.5,
                  flexShrink: 0,
                }}
              />
            )}
          </Box>
        ) : (
          label
        )
      }
      disabled={submitting}
      name={id}
      options={options ?? []}
      renderOptions={renderOptions}
      value={value}
      onClear={() => onChange(null, id)}
      onChange={event => onChange(event.target.value, id)}
      MenuProps={{
        PaperProps: {
          sx: {
            maxWidth: 484,
          },
        },
      }}
    />
  )
}
