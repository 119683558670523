import { ClickAwayListener, MenuItem, Paper, Popper, useThemeProps } from '@mui/material'
import { TextField } from '@workwave-tidal/core/components/TextField'

import { MenuList } from './components/MenuList'
import { Root } from './components/Root'
import { useDurationInput } from './hooks/useDurationInput/useDurationInput'
import type { DurationInputProps } from './types'

export function DurationInput(inProps: DurationInputProps) {
  const props = useThemeProps({ name: 'DurationInput', props: inProps })

  const { slotProps } = props
  const {
    textFieldProps,
    rootProps,
    dropdownOptions,
    dropdownProps,
    popperProps,
    paperWidth,
    dropdownSelectedIndex,
    clickAwayProps,
  } = useDurationInput(props)

  return (
    <Root {...rootProps}>
      <TextField {...textFieldProps} />

      <Popper {...popperProps}>
        <ClickAwayListener {...clickAwayProps}>
          <Paper style={{ width: paperWidth }}>
            <MenuList {...slotProps?.menuList}>
              {dropdownOptions.map((option, index) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={dropdownProps.onClick}
                  selected={dropdownSelectedIndex === index}
                  data-int-value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Root>
  )
}
