import { IconButton } from '@/components/primitives/buttons'
import { theme } from '@/styles'
import { styled } from '@mui/material/styles'

import { MenuIconTrigger } from '@/components/DropdownMenu'

export const StyledButton = styled(IconButton)({
  height: '26px !important',
  padding: '6px !important',
  margin: '0 11px !important',
  '&:hover': { background: `${theme.colors.$whiteSmoke} !important` },
})

export const StyledMenuIconTrigger = styled(MenuIconTrigger)({
  height: '26px !important',
  padding: '6px !important',
  margin: '0 11px !important',
  '&:hover': { background: `${theme.colors.$whiteSmoke} !important` },
})
