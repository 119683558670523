import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'
import { selectBreadcrumbsMapStyles, selectCalendarRange } from '@/features/domain/ui'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { getDeviceId } from '@/server-data'

export function useBreadcrumbsStatus(vehicles: uui.domain.client.UnifiedVehicle[]) {
  const drivers = useSelector(selectDrivers)
  const mapStyles = useSelector(selectBreadcrumbsMapStyles)
  const calendarRange = useSelector(selectCalendarRange)
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  if (vehicles.length !== 1 || calendarRange.minDate !== calendarRange.maxDate) return 'off'

  const vehicle = vehicles[0]

  const deviceId = getDeviceId(drivers, vehicle, trackingProvider, calendarRange.minDate)

  if (!deviceId) return 'off'

  return mapStyles.custom[deviceId]
}
