import type { FormErrors, FormFields } from '../../formFields'
import { useFormField } from '@workwave-tidal/form-fairy'
import { SimpleSelect as Select } from '@workwave-tidal/form-ui'
import { useTexts } from './hooks/useTexts'

type Option = {
  type: 'option'
  value: uui.domain.server.rm.VoiceConfig['status']
  label: string
}

export function StatusField() {
  const { formApi } = useFormField<'status', FormFields, FormErrors>('status')
  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled
  const texts = useTexts()
  const options: Option[] = [
    { type: 'option', value: 'OFF', label: texts.status.values.off },
    { type: 'option', value: 'BASIC', label: texts.status.values.basic },
    { type: 'option', value: 'ADVANCED', label: texts.status.values.advanced },
  ]

  return (
    <Select<'status', uui.domain.server.rm.VoiceConfig['status'], FormFields, FormErrors>
      label={texts.status.label}
      name="status"
      options={options}
      validateOn="blur change"
      disabled={formDisabled}
      selectProps={{
        MenuProps: {
          MenuListProps: {
            // @ts-expect-error
            ['data-trackid']: 'status-field__menu',
          },
        },
      }}
    ></Select>
  )
}
