import type { TrafficAreaFormValuesValidation } from '../types'
import { useCallback } from 'react'

import { useTexts } from './useTexts'

export const useComposeFieldsWithError = () => {
  const texts = useTexts()

  return useCallback(
    (errors: TrafficAreaFormValuesValidation) => {
      return Object.keys(errors).map(field => {
        switch (field) {
          case 'name':
            return texts.labelName

          case 'points':
            return texts.labelPoints

          default:
            return ''
        }
      })
    },
    [texts],
  )
}
