import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { PlacesRoot } from './components/form/PlacesRoot'
import { useEnsurePlaceVisibility } from './hooks/useEnsurePlaceVisibility'

import { Box, Stack } from '@mui/material'

export function Places() {
  useEnsurePlaceVisibility()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="setup-places-root">
      <PlacesRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%" flexShrink={0}>
          <MapControls />

          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
