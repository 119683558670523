import type { PopoverOrigin, PopoverPosition } from '@mui/material'
import type { OrderStatus } from '../../typings'

import { useIsInPopup } from '@/atoms'

import { useOpenWithDelay } from '../../hooks/useOpenWithDelay'
import { useOrdersStatus } from '../../hooks/useOrdersStatus'

import { OrderStatusBadge } from '../OrderStatusBadge'

import { useFixPopupPosition } from '../../hooks/useFixPopupPosition'
import { DriverName } from './components/DriverName'
import { OrderEta } from './components/OrderEta'
import { OrderName } from './components/OrderName'
import { OrderServiceTime } from './components/OrderServiceTime'
import { OrderTimeWindow } from './components/OrderTimeWindow'

import { StyledStack, StyledPopover, StyledNoDispatchIcon } from './hooks/useStyles'

interface Props {
  extOrderStep: uui.domain.client.rm.ExtendedOrderStep
  delay?: number
  transformOrigin: PopoverOrigin
  anchorPosition: PopoverPosition
}

export function SingleOrder(props: Props) {
  const { extOrderStep, delay, ...popoverProps } = props

  const orderStatus = useOrdersStatus(extOrderStep) as OrderStatus
  const actionsRef = useFixPopupPosition()
  const isInPopup = useIsInPopup()
  const isOpen = useOpenWithDelay(delay)

  const disablePortal = isInPopup || window.innerHeight == window.screen.height

  return (
    <StyledPopover
      open={isOpen}
      action={actionsRef}
      // Avoid MUI moves the tooltip back to the main window through a portal
      disablePortal={disablePortal}
      anchorReference="anchorPosition"
      {...popoverProps}
    >
      <StyledStack
        direction="column"
        paddingTop={2}
        paddingBottom={2}
        paddingLeft={2}
        paddingRight={orderStatus === 'notDispatched' ? 4 : 2}
      >
        {orderStatus === 'notDispatched' && <StyledNoDispatchIcon size={10} />}

        <DriverName extOrder={extOrderStep} />

        <OrderName extOrder={extOrderStep} />

        <OrderStatusBadge status={orderStatus} />

        {!extOrderStep.isUnassigned && (
          <OrderEta
            status={orderStatus}
            orderStepId={extOrderStep.id}
            routeId={extOrderStep.routeId}
            stepStartSec={extOrderStep.stepStartSec}
          />
        )}

        {extOrderStep.orderStep.timeWindows.length > 0 && (
          <OrderTimeWindow
            timeWindows={extOrderStep.orderStep.timeWindows}
            violations={!extOrderStep.isUnassigned ? extOrderStep.violations : undefined}
          />
        )}

        <OrderServiceTime extOrderStep={extOrderStep} />
      </StyledStack>
    </StyledPopover>
  )
}
