import type { FormFields, FormErrors } from './formFields'

import { FormProvider } from '@workwave-tidal/form-ui/layout'

import { useGpsFormatsData } from '../hooks/useGpsFormatsData'

import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { formFields } from './formFields'
import { GpsFormatsForm } from './GpsFormatsForm'

type Props = {
  onDiscard: () => void
}

export function GpsFormatsFormProvider(props: Props) {
  const { onDiscard } = props

  // access form initial values and a function update the data when the form is saved
  const [account, updateFormats] = useGpsFormatsData()

  // create a memoized configuration including initial form valuers and form validation
  const createFormConfig = useCreateFormConfig(account)

  return (
    <FormProvider<FormFields, FormErrors>
      config={createFormConfig}
      name="gps-formats-form"
      fields={formFields}
      verbose={false}
    >
      <GpsFormatsForm onDiscard={onDiscard} updateFormats={updateFormats} />
    </FormProvider>
  )
}
