import { Stack, styled } from '@mui/material'

export const StatsContainer = styled(Stack, { name: 'AppContent' })({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',

  '& > * + *': {
    marginLeft: '24px',
  },
})
