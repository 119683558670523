import { Tooltip } from '@/components/primitives/Tooltip'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { Text } from '@/local/components'
import { useSelector } from 'react-redux'

import { useTexts } from './useTexts'

import { Box, Stack } from '@mui/material'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Details(props: Props) {
  const { drivers } = props

  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const texts = useTexts()

  if (drivers.length !== 1) return null

  const name = drivers[0].driver.name
  const { gpsDeviceId } = drivers[0].driver.deployment
  const isTelematicsUser = gpsDeviceId !== null && trackingProvider === 'telematics'

  // TODO: As from V2 we're currently not providing any last position
  const lastPosition = texts.unknownPosition

  return (
    <Box
      data-trackid="navigo-drivers-overview-details"
      data-testid="navigo-drivers-overview-details"
      sx={growStyle}
      width="100%"
      flex="0 1 auto"
    >
      <Stack
        direction="column"
        height="100%"
        width="100%"
        justifyContent="space-between"
        flexShrink={0}
      >
        <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
          <Tooltip title={name} placement="top">
            <Text
              testid="navigo-drivers-overview-details-name"
              size="$xl"
              weight="$semiBold"
              ellipsis
            >
              {name}
            </Text>
          </Tooltip>
        </div>
        {!isTelematicsUser && (
          <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <Tooltip title={lastPosition} placement="top">
              <Text
                testid="navigo-drivers-overview-details-lastPosition"
                size="$h6"
                italic
                ellipsis
              >
                {lastPosition}
              </Text>
            </Tooltip>
          </div>
        )}
      </Stack>
    </Box>
  )
}
