import { Stack } from '@mui/material'

import { useCallback, useMemo, useState } from 'react'

import { Button } from '@/local/components'
import { composeRecurrenceId } from '@/server-data'

import { RecurrencePicker } from '../RecurrencePicker'
import { useTexts } from './useTexts'

type Props = {
  disabled?: boolean
  onCancel: () => void
  onCreate: (r: uui.domain.rm.Recurrence) => void
  onOpenExisting: (r: uui.domain.rm.Recurrence) => void
  recurrenceMap:
    | uui.domain.helpers.RequireAtLeastOne<
        Record<uui.domain.rm.RecurrenceTypes, uui.domain.rm.Recurrence[]>
      >
    | undefined
}

export function RecurrenceSelector(props: Props) {
  const { recurrenceMap, onCreate, onOpenExisting, onCancel, disabled } = props
  const texts = useTexts()

  const [recurrence, setRecurrence] = useState<uui.domain.rm.Recurrence | undefined>(undefined)

  const selectingAlreadyExistent = useMemo<boolean>(() => {
    if (!recurrence || !recurrenceMap) return false
    return (
      recurrenceMap[recurrence.type]?.some(
        r =>
          composeRecurrenceId(r.type, r.value) ===
          composeRecurrenceId(recurrence.type, recurrence.value),
      ) || false
    )
  }, [recurrenceMap, recurrence])

  const handleOnConfirm = useCallback(() => {
    if (!recurrence) return
    selectingAlreadyExistent ? onOpenExisting(recurrence) : onCreate(recurrence)
  }, [onCreate, onOpenExisting, selectingAlreadyExistent, recurrence])

  return (
    <Stack
      direction="column"
      width="100%"
      height="auto"
      flexShrink={0}
      data-testid="recurrence-selector-root"
    >
      <Stack spacing={2} width="100%">
        <RecurrencePicker
          value={recurrence}
          onChange={setRecurrence}
          recurrenceMap={recurrenceMap}
        />
        <Stack direction="row" justifyContent="space-between" paddingX={1}>
          <Button
            disabled={!recurrence || disabled}
            preset="confirm"
            onClick={handleOnConfirm}
            autoWidth
          >
            {selectingAlreadyExistent
              ? texts.openExistingTranslation
              : texts.setRecurrenceTranslation}
          </Button>

          <Button preset="cancel" onClick={onCancel} disabled={disabled} autoWidth>
            {texts.cancelTranslation}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
