import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

import { Box } from '@mui/material'

interface Props {
  places: uui.domain.client.gps.wwgps.Place[]
}

export function Details(props: Props) {
  const { places } = props

  if (places.length === 0) return null

  return (
    <Box
      data-trackid="navigo-places-overview-details"
      data-testid="navigo-places-overview-details"
      height="auto"
      width="100%"
      flex="0 1 auto"
    >
      {places.length > 1 ? <Bulk places={places} /> : <Single place={places[0]} />}
    </Box>
  )
}
