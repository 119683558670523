import { FormatDateInterval } from '@/components/smartUtils/time/FormatDateInterval'
import { Order, Truck } from '@/icons'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { EntityButton } from './components/EntityButton'

import { useEntityRelations } from './hooks/useEntityRelations'
import { useTexts } from './useTexts'

const ordersButtonStyle = { marginLeft: 10 }

export function EntityRelations() {
  const texts = useTexts()
  const {
    orders,
    actions,
    vehicles,
    interval: { start, end },
  } = useEntityRelations()
  const noOrders = orders.length === 0
  const noVehicles = vehicles.length === 0

  return (
    <Stack direction="row" width="auto" alignItems="center" height="100%">
      <EntityButton
        autoWidth
        disabled={noVehicles}
        Icon={<Truck size={18} />}
        onClick={actions.setVehiclesSelection}
        testid="navigo-depots-relations-vehicles"
      >
        <Stack
          direction="column"
          width="auto"
          height="100%"
          justifyContent="center"
          textAlign="start"
          flexShrink={0}
        >
          <Text size="$m">
            {vehicles.length} {texts.vehicles(vehicles.length)}
          </Text>
          <Text size="$xs">
            {texts.on} <FormatDateInterval start={start} end={end} />
          </Text>
        </Stack>
      </EntityButton>
      <EntityButton
        autoWidth
        disabled={noOrders}
        style={ordersButtonStyle}
        Icon={<Order size={11} />}
        onClick={actions.setOrderStepsSelection}
        testid="navigo-depots-relations-orders"
      >
        <Stack
          direction="column"
          height="100%"
          width="auto"
          justifyContent="center"
          textAlign="start"
          flexShrink={0}
        >
          <Text size="$m">
            {orders.length} {texts.orders(orders.length)}
          </Text>
          <Text size="$xs">
            {texts.on} <FormatDateInterval start={start} end={end} />
          </Text>
        </Stack>
      </EntityButton>
    </Stack>
  )
}
