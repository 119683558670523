import { keyframes, styled } from '@mui/material/styles'
import { theme } from '@/local/components'

export interface Props extends workwave.Div {
  dimension: number
  speed?: 'slow' | 'normal' | 'fast'
}

const getOffset = ({ dimension }: Props) => dimension + dimension / 2

const getDuration = ({ speed }: Props) => {
  switch (speed) {
    case 'slow':
      return 2
    case 'fast':
      return 0.5
    case 'normal':
    default:
      return 1
  }
}

const flashingDotsAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

interface DotProps {
  firstDotLeft: string
  lastDotLeft: string
  left: string
  dimension: string
  secondDotDelay: string
  lastDotDelay: string
  duration: string
}

const FlashingDotsDiv = styled('div')<DotProps>(
  ({ firstDotLeft, lastDotLeft, left, dimension, secondDotDelay, lastDotDelay, duration }) => ({
    animation: `${flashingDotsAnimation} ${duration} infinite linear alternate`,
    backgroundColor: theme.colors.$pureBlack,
    color: theme.colors.$pureBlack,
    animationDelay: secondDotDelay,
    height: dimension,
    width: dimension,
    position: 'relative',
    left: left,
    borderRadius: '50%',
    marginLeft: 2,

    '&::before, &::after': {
      animation: `${flashingDotsAnimation} ${duration} infinite linear alternate`,
      backgroundColor: theme.colors.$pureBlack,
      color: theme.colors.$pureBlack,
      height: dimension,
      width: dimension,
      display: 'inline-block',
      position: 'absolute',
      borderRadius: '50%',
      content: '" "',
      top: 0,
    },

    '&::before': {
      left: firstDotLeft,
      animationDelay: 0,
    },

    '&::after': {
      left: lastDotLeft,
      animationDelay: lastDotDelay,
    },
  }),
)

export function FlashingDots(props: Props) {
  const duration = getDuration(props)
  const offset = getOffset(props)

  const dotProps = {
    firstDotLeft: `-${offset}px`,
    lastDotLeft: `${offset}px`,
    left: `${offset + 2}px`,
    dimension: `${props.dimension}px`,
    secondDotDelay: `${duration / 2}s`,
    lastDotDelay: `${duration}s`,
    duration: `${duration}s`,
  }

  return <FlashingDotsDiv {...dotProps} />
}
