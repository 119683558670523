import { Alert, Stack, Typography } from '@mui/material'

import { useController } from '../../../hooks/useController'
import { useTexts } from '../../../useTexts'

export function ErrorCard() {
  const texts = useTexts()
  const ctrl = useController()

  if (ctrl.data.issue === 'none') return null

  return (
    <Stack spacing={2} paddingBottom={1}>
      <Typography variant="body1">{texts.errorCardTitle}</Typography>

      <Alert severity="warning">{texts.errorCardText(ctrl.data.issue)}</Alert>
    </Stack>
  )
}
