import type { ExtraLayoutRendererProps } from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import type { VehicleChip } from '../../../components/Navigator/typings'

import { useCallback } from 'react'

import { Navigator } from '../../../components/Navigator'
import CollapsedLayoutRenderer from '../../../components/Navigator/layoutRenderers/CollapsedLayoutRenderer'
import { useVehiclesNavigator } from '../hooks/useVehiclesNavigator'
import { VehicleItemRenderer } from './VehicleItemRenderer'

import { Stack } from '@mui/material'

const renderProps: ExtraLayoutRendererProps = {
  chipShape: 'rounded',
}

export function VehiclesNavigator() {
  const { chips, page, setPage } = useVehiclesNavigator()

  const onChangeIndex = useCallback(index => setPage(index), [setPage])

  return (
    <Stack
      direction="column"
      data-trackid="navigo-vehicle-navigator"
      data-testid="navigo-vehicle-navigator"
      justifyContent="center"
      alignItems="center"
      width="auto"
      height="46px"
      flexShrink={0}
    >
      <Navigator<VehicleChip, ExtraLayoutRendererProps, Record<string, any>>
        items={chips}
        selectedIndex={page}
        onChangeIndex={onChangeIndex}
        ItemRenderer={VehicleItemRenderer}
        extraItemRendererProps={renderProps}
        extraLayoutRendererProps={renderProps}
        LayoutRenderer={CollapsedLayoutRenderer}
      />
    </Stack>
  )
}
