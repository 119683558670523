import type { ValidationSummaryItem } from '@workwave-tidal/form-ui/utils/ValidationSummary'
import type { LazyCreateFormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../../formFields'

import { useState } from 'react'

import { useStandardPasswordValidations } from '@/hooks'

import { useOldPasswordValidations } from './hooks/useOldPasswordValidations'

export function useCreateFormConfig(): {
  createFormConfig: LazyCreateFormConfig<FormFields, FormErrors>
  validationSummaryItems: ValidationSummaryItem<FormFields>[]
} {
  // Old password validations
  const oldPasswordValidations = useOldPasswordValidations<FormFields, FormErrors>()

  // New Password validations
  const { validations: newPasswordvalidations, validationSummaryItems } =
    useStandardPasswordValidations<FormFields, FormErrors>('newPassword')

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return { validations: [...newPasswordvalidations, ...oldPasswordValidations] }
  })

  return { createFormConfig, validationSummaryItems }
}
