import { FlexBox, Text, getColorWithAlpha } from '@/local/components'
import { styled } from '@mui/material/styles'
import type { CSSProperties, ReactElement, ReactNode } from 'react'
import { DownArrow } from '../../icons/DownArrow'

type Props = {
  disabled?: boolean
  Icon?: ReactElement
  className?: string
  menuOpen?: boolean
  children?: ReactNode
  testid?: string
  showDownArrow?: boolean
}

const downIconStyle: CSSProperties = {
  opacity: 0.4,
}

const StyledFlexBox = styled(FlexBox, {
  shouldForwardProp: prop => prop !== 'disabled' && prop !== 'menuOpen',
})<{
  disabled?: boolean
  menuOpen?: boolean
}>(({ disabled, menuOpen }) => ({
  opacity: disabled ? 0.5 : 1,
  transition: 'background 0.3s ease',
  background: getColorWithAlpha('$pureWhite', menuOpen ? 0.2 : 0),
}))

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '18px',
})

export function MenuTrigger(props: Props) {
  const { className, menuOpen, Icon, children, disabled, testid, showDownArrow = true } = props

  return (
    <StyledFlexBox
      h={36}
      shrink={0}
      borderRadius={4}
      color="$pureWhite"
      position="relative"
      data-testid={testid}
      data-trackid={testid}
      vAlignContent="center"
      padding={'0 8px 0 12px'}
      className={className}
      disabled={disabled}
      menuOpen={menuOpen}
    >
      {Icon && <IconContainer>{Icon}</IconContainer>}

      <Text ellipsis size="$p2" weight="$regular" marginRight={showDownArrow ? 6 : 0}>
        {children}
      </Text>

      {showDownArrow && <DownArrow size={14} style={downIconStyle} />}
    </StyledFlexBox>
  )
}
