import { Stack } from '@mui/material'

import { AddressAndViolations } from './AddressAndViolations'
import { NameAndRoute } from './NameAndRoute'

interface Props {
  extOrder: uui.domain.client.rm.ExtendedOrderStep
}

export function Single(props: Props) {
  return (
    <Stack
      direction="column"
      height="100%"
      data-testid="navigo-order-detail"
      justifyContent="space-between"
      width="100%"
      flexShrink={0}
    >
      <NameAndRoute extOrder={props.extOrder} />
      <AddressAndViolations extOrder={props.extOrder} />
    </Stack>
  )
}
