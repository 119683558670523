import type { ActionPermission } from '../../../../../hooks/useVehiclesNavigoMachine/types'

import { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import { CircularProgress } from '@mui/material'
import { NavigoIconButton } from '@/components/Navigo/elements/NavigoIconButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'
import { Bell } from '@/icons'

import { useTexts } from '../useTexts'

interface Props {
  permission: ActionPermission | 'disabledNoDevice' | 'disabledLoadingGpsData'
  vehicles: uui.domain.client.UnifiedVehicle[]
  onClick: () => void
  count: number
}

const StyledDiv = styled('div')({
  position: 'relative',
})

const StyledCount = styled('div')({
  background: theme.colors.$sailBlue,
  color: theme.colors.$pureWhite,
  fontSize: theme.fonts.$xs,
  whiteSpace: 'nowrap',
  position: 'absolute',
  textAlign: 'center',
  padding: '0px 4px',
  borderRadius: 9,
  width: 'auto',
  lineHeight: '14px',
  minWidth: 18,
  height: 14,
  right: 0,
  top: 0,
})

export function EventsButton(props: Props) {
  const { permission, vehicles, onClick, count } = props

  const texts = useTexts()

  const isLoadingGpsData = permission === 'disabledLoadingGpsData'
  const disabled = permission.startsWith('disabled')

  const tooltipTitle = useMemo(() => {
    switch (permission) {
      case 'disabledNoDevice':
        return vehicles.length === 1
          ? texts.disabledNoDevice(
              vehicles[0].hasRoutingLicense ? vehicles[0].vehicle.externalId : '',
            )
          : ''

      case 'disabledLoadingGpsData':
        return texts.disabledLoadingGpsData

      case 'disabled':
        return texts.disabledNotAvailableInThePast

      default:
        return ''
    }
  }, [texts, permission, vehicles])

  const iconColor = disabled ? '$silver' : '$nightRider'
  const Icon = isLoadingGpsData ? (
    <CircularProgress size={16} color="inherit" />
  ) : (
    <Bell size={16} color={iconColor} />
  )

  return (
    <Tooltip placement="top" disabled={!disabled} title={tooltipTitle}>
      <StyledDiv>
        <NavigoIconButton
          onClick={onClick}
          disabled={disabled}
          text={texts.events}
          Icon={Icon}
          testid="navigo-vehicle-overview-actions-events"
        />

        <StyledCount>{count}</StyledCount>
      </StyledDiv>
    </Tooltip>
  )
}
