import { useMemo, useState } from 'react'

import { type LazyCreateFormConfig, type FormValues } from '@workwave-tidal/form-fairy'

import { type FormErrors, type FormFields } from '../formFields'

export function useFormConfig(drivers: uui.domain.client.rm.ExtendedDriver[]) {
  const overrideValues = useDriversValues(drivers)

  const [formConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues,
    })
  })

  return { formConfig }
}

export function useDriversValues(drivers: uui.domain.client.rm.ExtendedDriver[]) {
  return useMemo<FormValues<FormFields>>(() => {
    const firstDriver = drivers[0].driver
    const allMobileFutureDaysVisibilityEquals = drivers.every(
      d => d.driver.mobileFutureDaysVisibility === firstDriver.mobileFutureDaysVisibility,
    )
    const allSummarizeFutureMobileRoute = drivers.every(
      d => d.driver.summarizeFutureMobileRoute === firstDriver.summarizeFutureMobileRoute,
    )

    return {
      mobileFutureDaysVisibility: allMobileFutureDaysVisibilityEquals
        ? { status: 'exact', value: `${firstDriver.mobileFutureDaysVisibility}` }
        : { status: 'mixed' },
      summarizeFutureMobileRoute: allSummarizeFutureMobileRoute
        ? { status: 'exact', value: firstDriver.summarizeFutureMobileRoute }
        : { status: 'mixed' },
    }
  }, [drivers])
}
