import { Component } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'

import TextInputState, { ChangeEventHandler } from '../../../../../../enhancers/TextInputState'

import { Props } from './typings'

import CustomFieldContainer from './elements/CustomFieldContainer'
import CustomFieldInputField from './elements/CustomFieldInputField'
import CustomFieldLabel from './elements/CustomFieldLabel'

export default class CustomField extends Component<Props> {
  private handleChangeValue = (value: string): void => {
    const { onChange, label } = this.props

    onChange(label, value, label)
  }

  render() {
    const { label, value } = this.props

    return (
      <CustomFieldContainer data-testid="custom-fields-container">
        <CustomFieldLabel data-testid="custom-field__label">{label}</CustomFieldLabel>
        <Tooltip placement="top" title={value} disabled={value.trim() === ''}>
          <TextInputState
            value={value}
            delay={300}
            onChange={this.handleChangeValue}
            render={(text: string, onChange: ChangeEventHandler) => (
              <CustomFieldInputField
                value={text}
                onChange={onChange}
                name="custom-field-input-value"
              />
            )}
          />
        </Tooltip>
      </CustomFieldContainer>
    )
  }
}
