import styled from 'styled-components'

import { normalizeCssValue } from '../theme/utils'

export interface Props extends workwave.Button {
  enableFocus?: boolean
  width?: number | string
  height?: number | string
  textAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start'
  enableHoverEffects?: boolean
}

const btn = styled.button<Props>``
export const InvisibleButton = styled(btn).attrs<Props>(({ enableFocus }: Props) => ({
  className: 'o-transparent-button',
  tabIndex: enableFocus ? 0 : -1,
  type: 'button',
}))`
  position: relative;
  background: transparent;
  padding: 0;
  outline: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  width: ${p => normalizeCssValue(p.width || 'auto')};
  height: ${p => normalizeCssValue(p.height || 'auto')};
  text-align: ${p => p.textAlign || 'left'};
  transition: background 0.3s;
  
 &:disabled {
    color: currentColor;
    opacity: 0.2;
  }

  ${p =>
    p.enableHoverEffects &&
    `
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: $pureWhite;
      border-radius: 3px;
    }
  `}
`

InvisibleButton.displayName = 'InvisibleButton'
