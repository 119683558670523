import type { FormFields, FormErrors } from '../formFields'
import { FormProvider } from '@workwave-tidal/form-ui/layout/FormProvider'

import { useConfigureController } from '../../hooks/useController'
import { formFields } from '../formFields'

import { useCreateFormConfig } from './hooks/useCreateFormConfig'
import { Form } from './components/Form'

type Props = {
  userId: string
}

export function Content(props: Props) {
  const { ready } = useConfigureController(props.userId)

  // create a memoized configuration
  const { createFormConfig, validationSummaryItems } = useCreateFormConfig()

  if (!ready) return null

  return (
    <FormProvider<FormFields, FormErrors>
      config={createFormConfig}
      name="user-change-password-form"
      fields={formFields}
      verbose={false}
    >
      <Form validationSummaryItems={validationSummaryItems} />
    </FormProvider>
  )
}
