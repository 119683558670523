import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    labelName: translate({ id: 'setup.regions.edit.labels.name' }),
    labelColor: translate({ id: 'setup.regions.edit.labels.color' }),
    editTitle: translate({ id: 'setup.regions.edit.title' }),
    labelCost: translate({ id: 'setup.regions.edit.labels.cost' }),
    labelTime: translate({ id: 'setup.regions.edit.labels.time' }),
    labelPoints: translate({ id: 'setup.regions.edit.labels.points' }),
    enteringThisRegionImplies: translate({
      id: 'setup.regions.edit.labels.enteringThisRegionImplies',
    }),

    errorName: translate({ id: 'setup.regions.edit.validation.name' }),
    errorMinCost: translate({ id: 'setup.regions.edit.validation.minCost' }),
    errorMinTime: translate({ id: 'setup.regions.edit.validation.minTime' }),
    errorPoints: translate({ id: 'setup.regions.edit.validation.points' }),
    errorMaxCost: translate({ id: 'setup.regions.edit.validation.maxCost' }),
    errorMaxTime: translate({ id: 'setup.regions.edit.validation.maxTime' }),

    headerTitle: (name: string) => name || translate({ id: 'setup.regions.unnamedRegion' }),
    headerDescription: translate({ id: 'global.regions' }),
    footerTitle: translate({ id: 'setup.regions.edit.formErrorsTitle' }),
    submit: translate({ id: 'global.save' }),
    submitTitle: (valid: boolean, planLocked: boolean, pristine: boolean) => {
      if (!valid) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.invalid',
        })
      }
      if (planLocked) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.planLocked',
        })
      }

      if (pristine) {
        return translate({
          id: 'regions.form.submitButton.disabled.title.pristine',
        })
      }
      return ''
    },
    cancel: translate({ id: 'global.cancel' }),
    change: translate({ id: 'global.change' }),
    info: {
      title: translate({ id: 'setup.regions.edit.info.title' }),
      point1: 'setup.regions.edit.info.point1',
      point2: 'setup.regions.edit.info.point2',
      point3: 'setup.regions.edit.info.point3',
      point4: 'setup.regions.edit.info.point4',
    },

    creatingPolygon: [
      translate({ id: 'setup.regions.edit.creatingPolyRow1' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow2' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow3' }),
      translate({ id: 'setup.regions.edit.creatingPolyRow4' }),
    ],
    editingPolygon: [
      translate({ id: 'setup.regions.edit.editPolyRow1' }),
      translate({ id: 'setup.regions.edit.editPolyRow2' }),
      translate({ id: 'setup.regions.edit.editPolyRow3' }),
      translate({ id: 'setup.regions.edit.editPolyRow4' }),
    ],
  }))

  return api
}
