import type { LazyCreateFormConfig, FormValues } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'

import { useState } from 'react'
import { useValidateEmail } from '@/hooks'

import { createValidations } from './validations/createValidations'
import { useTexts } from './useTexts'

export function useCreateFormConfig(
  overrideValues: FormValues<FormFields>,
): LazyCreateFormConfig<FormFields, FormErrors> {
  const texts = useTexts()
  const validateEmail = useValidateEmail()

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return () => ({
      overrideValues,
      validations: createValidations(validateEmail, texts),
    })
  })

  return createFormConfig
}
