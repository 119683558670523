import type { ReactElement, ReactNode } from 'react'

import { useCallback } from 'react'

import { Stack } from '@mui/material'

import { ClickableContainer } from './components/ClickableContainer'
import { Indicator } from './components/Indicator'

interface Props {
  trigger: ReactElement
  collapsed: boolean
  setCollapsed: (value: boolean) => void
  children?: ReactNode
}

export function Collapsible(props: Props) {
  const { children, trigger, collapsed, setCollapsed } = props

  const handleOnClick = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])

  return (
    <>
      <ClickableContainer onClick={handleOnClick} marginBottom={collapsed ? 0 : 8}>
        <Stack direction="row" width="100%" alignItems="center">
          <Indicator collapsed={collapsed} />
          {trigger}
        </Stack>
      </ClickableContainer>
      <>{!collapsed && children}</>
    </>
  )
}
