import type { FormErrors, FormFields } from '../formFields'

import { useEffect, useRef } from 'react'

import { useFormField } from '@workwave-tidal/form-fairy'

export function useBackupNotification() {
  const {
    formApi,
    field: { value: backupNotificationEnabled, visible: backupNotificationVisible },
  } = useFormField<'backupNotification', FormFields, FormErrors>('backupNotification', {
    fieldsOfInterest: ['value', 'visible'],
  })

  const firstUpdate = useRef<boolean>(true)

  useEffect(() => {
    /**
     * Prevent any update during the first execution to not trigger changes to the form state that
     * will bring the status to indeterminate (due to a change of required fields)
     */
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    // EMAIL BACKUP SUBJECT must be visible and required if EMAIL BACKUP is visible and enabled
    const mustProvideBackupSubjectCustom = !!backupNotificationVisible && backupNotificationEnabled
    formApi.setFieldVisibleState('backupSubjectCustom', mustProvideBackupSubjectCustom)
    formApi.setFieldRequiredState('backupSubjectCustom', mustProvideBackupSubjectCustom)
  }, [formApi, backupNotificationVisible, backupNotificationEnabled])
}
