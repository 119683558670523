import { padLeft } from '@workwave-tidal/core/javascript-utils/string/padLeft'

import type { DurationDisplayValue } from '../../types'
import { SEPARATOR_CHARACTER } from './const'

export function parseDurationHHMM(rawDisplayValue: DurationDisplayValue) {
  if (rawDisplayValue.length === 0) return null

  // 5 is the maximum digits for hh:mm
  if (rawDisplayValue.length > 5) return 'invalidFormat'

  // required to match 100 as 1hr 0min instead of 10hr 0min
  const displayValue = rawDisplayValue.includes(SEPARATOR_CHARACTER)
    ? rawDisplayValue
    : padLeft(rawDisplayValue, 4, '0')

  const regexBody = `^(?<hh>[0-9][0-9]?)(${SEPARATOR_CHARACTER}?(?<mm>[0-9]|[0-5][0-9])?)?$`
  const regex = new RegExp(regexBody)

  const regRes = regex.exec(displayValue)

  if (regRes === null || regRes.groups === undefined) {
    return 'invalidFormat'
  }

  return Number(regRes.groups.hh ?? 0) * 3600 + Number(regRes.groups.mm ?? 0) * 60
}
