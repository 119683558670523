import { styled } from '@mui/material/styles'
import { theme } from '@/styles'

interface StyleProps {
  size: number
  backgroundColor: string
  color: string
  marginRight: number
}

export const AvatarContainer = styled('div')<StyleProps>(
  ({ size, backgroundColor, color, marginRight }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: color,
    backgroundColor: backgroundColor,
    fontSize: theme.fonts.$p3,
    fontWeight: theme.weights.$semiBold,
    overflow: 'hidden',
    width: `${size}px`,
    height: `${size}px`,
    marginRight: `${marginRight}px`,
    border: `1px solid ${theme.colors.$pureWhite}`,
  }),
)
