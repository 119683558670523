import { useLayoutEffect } from 'react'
import type { DurationInputState } from '../types'

export function useScrollToSelectedItem(state: DurationInputState, popper: HTMLDivElement | null) {
  const { open, dropdownSelectedIndex, error, dropdownOptions } = state

  useLayoutEffect(() => {
    if (popper === null) return
    if (!open) return
    if (dropdownSelectedIndex === -1) return
    if (error) return

    const selectedElement = popper.querySelector<HTMLDivElement>(
      `[data-int-value="${dropdownOptions[dropdownSelectedIndex].value}"]`,
    )
    if (!selectedElement) return

    selectedElement.scrollIntoView({ block: 'start' })
  }, [popper, dropdownSelectedIndex, open, error, dropdownOptions])
}
