import type { MutableRefObject } from 'react'
import type VectorSource from 'ol/source/Vector'
import type { Geometry, Point } from 'ol/geom'
import type { Layer } from 'ol/layer'
import type OlMap from 'ol/Map'
import type { FormFields, FormErrors } from '../../../formFields'

import { useCallback } from 'react'
import { useForm } from '@workwave-tidal/form-fairy'

import { ModalFooter, ModalFooterButton } from '@/components/Modal'
import { gis } from '@/server-data'

import { useControllerById } from '../../../../../hooks/useController'
import { useTexts } from '../../../../../useTexts'

type Props = {
  onSubmit: () => Promise<void>
  map: MutableRefObject<OlMap | undefined>
  pinId:
    | 'depotFormPin'
    | 'placeFormPin'
    | 'geofenceFormPin'
    | 'orderForm_pickup'
    | 'orderForm_service'
    | 'orderForm_delivery'
}

export function Footer(props: Props) {
  const { map, pinId, onSubmit } = props

  const { close, updateData } = useControllerById(pinId)
  const {
    api,
    meta: { status },
  } = useForm<FormFields, FormErrors>()
  const texts = useTexts()

  const reset = useCallback(async () => {
    // Reset the form
    api.reset('force')
    api.validate()

    // Reset the proposed label
    const newLocation = api.getField('newLocation').value
    updateData({ proposedLabel: newLocation.address })

    if (!map.current) return

    // get the last layer on the map
    const layer = map.current.getLayers().item(map.current.getLayers().getLength() - 1) as Layer<
      VectorSource<Geometry>
    >

    // return if layer is not found
    if (!layer) return

    // get the first feature on the layer
    const feature = layer?.getSource()?.getFeatures()?.[0]

    // return if feature is not found
    if (!feature) return

    // reset the pin position
    const geometry = feature.getGeometry() as Point
    const coords = gis.fromLatLngToCoordinate(api.getField('newLocation').value.latLng)
    geometry.setCoordinates(coords)

    // reset the map center
    map.current.getView().setCenter(coords)
  }, [map, api, updateData])

  const pristine = status === 'pristine'

  return (
    <ModalFooter
      primaryAction={
        <ModalFooterButton
          testid="location-editor-modal_confirm-changes-button"
          variant="contained"
          onClick={onSubmit}
          maxWidth="50%"
          disabled={pristine}
        >
          {texts.confirmChanges}
        </ModalFooterButton>
      }
      tertiaryAction={
        <ModalFooterButton
          testid="location-editor-modal_reset-button"
          onClick={reset}
          maxWidth="50%"
          disabled={pristine}
        >
          {texts.reset}
        </ModalFooterButton>
      }
      secondaryAction={
        <ModalFooterButton
          testid="location-editor-modal_cancel-button"
          onClick={close}
          maxWidth="50%"
        >
          {texts.cancel}
        </ModalFooterButton>
      }
    />
  )
}
