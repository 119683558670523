import type { DurationInputProps } from '../../types'
import { useScrollToSelectedItem } from '../useScrollToSelectedItem'
import { useClickAwayProps } from './useClickAwayProps'
import { useDropdownProps } from './useDropdownProps'
import { useDurationInputState } from './useDurationInputState'
import { usePaperWidth } from './usePaperWidth'
import { usePopperProps } from './usePopperProps'
import { useRootProps } from './useRootProps'
import { useTextfieldProps } from './useTextfieldProps'

export function useDurationInput(props: DurationInputProps) {
  const { state, dispatch } = useDurationInputState(props)
  const { displayValue, error, fieldValue, open, dropdownOptions, dropdownSelectedIndex } = state

  const { rootProps, rootRef } = useRootProps(props)
  const textFieldProps = useTextfieldProps(dispatch, state, props)
  const dropdownProps = useDropdownProps(dispatch)

  const paperWidth = usePaperWidth(rootRef)
  const { popperProps, popperRef } = usePopperProps(state, rootRef)
  const clickAwayProps = useClickAwayProps(dispatch)

  useScrollToSelectedItem(state, popperRef)

  return {
    textFieldProps,
    dropdownProps,
    rootProps,
    open,
    error,
    displayValue,
    fieldValue,
    dropdownOptions,
    paperWidth,
    popperProps,
    dropdownSelectedIndex,
    clickAwayProps,
  }
}
