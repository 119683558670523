import { Stack, Typography } from '@mui/material'
import { Checkbox } from '@workwave-tidal/form-ui'

import { type FormFields, type FormErrors } from '../../../formFields'
import { useTexts } from '../hooks/useTexts'

export function SummarizeFutureMobileRoute() {
  const texts = useTexts()

  return (
    <Stack>
      <Checkbox<'summarizeFutureMobileRoute', FormFields, FormErrors>
        name="summarizeFutureMobileRoute"
        testId="summarizeFutureMobileRoute"
        label={texts.labels.summarizeFutureMobileRoute}
        validateOn="change"
      />
      <Typography
        variant="caption"
        sx={theme => ({ ml: 4, color: theme.vars.palette.text.secondary })}
      >
        {texts.helpers.summarizeFutureMobileRoute}
      </Typography>
    </Stack>
  )
}
