import type { FormErrors, FormFields } from '../../../formFields'

import { Box } from '@mui/material'

import { WarningCard } from '@local/components'
import { Checkbox } from '@workwave-tidal/form-ui'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../../../useTexts'
import { useValues } from './hooks/useValues'

export function UnsubscribeInstructions() {
  const { showWarningCard, notificationWay } = useValues()
  const texts = useTexts()

  const warningCardTitle =
    notificationWay === 'EMAIL'
      ? texts.unsubscribeInstructionsAlertTitleEmail
      : texts.unsubscribeInstructionsAlertTitlePhone

  const warningCardText =
    notificationWay === 'EMAIL'
      ? texts.unsubscribeInstructionsAlertTextEmail
      : texts.unsubscribeInstructionsAlertTextPhone

  return (
    <>
      <Checkbox<'includeMessageUnsubscriptionInstructions', FormFields, FormErrors>
        helperText={texts.unsubscribeInstructionsHelperText}
        name="includeMessageUnsubscriptionInstructions"
        testId="includeMessageUnsubscriptionInstructions"
        label={texts.unsubscribeInstructionsLabel}
        validateOn="blur focus"
      />

      {showWarningCard && (
        <Box marginTop={2}>
          <WarningCard
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            description={warningCardText}
            title={warningCardTitle}
            preset="warning"
          />
        </Box>
      )}
    </>
  )
}
