import { Delete } from '@workwave-tidal/core/icons'
import { useMemo } from 'react'

import { preview } from '@/components/form/preview'
import { useReadOnlyTooltipData } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { DownloadKML } from '@/icons'

import { useDeleteRegionModal } from '../../../../../modals/DeleteRegionModal'
import { useActions } from '../hooks/useActions'
import { useTexts } from '../hooks/useTexts'

interface Props {
  regions: uui.domain.client.rm.Region[]
}

export function Header(props: Props) {
  const { regions } = props
  const actions = useActions(regions)
  const texts = useTexts()
  const { preventEditing, title } = useReadOnlyTooltipData()
  const { Modal: DeleteRegionModal } = useDeleteRegionModal()

  const regionIds = useMemo(() => regions.map(region => region.id), [regions])

  return (
    <>
      <preview.Header
        title={texts.bulkTitle(regions.length)}
        testId="region-view-header"
        action={actions.onReset}
      >
        <preview.ActionBar>
          <preview.ActionBarItem
            icon={<DownloadKML size={24} />}
            key="export"
            onClick={actions.onExport}
            label={texts.exportKml}
            tooltip={texts.exportKml}
            data-testid="setup-region__exportkml"
          />
          <preview.ActionBarItem
            icon={<Delete />}
            key="delete"
            onClick={actions.onDelete}
            label={texts.delete}
            tooltip={preventEditing ? title : texts.delete}
            disabled={preventEditing}
            data-testid="setup-region__delete"
          />
        </preview.ActionBar>
      </preview.Header>

      <DeleteRegionModal regions={regionIds} />
    </>
  )
}
