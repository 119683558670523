import { timeUtils } from '@/server-data'
import { type ResourceModel } from '@bryntum/schedulerpro'
import { type CSSProperties } from 'react'

import { getSchedulerPreferences } from '../../../../utils/getSchedulerPreferences'

interface Props {
  userConfig: uui.domain.UserConfiguration
  record: ResourceModel
}

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' }
const separatorStyle: CSSProperties = { width: '100%', margin: '0 0 8px 0' }

const textStyle: CSSProperties = {
  letterSpacing: '0.00714em',
  marginBottom: '0.35rem',
  fontWeight: 500,
  fontSize: '0.875rem',
}

export function RouteEndTooltip(props: Props) {
  const { record, userConfig } = props
  const routeEnd = record.get('routeEnd') as uui.domain.client.rm.SchedulerResource['routeEnd']

  const formatDateTime = timeUtils.formatDateTime(userConfig)

  const current = routeEnd?.current ? formatDateTime(new Date(routeEnd.current.value)) : '-'
  const approved = routeEnd?.approved
    ? formatDateTime(new Date(routeEnd.approved.value))
    : undefined

  const { showApprovedRoutes } = getSchedulerPreferences()

  return (
    <div style={containerStyle}>
      <div style={textStyle}>{current}</div>
      {approved && showApprovedRoutes && (
        <>
          <hr style={separatorStyle} />
          <div style={textStyle}>{approved}</div>
        </>
      )}
    </div>
  )
}
