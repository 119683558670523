import { useSelector } from 'react-redux'

import { Tooltip } from '@/components/primitives/Tooltip'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { Text } from '@/local/components'

import { useTexts } from '../../useTexts'

import { Box, Stack } from '@mui/material'

import { LastPosition } from './components/LastPosition'

interface Props {
  device: uui.domain.client.gps.wwgps.DeviceInfo
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260 }

const ellipsisContainerStyle = { maxWidth: '100%', overflow: 'hidden' }

export function Single(props: Props) {
  const { device } = props

  const calendarRange = useSelector(selectCalendarRange)
  const userConfig = useSelector(selectUserConfiguration)
  const texts = useTexts()

  const isToday =
    calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === userConfig.today

  const name = device.label

  return (
    <Box
      data-trackid="navigo-device-overview-details-single"
      data-testid="navigo-device-overview-details-single"
      sx={growStyle}
      height="auto"
      width="100%"
      flex="0 1 auto"
    >
      <Stack
        direction="column"
        width="100%"
        height="100%"
        justifyContent="space-between"
        flexShrink={0}
      >
        <Stack direction="row" width="100%" height="100%" alignItems="center">
          <div style={ellipsisContainerStyle}>
            <Tooltip placement="top" title={name}>
              <Text
                data-trackid="navigo-device-overview-details-single-name"
                data-testid="navigo-device-overview-details-single-name"
                size="$xl"
                weight="$semiBold"
                ellipsis
              >
                {name}
              </Text>
            </Tooltip>
          </div>
        </Stack>

        {isToday ? (
          <LastPosition device={device} />
        ) : (
          <div style={ellipsisContainerStyle}>
            <Tooltip placement="top" title={texts.livePositionNotAvailable}>
              <Text
                data-trackid="navigo-device-overview-details-single-lastPosition"
                data-testid="navigo-device-overview-details-single-lastPosition"
                size="$h6"
                ellipsis
                italic
              >
                {texts.livePositionNotAvailable}
              </Text>
            </Tooltip>
          </div>
        )}
      </Stack>
    </Box>
  )
}
