import { FormHeader } from '@workwave-tidal/form-ui/layout'
import { ViewContainer, ViewContent, ViewFieldsLayout, ViewField } from '@/formUi'

import { useGpsFormatsData } from '../hooks/useGpsFormatsData'

import { distanceFormats, timeFormats, getDateFormats } from '../../formats'

import { GpsFormatsViewActions } from './components/GpsFormatsViewActions'
import { useTexts } from './hooks/useTexts'

type Props = {
  onEdit: () => void
}

export function GpsFormatsView(props: Props) {
  const { onEdit } = props

  const [gpsFormats] = useGpsFormatsData()

  const dateFormats = getDateFormats()
  const texts = useTexts()

  return (
    <ViewContainer testid="settings-preferences-and-formats">
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />

        <ViewFieldsLayout>
          {/* View Main Actions */}
          <GpsFormatsViewActions onEdit={onEdit} />

          {/* View Fields */}
          <ViewField label={texts.dateFormat}>{dateFormats[gpsFormats.dateFormat]}</ViewField>

          <ViewField label={texts.timeFormat}>{timeFormats[gpsFormats.timeFormat]}</ViewField>

          <ViewField label={texts.distanceFormat}>
            {distanceFormats[gpsFormats.distanceFormat]}
          </ViewField>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
