import { Box, Stack, Typography } from '@mui/material'
import { type ReactNode } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'

export type Props = {
  testId?: string
  rightAdornment?: ReactNode
  children: string
}

// tester: https://codesandbox.io/s/stoic-paper-dmjfhj?file=/src/Demo.tsx

const tooltipStyles = {
  overflow: 'hidden',
  maxWidth: '100%',
  display: 'flex',
}

export function ListItemTitle(props: Props) {
  const { testId = 'ListItemTitle', children, rightAdornment: rightAdornmentProps } = props

  const secondaryText =
    typeof rightAdornmentProps === 'string' ? (
      <Typography
        noWrap
        sx={{ flex: 1 }}
        lineHeight={1.99}
        variant="caption"
        color={theme => theme.palette.text.secondary}
      >
        {rightAdornmentProps}
      </Typography>
    ) : null

  const rightAdornment =
    typeof rightAdornmentProps === 'string' || !rightAdornmentProps ? null : (
      <Box ml={1}>{rightAdornmentProps}</Box>
    )

  return (
    <>
      <Stack direction="row" alignItems="baseline" data-testid={testId} spacing={1}>
        <Tooltip title={children} placement="bottom" sx={tooltipStyles}>
          <>
            <Typography
              noWrap
              variant="subtitle2"
              sx={{ maxWidth: !!secondaryText ? `${(2 / 3) * 100}%` : undefined }}
            >
              {children}
            </Typography>
            {!!secondaryText && (
              <Typography noWrap variant="subtitle2">
                {`・`}
              </Typography>
            )}
            {!!secondaryText && secondaryText}
          </>
        </Tooltip>
        {!!rightAdornment && rightAdornment}
      </Stack>
    </>
  )
}
