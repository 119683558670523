import { preview } from '@/components/form/preview'

import { Header } from './components/Header'
import { useBulkData } from './hooks/useBulkData'
import { useTexts } from './hooks/useTexts'

interface Props {
  regions: uui.domain.client.rm.Region[]
}

export function BulkView(props: Props) {
  const { regions } = props
  const { enterCost, costMixed, enterTimeSec, timeMixed } = useBulkData(regions)

  const texts = useTexts()

  return (
    <preview.Root applyPendingFitMapOnMount header={<Header regions={regions} />}>
      <preview.Subtitle>{texts.title}</preview.Subtitle>

      <preview.Field label={texts.cost}>
        {costMixed ? (
          <preview.MultipleValues />
        ) : (
          <preview.CurrencyValue>{enterCost}</preview.CurrencyValue>
        )}
      </preview.Field>

      <preview.Field label={texts.time}>
        {timeMixed ? (
          <preview.MultipleValues />
        ) : (
          <preview.DurationValue>{enterTimeSec}</preview.DurationValue>
        )}
      </preview.Field>
    </preview.Root>
  )
}
