import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'
import type { ValidationTexts } from './useTexts'

export const createValidateMessage =
  (texts: ValidationTexts): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const { value } = formApi.getField('message')

    const error: FormErrors = {
      id: 'invalidMessageError',
      field: 'message',
      message: texts.message,
      priority: 3,
    }

    const valid = !!value && value.trim().length > 0

    return {
      [error.id]: !valid ? error : null,
    }
  }
