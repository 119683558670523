import { useFormTransaction } from '@/hooks'
import { ReactNode } from 'react'
import { FormLoading } from '../../FormLoading'

interface Props {
  children: ReactNode
}

export function RequireTransaction(props: Props) {
  const { children } = props

  const transactionState = useFormTransaction()

  switch (transactionState) {
    case 'ready':
      return children

    default:
      return <FormLoading style={{ width: '100%' }} />
  }
}
