import { Outlet } from 'react-router'

import { useResetOrdersFilterOnMount } from '@/hooks'

import { Popup } from './components/Popup'
import { SettingsSidebar } from './components/SettingsSidebar'

import { Box, Stack } from '@mui/material'

export function Settings() {
  useResetOrdersFilterOnMount()

  return (
    <>
      <Stack direction="row" height="100%" width="100%">
        <SettingsSidebar />

        <Box width="100%" height="100%" flex="0 1 auto">
          <Outlet />
        </Box>
      </Stack>

      <Popup />
    </>
  )
}
