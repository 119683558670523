import { preview } from '@/components/form/preview'

import { Header } from './components/Header'
import { useTexts } from './hooks/useTexts'

interface Props {
  region: uui.domain.client.rm.Region
}

export function SingleView(props: Props) {
  const { region } = props
  const { enterCost, enterTimeSec } = region

  const texts = useTexts()

  return (
    <preview.Root applyPendingFitMapOnMount header={<Header region={region} />}>
      <preview.Subtitle>{texts.title}</preview.Subtitle>

      <preview.Field label={texts.cost}>
        <preview.CurrencyValue>{enterCost}</preview.CurrencyValue>
      </preview.Field>

      <preview.Field label={texts.time}>
        <preview.DurationValue>{enterTimeSec}</preview.DurationValue>
      </preview.Field>
    </preview.Root>
  )
}
