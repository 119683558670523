import type { InvalidDriversResult } from '../../../../hooks/useInvalidData'

import { Stack, Typography, Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Scrollbar } from '@workwave-tidal/core/components'

import { WarningCard } from '@local/components'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../../../../useTexts'

import { DriverDeletionDay } from './components/DriverDeletionDay'

type Props = {
  driverIds: string[]
  invalidDriversResult: InvalidDriversResult
}

export function MultipleDrivers(props: Props) {
  const {
    driverIds,
    invalidDriversResult: { quantity, details },
  } = props
  const deletionDates = Object.keys(details)
  const texts = useTexts()

  const someDriversInvalid = quantity > 0 && driverIds.length !== quantity

  const height = quantity > 2 ? 460 : quantity === 2 ? 440 : 370

  return (
    <Box height={height}>
      <Scrollbar>
        <Stack width="100%" paddingX={1} spacing={3}>
          {someDriversInvalid && (
            <Typography component="div">
              <FormattedMessage
                id={texts.onlySomeDriversCanBeDeleted}
                values={{
                  b: chunks => (
                    <Typography fontWeight={600} display="inline">
                      {chunks}
                    </Typography>
                  ),
                  deletableDrivers: driverIds.length - quantity,
                  totalDrivers: driverIds.length,
                }}
              />
            </Typography>
          )}
          <WarningCard
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
            preset="warning"
            title={texts.warningTitle}
            description={
              someDriversInvalid ? (
                <FormattedMessage
                  id={texts.warningSubtitlePartial}
                  values={{
                    b: chunks => (
                      <Typography fontWeight={600} display="inline">
                        {chunks}
                      </Typography>
                    ),
                    count: quantity,
                  }}
                />
              ) : (
                texts.warningSubtitleAll
              )
            }
          />

          <Typography fontWeight={600}>{texts.driversThatCantBeDeleted}</Typography>
          <Stack data-testid="non-deletable-drivers">
            {deletionDates.map((date, index) => (
              <DriverDeletionDay
                key={`day_${index}`}
                date={date}
                driverDeletionData={details[date]}
              />
            ))}
          </Stack>
        </Stack>
      </Scrollbar>
    </Box>
  )
}
