import type { FormApi, FormValidator } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from '../formFields'
import { useTexts } from './useTexts'

export function useReasonsForNotCompletedOrdersValidation() {
  const texts = useTexts()

  const validateReasonsLength: FormValidator<FormFields, FormErrors> = (
    formApi: FormApi<FormFields, FormErrors>,
  ) => {
    const { value } = formApi.getField('reasonsForNotCompletedOrders')
    const invalidReasonsIndexes = value.reduce((acc: number[], reason, index) => {
      if (reason.length > 30) {
        acc.push(index)
      }

      return acc
    }, [])

    const reasonsError: FormErrors = {
      id: 'reasonsError',
      field: 'reasonsForNotCompletedOrders',
      message: texts.reasonsError,
      invalidReasonsIndexes,
      priority: 3,
    }

    const valid = invalidReasonsIndexes.length === 0

    return {
      [reasonsError.id]: !valid ? reasonsError : null,
    }
  }

  return validateReasonsLength
}
