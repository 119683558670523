import { useState } from 'react'
import { useCloseModal, useConfigureModalController, useModalController } from '@/atoms'

type ModalStatus = 'ready'

interface ModalData {
  regionIds: string[]
}

type ModalProps = {
  regions: string[]
}

export const modalId = 'deleteRegion'
export const useController = () => useModalController<ModalData, ModalStatus>(modalId)

export const useConfigureController = ({ regions }: ModalProps) => {
  const close = useCloseModal()

  const [options] = useState(() => ({
    createInitialState: () => ({
      status: 'ready' as ModalStatus,
      data: { regionIds: regions },
      close,
    }),
  }))

  return useConfigureModalController<ModalData, ModalStatus>(modalId, options)
}
