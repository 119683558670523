import { Stack, styled } from '@mui/material'

export const ButtonsContainer = styled(Stack, { name: 'ButtonsContainer' })({
  flexDirection: 'row',
  alignItems: 'center',
  width: 'auto',
  '& > * + *': {
    marginLeft: ' 8px',
  },
})
