import { form } from '../formState'
import { useTexts } from '../hooks/useTexts'

export function AdditionalDrivingTime() {
  const texts = useTexts()

  form.useAddFieldValidation<'enterTimeSec'>('enterTimeSec', (field, _formApi) => {
    if (field.value < 0) {
      return texts.errorMinTime
    }

    if (field.value > 24 * 3_600) {
      return texts.errorMaxTime
    }
  })

  return (
    <form.DurationInput
      label={texts.labelTime}
      name="enterTimeSec"
      testId="region-enterTimeSec"
      durationFormat="hh:mm"
    />
  )
}
