import { addMinutes, parse, parseISO } from 'date-fns/esm'
import { parseTimeString } from './time'

export type ExplodedDate = [number, number, number, number, number, number]

export const getTimezoneOffsetAfterFromTransitions = (
  date: Date | string | number,
  transitions: uui.domain.TimezoneTransition[],
): string => {
  let utcTime: number = -1

  if (date instanceof Date) {
    utcTime = date.getTime()
  } else if (typeof date === 'string') {
    utcTime = parseDateString(date)?.getTime() ?? -1
  } else {
    utcTime = date
  }

  // transitions are always sorted from the older
  let transition: uui.domain.TimezoneTransition | undefined
  for (const tmp of transitions) {
    if (tmp.epochMs <= utcTime) {
      transition = tmp
    } else {
      break
    }
  }

  if (!transition) {
    console.warn(
      `The date: ${date} is outside the supported range. Fallback to the nearer timezone offset`,
    )

    // transitions can't be empty
    const first = transitions[0]
    const last = transitions[transitions.length - 1]
    transition = utcTime < first.epochMs ? first : last
  }

  return transition.offsetAfter
}

export const getTimezoneFromTransitions = (
  date: Date | string | number,
  transitions: uui.domain.TimezoneTransition[],
): string => {
  return `UTC${getTimezoneOffsetAfterFromTransitions(date, transitions)}`
}

export const parseTimeZoneOffsetToMinutes = (source: string): number => {
  let HHMM = source
  let modifier = 1

  if (source.startsWith('UTC')) {
    HHMM = source.slice(3)
  }

  modifier = HHMM.slice(0, 1) === '-' ? -1 : 1
  HHMM = HHMM.slice(1)

  const { error, time } = parseTimeString(HHMM)

  if (error || !time) {
    throw new Error(`Impossible to compute timezone in: [parseTimeZoneOffsetToMinutes]. ${HHMM}`)
  }

  const { hours = 0, minutes = 0 } = time
  return (hours * 60 + minutes) * modifier
}

// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------

const isTimezoneConverted = (source: Date): boolean => {
  // @ts-expect-error
  return !!source.timezoneConverted
}

const markTimezoneConverted = (mutableSource: Date): Date => {
  // @ts-expect-error
  mutableSource.timezoneConverted = true
  return mutableSource
}

// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------

// used by resetToUTCDate
const explodeYYYYMMDD = (source: string): ExplodedDate | undefined => {
  const year = parseInt(source.slice(0, 4))
  const month = parseInt(source.slice(4, 6))
  const date = parseInt(source.slice(6, 8))

  if (
    isNaN(year) ||
    isNaN(month) ||
    isNaN(year) ||
    date < 1 ||
    date > 31 ||
    month < 1 ||
    month > 12
  ) {
    return undefined
  }

  return [year, month - 1, date, 0, 0, 0]
}

// used by resetToUTCDate
const explodeDashedYYYYMMDD = (source: string): ExplodedDate | undefined => {
  const year = parseInt(source.slice(0, 4))
  const month = parseInt(source.slice(5, 7))
  const date = parseInt(source.slice(9, 11))
  if (
    isNaN(year) ||
    isNaN(month) ||
    isNaN(year) ||
    date < 1 ||
    date > 31 ||
    month < 1 ||
    month > 12
  ) {
    return undefined
  }

  return [year, month - 1, date, 0, 0, 0]
}

// used by resetToUTCDate
const explodeDateFromUTC = (source: Date): ExplodedDate => {
  return [
    source.getUTCFullYear(),
    source.getUTCMonth(),
    source.getUTCDate(),
    source.getUTCHours(),
    source.getUTCMinutes(),
    source.getUTCSeconds(),
  ]
}

// API
const resetToUTCDate = (source: ExplodedDate | undefined): Date | undefined => {
  if (!source) {
    return undefined
  }

  const tmp = new Date(
    source[0],
    source[1],
    source[2],
    source[3] || 0,
    source[4] || 0,
    source[5] || 0,
  )

  markTimezoneConverted(tmp)

  return tmp
}

// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------

/**
 * Detects if a string terminates with: (d = digit)
 * - .dd+dd
 * - .dd-dd
 * - .d+dd
 * - .d-dd
 **/
function isISODateWithTwoDigitsOffset(date: string) {
  const twoDigitOffsetDate = new RegExp(/\.[0-9]{1,2}[\+|\-]\d\d$/)
  return twoDigitOffsetDate.test(date)
}

/**
 * Detects if a string is an ISO date with a variable precision without offset
 */
function isISODateWithoutOffset(date: string) {
  const withoutOffset = new RegExp(/\.[0-9]+$/)
  return withoutOffset.test(date)
}

export const parseAcceptedDate = (
  date: uui.domain.AcceptedDate,
  offsetInMinutes: number | string,
): Date | undefined => {
  let parsedDate: Date | undefined

  const offset: number =
    typeof offsetInMinutes === 'number'
      ? offsetInMinutes
      : parseTimeZoneOffsetToMinutes(offsetInMinutes)

  if (typeof date === 'string') {
    switch (date.length) {
      // YYYYMMDD --> From RM world
      case 8:
        parsedDate = resetToUTCDate(explodeYYYYMMDD(date))
        if (parsedDate) {
          parsedDate = addMinutes(parsedDate, -offset)
        }
        break

      // YYYY-MM-DD --> From GPS report form
      case 10:
        parsedDate = resetToUTCDate(explodeDashedYYYYMMDD(date))
        if (parsedDate) {
          parsedDate = addMinutes(parsedDate, -offset)
        }
        break

      case 19:
        // 2024-02-19T18:12:30
        // yyyy-MM-ddTHH:mm:ss --> From Telematics

        // ATTENTION: parseISO consider an ISO date WITHOUT a specified offset as a local date and NOT a UTC date.
        parsedDate = parseDateString(`${date}+00:00`)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))
        break

      case 20:
        // 2024-02-19T18:12:30Z
        // yyyy-MM-ddTHH:mm:ssZ --> From Telematics

        parsedDate = parseDateString(date)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))
        break

      // 2018-12-02 23:00:00+00
      // yyyy-MM-dd HH:mm:ss+ZZ --> From GPS report form (Driver behavior scorecard)
      // 2025-01-10T16:21:09.8 --> From Telematics
      case 21:
        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))

        break

      // 2018-12-02 23:00:00+00
      // yyyy-MM-dd HH:mm:ss+ZZ --> From GPS report form (Driver behavior scorecard)
      // 2025-01-10T16:21:09.84 --> From Telematics
      case 22:
        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))

        break

      // 2018-12-02 23:00:00.000
      // yyyy-MM-dd HH:mm:ss.000
      case 23:
        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))

        break

      // YYYY-MM-DDTHH:MM:SS.000Z --> From GPS world
      // YYYY-MM-DD HH:MM:SS.x+00
      case 24:
        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))

        if (parsedDate && isISODateWithTwoDigitsOffset(date)) {
          const offsetString = `${date.slice(-3)}:00`
          const offsetInMinutes = parseTimeZoneOffsetToMinutes(offsetString)
          addMinutes(parsedDate, offsetInMinutes)
        }

        break

      // YYYY-MM-DDTHH:MM:SS+HH:MM --> From GPS world
      // YYYY-MM-DD HH:MM:SS.xx+00
      case 25:
        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)
        const offsetString = isISODateWithTwoDigitsOffset(date)
          ? `${date.slice(-3)}:00`
          : isISODateWithoutOffset(date)
            ? '+00:00'
            : date.slice(-6)

        const offsetInMinutes = parseTimeZoneOffsetToMinutes(offsetString)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))
        parsedDate = parsedDate ? addMinutes(parsedDate, offsetInMinutes) : parsedDate
        break

      // 2021-11-12 19:12:45.827+00
      case 26:
      // 2020-06-10 12:57:23.5358+00
      case 27:
      // 2019-04-24 15:03:33.77831+00
      case 28:
      // 2019-09-17 12:52:48.997849+00 --> From List idle settings
      case 29: {
        // ATTENTION: That type of input is not managed in v2. It's unclear if they can be cases or not
        // Further investigation required to decide if they should be removed or not.
        if (process.env.NODE_ENV === 'development' && (date.length === 27 || date.length === 28)) {
          console.warn('Unknown GPS timestamp, please investigate that.')
        }

        parsedDate = isISODateWithoutOffset(date)
          ? parseDateString(`${date}+00:00`)
          : parseDateString(date)

        const offsetString = isISODateWithoutOffset(date) ? '+00:00' : `${date.slice(-3)}:00`
        const offsetInMinutes = parseTimeZoneOffsetToMinutes(offsetString)
        parsedDate = resetToUTCDate(explodeDateFromUTC(parsedDate))
        parsedDate = parsedDate ? addMinutes(parsedDate, offsetInMinutes) : parsedDate

        break
      }
    }
  } else if (date instanceof Date) {
    if (isTimezoneConverted(date)) {
      const tmp = new Date(date.getTime())
      markTimezoneConverted(tmp)
      return tmp
    }
    parsedDate = resetToUTCDate(explodeDateFromUTC(date))
  } else if (typeof date === 'number') {
    parsedDate = resetToUTCDate(explodeDateFromUTC(new Date(date)))
  }

  if (!parsedDate) return undefined

  // compensate territory timezone offset
  parsedDate = addMinutes(parsedDate, offset)

  return parsedDate
}

// ------------------------------------------------------------------------------
// ------------------------------------------------------------------------------

export const getDateStringPattern = (date: string) => {
  switch (date.length) {
    case 8:
      // YYYYMMDD --> From RM world
      return 'yyyyMMdd'

    case 10:
      // YYYY-MM-DD --> From GPS report form
      return 'yyyy-MM-dd'

    case 19:
      // 2024-02-19T18:12:30
      // yyyy-MM-ddTHH:mm:ss --> From Telematics

      // ATTENTION: This is a valid ISO but since there's no timezone offset it's considered a local date and not a UTC date.
      return 'ISO'

    case 20:
      // 2024-02-19T18:12:30Z
      // 2024-04-19T13:27:29.4
      // yyyy-MM-ddTHH:mm:ssZ --> From Telematics

      return 'ISO'

    case 21:
      // 2024-04-19T13:27:29.4
      return 'ISO'

    case 22:
      // 2018-12-02 23:00:00+00
      // yyyy-MM-dd HH:mm:ss+ZZ --> From GPS report form (Driver behavior scorecard)

      // ATTENTION: it seems that this is considered a valid ISO, the commented return statement is the correct pattern for a manual parse()
      // return date[10] === 'T' ? `yyyy-MM-dd'T'HH:mm:ssX` : 'yyyy-MM-dd HH:mm:ssX'
      return 'ISO'

    case 23:
      return 'ISO'

    case 24:
      // ISO
      // YYYY-MM-DDTHH:MM:SS.000Z --> From GPS world
      // 2019-08-02T08:04:37.000Z
      return 'ISO'

    case 25:
      // YYYY-MM-DDTHH:MM:SS+HH:MM --> From GPS world
      // 2019-08-02T08:04:37+05:00

      // ATTENTION: it seems that this is considered a valid ISO, the commented return statement is the correct pattern for a manual parse()
      // return `yyyy-MM-dd'T'HH:mm:ssxxx`
      return 'ISO'

    case 26:
      // 2021-11-12 19:12:45.827+00
      return 'ISO'

    case 27:
      // 2020-06-10 12:57:23.5358+00
      return 'ISO'

    case 28:
      // 2019-04-24 15:03:33.77831+00
      return 'ISO'

    case 29:
      // 2019-09-17 12:52:48.997849+00
      return 'ISO'

    default:
      // TODO: it should throw
      return 'ISO'
  }
}

const parseDateStringCache = new Map<string, Date>()
export const parseDateString = (date: string) => {
  const cacheEntry = parseDateStringCache.get(date)

  if (cacheEntry) return cacheEntry

  const pattern = getDateStringPattern(date)

  if (!pattern) {
    throw new Error(`The input date string uses a unknown pattern: ${date}`)
  }

  const result = pattern === 'ISO' ? parseISO(date) : parse(date, pattern, new Date())
  parseDateStringCache.set(date, result)

  return result
}
