import { AdditionalCost } from './components/AdditionalCost'
import { AdditionalDrivingTime } from './components/AdditionalDrivingTime'
import { Color } from './components/Color'
import { DrawArea } from './components/DrawArea'
import { Name } from './components/Name'
import { form } from './formState'
import { useTexts } from './hooks/useTexts'

export function FormContent() {
  const texts = useTexts()

  return (
    <>
      <Name />

      <Color />

      <DrawArea />

      <form.Divider />

      <form.SectionTitle>{texts.labelCost}</form.SectionTitle>

      <AdditionalCost />
      <AdditionalDrivingTime />
    </>
  )
}
