import type { CheckboxProps as Props } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Checkbox as MUICheckbox } from '@mui/material'
import { Check } from '@/icons'
import { CheckboxIcon } from './CheckboxIcon'

const StyledCheckbox = styled(MUICheckbox)({
  padding: '0',
})

export function Checkbox(props: Props) {
  const { disabled } = props

  return (
    <StyledCheckbox
      {...props}
      disableRipple
      icon={<CheckboxIcon disabled={disabled} />}
      checkedIcon={
        <CheckboxIcon disabled={disabled} checked>
          <Check as="div" color="$pureWhite" size={9} style={{ marginTop: 2, marginLeft: 2 }} />
        </CheckboxIcon>
      }
    />
  )
}
