import { useMemo, useState } from 'react'

import { colorsUtils } from '@/utils'

import {
  type Color,
  type ColorValue,
  Palette,
  SelectTrigger,
  ColorPicker as TidalColorPicker,
} from '@workwave-tidal/core/components/ColorPicker'

import { Props } from './typings'

import { colorNames } from './colorNames'

export function ColorPicker(props: Props) {
  const { value, colors, onChange } = props
  const [open, onSetOpen] = useState(false)

  const palette: Color[] = useMemo(() => {
    return colors.map((c: string) => {
      return { color: colorsUtils.getValidHexColor(c) as ColorValue, label: colorNames[c] }
    })
  }, [colors])

  return (
    <TidalColorPicker
      testId="color-picker-button"
      localeText={{
        triggerSelectLabel: '',
      }}
      value={value as ColorValue}
      onColorChange={onChange}
      palette={<Palette palette={palette} />}
      trigger={{
        open,
        onSetOpen,
        Component: SelectTrigger,
        testId: 'color-chip-button',
        sx: {
          colorLabel: '',
          width: 85,
          '& .MuiTextField-root': {
            marginTop: 0,
          },
        },
      }}
    />
  )
}
