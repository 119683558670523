import { ResourceModel } from '@bryntum/schedulerpro'
import { getSchedulerPreferences } from '../../../utils/getSchedulerPreferences'

export function approvedDistanceSorter(item1: ResourceModel, item2: ResourceModel) {
  const approvedMileage1 = item1.getData(
    'approvedMileage',
  ) as uui.domain.client.rm.SchedulerResource['approvedMileage']

  const approvedMileage2 = item2.getData(
    'approvedMileage',
  ) as uui.domain.client.rm.SchedulerResource['approvedMileage']

  // It could have been written in a more concise way, but TypeScript is not able to infer the type of the object
  if (!approvedMileage1 || !approvedMileage2) {
    if (!approvedMileage1 && approvedMileage2) return -1
    if (approvedMileage1 && !approvedMileage2) return 1

    return 0
  }

  if (getSchedulerPreferences().showAbsoluteValues) {
    return approvedMileage1.absolute - approvedMileage2.absolute
  }

  return approvedMileage1.percentage - approvedMileage2.percentage
}
