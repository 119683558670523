import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@workwave-tidal/core/icons'
import type { ReactNode } from 'react'

export interface Props {
  avatar?: ReactNode

  title: Exclude<ReactNode, undefined | null>
  subtitle?: ReactNode

  onClose?: () => any

  testId?: string

  children: ReactNode
}

export function Header(props: Props) {
  const { avatar, title, subtitle, onClose, testId = 'form-header', children } = props

  return (
    <Stack data-testid={testId} data-trackid={testId} width="100%" spacing={2} paddingRight={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" flexGrow={1} overflow="hidden" minHeight={40} alignItems="center">
          {Boolean(avatar) && (
            <Box display="flex" alignItems="center">
              {avatar}
            </Box>
          )}

          <Stack>
            <Typography variant="h6" noWrap>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="caption" noWrap>
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Stack>

        {!!onClose && (
          <IconButton
            size="small"
            data-testid="secondary-column__close-btn"
            data-trackid="secondary-column__close-btn"
            onClick={onClose}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </Stack>

      {children}
    </Stack>
  )
}
