import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectGpsTrackingProvider, selectUserConfiguration } from '@/features/domain/user'
import { useFormApi, useFormField } from '@workwave-tidal/form-fairy'
import { type FormErrors, type FormFields } from '../../../formFields'

export function useCoordinateFields(creating: boolean) {
  const formApi = useFormApi<FormFields, FormErrors>()

  const { userType } = useSelector(selectUserConfiguration)

  const {
    field: { value: trackDriver },
  } = useFormField<'trackDriver', FormFields, FormErrors>('trackDriver')
  const trackingProvider = useSelector(selectGpsTrackingProvider)

  useEffect(() => {
    formApi.setFieldVisibleState('new-password', creating)
  }, [formApi, creating])

  useEffect(() => {
    formApi.setFieldVisibleState('gpsDeviceId', trackDriver && trackingProvider !== 'telematics')
  }, [formApi, trackDriver, trackingProvider])

  useEffect(() => {
    const isVisible = userType === 'rm360' && trackingProvider === 'WWGPS'
    formApi.setFieldVisibleState('trackDriver', isVisible)
  }, [formApi, userType, trackingProvider])
}
