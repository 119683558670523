import { useCallback, useState } from 'react'

import { resetCrudSelection } from '@/atoms'
import { deleteRegions } from '@/features/domain/region'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'
import { unproxify } from '@/utils'

import { useController } from './useController'

export function useDeleteRegions() {
  const [submitting, setSubmitting] = useState(false)

  const {
    data: { regionIds },
    close,
  } = useController()
  const dispatch = useAppDispatch()
  const toast = useNotification()
  const isUnmounted = useIsUnmounted()

  const submit = useCallback(async () => {
    try {
      setSubmitting(true)
      const areaIds = unproxify(regionIds)

      const result = await dispatch(deleteRegions(areaIds))

      if (isUnmounted()) return

      if (!deleteRegions.fulfilled.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      resetCrudSelection('regions')
    } catch (e) {
      toast.error(e.message)
    } finally {
      close?.()
      setSubmitting(false)
    }
  }, [dispatch, toast, regionIds, close, isUnmounted])

  return [submitting, submit] as const
}
