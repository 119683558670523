import { Box, Stack } from '@mui/material'

import { useConvertSpeed, useFormatDate, useFormatSeconds, useFormatTime } from '@/hooks'
import { Text } from '@/local/components'

import { useTexts } from './useTexts'

interface Props {
  point: uui.domain.client.gps.wwgps.GpsInfo
}

export function SpeedStop(props: Props) {
  const {
    point: { status, ts: timestamp, stoppedTime, speed: pointSpeed },
  } = props

  const pointDate = new Date(timestamp)

  const duration = useFormatSeconds(stoppedTime)
  const speed = useConvertSpeed(pointSpeed)
  const time = useFormatTime(pointDate, true)
  const date = useFormatDate(pointDate)

  const texts = useTexts()

  return (
    <Box width="100%" height="auto" flex="0 1 auto">
      <Box minWidth={260} height={46}>
        <Stack
          direction="column"
          height="100%"
          width="100%"
          justifyContent="space-between"
          flexShrink={0}
        >
          <Stack direction="row" height="100%" width="100%" alignItems="center">
            <Text size="$p3">{status === 'moving' ? texts.speed : texts.duration}:&nbsp;</Text>
            <Text size="$p3" weight="$semiBold">
              {status === 'moving' ? speed : duration}
            </Text>
          </Stack>

          <Stack direction="row" height="100%" width="100%" alignItems="center">
            <Text size="$p3">{status === 'moving' ? texts.date : texts.startedAt}:&nbsp;</Text>
            <Text size="$p3" weight="$semiBold">
              {`${time} • ${date}`}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
