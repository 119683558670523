import { Stack } from '@mui/material'

import { ExpandButton } from '@/components/Navigo/components/ExpandButton'
import { useSendMessageToDriverModal } from '@/components/modals/SendMessageToDriverModal'

import { usePlacesNavigator } from '../../../../hooks/usePlacesNavigator'
import { showByPermission } from '../../../../utils'
import { CreateGeofenceButton } from './components/CreateGeofenceButton'
import { CreateOrderButton } from './components/CreateOrderButton'
import { EditPlaceButton } from './components/EditPlaceButton'
import { SendMessageToDriverButton } from './components/SendMessageToDriverButton'
import { useActions } from './hooks/useActions'
import { useIsGpsOnly } from './hooks/useIsGpsOnly'
import { usePermissions } from './hooks/usePermissions'
import { StyledStack } from './hooks/useStyles'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
}

export function Actions(props: Props) {
  const { expanded, toggleExpanded } = props

  const { currentPlaces } = usePlacesNavigator()
  const canCreateOrder = !useIsGpsOnly()
  const permissions = usePermissions()

  const { createOrder, createGeofence, editPlace } = useActions(currentPlaces)
  const { show: showSendMessageToDriver, Modal: SendMessageToDriver } =
    useSendMessageToDriverModal()

  if (currentPlaces.length === 0) return null

  const multiplePlaces = currentPlaces.length > 1

  return (
    <Stack
      direction="row"
      height="100%"
      data-trackid="navigo-places-overview-actions"
      data-testid="navigo-places-overview-actions"
      alignItems="center"
      width="auto"
    >
      {expanded && (
        <StyledStack direction="row" height="100%" width="auto">
          {canCreateOrder && <CreateOrderButton onClick={createOrder} disabled={multiplePlaces} />}

          {showByPermission(permissions.createGeofence) && (
            <CreateGeofenceButton onClick={createGeofence} disabled={multiplePlaces} />
          )}

          <SendMessageToDriverButton onClick={showSendMessageToDriver} disabled={multiplePlaces} />

          <EditPlaceButton disabled={multiplePlaces} onClick={editPlace} />

          <SendMessageToDriver locationToShare={currentPlaces[0].latLng} />
        </StyledStack>
      )}

      <ExpandButton expanded={expanded} toggleExpanded={toggleExpanded} />
    </Stack>
  )
}
