import { FormHeader } from '@workwave-tidal/form-ui/layout'
import { ViewContainer, ViewContent, ViewFieldsLayout, ViewField } from '@/formUi'

import { getDayLabelFromIndex } from '@/intl'

import { usePersonalizationData } from '../hooks/usePersonalizationData'
import { weekDays } from '../weekDays'

import { PersonalizationViewActions } from './components/PersonalizationViewActions'
import { useTexts } from './hooks/useTexts'

type Props = {
  onEdit: () => void
}

export function GpsPersonalizationView(props: Props) {
  const { onEdit } = props

  const texts = useTexts()
  const [data] = usePersonalizationData()

  return (
    <ViewContainer testid="preferences-view">
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />

        <ViewFieldsLayout>
          {/* View Main Actions */}
          <PersonalizationViewActions onEdit={onEdit} />

          {/* View Fields */}
          <ViewField label={texts.weekStartsWith}>
            {getDayLabelFromIndex(weekDays[data.weekStartsOn], 'long')}
          </ViewField>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
