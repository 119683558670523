import { useCallback } from 'react'
import { IconButton, InputAdornment, styled } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { TextField } from '@workwave-tidal/core/components'

type Props = {
  query: string
  testId?: string
  setQuery: (query: string) => void
  disabled?: boolean
  placeholder: string
}

const FilterTextField = styled(TextField, {
  name: 'FilterTextField',
})(({ disabled }) => ({
  backgroundColor: disabled ? 'transparent' : 'white',
}))

export function ListHeaderFilterInput(props: Props) {
  const {
    placeholder,
    disabled = false,
    setQuery,
    testId = 'list-header-filter-input',
    query,
  } = props

  const onQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)
    },
    [setQuery],
  )

  const resetQuery = useCallback(() => {
    setQuery('')
  }, [setQuery])

  return (
    <FilterTextField
      data-trackid={testId}
      data-testid={testId}
      placeholder={placeholder}
      autoComplete="off"
      name="header-filter-input"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),

        endAdornment:
          query === '' ? null : (
            <InputAdornment position="end">
              <IconButton size="small" onClick={resetQuery} edge="end">
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
      }}
      onChange={onQueryChange}
      disabled={disabled}
      value={query}
    />
  )
}
