import { MapControls } from '@/components/MapControls'

import { Box, Stack } from '@mui/material'
import { Map } from './components/Map'
import { VehiclesRoot } from './components/form/VehiclesRoot'

export function Vehicles() {
  return (
    <Stack direction="row" data-testid="setup-vehicles-root" height="100%" width="100%">
      <VehiclesRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%" flexShrink={0}>
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
