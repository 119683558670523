import { proxy, snapshot, subscribe, useSnapshot } from 'valtio'

import { notifyLanguageChange } from '@/intl'
import { journal } from '@/server-data'
import { domainProxy } from '@/store'
import { subscribeKey } from 'valtio/utils'

type AppLanguage = {
  language: uui.domain.Language
  // `nextLanguage` is valued when the language loading in progress. It's always different from
  // `language` with the exception of the first loading
  nextLanguage: null | uui.domain.Language
}

type AppLanguageAtom = { settings: AppLanguage }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultAppLanguage: AppLanguage = {
  language: 'en',
  nextLanguage: null,
}

// ---------------------------------------------------------------------------
// Restore from Local Storage
// ---------------------------------------------------------------------------

const localStorageId = 'routemanager/v1/atoms/appLanguage'

const localStorageValue = localStorage.getItem(localStorageId)
const initialSettings = (
  localStorageValue ? JSON.parse(localStorageValue) : defaultAppLanguage
) as AppLanguage

// ---------------------------------------------------------------------------
// App language atom
// ---------------------------------------------------------------------------

const createDefaultAppLanguage = (): AppLanguageAtom => ({
  settings: initialSettings,
})

export const appLanguageAtom = proxy<AppLanguageAtom>(createDefaultAppLanguage())

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

type SetAppLanguage = (prev: AppLanguage) => AppLanguage
type SetAppLanguageParam = SetAppLanguage | Partial<AppLanguage> | 'reset'

export function resetAppLanguage() {
  appLanguageAtom.settings = defaultAppLanguage
}

export const setAppLanguage = (valueOrFunc: SetAppLanguageParam) => {
  if (valueOrFunc === 'reset') return resetAppLanguage()

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(appLanguageAtom.settings, valueOrFunc(appLanguageAtom.settings))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      appLanguageAtom.settings[field] = valueOrFunc[field]
    }
  }

  return appLanguageAtom
}

// ---------------------------------------------------------------------------
// Write to Local Storage
// ---------------------------------------------------------------------------

subscribe(appLanguageAtom.settings, () => {
  localStorage.setItem(localStorageId, JSON.stringify(appLanguageAtom.settings))
})

// ---------------------------------------------------------------------------
// Notify every language change
// ---------------------------------------------------------------------------

subscribeKey(appLanguageAtom.settings, 'nextLanguage', nextLanguage => {
  if (!nextLanguage) {
    // nextLanguage is set to NULL when the loading of a language completes
    notifyLanguageChange(appLanguageAtom.settings.language)
  }
})

// ---------------------------------------------------------------------------
// SUBSCRIBE METHOD
// ---------------------------------------------------------------------------

export function subscribeAppLanguage() {
  return domainProxy.subscribeToNotifications(
    notification => {
      if (notification.notificationType === 'language') {
        const nextLanguage = notification.payload

        if (nextLanguage !== appLanguageAtom.settings.language) {
          journal.atoms(`Language updated`, { info: nextLanguage })
        }

        setAppLanguage({ nextLanguage })
      }
    },
    ['language'],
  )
}

// ---------------------------------------------------------------------------
// React Hooks
// ---------------------------------------------------------------------------

export function useAppLanguage() {
  return [useSnapshot(appLanguageAtom).settings, setAppLanguage] as const
}

// ------------------------------------
// Read functions
// ------------------------------------

export function getAppLanguage(immutable: boolean = true) {
  return immutable ? snapshot(appLanguageAtom.settings) : appLanguageAtom.settings
}
