import { Box, Button, Stack, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'

import { LayoutComposer, ListFooter, NoEntries, NoResults } from '@/components/List'
import { StructuredVirtualList } from '@/components/StructuredVirtualList'
import { ComponentSize, ListOverlayEditCover } from '@/components/layout'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { selectUsersListStructure } from '@/features/domain/lists'
import { selectUsers } from '@/features/domain/user'

import { useSettingsEditing } from '../../../hooks/useSettingsEditing'

import { Header } from './components/Header'
import { useActions } from './hooks/useActions'
import { useSearchList } from './hooks/useSearchList'
import { useTexts } from './hooks/useTexts'
import { useUsersListItem } from './hooks/useUsersListItem'

type Props = {
  selection: string[]
  selectedUsers: uui.domain.client.rm.RouteManagerUser[]
  disabled: boolean
}

const height = { height: 'calc(100vh - 54px)' }
function getItemHeights() {
  return 59
}

export function ListUsers(props: Props) {
  const { selection } = props
  const { setQuery } = useSearchList()
  const structure = useSelector(selectUsersListStructure)
  const users = useSelector(selectUsers)
  const { editing } = useSettingsEditing('user-management', 'none')
  const { onUserSelect, onUserEdit, createNewUser, toggleAllUsers } = useActions({ setQuery })
  const texts = useTexts()
  const theme = useTheme()
  const Item = useUsersListItem()
  const borderRight = `1px solid ${theme.palette.grey['300']}`

  const allSelected = selection.length >= structure.shownItems
  const multiSelect = selection.length > 1

  return (
    <Box borderRight={borderRight} data-testid="form__column-primary">
      <Stack width={320} height={height} position="relative">
        <Header />

        <Box width="100%" height="100%" flex="0 1 auto" position="relative">
          <LayoutComposer
            totalItems={structure.totalItems}
            shownItems={structure.shownItems}
            noResults={
              <NoResults title={texts.noResults.title} description={texts.noResults.description} />
            }
            noEntries={
              <NoEntries
                title={texts.noEntries.title}
                description={texts.noEntries.description}
                action={
                  <ReadOnlyTooltip
                    render={preventEditing => (
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={createNewUser}
                        disabled={preventEditing || editing}
                        data-testid="no-entries-add-item"
                        data-trackid="no-entries-add-item"
                      >
                        {texts.noEntries.action}
                      </Button>
                    )}
                  />
                }
              />
            }
            list={
              <ComponentSize
                render={size => {
                  return (
                    <>
                      <StructuredVirtualList<'users'>
                        itemsById={users}
                        structure={structure}
                        listApiCategory="users"
                        getItemHeights={getItemHeights}
                        RenderItem={Item}
                        listHeight={size.height}
                        selectedItemIds={selection}
                        onDoubleClick={onUserEdit}
                        onSelect={onUserSelect}
                        buffer={50}
                      />
                      {editing && <ListOverlayEditCover />}
                    </>
                  )
                }}
              />
            }
          />
        </Box>

        {multiSelect && (
          <ListFooter
            label={texts.selectBtn(allSelected)}
            onToggleSelection={toggleAllUsers}
            checked={allSelected}
          />
        )}
      </Stack>
    </Box>
  )
}
