import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { EventsRoot } from './components/form/EventsRoot'

import { Box, Stack } from '@mui/material'

export function Events() {
  return (
    <Stack direction="row" height="100%" width="100%" data-testid="events-root">
      <EventsRoot />
      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%">
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
