import { Text } from '@/local/components'

import { Box, Stack } from '@mui/material'

import { useDepotsNavigator } from '../../../../hooks/useDepotsNavigator'
import { useStats } from './hooks/useStats'
import { useTexts } from './useTexts'

const growStyle = { height: 42 }

export function Stats() {
  const texts = useTexts()
  const { currentDepots } = useDepotsNavigator()
  const { setupCost, setupTime } = useStats(currentDepots)

  if (currentDepots.length === 0) return null

  return (
    <Box width="100%" flex="0 1 auto" style={growStyle}>
      <Stack
        direction="column"
        width="auto"
        height="100%"
        justifyContent="space-between"
        flexShrink={0}
      >
        <Stack direction="row" height="auto">
          <Text size="$p3">{texts.setupCost}:&nbsp;</Text>

          <Text size="$p3" weight="$semiBold">
            {setupCost}
          </Text>
        </Stack>

        <Stack direction="row" height="auto" width="100%">
          <Text size="$p3">{texts.setupTime}:&nbsp;</Text>

          <Text size="$p3" weight="$semiBold">
            {setupTime}
          </Text>
        </Stack>
      </Stack>
    </Box>
  )
}
