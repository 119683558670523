import type { FormFields, FormErrors } from '../formFields'

import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'

export function CompanySlogan() {
  const texts = useTexts()

  return (
    <TextField<'companySlogan', FormFields, FormErrors>
      name="companySlogan"
      label={texts.companySlogan}
      testId="companySlogan"
      validateOn="blur focus change"
    />
  )
}
