import { Box, CircularProgress, Stack } from '@mui/material'

import { OrdersVisibleMinimized } from '@/icons'
import { Text } from '@/local/components'

import { NavigoIconButton } from '../../../../../../../../elements/NavigoIconButton'

import { ButtonsContainer } from '../ButtonsContainer'
import { useTexts } from '../useTexts'
import { useActivePlaceMapStyle } from './hooks/useActivePlaceMapStyle'
import { usePlaceActions } from './hooks/usePlaceActions'

interface Props {
  placeIds: string[]
}

export function PlaceStyler(props: Props) {
  const { placeIds } = props

  const activePlaceMapStyle = useActivePlaceMapStyle(placeIds)
  const { methodInExecution, actions } = usePlaceActions(placeIds)

  const texts = useTexts()

  return (
    <Box height="100%" width="100%" flex="0 1 auto">
      <Stack direction="row" height="100%" width="100%" alignItems="center">
        <Stack direction="column" justifyContent="space-between" width="auto" marginRight={3}>
          <Text size="$p3" weight="$semiBold">
            {texts.placeTitle}
          </Text>

          <Text size="$p3">{texts.placeSubtitle}</Text>
        </Stack>

        <ButtonsContainer>
          <NavigoIconButton
            Icon={
              methodInExecution === 'showPlacePaths' ? (
                <CircularProgress size={16} color="primary" />
              ) : (
                <OrdersVisibleMinimized size={16} color="$nightRider" />
              )
            }
            testid="navigo-places-mapStyles-ordersStyle-on"
            active={activePlaceMapStyle === 'on'}
            onClick={actions.showPlacePaths}
            text={texts.placeShow}
          />

          <NavigoIconButton
            Icon={
              methodInExecution === 'hidePlacePaths' ? (
                <CircularProgress size={16} color="primary" />
              ) : (
                <OrdersVisibleMinimized size={16} color="$silver" />
              )
            }
            testid="navigo-places-mapStyles-ordersStyle-hide"
            active={activePlaceMapStyle === 'off'}
            onClick={actions.hidePlacePaths}
            text={texts.placeHide}
          />
        </ButtonsContainer>
      </Stack>
    </Box>
  )
}
