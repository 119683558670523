import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { type CSSProperties, useCallback } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'
import { NewScheduler, OrdersGrid } from '@/icons'

import { SearchInput } from '@/components/primitives/SearchInput'

import { useShowHideScheduler } from './hooks/useShowHideScheduler'

import * as LocalTexts from './intl'
import { Props } from './typings'

const containerStyle: CSSProperties = { display: 'flex', alignItems: 'center' }

export const SearchOrdersBar = (props: Props) => {
  const {
    filteredOrdersIds,
    selectOrderSteps,
    showSearch,
    showSwitch,
    setFilter,
    filter,
    showScheduler,
  } = props

  const { onRadioChange, radioValue } = useShowHideScheduler(showScheduler)

  const handleOnFilterChange = useCallback(
    (value: string) => {
      setFilter(value)
    },
    [setFilter],
  )

  const handleOnCounterClick = useCallback(() => {
    if (filteredOrdersIds.length > 0) {
      // TODO: additional parameters are not handled yet
      // selectOrderSteps(filteredOrdersIds, undefined, true)
      selectOrderSteps(filteredOrdersIds)
    }
  }, [selectOrderSteps, filteredOrdersIds])

  return (
    <div style={containerStyle}>
      {showSwitch && (
        <ToggleButtonGroup
          data-testid="orders-grid-switcher"
          data-trackid="orders-grid-switcher"
          size="small"
          value={radioValue}
          exclusive
          onChange={onRadioChange}
        >
          <Tooltip placement="bottom" title="Scheduler">
            <ToggleButton sx={{ padding: 0 }} value="scheduler">
              <Box padding={1}>
                <NewScheduler size={16} />
              </Box>
            </ToggleButton>
          </Tooltip>

          <Tooltip placement="bottom" title="Orders grid">
            <ToggleButton sx={{ padding: 0 }} value="orders-grid">
              <Box padding={1}>
                <OrdersGrid size={16} />
              </Box>
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      )}

      {showSearch && (
        <SearchInput
          filter={filter}
          onChange={handleOnFilterChange}
          className="pendo-order__search"
          placeholder={LocalTexts.getSearchText()}
          extraLabelClassName="pendo-order__search-results"
          extraLabelOnClick={filteredOrdersIds.length > 0 ? handleOnCounterClick : undefined}
          testId="orders-grid-filter-orders"
          trackid="orders-grid-filter-orders"
          extraLabel={
            filter.trim().length > 0
              ? LocalTexts.getOrdersCounterText(filteredOrdersIds.length)
              : undefined
          }
        />
      )}
    </div>
  )
}
