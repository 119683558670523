import { ActionBarItem } from '@workwave-tidal/core/components/ActionBar'
import { Close } from '@workwave-tidal/core/icons'
import { useMemo } from 'react'
import { differenceInCalendarDays } from 'date-fns/esm'

import { ModalHeader } from '@/components/Modal'
import { useFormatDateInterval } from '@/hooks'

import { useController } from '../hooks/useController'
import { useTexts } from '../useTexts'

export function Header() {
  const {
    close,
    data: {
      calendarRange: { start, end },
    },
  } = useController()
  const texts = useTexts()

  const numberOfDaysToExport = useMemo(() => differenceInCalendarDays(end, start) + 1, [start, end])
  const interval = useFormatDateInterval(start, end, false, true)

  const title = `${texts.title(numberOfDaysToExport)}, ${interval}`

  return (
    <ModalHeader
      actions={[<ActionBarItem icon={<Close />} key="close" onClick={close} label="" />]}
      subtitle={texts.subtitle}
      title={title}
    />
  )
}
