import { updateUserUiDataSettings } from '@/features/domain/ui'
import { selectMapConfig } from '@/features/domain/user'
import { store } from '@/store'

export const changeMapTiles = (tile: uui.domain.ui.map.MapTile) => {
  const { showTraffic } = selectMapConfig(store.getState())

  store.dispatch(
    updateUserUiDataSettings({
      mapConfig: {
        tile,
        showTraffic,
      },
    }),
  )
}
