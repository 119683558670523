import type { FormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../formFields'

import { type AppDispatch } from '@/store'

import { useTexts } from '../useTexts'

import { createVerizonConnectFormValidation } from './createVerizonConnectFormValidation'
import { createLinxupFormValidation } from './createLinxupFormValidation'
import { createAzugaFormValidation } from './createAzugaFormValidation'
import { createGeotabFormValidation } from './createGeotabFormValidation'

export function createFormValidation(
  source: uui.domain.server.gps.telematics.Source,
  dispatch: AppDispatch,
  texts: ReturnType<typeof useTexts>,
): FormConfig<FormFields, FormErrors>['validations'] {
  switch (source.label) {
    case 'Azuga':
      return createAzugaFormValidation(dispatch, texts)

    case 'Linxup':
      return createLinxupFormValidation(dispatch, texts)

    case 'Geotab':
      return createGeotabFormValidation(dispatch, texts)

    case 'Verizon Connect':
    case 'Verizon Connect (EU)':
    case 'Verizon Connect (US)':
      return createVerizonConnectFormValidation(dispatch, texts)
  }
}
