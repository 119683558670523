import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import type { GridCellParams } from '@mui/x-data-grid-pro'
import { useDotColor } from './hooks/useDotColor'
import { useCrateFormatValue } from './hooks/useFormatValue'

type Props = { params: GridCellParams<uui.domain.client.rm.FormattedData['assignedTo']> }

// Styled Dot component
const Dot = styled('div')<{ dotColor: string }>(({ dotColor }) => ({
  backgroundColor: dotColor,
  borderRadius: 5,
  marginRight: 5,
  marginLeft: 5,
  height: 10,
  width: 10,
}))

function AssignedToCellRenderer(props: Props) {
  const {
    params: { value, row },
  } = props

  const orderStepId = row.id as string
  const color = useDotColor(orderStepId) || '#FFFFFF' // Fallback to white if color is not available
  const formatValue = useCrateFormatValue()

  return (
    <span className="table-cell-truncate">
      <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
        {color && <Dot dotColor={color} />}
        {formatValue(value)}
      </Box>
    </span>
  )
}

export function AssignedToCell(params: GridCellParams) {
  return <AssignedToCellRenderer params={params} />
}
