import type { FormFields, FormErrors } from '../../../../../formFields'

import { Password } from '@workwave-tidal/form-ui'

import { useTexts } from './useTexts'

interface Props {
  firstSubmitDone: boolean
}
export function NewPassword(props: Props) {
  const { firstSubmitDone } = props

  const texts = useTexts()

  return (
    <Password<'newPassword', FormFields, FormErrors>
      name="newPassword"
      label={texts.newPassword}
      autoFocus
      validateOn="blur focus change"
      errorVisibility={firstSubmitDone ? 'accent-only' : 'none'}
    />
  )
}
