import type OverviewMap from 'ol/control/OverviewMap'
import XYZ from 'ol/source/XYZ'

import { selectMapConfig } from '@/features/domain/user'
import { store } from '@/store'

import { mapAtom } from '../atoms/map/map'
import { getTilesLayer } from '../atoms/map/utils/getTilesLayer'
import { setLiveTrafficLayerVisibility } from '../atoms/map/utils/setLiveTrafficLayerVisibility'
import { getTileUrl } from '../atoms/map/utils/tiles'

export function subscribeMapToStoreUpdates() {
  const mapConfig = selectMapConfig(store.getState())

  let prevShowTraffic = mapConfig.showTraffic
  let prevTile = mapConfig.tile

  function handleStoreChange() {
    try {
      const { showTraffic, tile } = selectMapConfig(store.getState())

      if (prevShowTraffic !== showTraffic) {
        setLiveTrafficLayerVisibility(showTraffic)
        prevShowTraffic = showTraffic
      }

      if (prevTile !== tile) {
        prevTile = tile
        updateMapTile(tile)
      }
    } catch (error) {
      console.error(error)
    }
  }

  console.log('Map: subscribing to store updates')
  const unsubscribe = store.subscribe(handleStoreChange)

  return () => {
    console.log('Map: unsubscribing from store updates')
    unsubscribe()
  }
}

function updateMapTile(tile: uui.domain.ui.map.MapTile) {
  const tileLayer = getTilesLayer()

  if (!tileLayer || !mapAtom.authenticationToken) {
    throw new Error(`Trying to change map tiles before the map is ready.`)
  }

  const tileSource = tileLayer.getSource()

  if (!(tileSource instanceof XYZ)) {
    throw new Error(`The Map Tile Source is not valid.`)
  }

  // change source
  const tileUrl = getTileUrl({ tileType: tile })
  tileSource.setUrl(tileUrl)

  // Update overview map tiles

  // Compute the tile type for the overview map
  const overviewMapTileType = tile === 'normal' ? 'hybrid' : 'normal'

  const overviewMapControl: OverviewMap = mapAtom.map.getControls().get('rm-overview-map')
  const overviewMapControlLayers = overviewMapControl.getOverviewMap()?.getAllLayers()
  const overviewMapTileSource = overviewMapControlLayers?.at(0)?.getSource() as
    | XYZ
    | null
    | undefined

  if (!overviewMapTileSource) return

  const overviewMapTileUrl = getTileUrl({
    tileType: overviewMapTileType,
  })

  overviewMapTileSource.setUrl(overviewMapTileUrl)
}
