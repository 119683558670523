import type { FormFields, FormErrors } from '../formFields'

import { useCallback } from 'react'

import { useFormApi } from '@workwave-tidal/form-fairy'
import { useIsUnmounted } from '@/hooks'

const delay = (duration: number) => new Promise(resolve => window.setTimeout(resolve, duration))

export interface NotificationPreferences {
  companyFromName: string
  dateFormat: 'MMMD' | 'DMMM'
  timeFormat: uui.domain.server.TimeFormat
  notificationLanguage: string
}

export function useOnSubmit(
  updatePreferences: (payload: NotificationPreferences) => Promise<boolean>,
  clearEditingState: () => void,
) {
  const formApi = useFormApi<FormFields, FormErrors>()

  const isUnmounted = useIsUnmounted()

  return useCallback(async () => {
    formApi.setSubmitting(true)

    // Let's add a fake delay to let the user understand that a validation is in progress
    await delay(300)

    const valid = await formApi.validate()
    if (!valid) {
      formApi.setSubmitting(false)
      return
    }

    const formValues = formApi.getValues()
    const result = await updatePreferences(formValues)

    // Clear FormState submitting mode
    formApi.setSubmitting(false)

    // if the component has been unmounted during the async operation stop here
    if (isUnmounted()) return

    // if the update has been completed successfully let's close the form
    if (result) clearEditingState()
  }, [formApi, isUnmounted, updatePreferences, clearEditingState])
}
