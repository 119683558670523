import { updateUserUiDataSettings } from '@/features/domain/ui'
import { isGpsOnly, selectUserUiSettings } from '@/features/domain/user'
import { store } from '@/store'

import { collectDataToMigrate } from './collectDataToMigrate'

export function createMigrationToUiDataV2() {
  let skipMigrationPhase = false

  return async function migrationToUiDataV2() {
    try {
      const state = store.getState()
      const gpsOnly = isGpsOnly(state)
      const currentSettings = selectUserUiSettings(state)
      const migrationData = await collectDataToMigrate(gpsOnly)

      const { updatesRequired, data, clearLocalStorage } = migrationData

      if ((updatesRequired || currentSettings.version === 1) && !skipMigrationPhase) {
        console.log('Migration to UI data v2 started')
        const response = await store.dispatch(updateUserUiDataSettings(data))

        if (response.type === 'rpc-failure') {
          throw new Error('Failed to update user UI settings')
        }

        console.log('Migration to UI data v2 completed. Clearing local storage')
        skipMigrationPhase = true
        clearLocalStorage()
      } else {
        console.log('Migration to UI data v2 skipped')
      }
    } catch (error) {
      console.error('Migration to UI data v2 failed', error)
    }
  }
}
