import { CircularProgress, Stack } from '@mui/material'

import { LightBulb } from '@/icons'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { ButtonsContainer } from './components/ButtonsContainer'
import { PlaceStyler } from './components/PlaceStyler'

import { useStylerActions } from './hooks/useStylerActions'
import { useTexts } from './useTexts'

interface Props {
  placeIds: string[]
}

export function Styler(props: Props) {
  const { placeIds } = props

  const { methodInExecution, actions } = useStylerActions(placeIds)
  const texts = useTexts()

  return (
    <Stack
      direction="row"
      width="100%"
      data-trackid="navigo-places-mapStyles-styler"
      data-testid="navigo-places-mapStyles-styler"
      justifyContent="start"
      height={42}
    >
      <PlaceStyler placeIds={placeIds} />

      <ButtonsContainer>
        <NavigoIconButton
          testid="navigo-places-mapStyles-popoutPlacesButton"
          Icon={
            methodInExecution === 'popoutPlaces' ? (
              <CircularProgress size={16} color="primary" />
            ) : (
              <LightBulb size={16} color="$nightRider" />
            )
          }
          text={texts.popoutPlace(placeIds.length)}
          onClick={actions.popoutPlaces}
        />
      </ButtonsContainer>
    </Stack>
  )
}
