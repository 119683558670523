import { FormControl, Slider, SliderMarkLabel, Stack, Typography } from '@mui/material'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useTexts } from '../../../hooks/useTexts'

import { SliderMarkLabelSlotProps } from '@mui/base'
import { useCallback } from 'react'
import { IconButton } from './IconButton'
import { Rabbit } from './icons/Rabbit'
import { Tortoise } from './icons/Tortoise'
import { marks, snapToNext, snapToPrev } from './marks'

type ClickableSliderMarkProps = SliderMarkLabelSlotProps & {
  onChange: (e: React.SyntheticEvent, value: number) => void
}

const ClickableSliderMarkLabel = (props: ClickableSliderMarkProps) => {
  const { onChange, ...restProps } = props

  const markValue = props.ownerState?.marks?.[props?.['data-index']]?.value

  const noop = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation()
  }, [])

  const onClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (typeof markValue === 'number') {
        onChange(e, markValue)
      }
    },
    [onChange, markValue],
  )

  return <SliderMarkLabel onMouseDown={noop} onTouchStart={noop} onClick={onClick} {...restProps} />
}

function valueLabelFormat(value: number) {
  return `${parseFloat((Math.abs(value) / 100).toFixed(2))}x`
}

export function DrivingTime() {
  const texts = useTexts()
  const { onChangeBaseTrafficFactor } = useControllerActions()
  const {
    data: {
      formValues: { baseTrafficFactor },
    },
  } = useController()

  // this functions are re-created every time baseTrafficFactor changes
  // there is no value to memoize them
  function onSnapToPrev() {
    onChangeBaseTrafficFactor(new Event(''), snapToPrev(baseTrafficFactor))
  }
  function onSnapToNext() {
    onChangeBaseTrafficFactor(new Event(''), snapToNext(baseTrafficFactor))
  }

  return (
    <FormControl data-testid="driving-time-field">
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {texts.form.drivingTime}
      </Typography>

      <Stack direction="row" alignItems="flex-start" gap={1}>
        <IconButton onClick={onSnapToPrev} data-testid="driving-time-prev-btn">
          <Rabbit />
        </IconButton>
        <Slider
          size="small"
          min={-600}
          max={-50}
          step={1}
          value={baseTrafficFactor}
          onChange={onChangeBaseTrafficFactor}
          valueLabelFormat={valueLabelFormat}
          marks={marks}
          valueLabelDisplay="auto"
          components={{ MarkLabel: ClickableSliderMarkLabel }}
          // @ts-ignore
          componentsProps={{ markLabel: { onChange: onChangeBaseTrafficFactor } }}
        />
        <IconButton onClick={onSnapToNext} data-testid="driving-time-next-btn">
          <Tortoise />
        </IconButton>
      </Stack>
    </FormControl>
  )
}
