import type { FormFields, FormErrors } from '../formFields'

import { RadioGroup } from '@workwave-tidal/form-ui'

import { useTexts } from '../../hooks/useTexts'
import { useDateFormatOptions } from '../hooks/useDateFormatOptions'

interface Props {
  submitting: boolean
}

export function DateFormat(props: Props) {
  const texts = useTexts()
  const options = useDateFormatOptions()

  return (
    <RadioGroup<
      'dateFormat',
      uui.domain.client.rm.NotificationConfig['dateFormat'],
      FormFields,
      FormErrors
    >
      disabled={props.submitting}
      label={texts.dateFormat}
      validateOn="blur focus"
      options={options}
      name="dateFormat"
      testId="dateFormat"
    />
  )
}
