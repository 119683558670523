import { useSelector } from 'react-redux'
import { FormHeader } from '@workwave-tidal/form-ui/layout'

import { ViewContainer, ViewContent, ViewFieldsLayout, ViewField } from '@/formUi'

import { selectUserType } from '@/features/domain/user'
import { useRmAccountData } from '../hooks/useRmAccountData'
import { useTexts } from './hooks/useTexts'

import { RmAccountViewActions } from './components/RmAccountViewActions'
import { RmAccountViewApiKeyActions } from './components/RmAccountViewApiKeyActions'
import { useApiKey } from './hooks/useApiKey'

type Props = {
  onEdit: () => void
}

export function RmAccountView(props: Props) {
  const { onEdit } = props

  const texts = useTexts()
  const apiKey = useApiKey()
  const [account] = useRmAccountData()

  const userType = useSelector(selectUserType)
  const gotAccessToApiKey = userType === 'admin'

  return (
    <ViewContainer testid="preferences-view">
      <ViewContent>
        <FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />

        <ViewFieldsLayout>
          {/* View MAin Actions */}
          <RmAccountViewActions onEdit={onEdit} />

          {/* View Fields */}
          <ViewField label={texts.name}>{account.name}</ViewField>
          <ViewField label={texts.surname}>{account.surname}</ViewField>
          <ViewField label={texts.email}>{account.email}</ViewField>
          <ViewField label={texts.username}>{account.username}</ViewField>
          {gotAccessToApiKey && (
            <ViewField label={texts.apiKey}>{apiKey ?? texts.missingApiKey}</ViewField>
          )}

          {/* API Key Actions */}
          {gotAccessToApiKey && <RmAccountViewApiKeyActions apiKey={apiKey} />}
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
