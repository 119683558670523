import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { RegionsRoot } from './components/form/RegionsRoot'
import { useEnsureRegionVisibility } from './hooks/useEnsureRegionVisibility'

import { Box, Stack } from '@mui/material'

export function Regions() {
  useEnsureRegionVisibility()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="setup-regions-root">
      <RegionsRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%">
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
