import type { ModalData } from './typings'

import { useIntl } from '@/intl'
import { useState } from 'react'

type ErrorType = Exclude<ModalData['issue'], 'none'>

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerTitle: (
      orderStepStatus: 'not-executed' | 'not-completed' | 'executed',
      pairedOrderStepStatus:
        | 'not-executed'
        | 'not-completed'
        | 'executed'
        | 'not-assigned'
        | 'in-a-locked-route',
      pairedOrderStepType: uui.domain.client.rm.OrderStepIdentifier,
    ) => {
      switch (orderStepStatus) {
        case 'not-executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Move paired order as well?
              return translate({ id: 'modals.movePairedOrders.header.title' })
            }
            case 'executed': {
              // Move executed pickup/dropoff as well?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedPairedDropoff',
                  })
              }
            }
            case 'not-assigned': {
              // Assign paired pickup/dropoff as well?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedDropoff',
                  })
              }
            }
            case 'in-a-locked-route': {
              // Paired order can't be moved
              return translate({
                id: 'modals.movePairedOrders.header.title.pairedCantBeMoved',
              })
            }
          }
        }
        case 'not-completed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Move paired order as well?
              return translate({ id: 'modals.movePairedOrders.header.title' })
            }
            case 'executed': {
              // Move executed pickup/dropoff as well?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedPairedDropoff',
                  })
              }
            }
            case 'not-assigned': {
              // Assign paired pickup/dropoff as well?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedDropoff',
                  })
              }
            }
            case 'in-a-locked-route': {
              // Paired order can't be moved
              return translate({
                id: 'modals.movePairedOrders.header.title.pairedCantBeMoved',
              })
            }
          }
        }
        case 'executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Move executed order and paired pickup/dropoff?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedOrderAndPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.moveExecutedOrderAndPairedDropoff',
                  })
              }
            }
            case 'executed': {
              // Move executed orders?
              return translate({
                id: 'modals.movePairedOrders.header.title.moveExecutedOrders',
              })
            }
            case 'not-assigned': {
              // Assign paired pickup/dropoff as well?
              switch (pairedOrderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedPickup',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.header.title.assignPairedDropoff',
                  })
              }
            }
            case 'in-a-locked-route': {
              // Move executed order?
              return translate({
                id: 'modals.movePairedOrders.header.title.moveExecutedOrder',
              })
            }
          }
        }
      }
    },

    btnDoNotMove: (stepIdentifier: uui.domain.client.rm.OrderStepIdentifier) => {
      const pickUp = stepIdentifier === 'p'

      return pickUp
        ? translate({ id: 'modals.movePairedOrders.button.doNotMove.unassigned.delivery' })
        : translate({ id: 'modals.movePairedOrders.button.doNotMove.unassigned.pickup' })
    },

    btnCancel: translate({ id: 'modals.movePairedOrders.button.cancel' }),

    btnMove: (
      stepIdentifier: uui.domain.client.rm.OrderStepIdentifier,
      pairedUnassigned: boolean,
    ) => {
      const pickUp = stepIdentifier === 'p'
      if (pickUp) {
        return pairedUnassigned
          ? translate({ id: 'modals.movePairedOrders.button.move.pickup.unassigned' })
          : translate({ id: 'modals.movePairedOrders.button.move.moveBoth' })
      }

      return pairedUnassigned
        ? translate({ id: 'modals.movePairedOrders.button.move.delivery.unassigned' })
        : translate({ id: 'modals.movePairedOrders.button.move.moveBoth' })
    },

    btnCancelTitle: translate({ id: 'modals.movePairedOrders.button.cancel.title' }),

    savePreference: translate({ id: 'modals.movePairedOrders.savePreferenceText' }),

    cardTitle: (
      orderStepStatus: 'not-executed' | 'not-completed' | 'executed',
      orderStepType: uui.domain.client.rm.OrderStepIdentifier,
      pairedOrderStepStatus:
        | 'not-executed'
        | 'not-completed'
        | 'executed'
        | 'not-assigned'
        | 'in-a-locked-route',
    ) => {
      switch (orderStepStatus) {
        case 'not-executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // This Drop-Off order is linked to a Pick-Up order. Do you want to move both orders?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.pickupLinkedToDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.dropoffLinkedToPickup',
                  })
              }
            }
            case 'executed': {
              // This dropoff order is linked to a pickup order that has already been executed.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupPairedExecutedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffPairedExecutedPickup',
                  })
              }
            }
            case 'not-assigned': {
              // This dropoff order is linked to a pickup order that is not assigned to a route. The dropoff will be moved to the new route, do you also want to assign the pickup?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupPairedUnassignedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffPairedUnassignedPickup',
                  })
              }
            }
            case 'in-a-locked-route': {
              // This dropoff order is linked to a pickup order in a locked route.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupPairedLockedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffPairedLockedPickup',
                  })
              }
            }
          }
        }
        case 'not-completed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // This Drop-Off order is linked to a Pick-Up order. Do you want to move both orders?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.pickupLinkedToDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.dropoffLinkedToPickup',
                  })
              }
            }
            case 'executed': {
              // This dropoff order is linked to a pickup order that has already been executed.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupPairedExecutedDropoff',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffPairedExecutedPickup',
                  })
              }
            }
            case 'not-assigned': {
              // This dropoff order is linked to a pickup order that is not assigned to a route. The dropoff will be moved to the new route, do you also want to assign the pickup?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupPairedUnassignedDropoff',
                  })
                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffPairedUnassignedPickup',
                  })
              }
            }
            case 'in-a-locked-route': {
              // This dropoff order is linked to a pickup order in a locked route.
              return translate({
                id: 'modals.movePairedOrders.header.title.pairedCantBeMoved',
              })
            }
          }
        }
        case 'executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // This dropoff order has been executed and is linked to a pickup order. You can move both orders to keep them paired, or move only this order.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.movePickupExecutedAndPairedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveDropoffExecutedAndPairedPickup',
                  })
              }
            }
            case 'executed': {
              // This dropoff order is linked to a pickup order that has already been executed.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.bothPickupAndDropoffExecuted',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.bothDropoffAndPickupExecuted',
                  })
              }
            }
            case 'not-assigned': {
              // This executed dropoff order is linked to a pickup that is not assigned. The dropoff will be moved to the new route, do you also want to assign the pickup?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveExecutedPickupPairedUnassignedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveExecutedDropoffPairedUnassignedPickup',
                  })
              }
            }
            case 'in-a-locked-route': {
              // This executed order order is linked to a pickup order in a locked route. Since the paired order is locked, only the executed order will be moved.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveExecutedPickupPairedLockedDropoff',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.title.moveExecutedDropoffPairedLockedPickup',
                  })
              }
            }
          }
        }
      }
    },

    cardText: (
      orderStepStatus: 'not-executed' | 'not-completed' | 'executed',
      orderStepType: uui.domain.client.rm.OrderStepIdentifier,
      pairedOrderStepStatus:
        | 'not-executed'
        | 'not-completed'
        | 'executed'
        | 'not-assigned'
        | 'in-a-locked-route',
    ) => {
      switch (orderStepStatus) {
        case 'not-executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Separating paired pickup and dropoff will create violations
              return translate({ id: 'modals.movePairedOrders.card.text' })
            }
            case 'executed': {
              // Moving the executed order will delete its execution data permanently.
              return translate({
                id: 'modals.movePairedOrders.card.text.executedPaired',
              })
            }
            case 'not-assigned':
            case 'in-a-locked-route':
              return ''
          }
        }
        case 'not-completed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Separating paired pickup and dropoff will create violations
              return translate({ id: 'modals.movePairedOrders.card.text' })
            }
            case 'executed': {
              // Moving the executed order will delete its execution data permanently.
              return translate({
                id: 'modals.movePairedOrders.card.text.executedPaired',
              })
            }
            case 'not-assigned':
            case 'in-a-locked-route':
              return ''
          }
        }
        case 'executed': {
          switch (pairedOrderStepStatus) {
            case 'not-executed':
            case 'not-completed': {
              // Moving the dropoff order will delete its execution data and may cause pairing violations if only one is moved.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.executedStep.pickup',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.executedStep.dropoff',
                  })
              }
            }
            case 'executed': {
              // Moving them will delete their execution data and may cause pairing violations if only one is moved.
              return translate({
                id: 'modals.movePairedOrders.card.text.executedStep.both',
              })
            }
            case 'not-assigned': {
              // This executed dropoff order is linked to a pickup that is not assigned. The dropoff will be moved to the new route, do you also want to assign the pickup?
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.unassignedPaired.pickup',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.unassignedPaired.dropoff',
                  })
              }
            }
            case 'in-a-locked-route': {
              // This executed order order is linked to a pickup order in a locked route. Since the paired order is locked, only the executed order will be moved.
              switch (orderStepType) {
                case 'p':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.lockedPaired.pickup',
                  })

                case 'd':
                  return translate({
                    id: 'modals.movePairedOrders.card.text.lockedPaired.dropoff',
                  })
              }
            }
          }
        }
      }
    },

    lockedRouteSuggestion: 'modals.movePairedOrders.lockedRouteSuggestion',

    btnErrorClose: translate({ id: 'modals.movePairedOrders.button.errorClose' }),

    errorCardTitle: translate({ id: 'modals.movePairedOrders.errorCard.title' }),

    errorCardText: (errorType: ErrorType) => {
      if (errorType === 'orderStepMissing' || errorType === 'routeUnavailable') {
        return translate({ id: `modals.movePairedOrders.errorCard.text.${errorType}` as const })
      } else {
        // don't know why all the status are not mapped in the lang files
        return ''
      }
    },
  }))

  return api
}
