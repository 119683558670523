import { Separator } from '@/components/Separator'
import { MapStyle } from '@/icons'

import { CloseButton } from '../../../../elements/CloseButton'
import { NavigoIconButton } from '../../../../elements/NavigoIconButton'

import { useRoutesNavigator } from '../../hooks/useRoutesNavigator'
import { Actions } from './components/Actions'
import { Details } from './components/Details'
import { OrdersButton } from './components/OrdersButton'
import { Stats } from './components/Stats'

import { useTexts } from './useTexts'

interface Props {
  expanded: boolean
  goToStyle: () => void
  toggleExpanded: () => void
}

export function Overview(props: Props) {
  const { goToStyle, expanded, toggleExpanded } = props

  const { currentRoutes } = useRoutesNavigator()
  const texts = useTexts()

  return (
    <>
      <Details routeInfos={currentRoutes} />

      <Separator />

      <Stats routeInfos={currentRoutes} />

      <Actions expanded={expanded} toggleExpanded={toggleExpanded} />

      <NavigoIconButton
        text={currentRoutes.length > 1 ? texts.styleAll : texts.style}
        Icon={<MapStyle size={20} color="$nightRider" />}
        testid="navigo-routes-map-style-button"
        onClick={goToStyle}
      />

      <OrdersButton routeInfos={currentRoutes} />
      <CloseButton />
    </>
  )
}
