import type { FormFields, FormErrors } from '../../../../../formFields'

import { TextField } from '@workwave-tidal/form-ui'
import { useTexts } from '../../../../../useTexts'

export function Label() {
  const texts = useTexts()
  return (
    <TextField<'label', FormFields, FormErrors>
      name="label"
      label={texts.azuga.label}
      required={false}
      helperText={texts.azuga.labelHelperText}
      validateOn="blur focus change"
      textfieldProps={{ size: 'medium' }}
    />
  )
}
