import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { useBuildProgressModal } from '@/components/modals/BuildProgressModal'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { ActionButton } from '@/components/primitives/buttons/ActionButton'
import { useCopyOrdersModal } from '@/components/modals/CopyOrdersModal'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useCanAddNewOrder, useIsSimulation, useOrdersLimit } from '@/hooks'
import { selectOrderStepIdsInRange } from '@/features/domain/order'
import { Plus, Flash } from '@/icons'

import { useActions } from '../hooks/useActions'
import { StyledCopyIcon } from '../hooks/useStyles'
import { useTexts } from '../useTexts'

import { ImportWizardButton } from './ImportWizardButton'
import { Separator } from './Separator'

export function Simulation() {
  const { show: showBuildProgressModal, Modal: BuildProgressModal } = useBuildProgressModal()
  const { show: showCopyOrdersModal, Modal: CopyOrdersModal } = useCopyOrdersModal()
  const { addOrder, buildRoutes, exportToOperations } = useActions(
    showBuildProgressModal,
    showCopyOrdersModal,
  )

  const orderStepIdsInRange = useSelector(selectOrderStepIdsInRange)
  const canAddNewOrder = useCanAddNewOrder()
  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const texts = useTexts()

  const gotOrdersToCopy = Object.keys(orderStepIdsInRange).length > 0
  return (
    <Stack marginTop={2} padding="0 24px 24px 16px" spacing={1} width="100%">
      <ReadOnlyTooltip
        placement="right"
        canEdit={canAddNewOrder}
        title={texts.addOrderDisabledTooltipTitle(isSimulation)}
        subtitle={texts.addOrderDisabledTooltipSubtitle(isSimulation, ordersLimit)}
        render={preventEditing => (
          <ActionButton
            onClick={addOrder}
            text={texts.addOrder}
            disabled={preventEditing}
            secondaryButton={<ImportWizardButton disabled={preventEditing} />}
            testid="sidebar-simulations__add-order-button"
            Icon={<Plus size={14} color={preventEditing ? '$silver' : '$nightRider'} />}
          />
        )}
      />

      <Separator />

      <ActionButton
        onClick={buildRoutes}
        text={texts.buildRoutes}
        Icon={<Flash size={14} color="$nightRider" />}
        testid="sidebar-simulations__build-button"
      />

      <Separator />

      <Tooltip
        subtitle={texts.emptyPlanSubtitle}
        title={texts.emptyPlanTitle}
        disabled={gotOrdersToCopy}
        placement="right"
      >
        <ActionButton
          disabled={!gotOrdersToCopy}
          onClick={exportToOperations}
          text={texts.copyToOperations}
          Icon={<StyledCopyIcon color={gotOrdersToCopy ? undefined : 'disabled'} />}
          testid="sidebar-simulations__copy-button"
        />
      </Tooltip>

      <BuildProgressModal />
      <CopyOrdersModal />
    </Stack>
  )
}
