import { Separator } from '@/components/Separator'

import { NavigoContainer } from '../../elements/NavigoContainer'
import { useResetOnUnmount } from '../../hooks/useResetOnUnmount'
import { DepotsNavigator } from './components/DepotsNavigator'
import { Details } from './components/Details'
import { useNavigoView } from './hooks/useNavigoView'
import { MapStyle } from './views/MapStyle'
import { Overview } from './views/Overview'

export function Depots() {
  const { view } = useNavigoView()
  useResetOnUnmount()

  return (
    <NavigoContainer data-testid="navigo-depots" data-trackid="navigo-depots">
      <DepotsNavigator />
      <Details />
      <Separator />
      {view === 'overview' && <Overview />}
      {view === 'mapStyle' && <MapStyle />}
    </NavigoContainer>
  )
}
