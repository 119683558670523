import { styled } from '@mui/material/styles'
import { ButtonBase, Box } from '@mui/material'
import { theme } from '@local/components'

interface StyledProps {
  $active?: boolean
  $disabled?: boolean
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: prop => typeof prop === 'string' && !prop.startsWith('$'),
})<StyledProps>(({ theme: muiTheme, $disabled }) => ({
  marginBottom: muiTheme.spacing(2),
  opacity: $disabled ? 0.5 : 1,
  textAlign: 'left',
  width: '100%',
}))

export const StyledContainer = styled(Box)<StyledProps>(({ theme: muiTheme, $active }) => ({
  backgroundColor: $active ? theme.colors.$scienceBlue : theme.colors.$alabaster,
  color: $active ? theme.colors.$pureWhite : theme.colors.$nightRider,
  padding: muiTheme.spacing(2),
  borderRadius: 4,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const Title = styled('div')({
  fontWeight: theme.weights.$semiBold,
  fontSize: theme.fonts.$xl,
  marginBottom: 2,
})

export const Subtitle = styled('div')<StyledProps>(({ $active }) => ({
  color: $active ? theme.colors.$pureWhite : theme.colors.$shadyLady,
  fontSize: theme.fonts.$s,
  lineHeight: 1.25,
}))

export const IconContainer = styled(Box)<StyledProps>(({ $active }) => ({
  color: $active ? theme.colors.$pureWhite : theme.colors.$pigmentGreen,
}))
