import { differenceInHours } from 'date-fns/esm'
import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { selectLivePositions } from '@/features/domain/device'
import { selectDrivers } from '@/features/domain/driver'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectGpsTrackingProvider, selectUserConfiguration } from '@/features/domain/user'
import { Text } from '@/local/components'
import { useComponentSize } from '@/local/hooks'
import {
  TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID,
  conversionUtils,
  geo,
  getDeviceId,
  timeUtils,
} from '@/server-data'

import { RenderFrom } from '../../../../../../components/RenderFrom'

import { Box, Stack } from '@mui/material'

import { TrackingStatus } from './components/TrackingStatus'
import { useTexts } from './useTexts'

interface Props {
  vehicle: uui.domain.client.UnifiedVehicle
}

const growStyle = { height: 46 }
const HISTORICAL_GPS_POSITION_THRESHOLD_HOURS = 48

export function VehicleInfo(props: Props) {
  const { vehicle: uv } = props

  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const calendarRange = useSelector(selectCalendarRange)
  const livePositions = useSelector(selectLivePositions)
  const userConfig = useSelector(selectUserConfiguration)
  const drivers = useSelector(selectDrivers)
  const texts = useTexts()

  const ref = useRef<HTMLDivElement | null>(null)
  const [{ width }] = useComponentSize(ref)

  const deviceId = getDeviceId(drivers, uv, trackingProvider, calendarRange.minDate)
  if (!deviceId) return null

  const lastPosition = livePositions[deviceId]
  if (!lastPosition) return null

  const isHistorical =
    differenceInHours(new Date(), new Date(lastPosition.ts)) >
    HISTORICAL_GPS_POSITION_THRESHOLD_HOURS

  const lastReport = new Date(lastPosition.ts)
  const time = timeUtils.formatTime(userConfig)(lastReport, true)
  const date = timeUtils.formatDate(userConfig)(lastReport)

  const showExtraStatus = !isHistorical

  let extraStatus = ''

  // If the vehicle is tracked via mobile app we should not show the odometer since it's not possible to determine
  // the correct value of the odometer for a vehicle
  const canShowOdometer = uv.hasRoutingLicense
    ? uv.vehicle.gpsDeviceId && uv.vehicle.gpsDeviceId !== TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID
    : false

  if (showExtraStatus) {
    switch (lastPosition.status) {
      case 'moving':
        const speed = conversionUtils.convertGpsSpeed(lastPosition.speed, userConfig)
        extraStatus =
          lastPosition.heading === -1
            ? `(${speed})`
            : `(${geo.computeCardinalDirection(lastPosition.heading)} • ${speed})`
        break

      case 'stopped':
        if (
          (lastPosition as uui.domain.client.gps.wwgps.GpsInfo).stopTimestamp ??
          lastPosition.ts
        ) {
          extraStatus = `(${texts.since} ${timeUtils.formatDate(userConfig)(lastReport)})`
        }
        break
    }
  }

  return (
    <Box
      data-trackid="navigo-vehicle-overview-info"
      data-testid="navigo-vehicle-overview-info"
      width="100%"
      flex="0 1 auto"
      sx={growStyle}
      ref={ref}
    >
      <Stack direction="row" height="100%" width="100%">
        <Stack
          direction="column"
          height="100%"
          justifyContent="space-between"
          width="auto"
          flexShrink={0}
        >
          <Stack
            direction="row"
            height="100%"
            width="100%"
            alignItems="center"
            data-trackid="navigo-vehicle-overview-info-vehicleMovement"
            data-testid="navigo-vehicle-overview-info-vehicleMovement"
          >
            <TrackingStatus lastPosition={lastPosition} />

            <Text size="$p3" weight="$semiBold">
              {texts.status(lastPosition)}&nbsp;
            </Text>
            <Text size="$p3">{extraStatus}</Text>
          </Stack>

          <Stack
            direction="row"
            height="100%"
            width="100%"
            alignItems="center"
            data-trackid="navigo-vehicle-overview-info-lastPosition"
            data-testid="navigo-vehicle-overview-info-lastPosition"
          >
            <Text size="$p3">{texts.lastReport}:&nbsp;</Text>

            <Text size="$p3" weight="$semiBold">
              {`${time} • ${date}`}
            </Text>
          </Stack>
        </Stack>

        {canShowOdometer && lastPosition.odometer !== -1 && (
          <RenderFrom width={width} renderFrom={650}>
            <Stack
              direction="column"
              height="100%"
              justifyContent="space-between"
              width="auto"
              marginLeft={3}
              flexShrink={0}
            >
              <Stack
                direction="row"
                alignItems="center"
                height="50%"
                width="100%"
                data-trackid="navigo-vehicle-overview-info-odometer"
                data-testid="navigo-vehicle-overview-info-odometer"
              >
                <Text size="$p3">{texts.odometer}:&nbsp;</Text>

                <Text size="$p3" weight="$semiBold">
                  <ConvertDistance meters={lastPosition.odometer} />
                </Text>
              </Stack>
            </Stack>
          </RenderFrom>
        )}
      </Stack>
    </Box>
  )
}
