import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'
import { selectRmVehicles } from '@/features/domain/vehicle'
import { naturalSort } from '@/server-data'

import { type DriverSelectOption } from '../../../typings'
import { useTexts } from './useTexts'

type DropdownOptionsByVehicleId = Record<
  string,
  {
    vehicleId: string
    options: DriverSelectOption[]
    label: string
    value: string
    color: string
    hasDevice: boolean
  }
>

export function useDropdownOptionsByVehicleId(assignments: Record<string, string | null>) {
  const driversMap = useSelector(selectDrivers)
  const vehiclesMap = useSelector(selectRmVehicles)
  const associationVehicleByDriverId = Object.entries(assignments).reduce<Record<string, string>>(
    (acc, [vehicleId, driverId]) => {
      if (driverId) {
        acc[driverId] = vehicleId
      }
      return acc
    },
    {},
  )
  const texts = useTexts()

  return useMemo(() => {
    const vehicles = Object.values(vehiclesMap).sort((v1, v2) =>
      naturalSort(v1.externalId, v2.externalId),
    )
    const drivers = Object.values(driversMap).sort((d1, d2) =>
      naturalSort(d1.driver.name, d2.driver.name),
    )

    // Let's add external driver to the array
    const driverIds = Object.keys(associationVehicleByDriverId)
    for (const driverId of driverIds) {
      // If is an external vehicle, skip it
      if (associationVehicleByDriverId[driverId].indexOf('-external') !== -1) continue

      // If isn't an external driver skip it
      if (driverId.indexOf('-external') === -1) continue

      drivers.push(getExternalDriver(driverId, texts.externalDriver))
    }

    return vehicles.reduce<DropdownOptionsByVehicleId>((acc, vehicle) => {
      const vehicleId = vehicle.id
      const value = assignments[vehicleId] ?? ''
      const color = vehicle.color
      const hasDevice = vehicle.gpsDeviceId !== undefined
      const options = computeOptionByVehicleId({
        vehicleId,
        associationVehicleByDriverId,
        assignments,
        drivers,
        vehiclesMap,
        texts,
      })
      acc[vehicleId] = {
        options,
        vehicleId,
        label: vehicle.externalId,
        value,
        color,
        hasDevice,
      }
      return acc
    }, {})
  }, [driversMap, texts, assignments, vehiclesMap, associationVehicleByDriverId])
}

interface Params {
  vehicleId: string
  drivers: uui.domain.client.rm.ExtendedDriver[]
  vehiclesMap: Record<string, uui.domain.client.rm.Vehicle>
  assignments: Record<string, string | null>
  associationVehicleByDriverId: Record<string, string>
  texts: ReturnType<typeof useTexts>
}

function getExternalDriver(id: string, name: string): uui.domain.client.rm.ExtendedDriver {
  return {
    id,
    type: 'free',
    driver: {
      id,
      name,
      password: '',
      mobileFutureDaysVisibility: 2,
      summarizeFutureMobileRoute: true,
      deployment: {
        email: '',
        gpsDeviceId: null,
      },
    },
  }
}

function computeOptionByVehicleId(params: Params): DriverSelectOption[] {
  const { drivers, associationVehicleByDriverId, texts, vehiclesMap } = params

  const result: DriverSelectOption[] = []
  const driversAlreadyInUse: DriverSelectOption[] = []
  const unassignedDrivers: DriverSelectOption[] = []

  for (const driver of drivers) {
    const vehicleName =
      vehiclesMap[associationVehicleByDriverId[driver.id]]?.externalId ?? undefined

    const alreadyInUse = !!associationVehicleByDriverId[driver.id]

    if (alreadyInUse) {
      driversAlreadyInUse.push({
        value: driver.id,
        label: driver.driver.name,
        disabled: driver.id.indexOf('-external') !== -1,
        extraLabel: vehicleName,
      })
    } else {
      unassignedDrivers.push({
        value: driver.id,
        label: driver.driver.name,
      })
    }
  }

  if (unassignedDrivers.length > 0) {
    result.push({
      value: 'unassigned-drivers',
      label: texts.unassignedDrivers,
    })
    result.push(...unassignedDrivers)
  }

  if (driversAlreadyInUse.length > 0) {
    result.push({
      value: 'drivers-already-in-use',
      label: texts.driversAlreadyInUse,
      tooltip: {
        title: texts.driversAlreadyInUseTooltipTitle,
        description: texts.driversAlreadyInUseTooltipDescription,
      },
    })
    result.push(...driversAlreadyInUse)
  }

  return result
}
