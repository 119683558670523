import { useCallback } from 'react'

import { ModalFooterButton } from '@/components/Modal'

import { useController } from '../../../hooks/useController'
import { useControllerActions } from '../../../hooks/useControllerActions'
import { useModalData } from '../../../hooks/useModalData'
import { useTexts } from '../../../useTexts'

import { useButtonClassName } from './hooks/useButtonClassName'

interface Props {
  variant?: 'text' | 'outlined' | 'contained'
}

export function ButtonDoNotMove(props: Props) {
  const { variant = 'text' } = props

  const ctrl = useController()
  const { move } = useControllerActions()
  const texts = useTexts()
  const data = useModalData()
  const { type, suffix } = useButtonClassName(data)
  const handleOnClick = useCallback(() => {
    move(false)
  }, [move])

  const { isExecuted, gotExecutedPaired } = data

  const className = `pendo-moving-${type}_dont-move${suffix}`
  const testid = `moving-${type}_dont-move${suffix}`
  const color = isExecuted || gotExecutedPaired ? 'error' : 'primary'

  return (
    <ModalFooterButton
      className={className}
      disabled={ctrl.status === 'pending'}
      variant={variant}
      onClick={handleOnClick}
      testid={testid}
      color={color}
    >
      {texts.btnDoNotMove(data.orderStep.type)}
    </ModalFooterButton>
  )
}
