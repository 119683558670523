import type { ReactElement } from 'react'

import { styled } from '@mui/material/styles'

import { Tooltip } from '@/components/primitives/Tooltip'
import { IconButton } from '@/components/primitives/buttons/IconButton'
import { Link } from '@/routing'
import { theme } from '@/styles'

interface Props {
  tooltipTitle: string
  tooltipSubtitle?: string
  className?: string
  Icon: ReactElement
  to: string
  testid?: string
}

const LinkWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',

  '&::after': {
    left: 0,
    bottom: 0,
    content: '" "',
    width: '100%',
    height: '5px',
    display: 'block',
    position: 'absolute',
    transform: 'scale(0, 1)',
    transformOrigin: 'left center',
    transition: 'transform 0.3s ease',
    backgroundColor: theme.colors.$pureWhite,
  },

  '.active &::after': {
    transform: 'scale(1, 1)',
  },
})

const StyledLink = styled(Link)({
  height: '100%',
})

const StyledIconButton = styled(IconButton)({
  height: 36,
  padding: '8px !important',
})

export function LinkIcon(props: Props) {
  const { to, className, tooltipTitle, tooltipSubtitle, Icon, testid } = props

  return (
    <StyledLink to={to} className={className}>
      <LinkWrapper>
        <Tooltip placement="bottom" title={tooltipTitle} subtitle={tooltipSubtitle}>
          <StyledIconButton data-testid={testid} data-trackid={testid}>
            {Icon}
          </StyledIconButton>
        </Tooltip>
      </LinkWrapper>
    </StyledLink>
  )
}
