import { Skeleton, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { selectUserAccountPreferences } from '@/features/domain/user'
import { Text } from '@/local/components'
import { timeUtils } from '@/server-data'

import { useTexts } from '../../../useTexts'

import { useComputeTravelledDistance } from '../hooks/useComputeTravelledDistance'

interface Props {
  routeId: string
  start: number
  end: number
  violations: uui.domain.rm.RouteViolations
}

function checkRouteVehicleViolationByType(
  violations: uui.domain.rm.RouteViolations,
  violationType: uui.domain.rm.RouteViolationsType,
) {
  const { vehicle } = violations

  if (vehicle) {
    return vehicle.some(
      (type: uui.domain.rm.RouteViolationsType): boolean => violationType === type,
    )
  }

  return false
}

export function WithTravelledDistance(props: Props) {
  const { start, end, routeId, violations } = props

  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const plannedStartTitle = `${texts.plannedStart}:`
  const plannedEndTitle = `${texts.plannedEnd}:`

  const plannedEndTitleColor = useMemo(() => {
    return checkRouteVehicleViolationByType(violations, 'TWE') ? '$outrageousRed' : '$pureBlack'
  }, [violations])

  const travelledDistanceResult = useComputeTravelledDistance(routeId)

  const startTime = timeUtils.formatSecondsFromMidnight(start, accountPreferences.timeFormat)
  const endTime = timeUtils.formatSecondsFromMidnight(end, accountPreferences.timeFormat)

  const canShowTravelledDistance =
    travelledDistanceResult.status === 'loading' ||
    (travelledDistanceResult.status === 'ok' && travelledDistanceResult.value !== -1)

  return (
    <Stack
      direction="column"
      data-trackid="navigo-routes-overview-details-single-routeStats-timeBlock"
      data-testid="navigo-routes-overview-details-single-routeStats-timeBlock"
      justifyContent="space-between"
      marginLeft={3}
      height="100%"
      width="auto"
      flexShrink={0}
    >
      <Stack direction="row" width="100%" height="auto">
        {canShowTravelledDistance && (
          <Stack direction="row" alignItems="flex-start" marginRight={3}>
            <Text size="$p3">{texts.travelledDistance}:&nbsp;</Text>
            {travelledDistanceResult.status === 'loading' ? (
              <Skeleton variant="text" width={50} height={14} />
            ) : (
              <Text
                testid="navigo-routes-overview-details-single-routeStats-timeBlock-startTime"
                size="$p3"
                weight="$semiBold"
              >
                <ConvertDistance meters={travelledDistanceResult.value} />
              </Text>
            )}
          </Stack>
        )}

        <Text size="$p3">{plannedEndTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-endTime"
          color={plannedEndTitleColor}
          weight="$semiBold"
          size="$p3"
        >
          {endTime}
        </Text>
      </Stack>

      <Stack direction="row" width="100%" height="auto">
        <Text size="$p3">{plannedStartTitle}&nbsp;</Text>

        <Text
          testid="navigo-routes-overview-details-single-routeStats-timeBlock-startTime"
          size="$p3"
          weight="$semiBold"
        >
          {startTime}
        </Text>
      </Stack>
    </Stack>
  )
}
