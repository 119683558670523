import type { FormErrors, FormFields } from '../../formFields'

import { TextField } from '@workwave-tidal/form-ui'

import { useTexts } from './hooks/useTexts'

export function AutomatedMessageField() {
  const texts = useTexts()

  return (
    <TextField<'automatedMessage', FormFields, FormErrors>
      label={texts.automatedMessage.label}
      textfieldProps={{
        multiline: true,
        rows: 3,
      }}
      name="automatedMessage"
      validateOn="blur change"
    />
  )
}
