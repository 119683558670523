import { Stack } from '@mui/material'
import { useReducer } from 'react'

import { ExpandButton } from '@/components/Navigo/components/ExpandButton'

import { ExpandedActions } from './ExpandedActions'
import { useActions } from './hooks/useActions'
import { useShareLocation } from './hooks/useShareLocation'

export function Actions() {
  const [expanded, toggleExpanded] = useReducer(state => !state, true)
  const { currentDepots } = useActions()
  const { modal: shareLocationModal } = useShareLocation()

  if (currentDepots.length === 0) return null

  return (
    <Stack direction="row" width="auto" alignItems="center" height="100%">
      {expanded && <ExpandedActions />}

      <ExpandButton expanded={expanded} toggleExpanded={toggleExpanded} />
      {shareLocationModal}
    </Stack>
  )
}
