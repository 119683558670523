import { MapControls } from '@/components/MapControls'

import { Map } from './components/Map'
import { DevicesRoot } from './components/form/DevicesRoot'

import { useEnsureDeviceVisibility } from './hooks/useEnsureDeviceVisibility'

import { Box, Stack } from '@mui/material'

export function Devices() {
  useEnsureDeviceVisibility()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="setup-devices-root">
      <DevicesRoot />

      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%">
          <MapControls />
          <Map />
        </Stack>
      </Box>
    </Stack>
  )
}
