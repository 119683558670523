import { produceWithPatches, castDraft } from 'immer'

import { computeTags } from './computeTags'
import { patchSpeedMonitor } from './patchSpeedMonitor'
import { areGpsTagsChanged } from './areGpsTagsChanged'
import { patchDriverBehaviors } from './patchDriverBehaviors'
import { computeChangesSummary } from './computeChangesSummary'
import { patchDeviceConfiguration } from './patchDeviceConfiguration'
import { computeIdleStartThreshold } from './computeIdleStartThreshold'

export function patchGpsData(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  formValues: uui.domain.ui.forms.GpsVehicleFormValues,
  metric: boolean,
) {
  if (!unifiedVehicle.isPhysicalDevice) {
    throw new Error('physical device is required to patch gps data')
  }

  const [result, patches] = produceWithPatches(unifiedVehicle, draft => {
    // update gps data
    if (draft.trackingProvider === 'WWGPS') {
      draft.device.iconColor1 = formValues.gps.color.color.slice(1)
      draft.device.label = formValues.gps.name
      draft.device.iconId = formValues.gps.icon.icon

      // update device data
      if (formValues.device.hasDriverBehaviorCapability) {
        draft.driverBehaviors = patchDriverBehaviors(
          draft.driverBehaviors,
          formValues.device,
          metric,
        )
      }

      draft.speedMonitor = patchSpeedMonitor(
        draft.speedMonitor,
        formValues.device,
        unifiedVehicle.device.deviceId,
      )
      draft.idleStartThreshold = computeIdleStartThreshold(formValues.device)

      draft.deviceConfiguration = patchDeviceConfiguration(
        draft.deviceConfiguration,
        formValues.device,
      )
    }

    if (draft.allowGps) {
      const nextTags = computeTags(formValues.device)
      if (areGpsTagsChanged(draft.gpsTags, nextTags)) {
        draft.gpsTags = nextTags
      }
    }
  })

  return { unifiedVehicle: castDraft(result), summary: computeChangesSummary(patches) }
}
