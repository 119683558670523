import localforage from 'localforage'

export async function readFromPersistentStorage<T>(key: string) {
  const value = await localforage.getItem<T>(key)

  if (!value) return

  return value
}

export async function writeToPersistentStorage<T>(key: string, value: T) {
  await localforage.setItem<T>(key, value)
}

export async function removeFromPersistentStorage(key: string) {
  await localforage.removeItem(key)
}
