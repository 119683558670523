import type { Option } from './types'
import { Box, FormHelperText, Radio, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledFormControlLabel = styled(FormControlLabel)({
  alignItems: 'flex-start',
})

interface Props<VALUE extends string> {
  option: Option<VALUE>
}

export function RadioGroupOption<VALUE extends string>(props: Props<VALUE>) {
  const { value, disabled, label, description } = props.option

  return (
    <StyledFormControlLabel
      control={<Radio />}
      value={value}
      disabled={disabled}
      label={
        <>
          <Box paddingTop="9px">{label}</Box>
          {description && <FormHelperText>{description}</FormHelperText>}
        </>
      }
    />
  )
}
