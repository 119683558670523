import { useCallback } from 'react'

import { DropdownMenuItem } from '@/components/DropdownMenu'
import { useGpsReportsLink } from '@/routing'

import { useTexts } from '../../../useTexts'
import { getSelectedDeviceIds } from '../../../utils/getSelectedDeviceIds'
import { ActiveIcon } from './ActiveIcon'

import { StyledButton, StyledLink } from './hooks/useStyles'

export function GpsOnlyLinks() {
  const { gpsReportsLink, createGpsReportsCookie } = useGpsReportsLink()
  const texts = useTexts()

  const onGpsReportClick = useCallback(() => {
    createGpsReportsCookie(getSelectedDeviceIds())
  }, [createGpsReportsCookie])

  return (
    <>
      <StyledLink
        to="tracking"
        data-testid="header__nav-tracking-button-link"
        className={'pendo-bluebar__tracking-list-menu'}
      >
        <DropdownMenuItem
          data-testid="header__nav-tracking-button-content"
          data-trackid="gpsonly-header__nav-tracking-button-content"
        >
          <ActiveIcon />
          {texts.tracking}
        </DropdownMenuItem>
      </StyledLink>

      <StyledButton
        href={gpsReportsLink}
        target="_blank"
        data-testid="header__nav-reports-button-link"
        className={'pendo-bluebar__GPS-reports-menu'}
        onClick={onGpsReportClick}
      >
        <DropdownMenuItem
          data-testid="header__nav-reports-button-content"
          data-trackid="gpsonly-header__nav-reports-button-content"
        >
          <ActiveIcon />
          {texts.reports}
        </DropdownMenuItem>
      </StyledButton>
    </>
  )
}
