import { useSelector } from 'react-redux'
import { Divider, Stack } from '@mui/material'
import { FormHeader } from '@workwave-tidal/form-ui/layout'

import {
  ViewContent,
  ViewActions,
  ViewContainer,
  ViewFieldsLayout,
  ViewActionButton,
} from '@/formUi'

import {
  selectReasonsForNotCompletedOrders,
  selectTerritories,
  selectTerritory,
} from '@/features/domain/territory'

import { StartEndRoute } from './StartEndRoute/StartEndRoute'
import { DynamicLoadsCfView } from './DynamicLoadsCfView/DynamicLoadsCfView'
import { ReasonsForNotCompletedOrders } from './ReasonsForNotCompletedOrders/ReasonsForNotCompletedOrders'
import { useTexts } from './hooks/useTexts'

interface Props {
  onEdit: () => void
}

export function DriverActivityView(props: Props) {
  const { onEdit } = props
  const texts = useTexts()

  const reasonsForNotCompletedOrders = useSelector(selectReasonsForNotCompletedOrders)

  const territories = useSelector(selectTerritories)
  const territory = useSelector(selectTerritory)

  const singleTerritory = Object.keys(territories).length === 1

  return (
    <ViewContainer testid="driver-activity-view">
      <ViewContent>
        <FormHeader
          title={texts.headerTitle}
          subtitle={texts.headerDescription}
          testId="driver-activity-header"
        />
        <ViewFieldsLayout>
          <Stack spacing={4}>
            <ViewActions paddingBottom={0}>
              <ViewActionButton
                label={texts.edit}
                onClick={onEdit}
                testId="driver-activity-edit-btn"
              />
            </ViewActions>

            <StartEndRoute
              singleTerritory={singleTerritory}
              startEndRouteActive={territory.mobileAppsTrackingType === 'ORDERSTEPS_AND_BOUND'}
            />

            <DynamicLoadsCfView territory={territory} hasOnlyOneTerritory={singleTerritory} />

            <Divider />

            <ReasonsForNotCompletedOrders
              territories={territories}
              reasonsForNotCompletedOrders={reasonsForNotCompletedOrders}
            />
          </Stack>
        </ViewFieldsLayout>
      </ViewContent>
    </ViewContainer>
  )
}
