import type { FormErrors, FormFields } from '../formFields'
import { useCallback } from 'react'
import { useFormField } from '@workwave-tidal/form-fairy'

export function useActions() {
  const {
    field: { value },
    fieldApi: { change: apiChange, validate },
  } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )

  const addReason = useCallback(() => {
    apiChange(['', ...value])
    validate()
  }, [apiChange, validate, value])

  const removeAll = useCallback(() => {
    apiChange([])
    validate()
  }, [validate, apiChange])

  const sortASC = useCallback(() => {
    const updated = [...value]
    updated.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    apiChange(updated)
    validate()
  }, [value, apiChange, validate])

  const sortDESC = useCallback(() => {
    const updated = [...value]
    updated.sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))
    apiChange(updated)
    validate()
  }, [value, apiChange, validate])

  const saveReasons = (save: (value: string[]) => void) => {
    const nonEmptyReasons = value.filter(reason => !!reason)
    save(nonEmptyReasons)
  }

  return {
    addReason,
    removeAll,
    sortASC,
    sortDESC,
    saveReasons,
  }
}
