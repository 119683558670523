import { useCallback } from 'react'

import { useMainSelection } from '@/atoms'
import { useRoutingLayout } from '@/hooks'

import { useSearchedOrderStepIds } from './hooks/useFilteredOrderStepIds'
import { useSetQuery } from './hooks/useSetQuery'

import { SearchOrdersBar } from './SearchOrdersBar'

export function SearchOrdersBarProvider() {
  const [, selectOrderSteps] = useMainSelection('orderSteps')
  const [layout, setLayout] = useRoutingLayout()

  const { filteredOrderStepIds, query } = useSearchedOrderStepIds()
  const setQuery = useSetQuery()

  const showScheduler = useCallback(
    (show: boolean) => {
      setLayout({ bottomElement: show ? 'scheduler' : 'orders-grid' })
    },
    [setLayout],
  )

  const showSearch = layout.mode !== 'split'

  return (
    <SearchOrdersBar
      filter={query}
      setFilter={setQuery}
      showSearch={showSearch}
      showSwitch
      showScheduler={showScheduler}
      selectOrderSteps={selectOrderSteps}
      filteredOrdersIds={filteredOrderStepIds}
    />
  )
}
