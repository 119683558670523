import { useCallback } from 'react'

import { createRegion, updateRegion } from '@/features/domain/region'

import { setCrudSelection, useListApi, useResetEditingState } from '@/atoms'
import { useIsUnmounted, useNotification } from '@/hooks'
import { useAppDispatch } from '@/store'

import type { RegionFormValues } from '../typings'
import { parseRegionFromFormValues } from '../utils'

export const useOnSubmit = () => {
  const stopEditing = useResetEditingState()
  const { scrollTo } = useListApi('regions')
  const isUnMounted = useIsUnmounted()
  const dispatch = useAppDispatch()
  const toast = useNotification()

  const onCreate = useCallback(
    async (formValues: RegionFormValues) => {
      try {
        const region = parseRegionFromFormValues(formValues)
        const thunkResult = await dispatch(createRegion(region))

        if (createRegion.rejected.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()

        if (isUnMounted()) return

        // FIXME: remove this condition once the backend UPSERT will return an ID on creation
        if (!thunkResult.payload) return

        setCrudSelection('regions', [thunkResult.payload])

        scrollTo(thunkResult.payload)
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnMounted, stopEditing, scrollTo, toast],
  )

  const onUpdate = useCallback(
    async (formValues: RegionFormValues) => {
      try {
        const region = parseRegionFromFormValues(formValues)
        const thunkResult = await dispatch(updateRegion(region))

        if (!updateRegion.fulfilled.match(thunkResult)) {
          throw new Error(thunkResult.payload?.message ?? 'Internal error')
        }

        stopEditing()
      } catch (e) {
        toast.error(e.message)
      }
    },
    [dispatch, isUnMounted, stopEditing, toast],
  )

  return useCallback(
    (formState: RegionFormValues) => (formState.id ? onUpdate(formState) : onCreate(formState)),
    [onCreate, onUpdate],
  )
}
