// CLSX (classnames replacement)
import { toggleCss as clsx } from './toggleCss'

import * as routingUtils from './routing'
export const is = {
  ...routingUtils.guards.uui,
}
export { clsx }

// UTILS
export { isValidSms } from './sms'
export { unproxify } from './unproxify'
export { resumeImport } from './resumeImport'
export { copyToClipboard } from './copyToClipboard'
export { setInitialValue } from './finalForm/mutators/setInitialValue'
export { getOperatingSystem } from './getOperatingSystem'
export { parseNotificationAddress } from './parseNotificationAddress'
export { getRelativeTimeDifferenceLabel } from './getRelativeTimeDifferenceLabel'
export { getViaDotNotation, setViaDotNotation } from './object'

export * as dateUtils from './date'
export * as fileUtils from './file'
export * as version from './version'
export * as colorsUtils from './colors'
export * as numberUtils from './numbers'
export * as regionUtils from './regions'
export * as locationUtils from './location'
export * as timeWindowUtils from './timeWindow'
export * as trafficAreasUtils from './trafficAreas'
export * as roadSegmentsUtils from './roadSegments'
export * as equalityUtils from './equality'

export * from './importWizardSession'
export * from './gpsReportsSession'

export { createMigrationToUiDataV2 } from './migrations/migrateUiDataV2'
