import type {
  FormFields,
  FormErrors,
} from '../../../../../../../notificationForms/sections/notifications/components/NotificationForm/formFields'

import { useEffect } from 'react'
import { useFormApi } from '@workwave-tidal/form-fairy'

/**
 * That hook will manage visibility/mandatory fields for TomorrowNotification
 */
export function useTomorrowNotificationInitForm() {
  const api = useFormApi<FormFields, FormErrors>()

  useEffect(() => {
    api.setFieldVisibleState('notificationCutoffMins', true)
  }, [api])
}
