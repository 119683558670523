import { Bulk } from './components/Bulk'
import { Single } from './components/Single'

import { Box } from '@mui/material'

interface Props {
  devices: uui.domain.client.gps.wwgps.DeviceInfo[]
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Details(props: Props) {
  const { devices } = props

  if (devices.length === 0) return null

  return (
    <Box
      data-trackid="navigo-device-overview-details"
      data-testid="navigo-device-overview-details"
      sx={growStyle}
      width="100%"
      flex="0 1 auto"
    >
      {devices.length > 1 ? <Bulk count={devices.length} /> : <Single device={devices[0]} />}
    </Box>
  )
}
