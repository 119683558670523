import { form } from '../formState'
import { useTexts } from '../hooks/useTexts'

export function AdditionalCost() {
  const texts = useTexts()

  form.useAddFieldValidation<'enterCost'>('enterCost', (field, _formApi) => {
    if (field.value < 0) {
      return texts.errorMinCost
    }
    if (field.value > 1_000_000) {
      return texts.errorMaxCost
    }
  })

  return (
    <form.CurrencyInput
      label={texts.labelCost}
      validateOn="change"
      name="enterCost"
      testId="region-enterCost"
    />
  )
}
