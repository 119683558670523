import { ActionBar } from '@workwave-tidal/core/components'

import { ActionBarItem } from './ActionBarItem'
import { CurrencyValue } from './CurrencyValue'
import { DurationValue } from './DurationValue'
import { Field } from './Field'
import { Header } from './Header'
import { Label } from './Label'
import { MultipleValues } from './MultipleValues'
import { NullValue } from './NullValue'
import { Subtitle } from './Subtitle'
import { Value } from './Value'

export const components = {
  ActionBar,
  ActionBarItem,
  CurrencyValue,
  DurationValue,
  Field,
  Header,
  Label,
  MultipleValues,
  NullValue,
  Subtitle,
  Value,
}
