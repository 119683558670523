import { type SchedulerEventModel, type SchedulerProConfig } from '@bryntum/schedulerpro'
import { addSeconds, differenceInSeconds, parse } from 'date-fns/esm'
import { toast } from 'react-toastify'

import { getMainSelection } from '@/atoms'
import { type MovePairedOrdersPayload } from '@/components/modals/MovePairedOrdersModal'
import { moveOrderStep } from '@/features/domain/order'
import { selectDayRangeInfo } from '@/features/domain/scheduler'
import { store } from '@/store'

type MoveOrderStepPayload = Parameters<typeof moveOrderStep>[0] & {
  paired?: uui.domain.client.rm.SchedulerEventPairedData
  edgeSeconds?: number
}

function sortEventsByStartDate(a: SchedulerEventModel, b: SchedulerEventModel) {
  // @ts-expect-error
  return a.startDate - b.startDate
}

function isCompatibleStep(event: SchedulerEventModel) {
  const type = event.getData('type') as uui.domain.client.rm.SchedulerEvent['type']
  const isUnApproved = event.getData('isApproved') === false
  const isCompatible = ['pickup', 'delivery', 'brk'].includes(type)
  return isUnApproved && isCompatible
}

// @return false if not paired, the payload otherwise
function computeMovePairedOrdersPayload(
  payload: MoveOrderStepPayload,
): false | MovePairedOrdersPayload {
  const { edgeSeconds, seconds, sourceRouteId, orderStepId, routeId, paired } = payload

  // not paired
  if (!paired) return false

  // before edge
  if (edgeSeconds !== undefined && seconds < edgeSeconds) return false

  // same route
  if (routeId === paired.pairedRouteId) return false

  return {
    seconds,
    orderStepId,
    sourceRouteId,
    targetRouteId: routeId,
    pairedExecuted: paired.pairedExecuted,
    pairedOrderStepId: paired.pairedOrderStepId,
    pairedOrderRouteId: paired.pairedRouteId,
  }
}

export function createOnBeforeEventDropFinalize(
  showMovePairedOrdersModal: (payload: MovePairedOrdersPayload) => void,
): SchedulerProConfig['onBeforeEventDropFinalize'] {
  return async event => {
    const {
      domEvent,
      // @ts-expect-error
      context: { origStart, newResource, eventRecords },
      source,
    } = event

    // eslint-disable-next-line no-param-reassign
    event.context.async = true

    const targetLocked = newResource.getData('routeLock')
    const targetAvailable = newResource.getData('available')
    const selectedOrderSteps = getMainSelection(true).orderSteps

    if (eventRecords.length !== 1 || targetLocked || !targetAvailable) {
      // eslint-disable-next-line no-param-reassign
      event.context.valid = false
      event.context.finalize(false)
      return
    }

    const { dayStartOffset } = selectDayRangeInfo(store.getState())

    const eventRecord = eventRecords[0]
    const resourceRecord = source.resourceStore.getById(eventRecord.resourceId)

    const day = parse(newResource.getData('dateAsString'), 'yyyyMMdd', new Date())
    day.setHours(0, 0, 0)

    const edgeStartDate = newResource.events.find(e => e.getData('type') === 'edge')?.startDate as
      | Date
      | undefined
    const edgeSeconds = edgeStartDate ? differenceInSeconds(edgeStartDate, day) : undefined

    let mouseDate = source.getDateFromDomEvent(domEvent)
    const eventDropSeconds = differenceInSeconds(mouseDate, source.startDate)

    // Since the mouse event is always set on the first day of calendar range, we need to set the date to the correct day.
    mouseDate.setFullYear(day.getFullYear(), day.getMonth(), day.getDate())
    // And the correct hours (seconds from midnight to drop point)
    mouseDate.setHours(0, 0, 0)
    mouseDate = addSeconds(mouseDate, dayStartOffset + eventDropSeconds)

    const seconds = differenceInSeconds(mouseDate, day)
    const routeId = newResource.id as string
    const orderStepId =
      selectedOrderSteps.length === 1 && eventRecord.getData('steps').length > 1
        ? selectedOrderSteps[0]
        : (eventRecord.id as string)
    const sourceRouteId = resourceRecord.id as string

    const paired = eventRecord.getData('pairedStepData') as
      | uui.domain.client.rm.SchedulerEventPairedData
      | undefined

    // If the poisition is the same as the original, we don't need to do anything.
    const sameRoute = routeId === sourceRouteId

    if (sameRoute) {
      const routeEvents = newResource.events
        .filter(e => e.id !== orderStepId && isCompatibleStep(e))
        .sort(sortEventsByStartDate) as SchedulerEventModel[]

      const newNextIndex = routeEvents.findIndex(e => {
        let eventStartDate = new Date(e.startDate)
        const eventSeconds = differenceInSeconds(eventStartDate, source.startDate)

        // Since the mouse event is always set on the first day of calendar range, we need to set the date to the correct day.
        eventStartDate.setFullYear(day.getFullYear(), day.getMonth(), day.getDate())

        // And the correct hours (seconds from midnight to the start of the event)
        eventStartDate.setHours(0, 0, 0)
        eventStartDate = addSeconds(eventStartDate, dayStartOffset + eventSeconds)
        return eventStartDate > mouseDate
      })
      const originalNextIndex = routeEvents.findIndex(e => e.startDate > origStart)

      if (originalNextIndex === newNextIndex) {
        event.context.finalize(false)
        return
      }
    }

    const movePairedOrdersPayload = computeMovePairedOrdersPayload({
      paired,
      seconds,
      routeId,
      edgeSeconds,
      orderStepId,
      sourceRouteId,
    })

    if (movePairedOrdersPayload) {
      showMovePairedOrdersModal(movePairedOrdersPayload)
      event.context.finalize(false)
      return
    }

    try {
      const result = await store.dispatch(
        moveOrderStep({
          orderStepId,
          sourceRouteId,
          routeId,
          seconds,
        }),
      )

      if (moveOrderStep.rejected.match(result)) {
        throw new Error(result.payload?.message ?? 'Internal error')
      }

      event.context.finalize(false)
    } catch (error) {
      toast.error(error.message)
      event.context.finalize(false)
    }
  }
}
