import type { FormFields, FormErrors } from '../../../formFields'
import type { FormValidator, FormError } from '@workwave-tidal/form-fairy'

import { useTexts } from '../useTexts'

const smsRegex: RegExp = /^\+\d{10,}$/

export const createValidateCompanyPhone =
  (texts: ReturnType<typeof useTexts>): FormValidator<FormFields, FormErrors> =>
  formApi => {
    const field = formApi.getField('companyPhone')
    const value = field.value.trim()

    const empty = value.length === 0
    const invalid = !empty && !smsRegex.test(value)

    const error: FormError<keyof FormFields> = {
      id: 'companyPhone-invalid',
      field: 'companyPhone',
      message: texts.companyPhoneInvalidFormat,
    }

    return {
      'companyPhone-invalid': invalid ? error : null,
    }
  }
