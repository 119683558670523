import type { TrafficAreaFormValues } from './types'

import { Form as FinalForm, FormRenderProps } from 'react-final-form'

import { FormContent } from '@/forms-legacy'
import { useEnvironmentQa, useTransactionState, useResetEditingState } from '@/atoms'
import { useFormTransaction } from '@/hooks'
import { FormLoading } from '@/components/FormLoading'
import { FormColumn } from '@/components/layout'

import { useFormValidation } from './hooks/useFormValidation'
import { useHideTrafficAreasWhileEditing } from './hooks/useHideTrafficAreasWhileEditing'
import { useOnSubmit } from './hooks/useOnSubmit'
import { useFormInitialValue } from './hooks/useFormInitialValue'
import { useComposeFieldsWithError } from './hooks/useComposeFieldsWithError'
import { Form } from './components/Form'
import { Footer } from './components/Footer'

interface Props {
  trafficProfile: uui.domain.client.rm.TrafficProfile
  creating?: boolean
}

export function SingleEdit(props: Props) {
  const { trafficProfile, creating } = props
  const initialValues = useFormInitialValue(trafficProfile, creating)
  const validateForm = useFormValidation()
  const composeFieldsWithError = useComposeFieldsWithError()
  const { onUpdate, submitting, onCreate } = useOnSubmit(trafficProfile)
  const { mode } = useTransactionState()
  const resetEditing = useResetEditingState()

  useHideTrafficAreasWhileEditing()

  const transactionState = useFormTransaction()
  const transactionOpen = transactionState === 'ready'

  const qa = useEnvironmentQa()

  const planLocked = mode === 'external'

  return (
    <FormColumn width={484} testid="form__column-primary">
      <FinalForm<TrafficAreaFormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={creating ? onCreate : onUpdate}
        render={(formRenderProps: FormRenderProps<TrafficAreaFormValues>) => {
          const { form, handleSubmit } = formRenderProps
          const { valid, pristine: rawPristine, errors = {} } = form.getState()

          const pristine = creating ? false : rawPristine

          const disabled = !valid || submitting || planLocked || pristine
          const fieldsWithError = composeFieldsWithError(errors)

          return (
            <FormContent
              qa={qa}
              footer={
                transactionOpen ? (
                  <Footer
                    creating={creating}
                    errors={errors}
                    fieldsWithError={fieldsWithError}
                    valid={valid}
                    planLocked={planLocked}
                    pristine={pristine}
                    saveDisabled={disabled}
                    onSubmit={handleSubmit}
                    onCancel={resetEditing}
                    submitting={submitting}
                  />
                ) : undefined
              }
            >
              {transactionOpen ? <Form initialValues={initialValues} /> : <FormLoading />}
            </FormContent>
          )
        }}
      />
    </FormColumn>
  )
}
