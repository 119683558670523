import type { PreferencesFormActions as Props } from '../types'
import type { FormFields, FormErrors } from './formFields'

import { Box } from '@mui/material'

import { useFormMeta } from '@workwave-tidal/form-fairy'
import { Form, FormHeader } from '@workwave-tidal/form-ui/layout'

import { useTexts } from '../hooks/useTexts'
import { FormFooter } from './FormFooter'

import { CompanyFromName } from './fields/CompanyFromName'
import { DateFormat } from './fields/DateFormat'
import { TimeFormat } from './fields/TimeFormat'
import { NotificationLanguage } from './fields/NotificationLanguage'

import { useFormValidation } from './hooks/useFormValidation'
import { useOnSubmit } from './hooks/useOnSubmit'

export function PreferencesForm(props: Props) {
  const { onCancel, supportedLanguages, updatePreferences } = props

  const {
    meta: { submitting },
  } = useFormMeta<FormFields, FormErrors>()

  const onSubmit = useOnSubmit(updatePreferences, onCancel)
  const texts = useTexts()
  useFormValidation()

  return (
    <Box width={442} height="100%" data-testid="form__column-secondary" display="flex" paddingY={2}>
      <Form
        testId="preferences-form"
        header={<FormHeader title={texts.headerTitle} subtitle={texts.headerDescription} />}
        footer={<FormFooter onSubmit={onSubmit} onCancel={onCancel} submitting={submitting} />}
        onEnter={onSubmit}
        fieldsSpacing={4}
        fieldsPadding="0 32px"
      >
        <NotificationLanguage supportedLanguages={supportedLanguages} submitting={submitting} />
        <CompanyFromName submitting={submitting} />
        <TimeFormat submitting={submitting} />
        <DateFormat submitting={submitting} />
      </Form>
    </Box>
  )
}
