import type { FormValidator } from '@workwave-tidal/form-fairy'
import type { FormErrors, FormFields } from '../../../hooks/formFields'

import { useCallback } from 'react'
import { useTexts } from '../../../useTexts'

const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function useEmailValid(): FormValidator<FormFields, FormErrors> {
  const texts = useTexts()

  return useCallback(
    async formApi => {
      // access the current name form-field
      const email = formApi.getField('email').value.trim()

      // Formal validation
      const formallyValid = emailRegex.test(email)

      return {
        emailValidError: formallyValid
          ? null
          : {
              id: 'emailValidError',
              message: texts.emailInvalid,
              field: 'email',
              priority: 10,
            },
      }
    },
    [texts],
  )
}
