import { Box, Stack, Typography, svgIconClasses, useTheme } from '@mui/material'
import { type ReactNode } from 'react'

import { Tooltip } from '@/components/primitives/Tooltip'

export type Props = {
  testId?: string
  children: ReactNode
  indent?: boolean
  icon?: ReactNode
  // if empty the text become gray, the content should be '-'
  empty?: ReactNode

  showTooltip?: boolean
}

const boxSx = {
  [`& > .${svgIconClasses.root}`]: {
    fontSize: 12,
    display: 'block',
  },
}

export function ListItemContent(props: Props) {
  const { icon, testId = 'ListItemContent', children, indent, empty } = props
  const {
    palette: { action, text },
  } = useTheme()

  const iconHidden = !icon && !indent

  const showTooltip = !!props.showTooltip && typeof children === 'string' && children.length > 0

  return (
    <Tooltip
      placement="bottom"
      disabled={!showTooltip}
      title={typeof children === 'string' ? children : ''}
      maxWidth="none"
    >
      <Stack data-testid={testId} direction="row" alignItems="center" gap={0.5} width="100%">
        <Box
          hidden={iconHidden}
          width={12}
          height={12}
          overflow="hidden"
          color={empty ? action.disabled : action.active}
          sx={boxSx}
          flexShrink={0}
        >
          {icon}
        </Box>

        <Typography
          variant="caption"
          data-testid="ListItemContentLabel"
          noWrap
          color={empty ? text.disabled : text.secondary}
        >
          {children}
        </Typography>
      </Stack>
    </Tooltip>
  )
}
