import type { FormFields, FormErrors } from '../formFields'
import type { FormConfig } from '@workwave-tidal/form-fairy'

import { useState } from 'react'

import { createFormValidation } from '../validations/createFormValidation'

export function useFormConfig(): FormConfig<FormFields, FormErrors> {
  // since the formConfig must not change (the form library will throw an error otherwise)
  // use the lazy initialization of the useState hook
  const [formConfig] = useState(() => ({
    validations: createFormValidation(),
  }))

  return formConfig
}
