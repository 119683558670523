import type { ValidationSummaryItem } from '@workwave-tidal/form-ui/utils/ValidationSummary'
import type { LazyCreateFormConfig } from '@workwave-tidal/form-fairy'
import type { FormFields, FormErrors } from '../../formFields'

import { useState } from 'react'

import { useStandardPasswordValidations } from '@/hooks'

export function useCreateFormConfig(): {
  createFormConfig: LazyCreateFormConfig<FormFields, FormErrors>
  validationSummaryItems: ValidationSummaryItem<FormFields>[]
} {
  // New Password validations
  const { validations, validationSummaryItems } = useStandardPasswordValidations<
    FormFields,
    FormErrors
  >('new-password')

  const [createFormConfig] = useState<LazyCreateFormConfig<FormFields, FormErrors>>(() => {
    return { validations }
  })

  return { createFormConfig, validationSummaryItems }
}
