import { MapControls } from '@/components/MapControls'
import { Navigo } from '@/components/Navigo'
import { useResetOrdersFilterOnMount } from '@/hooks'

import { Box, Stack } from '@mui/material'

import { Map } from './components/Map'
import { TrackingList } from './components/TrackingList'

export function Tracking() {
  useResetOrdersFilterOnMount()

  return (
    <Stack direction="row" height="100%" width="100%" data-testid="tracking-root">
      <TrackingList />
      <Box width="100%" height="100%" flex="0 1 auto">
        <Stack direction="column" height="100%" width="100%" flexShrink={0}>
          <MapControls enableInspectPin />
          <Map />
          <Navigo />
        </Stack>
      </Box>
    </Stack>
  )
}
