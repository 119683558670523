import { FormError, FormField } from '@workwave-tidal/form-fairy'

const label: FormField<string> = {
  value: '',
  required: true,
}

const tenantSourceId: FormField<string> = {
  value: '',
  required: true,
}

export const formFields = {
  label,
  tenantSourceId,
}

export type FormFields = typeof formFields
export interface FormErrors extends FormError<keyof FormFields> {}
