import { createRef, Component, RefObject } from 'react'

import {
  InputFieldTextProps,
  Field as UiField,
  InputFieldText,
  InputFieldTextBig,
} from '@/forms-legacy'

import { FieldConnectorProps, FieldProps } from './typings'

export type Props = FieldConnectorProps<InputFieldTextProps> & {
  maxChars?: number
}

export type InputFieldTextConnectorProps = Pick<
  InputFieldTextProps,
  'type' | 'showResetIcon' | 'readonly' | 'tabIndex' | 'useBigField'
>

export default class InputTextFieldConnector extends Component<Props> {
  private inputRef: RefObject<HTMLInputElement> = createRef()

  getInputRef = (): workwave.NullableHTMLInputElement => {
    if (this.inputRef?.current) {
      return this.inputRef.current
    }
  }

  getErrorText = (): string | undefined => {
    const {
      extraFieldProps: { error: extraFieldPropsError },
      meta: { error: metaError },
    } = this.props

    let error

    if (metaError) {
      error = metaError
    }

    if (extraFieldPropsError) {
      error = extraFieldPropsError
    }

    return error
  }

  handleOnChange = (value: string) => {
    const {
      input: { onChange },
      maxChars = null,
    } = this.props

    onChange(typeof maxChars === 'number' ? value.substr(0, maxChars) : value)
  }

  render() {
    const {
      input: { name, value },
      extraInputProps,
      extraFieldProps,
    } = this.props
    if (!extraInputProps) {
      throw new Error('Missing extraInputProps in InputTextFieldConnector')
    }

    const { useBigField = false, ...rest } = extraInputProps
    const inputProps: InputFieldTextProps = {
      ...rest,
      name,
      onChange: this.handleOnChange,
      value,
    }

    const uiFieldProps: FieldProps = {
      ...extraFieldProps,
      error: this.getErrorText(),
    }
    const Comp = useBigField ? InputFieldTextBig : InputFieldText
    return (
      <UiField {...uiFieldProps}>
        <Comp {...inputProps} innerRef={this.inputRef} />
      </UiField>
    )
  }
}
