import type { FormFields, FormErrors } from '../formFields'

import { Box } from '@mui/material'
import { WarningCard } from '@local/components'
import { useFormField } from '@workwave-tidal/form-fairy'
import { SimpleSelect } from '@workwave-tidal/form-ui'
import { WarningTriangle } from '@/icons'

import { useTexts } from '../../hooks/useTexts'
import { useLanguageOptions } from '../hooks/useLanguageOptions'

interface Props {
  supportedLanguages: Record<string, string>
  submitting: boolean
}

export function NotificationLanguage(props: Props) {
  const texts = useTexts()
  const options = useLanguageOptions(props.supportedLanguages)

  const { field } = useFormField<'notificationLanguage', FormFields, FormErrors>(
    'notificationLanguage',
  )
  const fieldValueChanged = field.initialValue !== field.value

  return (
    <Box paddingTop={1}>
      {/* Please remove the above <Box /> when https://workwave.atlassian.net/browse/CUI-419 will be fixed */}
      <SimpleSelect<'notificationLanguage', string, FormFields, FormErrors>
        validateOn="blur focus"
        disabled={props.submitting}
        options={options}
        label={texts.notificationLanguage}
        name="notificationLanguage"
        testId="notificationLanguage"
      />
      {fieldValueChanged && (
        <Box paddingTop={1}>
          <WarningCard
            title={texts.cardTitle}
            description={texts.cardDescription}
            preset="alert"
            Icon={<WarningTriangle size={14} color="$pureWhite" background="$darkOrange" />}
          />
        </Box>
      )}
    </Box>
  )
}
