import { Typography } from '@mui/material'
import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}
export function Subtitle(props: Props) {
  const { children } = props
  return (
    <Typography variant="subtitle3" color="text.primary">
      {children}
    </Typography>
  )
}
