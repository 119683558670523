import { ReactNode, ReactElement, useState } from 'react'

import { Scrollbar } from '@workwave-tidal/core/components'

import { FlexBox } from '@/local/components'

type Props = {
  footer?: ReactElement
  children?: ReactNode

  // enable test actions
  testId?: string
  qa?: 'enable' | 'disable'
}

export function FormContent(props: Props) {
  const { footer, children, testId } = props

  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)

  return (
    <>
      <FlexBox column grow={1} shrink={0} w="100%" h="auto" position="relative">
        <Scrollbar
          hideOverflow="x"
          testId={testId}
          onVerticalVisibilityChange={setScrollbarVisible}
        >
          <>
            {children}
            {!scrollbarVisible && footer}
          </>
        </Scrollbar>
      </FlexBox>
      {scrollbarVisible && footer}
    </>
  )
}
